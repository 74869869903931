import {
    STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__INFO_TRESEN,
    STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__INFO_TRESEN_MIT_BERUF_INFOS,
    STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__PRAESENTATION_MODUL,
    STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_150,
    STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_50,
    STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_50_MIT_BERUF_INFOS,
} from '../../../backend-id-constants';

export function filterInfoDesks(features) {
    return features.filter(
        (feature) => isInfoDesk(feature) || isInfoDeskWithJobInfo(feature),
    );
}

export function filterPresentationModules(features) {
    return features.filter((feature) => isPresentationModule(feature));
}

export function filterSteles(features) {
    return features.filter((feature) => isStele(feature));
}

function isStringInFeatureType(feature, featureTypeString) {
    return (
        feature.idAusstattungTyp &&
        feature.idAusstattungTyp > 0 &&
        feature.txtAusstattungTyp &&
        feature.txtAusstattungTyp.includes(featureTypeString)
    );
}

function hasFeatureTypeId(feature, featureTypeId) {
    return (
        feature.idAusstattungTyp && feature.idAusstattungTyp === featureTypeId
    );
}

export function isStele(feature) {
    return (
        hasFeatureTypeId(
            feature,
            STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_150,
        ) ||
        hasFeatureTypeId(
            feature,
            STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_50,
        ) ||
        hasFeatureTypeId(
            feature,
            STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_50_MIT_BERUF_INFOS,
        )
    );
}

// Immer false, weil die Boards nicht mehr in den Austtattungen bearbeitet werden (?!)
export function isBoard(_feature) {
    return false;
}

export function isInfoDesk(feature) {
    return hasFeatureTypeId(
        feature,
        STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__INFO_TRESEN,
    );
}

export function isInfoDeskWithJobInfo(feature) {
    return hasFeatureTypeId(
        feature,
        STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__INFO_TRESEN_MIT_BERUF_INFOS,
    );
}

export function isPresentationModule(feature) {
    return hasFeatureTypeId(
        feature,
        STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__PRAESENTATION_MODUL,
    );
}

export function isSteleOrBoard(feature) {
    return isStele(feature) || isBoard(feature);
}

export function isInfoDeskOrPresentationModule(feature) {
    return (
        isInfoDesk(feature) ||
        isInfoDeskWithJobInfo(feature) ||
        isPresentationModule(feature)
    );
}
