import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';

import StepButtons from '../../common/stepbuttons';
import style from '../../common/index.module.css';

import Question from '../../common/question';

class Login extends Question {
    constructor(props) {
        super(props);
        this.state = {
            valid: false,
        };
        this.input = React.createRef();
        this.location = window.location.origin;
    }
    componentDidMount() {
        this.props.dispatch({
            type: 'LOGOUT',
        });
        if (this.props.loginkey) {
            this.props.change('loginkey', this.props.loginkey);
        }
        this.validate(this.input.current?.value);
    }
    validate(value) {
        const valid = value && value.length > 5;
        this.setState({
            valid: valid,
        });
        return valid;
    }
    continue(e) {
        e.preventDefault();
        if (this.validate(this.input.current?.value)) {
            this.props.dispatch(push('/check'));
        }
    }
    renderQuestion() {
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>Herzlich Willkommen bei der IdeenExpo!</h1>
                </div>
                <form method='post' action={`${this.location}/#/check`}>
                    <fieldset>
                        <legend>
                            Geben Sie bitte den <b>persönlichen Zugangscode</b>{' '}
                            ein, den Sie erhalten haben, um den Anmeldevorgang
                            zu beginnen.
                        </legend>
                        <Field
                            component='input'
                            name='loginkey'
                            className={classNames({
                                invalid: !this.state.valid,
                            })}
                            type='text'
                            placeholder='persönlicher Zugangscode'
                            ref={this.input}
                            onInput={(e) => this.validate(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    this.continue(e);
                                }
                            }}
                            autoFocus
                        />
                    </fieldset>
                </form>
                <StepButtons to='/check' disabled={!this.state.valid} />
            </div>
        );
    }
}

export default reduxForm({
    form: 'login',
    destroyOnUnmount: false,
})(connect()(Login));
