import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { getEntity } from '../../../../../../actions/exhibitor/exhibitor-actions';
import { mapEntriesByKey } from '../../../../../../actions/common/extern-id-mapper';
import FormButtons from '../../../../formbuttons';
import style from '../../../../index.module.css';
import { store } from '../../../../../../store.js';
import disableForm from '../../../../disableForm.js';
import exhibitSubmit from '../submit.js';
import {
    validateForm,
    showAlert,
    ERROR,
    ERROR_MSG_BACKEND_FETCH,
} from '../../../../alerts';

class ExhibitEditBasis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            submitSuccess: false,
            values: { files: this.props.files || {} }, // Files
        };
        this.input = React.createRef();
        this.textarea = React.createRef();
    }
    handleInput(_e) {
        this.setState({ valid: true });
    }
    resetForm() {
        this.setState({
            submitSuccess: false,
        });
    }
    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                // get company2person, when there are none in Redux
                // these are all persons that are connected to the company
                // of the user that is logged in
                if (
                    this.props.company2person &&
                    !Object.keys(this.props.company2person).length
                ) {
                    return this.props
                        .dispatch(getEntity('company2person'))
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_FETCH }),
                        );
                }
            })
            .then(() => {
                if (
                    this.props.person &&
                    !Object.keys(this.props.person).length
                ) {
                    return Promise.all(
                        this.props.company2person.map((item) => {
                            return new Promise((resolve, reject) => {
                                return this.props
                                    .dispatch(
                                        getEntity('person', {
                                            ID: item.idPerson,
                                        }),
                                    )
                                    .then((result) => {
                                        resolve(result);
                                    })
                                    .catch((result) => {
                                        reject(result);
                                    });
                            });
                        }),
                    );
                } else {
                    return Promise.resolve();
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    componentDidMount() {
        if (this.props.initialValues['status'] === 'freigegeben') {
            disableForm();
        }
    }

    submit(values) {
        const storeState = store.getState().form;
        const exhibitseditspecificationsValues =
            storeState.exhibitseditspecifications?.values || {};
        let exhibitseditdescriptionValues =
            storeState.exhibitseditdescription?.values || {};

        exhibitseditdescriptionValues = {
            //Files
            ...exhibitseditdescriptionValues,
            ...this.state.values,
        };

        validateForm(
            this,
            {
                ...exhibitseditspecificationsValues,
                ...exhibitseditdescriptionValues,
                ...values,
            },
            ['status', 'exponat_titel'],
        );

        exhibitSubmit(
            values,
            exhibitseditdescriptionValues,
            exhibitseditspecificationsValues,
            this,
        );
        this.props.toTab(1);
    }

    render() {
        const { handleSubmit, reset, error, submitFailed } = this.props;
        return (
            <div className={style.header}>
                {this.props.initialValues.status === 'freigegeben' ? (
                    <div>Sie haben diese Daten bereits freigegeben</div>
                ) : null}
                {!this.props.exhibit_deadline_within ? (
                    <div>
                        Sie können die Daten nur innerhalb des
                        Bearbeitungszeitraums bearbeiten
                    </div>
                ) : null}
                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <fieldset>
                        <legend />
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='exponat_id'
                            id='exponat_id'
                            hidden
                            disabled
                            value={this.props.handle || ''}
                        />
                        <label htmlFor='exponat_titel'>
                            <span className={style.bold}>
                                Titel des Exponats (*)
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='exponat_titel'
                            id='exponat_titel'
                            maxLength='100'
                            required
                        />
                        <label htmlFor='ansprechpartner'>
                            <span className={style.bold}>
                                Name Ansprechpartner (falls nicht Projektleiter)
                            </span>
                        </label>
                        <Field
                            component='select'
                            name='ansprechpartner'
                            id='ansprechpartner'
                        >
                            <option value='' />
                            {this.props.person && this.props.person.length >= 1
                                ? this.props.person.map((person) => (
                                      <option key={person.ID} value={person.ID}>
                                          {person.nachname || ''},{' '}
                                          {person.vorname || ''}
                                      </option>
                                  ))
                                : null}
                        </Field>
                        <Field
                            component='input'
                            type='checkbox'
                            id='interaktiv'
                            name='interaktiv'
                        />
                        <label htmlFor='interaktiv'>
                            <span className={style.bold}>Interaktiv</span>
                        </label>
                        <div className={style.space} />
                        <legend className={style.bold}>
                            Geschätzte Vorführdauer
                        </legend>
                        <Field
                            component='input'
                            type='radio'
                            id='5min'
                            name='vorfuerdauer'
                            value='5min'
                            required
                        />
                        <label htmlFor='5min'>
                            <span>bis 5 Minuten</span>
                        </label>
                        <Field
                            component='input'
                            type='radio'
                            id='15min'
                            name='vorfuerdauer'
                            value='15min'
                            required
                        />
                        <label htmlFor='15min'>
                            <span>über 5 Minuten</span>
                        </label>
                        <div className={style.space} />
                        <legend className={style.bold}>Zielgruppe</legend>
                        <Field
                            component='input'
                            type='checkbox'
                            id='zielgruppe56'
                            name='zielgruppe56'
                        />
                        <label htmlFor='zielgruppe56'>
                            <span>5./6. Klasse</span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='zielgruppe78'
                            name='zielgruppe78'
                        />
                        <label htmlFor='zielgruppe78'>
                            <span>7./8. Klasse</span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='zielgruppe910'
                            name='zielgruppe910'
                        />
                        <label htmlFor='zielgruppe910'>
                            <span>9./10. Klasse</span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='zielgruppe1112'
                            name='zielgruppe1112'
                        />
                        <label htmlFor='zielgruppe1112'>
                            <span>Jg. 11/12(13)</span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='zielgruppe_aelter'
                            name='zielgruppe_aelter'
                            onChange={(e) => this.handleInput(e)}
                        />
                        <label htmlFor='zielgruppe_aelter'>
                            <span>älter</span>
                        </label>
                        <div className={style.space} />
                        <legend className={style.bold}>
                            Wurde das Exponat bereits bei einer IdeenExpo
                            gezeigt?
                        </legend>
                        <Field
                            component='input'
                            type='radio'
                            id='wiederholt_ja'
                            name='wiederholt'
                            value='ja'
                            required
                        />
                        <label htmlFor='wiederholt_ja'>
                            <span>Ja</span>
                        </label>
                        <Field
                            component='input'
                            type='radio'
                            id='wiederholt_nein'
                            name='wiederholt'
                            value='nein'
                            required
                        />
                        <label htmlFor='wiederholt_nein'>
                            <span>Nein</span>
                        </label>
                        <FormButtons
                            showCancel={true}
                            overviewLink='/booths/exhibits'
                            hideBtn={true}
                            handleSubmit={handleSubmit}
                            submit={this.submit.bind(this)}
                        />
                        <legend>(*) = Pflichtfeld</legend>
                    </fieldset>
                </form>
                {/* <StepButtons back={'/booths/exhibits'} toTab={() => this.props.toTab(1)} disabled={!this.state.valid}  labelBack={'Liste der Exponate'} labelForward={'Beschreibungen'} /> */}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const Props = {
        person: state.person || [],
        idStand: props.booth_id,
        company2person: state.company2person || [],
    };
    const handle = props.handle;

    if (handle == null) {
        return { ...Props, initialValues: { status: '' } };
    }
    const zielgruppenIds = mapEntriesByKey(state.exponatzg);
    const found = state.exhibit.find((x) => x.ID === handle);
    const zielgruppenExponat =
        found?.tp_Exponat_2_ExponatZielgruppeLst?.TpExponatZielgruppeLstItem ||
        [];
    const zielgruppenExponatIds = zielgruppenExponat.map(
        (zielgruppe) => zielgruppe.idZielgruppe,
    );
    let initialValues = { status: '' };
    if (found && found.ID === handle) {
        initialValues = {
            exponat_titel: found.projekttitel || '',
            exponat_id: handle || '',
            ansprechpartner: found.idAnsprechpartner || '',
            interaktiv: found.idTyp === 2 || false,
            vorfuerdauer: found.idInteraktionszeit === 2 ? '15min' : '5min',
            wiederholt: found.idNeuheit === 1 ? 'ja' : 'nein',
            zielgruppe56: zielgruppenExponatIds.includes(zielgruppenIds.zg56),
            zielgruppe78: zielgruppenExponatIds.includes(zielgruppenIds.zg78),
            zielgruppe910: zielgruppenExponatIds.includes(zielgruppenIds.zg910),
            zielgruppe1112: zielgruppenExponatIds.includes(
                zielgruppenIds.zgjg1112,
            ),
            zielgruppe_aelter: zielgruppenExponatIds.includes(
                zielgruppenIds['zgälter'],
            ),
            status:
                found.kzFreigabeAussteller === 1
                    ? 'freigegeben'
                    : found.kzFreigabeAussteller === 0
                    ? 'inbearbeitung'
                    : '',
        };
    }
    return {
        ...Props,
        initialValues: initialValues || { status: '' },
        exhibit_deadline_within: state.deadlines?.exhibit_deadline_within,
        handle,
        found,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'exhibitseditbasis',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(ExhibitEditBasis),
);
