import React, { useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { withRouter, useLocation, useHistory } from 'react-router-dom';

import style from '../../index.module.css';
import sx from './banner-print.module.scss';
import FormButtons from '../../formbuttons';
import {
    fetchNewEntityFromConfig,
    fetchSingleEntityFromConfig,
} from '../../../../actions/exhibitor/fetcher/fetcher';
import { createEntityConfig } from '../../../../actions/exhibitor/fetcher/entity-list-detail.fetcher';
import {
    insertBannerPrint,
    mapBannerPrint,
    updateBannerPrint,
} from './banner-print.actions';
import { setBannerPrint } from '../../../../actions/exhibitor/exhibitor-actions';
import Loader from '../../../common/loader';
import UploadFile from '../../uploadfile';
import { ERROR, ERROR_MSG_BACKEND_SAVE, showAlert } from '../../alerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

export const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

const mapStateToProps = (state, props) => {
    return {
        initialValues: {
            ...mapBannerPrint(state.bannerPrint),
        },
        item: state.bannerPrint,
        formValues: state.form?.bannerPrintEdit?.values,
    };
};

export const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

/**
 * @param {*} rawFile Raw file object from input
 * @param {string} key e.g. bannerPrint
 * @returns Partial Body for POST request
 */
export const createFileBody = async (rawFile, key, keySuffix = 'Datei') => {
    let fileInfo;
    if (rawFile) {
        fileInfo = await UploadFile(rawFile.file);
    }
    const dataKey = `data4${capitalizeFirstLetter(key)}${keySuffix}`;
    const fileKey = `${key}${keySuffix}`;

    return fileInfo
        ? {
              [dataKey]: fileInfo.file,
              [fileKey]: fileInfo.name,
          }
        : undefined;
};

export const BannerFileInput = ({
    input: { onChange, onBlur, value: _omitValue, ...inputProps },
    meta: _omitMeta,
    ...props
}) => {
    if (props.disabled) {
        return null;
    }

    const handleChange = (handler) => ({ target: { files } }) =>
        handler(files.length ? { file: files[0], name: files[0].name } : {});

    return (
        <div className={sx.bannerFileInput}>
            <label>
                <input
                    type='file'
                    accept='*'
                    onChange={handleChange(onChange)}
                    onBlur={handleChange(onBlur)}
                    {...inputProps}
                    {...props}
                />
                Durchsuchen...
            </label>
        </div>
    );
};

export const CurrentFile = ({ name, data, rawInput }) => {
    if (rawInput) {
        return `Datei für Upload: ${rawInput?.name}`;
    }

    if (name) {
        return (
            <>
                <div>Hochgeladene Datei:</div>
                <a download={name} href={`data:image/jpeg;base64,${data}`}>
                    {name}
                    <FontAwesomeIcon icon={faDownload} size='1x' />
                </a>
            </>
        );
    }

    return 'Bislang keine Datei hochgeladen';
};

export const BannerPrintForm = ({
    dispatch,
    handleSubmit,
    initialValues,
    formValues,
    item,
}) => {
    const query = useQuery();
    const history = useHistory();
    const id = query.get('id');
    const isNew = id === null;
    const isDisabled = initialValues.kzFreigabeAussteller === 1;
    const [isReady, setIsReady] = useState(isNew);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmitFinish = () => {
        history.push('/organisation/banner-print');
    };

    const submit = async ({
        rawFile,
        beschreibung,
        data4BannerPrintDatei,
        bannerPrintDatei,
    }) => {
        try {
            setIsLoading(true);

            const fileBody = await createFileBody(rawFile, 'bannerPrint');

            const body = {
                ...(isNew ? {} : item),
                beschreibung,
                ...(fileBody || {
                    data4BannerPrintDatei,
                    bannerPrintDatei,
                }),
            };
            if (isNew) {
                await insertBannerPrint(body);
                setIsLoading(false);
                handleSubmitFinish();
                return;
            }

            await updateBannerPrint({
                ...body,
                ID: parseInt(id),
            });
            handleSubmitFinish();
        } catch (e) {
            console.error(e);
            showAlert(ERROR, ERROR_MSG_BACKEND_SAVE);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFetch = async () => {
        const ec = createEntityConfig('bannerPrint');

        let banner;
        if (isNew) {
            banner = await fetchNewEntityFromConfig(ec);
        } else {
            banner = await fetchSingleEntityFromConfig(ec, id);
        }

        dispatch(setBannerPrint(banner));
        setIsReady(true);
    };

    useEffect(() => {
        handleFetch();
    }, []);

    if (!isReady) {
        return (
            <div style={{ height: '90vh' }}>
                <Loader visible />
            </div>
        );
    }

    return (
        <div className={style.question}>
            <div className={style.header}>
                {isNew ? (
                    <h1>Neue Datei hochladen</h1>
                ) : (
                    <h1>Datei bearbeiten</h1>
                )}
            </div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <fieldset>
                    <div className={sx.currentFile}>
                        <CurrentFile
                            data={formValues?.data4BannerPrintDatei}
                            name={formValues?.bannerPrintDatei}
                            rawInput={formValues?.rawFile}
                        />
                    </div>
                    <Field
                        name='rawFile'
                        component={BannerFileInput}
                        disabled={isDisabled}
                    />
                    <label htmlFor='beschreibung'>Dateibeschreibung</label>
                    <Field
                        component='input'
                        type='text'
                        placeholder='Beschreibung'
                        name='beschreibung'
                        id='beschreibung'
                        disabled={isDisabled}
                    />
                </fieldset>
                <FormButtons
                    showCancel={true}
                    overviewLink='/organisation/banner-print'
                    hideBtn={true}
                    handleSubmit={handleSubmit}
                    submit={submit}
                    disabled={isDisabled}
                    loading={isLoading}
                />
            </form>
        </div>
    );
};

export const BannerPrintEdit = compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'bannerPrintEdit',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: false,
    })(BannerPrintForm),
);
