import React from 'react';
import style from '../index.module.css';
import StepButtons from '../stepbuttons';
import { connect } from 'react-redux';
import { getForwardActionForRoles, determineAuthForRoles } from '../functions';

const Home = (props) => {
    // Object hält Action und Label für den Link zur nächsten Seite in Abhängigkeit zu den Rollen
    // des angemeldeten Users; Nutzung siehe Einbindung Stepbuttons unten
    const forwardAction = getForwardActionForRoles('booths', props);

    return (
        <div className={style.question}>
            <h1>
                <strong>
                    Herzlich willkommen,{' '}
                    {props.exhibitorname ? props.exhibitorname : 'User'}{' '}
                </strong>
            </h1>
            <p>
                im Ausstellerportal der IdeenExpo {props.ie_year}! Hier können
                Sie sämtliche Informationen zu Ihrer Stand-, Workshop- oder
                Bühnenpräsentation hinterlegen. Im weiteren Verlauf der
                Veranstaltungsorganisation werden dann das mit Ihrer/Ihrem
                AnsprechpartnerIn vereinbarte Standmobiliar, geplante
                Standbesuche und ggf. detaillierte Informationen zu Ihrem
                Workshopangebot angezeigt.
            </p>
            <p>
                Die jeweiligen Schritte in den Formularen haben Deadlines. Wenn
                eine Deadline verstrichen ist, können Sie keine Eintragungen
                mehr vornehmen! Bis zu diesem Datum können Sie jedoch beliebig
                oft die Eintragungen ändern.
            </p>
            <p>
                Bitte beachten Sie: Wenn Sie den Status Ihrer Eintragungen auf
                „freigegeben“ setzen, übernehmen wir diese Daten. Damit sind
                Änderungen Ihrerseits nicht mehr möglich.
            </p>
            <StepButtons
                to={forwardAction.forwardAction}
                labelForward={forwardAction.forwardText}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    // Ermittlung der Rollenberechtigungen des angemeldeten Users
    const userAuth = determineAuthForRoles(state);

    return {
        ie_year: state.deadlines?.ie_year,
        exhibitorname:
            state.exhibitor &&
            Array.isArray(state.exhibitor) &&
            state.exhibitor.length > 0
                ? `${state.exhibitor[0].vorname} ${state.exhibitor[0].name}`
                : null,
        hasBoothAuthorization: userAuth.hasBoothAuthorization,
        hasWorkshopAuthorization: userAuth.hasWorkshopAuthorization,
        hasLiveprogramAuthorization: userAuth.hasLiveprogramAuthorization,
        hasClubzukunftAuthorization: userAuth.hasClubzukunftAuthorization,
        hasOrganisationAuthorization: userAuth.hasOrganisationAuthorization,
        hasCarreerStageAuthorization: userAuth.hasCarreerStageAuthorization,
    };
};

export default connect(mapStateToProps, null)(Home);
