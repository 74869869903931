import React, { useEffect } from 'react';
import style from './index.module.css';
import classNames from 'classnames';

export default (
    /** @type {{ visible: boolean, id: any, info: React.ReactNode, infoDelayMs: number }} */
    props,
) => {
    const [infoVisible, setInfoVisible] = React.useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setInfoVisible(true);
        }, props.infoDelayMs || 0);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <div
            id={props.id ? props.id : ''}
            className={`${style.loader} ${
                props.visible ? style.visible : style.hidden
            }`}
        >
            <div className={style.spinner}>
                <div className={style.bounce1} />
                <div className={style.bounce2} />
                <div className={style.bounce3} />
            </div>
            <div
                className={classNames(style.loaderInfo, {
                    [style.loaderInfoVisible]: infoVisible,
                })}
            >
                {props.info}
            </div>
        </div>
    );
};
