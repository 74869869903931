import React, { Component } from 'react';
import { reduxForm, destroy } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import style from '../../index.module.css';
import StepButtons from '../../stepbuttons';
import {
    getEntity,
    deleteEntity,
} from '../../../../actions/exhibitor/exhibitor-actions.js';
import {
    showAlert,
    ERROR,
    ERROR_MSG_BACKEND_FETCH,
    SUCCESS_DELETE,
    DELETE,
} from '../../alerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
    getForwardActionForRoles,
    getBackwardActionForRoles,
    determineAuthForRoles,
} from '../../functions';
import { approveItem } from '../../functions';

class ShowStage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
        };
    }
    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                if (
                    this.props.showstage &&
                    Object.keys(this.props.showstage).length
                ) {
                    Promise.resolve();
                } else {
                    this.props.dispatch(getEntity('showstage'));
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    deleteItem = (json) => {
        Promise.resolve()
            .then(() => {
                return this.props.dispatch(deleteEntity(json, 'showstage'));
            })
            .then(() => {
                showAlert(
                    DELETE,
                    SUCCESS_DELETE(`Die ShowBühne ${json.titelBeitrag}`),
                );
            })
            .catch((e) => {
                showAlert(ERROR, `Element konnte nicht gelöscht werden. ${e}`);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    };
    render() {
        const forwardAction = getForwardActionForRoles(
            'clubzukunft',
            this.props,
        );
        const backwardAction = getBackwardActionForRoles(
            'stageseven',
            this.props,
        );

        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>
                        Showbühne{' '}
                        {this.props.showStagePlace
                            ? this.props.showStagePlace
                            : 'Platz der Ideen, Außengelände'}
                    </h1>
                    <p>
                        Auf dieser Bühne findet die tägliche "Wissen LIVE"-Show
                        statt. Rund um dieses Programm können sich unsere
                        Aussteller auf dieser Bühne präsentieren. Weitere
                        Informationen zu möglichen Beiträgen finden Sie im pdf
                        Detailinfos.
                    </p>
                </div>
                <div className='exhibitorTableWrap'>
                    <span className='exhibitorTableHeader'>Beitrag</span>
                    {this.props.stages_deadline_within === true ? (
                        <Link
                            className='exhibitorTableButton small-button'
                            to={'/live-program/show-stage/edit/'}
                            onClick={() =>
                                this.props.dispatch(
                                    destroy(
                                        'showstageeditbasis',
                                        'showstageeditcontact',
                                    ),
                                )
                            }
                        >
                            Neuer Eintrag
                        </Link>
                    ) : null}
                    <div className='schoolTableWrap'>
                        <ul className='schoolTable four-gridlistpages'>
                            <div className='header'>Titel</div>
                            <div className='header'>Ansprechpartner</div>
                            <div className='header'>Freigabe</div>
                            <div className='header' />

                            {this.props.showstage.map((item) => (
                                <ul key={item.ID}>
                                    <Link
                                        to={{
                                            pathname:
                                                '/live-program/show-stage/edit',
                                            state: {
                                                handle: item.ID,
                                            },
                                            search: `${item.ID}`,
                                        }}
                                        id={item.ID}
                                    >
                                        <li className='schoolTable four-gridlistpages'>
                                            <label>{item.titelBeitrag}</label>
                                            <label>
                                                {item.nameAnsprechpartner}
                                            </label>
                                            {item.kzFreigabeAussteller ? (
                                                <div>Ja</div>
                                            ) : (
                                                <div>
                                                    <a
                                                        className='button approve'
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            approveItem(
                                                                this,
                                                                'showbühne',
                                                                item,
                                                            );
                                                        }}
                                                    >
                                                        Freigeben
                                                    </a>
                                                </div>
                                            )}
                                            {item.kzFreigabeAussteller ? (
                                                <div />
                                            ) : (
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (
                                                            window.confirm(
                                                                `Wollen Sie den Eintrag "${item.titelBeitrag}" wirklich löschen?`,
                                                            )
                                                        ) {
                                                            this.deleteItem(
                                                                item,
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                        size='1x'
                                                    />
                                                </div>
                                            )}
                                        </li>
                                    </Link>
                                </ul>
                            ))}
                        </ul>
                    </div>
                </div>
                <StepButtons
                    back={backwardAction.backwardAction}
                    to={forwardAction.forwardAction}
                    labelBack={backwardAction.backwardText}
                    labelForward={forwardAction.forwardText}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, _prop) => {
    // Ermittlung der Rollenberechtigungen des angemeldeten Users
    const userAuth = determineAuthForRoles(state);

    return {
        showstage: state.showstage || [],
        stages_deadline: state.deadlines?.stages_deadline,
        stages_deadline_within: state.deadlines?.stages_deadline_within,
        hasBoothAuthorization: userAuth.hasBoothAuthorization,
        hasWorkshopAuthorization: userAuth.hasWorkshopAuthorization,
        hasLiveprogramAuthorization: userAuth.hasLiveprogramAuthorization,
        hasClubzukunftAuthorization: userAuth.hasClubzukunftAuthorization,
        hasOrganisationAuthorization: userAuth.hasOrganisationAuthorization,
        hasCarreerStageAuthorization: userAuth.hasCarreerStageAuthorization,
    };
};

export default connect(
    mapStateToProps,
    null,
)(reduxForm({ form: 'showstage', enableReinitialize: true })(ShowStage));
