import React, { Component } from 'react';
import { connect } from 'react-redux';
import style from '../../../../exhibitor/index.module.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import WorkshopBasis from './basis';
import WorkshopEquipment from './equipment';
import WorkshopSchedule from './schedule';
import {
    getEntity,
    getCustomLst,
} from '../../../../../actions/exhibitor/exhibitor-actions.js';
import { showAlert, ERROR, ERROR_MSG_BACKEND_FETCH } from '../../../alerts';

class Workshops extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            selectedIndex: 0,
        };
    }

    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                if (this.props.workshop) {
                    return this.props
                        .dispatch(getEntity('workshop'))
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_FETCH }),
                        );
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                if (this.props.veranstzp) {
                    return this.props
                        .dispatch(getCustomLst('datesdef'))
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_FETCH }),
                        );
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                if (this.props.veranstzielgruppe) {
                    return this.props
                        .dispatch(getCustomLst('targetgroupworkshop'))
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_FETCH }),
                        );
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                if (this.props.bezugsgruppe) {
                    return this.props
                        .dispatch(getCustomLst('bezugsgruppe'))
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_FETCH }),
                        );
                } else {
                    return Promise.resolve();
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    handleSelect = (index) => {
        this.setState({ selectedIndex: index });
    };

    handleButtonClick = (number) => {
        this.setState({ selectedIndex: number });
    };
    render() {
        const found = this.props.found || {};
        return (
            <div className={style.question}>
                <div>
                    {this.props.handle ? (
                        <h1>Workshop {found.titel} bearbeiten</h1>
                    ) : (
                        <h1>Neuen Workshop erstellen</h1>
                    )}
                </div>
                <Tabs
                    selectedIndex={this.state.selectedIndex}
                    onSelect={this.handleSelect}
                    className={`${style.reactTabs}`}
                >
                    <TabList>
                        <Tab>
                            Basisdaten
                            <br />
                            Eintragungen können bis zum{' '}
                            {this.props.workshop_deadline} vorgenommen werden.
                        </Tab>
                        <Tab>
                            Zeitplanung
                            <br />
                            Eintragungen können bis zum{' '}
                            {this.props.workshop_deadline} vorgenommen werden.
                        </Tab>
                        <Tab>
                            Ausstattung
                            <br />
                            Eintragungen können bis zum{' '}
                            {this.props.workshop_deadline} vorgenommen werden.
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <WorkshopBasis
                            handle={this.props.handle}
                            toTab={this.handleButtonClick}
                        />
                    </TabPanel>
                    <TabPanel>
                        <WorkshopSchedule
                            handle={this.props.handle}
                            toTab={this.handleButtonClick}
                        />
                    </TabPanel>
                    <TabPanel>
                        <WorkshopEquipment
                            handle={this.props.handle}
                            toTab={this.handleButtonClick}
                        />
                    </TabPanel>
                </Tabs>
            </div>
        );
    }
}

const mapStateToProps = (state, _prop) => {
    const handle =
        state.router?.location?.state?.handle ||
        parseInt((state.router?.location?.search || '?').substring(1)) ||
        '';

    return {
        handle: handle,
        workshop_deadline: state.deadlines?.workshop_deadline,
        found:
            state.workshop && Array.isArray(state.workshop) && handle
                ? state.workshop.find((x) => x.ID === handle)
                : {},
        workshop: state.workshop && !Object.keys(state.workshop).length,
        veranstzp: state.veranstzp && !Object.keys(state.veranstzp).length,
        veranstzielgruppe:
            state.veranstzielgruppe &&
            !Object.keys(state.veranstzielgruppe).length,
        bezugsgruppe:
            state.bezugsgruppe && !Object.keys(state.bezugsgruppe).length,
    };
};
export default connect(mapStateToProps, null)(Workshops);
