import React from 'react';

import StepButtons from '../../common/stepbuttons';

export default () => (
    <>
        <h1>
            <strong>Fehler</strong>
        </h1>
        <p>Sie haben einen ungültigen persönlichen Zugangscode angegeben.</p>
        <StepButtons back='/' />
    </>
);
