import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
    getEntity,
    getCustomLst,
} from '../../../../../../actions/exhibitor/exhibitor-actions';
import {
    updateWorkshop,
    insertWorkshop,
} from '../../../../../../actions/exhibitor/workshop';
import style from '../../../../index.module.css';
import FormButtons from '../../../../formbuttons';
import { store } from '../../../../../../store.js';
import disableForm from '../../../../disableForm.js';
import {
    showAlert,
    validateForm,
    SUCCESS,
    SUCCESS_MSG,
    ERROR,
    ERROR_MSG_BACKEND_FETCH,
    ERROR_MSG_BACKEND_SAVE,
} from '../../../../alerts';
import NumberField from '../../../../../common/numberField';
import isNumber from '../../../../../common/util/ReduxFormValidator/hasToBeNumber';

class WorkshopEditsSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            submitSuccess: false,
        };
    }
    handleInput() {
        this.setState({ valid: true });
    }

    resetForm() {
        this.setState({
            submitSuccess: false,
        });
    }

    componentDidMount() {
        if (this.props.initialValues['kzStatusFreigabeAussteller'] === 1) {
            disableForm();
        }
    }

    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                if (
                    this.props.veranstzp &&
                    !Object.keys(this.props.veranstzp).length
                ) {
                    return this.props
                        .dispatch(getEntity('veranstzp'))
                        .then(null, (_e) => Promise.reject());
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                if (
                    this.props.veranstzielgruppe &&
                    !Object.keys(this.props.veranstzielgruppe).length
                ) {
                    return this.props
                        .dispatch(getCustomLst('targetgroupworkshop'))
                        .then(null, (_e) => Promise.reject());
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                console.error('error occured', e);
            });
    }

    submit(values) {
        const storeState = store.getState();
        const workshopeditbasisValues =
            storeState.form?.workshopeditbasis?.values || {};
        const workshopeditequipmentValues =
            storeState.form?.workshopeditequipment?.values || {};
        validateForm(
            this,
            {
                ...workshopeditbasisValues,
                ...workshopeditequipmentValues,
                ...values,
            },
            ['status', 'workshop_name'],
            this.props.workshop_deadline_within,
        );
        Promise.resolve()
            .then(() => {
                // if updating an existing workshop
                if (this.props.handle) {
                    return this.props
                        .dispatch(
                            updateWorkshop(this.props.found, {
                                ...workshopeditbasisValues,
                                ...workshopeditequipmentValues,
                                ...values,
                            }),
                        )
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                } else {
                    // if creating a new workshop
                    return this.props
                        .dispatch(
                            insertWorkshop({
                                ...workshopeditbasisValues,
                                ...workshopeditequipmentValues,
                                ...values,
                            }),
                        )
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                }
            })
            .then((json) => {
                if (!this.props.handle) {
                    this.props.history.push(
                        `/workshops/edits?${json.workshop.ID}`,
                    );
                }
            })
            .then(() =>
                this.setState({
                    submitSuccess: true,
                }),
            )
            .then(() => showAlert(SUCCESS, SUCCESS_MSG))
            .then(() => {
                if (
                    this.props.initialValues['kzStatusFreigabeAussteller'] === 1
                ) {
                    disableForm();
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_SAVE);
                this.setState({ submitSuccess: false });
                this.props.dispatch(stopSubmit('workshopeditschedule', e));
            });
    }
    render() {
        const { handleSubmit } = this.props;
        const maxLengthWorkshopDauerVeranstaltung = 100;
        const maxLengthWorkshopDauerAufbau = 100;
        const maxLengthWorkshopDauerAbbau = 100;
        const maxLengthWorkshopHaeufigkeitProTag = 200;
        const maxLengthWorkshopWunschzeiten = 200;
        const maxLengthWorkshopAnmerkungenZeitplan = 800;
        const maxLengthWorkshopFruehererAufbauAnmerkung = 800;
        return (
            <div className={style.header}>
                {this.props.initialValues.kzStatusFreigabeAussteller === 1 ? (
                    <div>Sie haben diese Daten bereits freigegeben</div>
                ) : null}
                {!this.props.workshop_deadline_within ? (
                    <div>
                        Sie können die Daten nur innerhalb des
                        Bearbeitungszeitraums bearbeiten
                    </div>
                ) : null}
                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <fieldset>
                        <legend />
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='workshop_id'
                            id='workshop_id'
                            hidden
                            disabled
                            value={this.props.handle || ''}
                            ref={this.input}
                        />
                        <label htmlFor='workshop_dauer'>
                            <span className={style.bold}>
                                Workshopdauer in Minuten
                            </span>
                        </label>
                        <Field
                            component={NumberField}
                            placeholder='Nur Zahlen sind als Eingaben erlaubt.'
                            type='text'
                            name='workshop_dauer'
                            id='workshop_dauer'
                            ref={this.input}
                            maxLength={maxLengthWorkshopDauerVeranstaltung}
                            validate={isNumber}
                        />
                        <label htmlFor='workshop_aufbau'>
                            <span className={style.bold}>
                                Aufbaudauer in Minuten
                            </span>
                        </label>
                        <Field
                            component={NumberField}
                            placeholder='Nur Zahlen sind als Eingaben erlaubt.'
                            type='text'
                            name='workshop_aufbau'
                            id='workshop_aufbau'
                            ref={this.input}
                            maxLength={maxLengthWorkshopDauerAufbau}
                            validate={isNumber}
                        />
                        <label htmlFor='workshop_abbau'>
                            <span className={style.bold}>
                                Abbaudauer in Minuten
                            </span>
                        </label>
                        <Field
                            component={NumberField}
                            placeholder='Nur Zahlen sind als Eingaben erlaubt.'
                            type='text'
                            name='workshop_abbau'
                            id='workshop_abbau'
                            ref={this.input}
                            maxLength={maxLengthWorkshopDauerAbbau}
                            validate={isNumber}
                        />
                        <legend className={style.bold}>
                            Gewünschte Veranstaltungstage in der Woche <br />
                            (Mit Buchung durch Schulklassen)
                        </legend>
                        {this.props.veranstzp &&
                        Array.isArray(this.props.veranstzp)
                            ? this.props.veranstzp.map((date, index) => (
                                  <div key={date.ID}>
                                      <Field
                                          component='input'
                                          type='checkbox'
                                          id={`day${index}`}
                                          name={`day${index}`}
                                      />
                                      <label htmlFor={`day${index}`}>
                                          <span>{date.description}</span>
                                      </label>
                                  </div>
                              ))
                            : null}
                        <label htmlFor='haeufigkeit'>
                            <span className={style.bold}>
                                Häufigkeit pro Tag
                            </span>
                        </label>
                        <Field
                            component={NumberField}
                            placeholder='Nur Zahlen sind als Eingaben erlaubt.'
                            type='text'
                            name='haeufigkeit'
                            id='haeufigkeit'
                            ref={this.input}
                            maxLength={maxLengthWorkshopHaeufigkeitProTag}
                            validate={isNumber}
                        />
                        <Field
                            component='input'
                            type='checkbox'
                            id='wochenende'
                            name='wochenende'
                        />
                        <label htmlFor='wochenende'>
                            <span>
                                Ich habe Interesse, auch am{' '}
                                <span className={style.bold}>Wochenende</span>
                                Workshops anzubieten.
                                <br />
                                Bitte nehmen Sie Kontakt mit Ihrem Betreuer bei
                                der IdeenExpo auf.
                            </span>
                        </label>
                        <label htmlFor='wunschzeiten'>
                            <span className={style.bold}>
                                Meine Wunschzeiten (Beginn ab 09:30 möglich)
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='wunschzeiten'
                            id='wunschzeiten'
                            maxLength={maxLengthWorkshopWunschzeiten}
                        />
                        <label htmlFor='anmerkungenZeitplanung'>
                            <span className={style.bold}>
                                Anmerkungen zur Zeitplanung
                            </span>
                        </label>
                        <Field
                            component='textarea'
                            placeholder=''
                            type='text'
                            name='anmerkungenZeitplanung'
                            rows='8'
                            maxLength={maxLengthWorkshopAnmerkungenZeitplan}
                            style={{
                                resize: 'none',
                            }}
                            id='anmerkungenZeitplanung'
                            ref={this.input}
                        />
                        <Field
                            component='input'
                            type='checkbox'
                            id='fruehererAufbau'
                            name='fruehererAufbau'
                        />
                        <label htmlFor='fruehererAufbau'>
                            <span>Früherer Aufbau gewünscht</span>
                        </label>

                        <label htmlFor='fruehererAufbauAnmerkung'>
                            <span className={style.bold}>
                                Anmerkungen zu früherem Aufbau
                            </span>
                        </label>
                        <Field
                            component='textarea'
                            placeholder=''
                            type='text'
                            name='fruehererAufbauAnmerkung'
                            rows='8'
                            maxLength={
                                maxLengthWorkshopFruehererAufbauAnmerkung
                            }
                            style={{
                                resize: 'none',
                            }}
                            id='fruehererAufbauAnmerkung'
                            ref={this.input}
                        />
                        <FormButtons
                            showCancel={true}
                            overviewLink='/workshops'
                            hideBtn={true}
                            handleSubmit={handleSubmit}
                            submit={this.submit.bind(this)}
                        />
                        <legend>(*) = Pflichtfeld</legend>
                    </fieldset>
                </form>
                {/* <StepButtons backTab={() => this.props.toTab(0)} toTab={() => this.props.toTab(2)} disabled={!this.state.valid} labelBack={'Basisdaten'} labelForward={'Ausstattung'} /> */}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    // Samstag und Sonntag sind jeweils die beiden ersten und letzten Elemente im Array der
    // Zeitpunkte. Diese sollen im Backend gesetzt werden können, nicht aber im Frontend.
    // Deswegen werden diese aus dem Array entfernt.

    // Wenn man hier über den Debugger den Ablauf verfolgt, dann kommt die Abarbeitung mehrfach an dieser
    // Stelle vorbei, deswegen werden hier shift() und pop() nur gemacht, wenn das Array die initialen
    // Längen von 9 (erstes WOE) und > 5 (zweites WOE) hat

    // Setzt voraus, dass im Backend per Konvention Sa,So,Mo,Di,Mi,Do,Fr,Sa,So eingepflegt sind
    while (state.veranstzp.length > 7) {
        state.veranstzp.shift();
    }

    while (state.veranstzp.length > 5) {
        state.veranstzp.pop();
    }

    const Props = {
        veranstzp: state.veranstzp,
        workshop_deadline_within: state.deadlines?.workshop_deadline_within,
    };
    let initialValues = {};
    if (!props.handle) {
        return { ...Props, initialValues };
    } else {
        const veranstzpIds = state.veranstzp;
        const found =
            state.workshop && Array.isArray(state.workshop)
                ? state.workshop.find((x) => x.ID === props.handle)
                : [];
        if (found && found.ID === props.handle) {
            initialValues = {
                workshop_id: props.handle || '',
                workshop_dauer: found.dauerVeranstaltung || '',
                workshop_aufbau: found.dauerAufbau || '',
                workshop_abbau: found.dauerAbbau || '',
                haeufigkeit: found.haeufigkeitProTag || '',
                wochenende:
                    found.interesseWorkshopWochenende === 1 ? true : false,
                fruehererAufbau: found.fruehererAufbau === 1 ? true : false,
                fruehererAufbauAnmerkung: found.fruehererAufbauAnmerkung || '',
                wunschzeiten: found.wunschzeiten || '',
                anmerkungenZeitplanung: found.anmerkungenZurZeitplanung || '',
                status: found.idStatus === 1 ? 'inbearbeitung' : '',
                kzStatusFreigabeAussteller:
                    found.kzStatusFreigabeAussteller || 0,
            };
            veranstzpIds.forEach((day, index) => {
                initialValues = {
                    ...initialValues,
                    [`day${index}`]: (
                        found.tp_Veranstaltung_2_VeranstaltungZeitpunktLst
                            ?.TpVeranstaltungZeitpunktLstItem || []
                    ).find((item) => item.idZeitraum === day.ID)
                        ? true
                        : false,
                };
            });
        }
        return {
            ...Props,
            initialValues: initialValues,
            veranstzpIds: veranstzpIds || [],
            found,
        };
    }
};

export default compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'workshopeditschedule',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(WorkshopEditsSchedule),
);
