import React from 'react';
import { withRouter } from 'react-router-dom';
import { destroy } from 'redux-form';
import style from '../../common/index.module.css';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Question from '../../common/question';

class Success extends Question {
    constructor(props) {
        super(props, null, {});
    }

    componentDidMount() {
        window.onpopstate = () => {
            this.resetGroupFields();
            this.props.history.replace('/');
        };
    }

    componentWillUnmount() {
        window.onpopstate = undefined;
    }

    resetGroupFields() {
        this.props.dispatch(destroy('group'));
        this.props.dispatch(destroy('schedule'));
        this.props.dispatch(destroy('allowance'));
        this.props.dispatch(destroy('validate'));
    }

    renderQuestion() {
        const anmeldecode = this.props.editedAnmeldung.anmeldecode;

        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>
                        <b>Vielen Dank</b> für Ihre Anmeldung zur{' '}
                        {this.props.authenticationData.TpProjektLstItem[0].name}
                        !
                    </h1>
                    <p>
                        Ihre Anmeldung ist hiermit abgeschlossen. Wenn Sie eine
                        weitere Lerngruppe anmelden wollen, klicken Sie auf den
                        Button am Ende der Seite. Die Daten der Schule sowie
                        Ihre persönlichen Daten werden dann automatisch
                        übernommmen.
                    </p>
                    <p>Ihre Buchungsnummer lautet: {anmeldecode}</p>
                </div>
                <fieldset>
                    <button
                        type='button'
                        onClick={() => {
                            this.resetGroupFields();
                            this.props.history.push('/group');
                        }}
                    >
                        weitere Lerngruppe hinzufügen
                    </button>
                </fieldset>
            </div>
        );
    }
}

export default compose(
    withRouter,
    connect((s) => ({ formdata: s.form, ...s }), null),
)(Success);
