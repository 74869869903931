import { store } from '../../store';
import {
    CUSTOMLIST_LIST,
    SCHOOL_GET,
    SCHOOL_LIST,
    BACKEND_URL,
    PARTICIPANT_LIST,
    PLZMAPPING_URL,
    ENV,
} from '../../global_constants';
import {
    SCHULE_LST_DEF_SCHUL_FORM,
    TEILNEHMER_LST_DEF_SCHUL_ZWEIG,
} from '../../backend-id-constants';
import { fetchSchoolDetails } from '../common/actions';

export const setMaxGroup = (maxGroup) => ({
    type: 'SET_MAX_GROUP',
    maxGroup: maxGroup,
});

export const fetchMaxGroup = () => {
    // TODO: insert fetch code
    const maxGroup = 40;
    return async function (dispatch) {
        return Promise.resolve().then((_e) => dispatch(setMaxGroup(maxGroup)));
    };
};

export const setSchoolTypeList = (schoolTypeList) => ({
    type: 'SET_SCHOOL_TYPE_LIST',
    schoolTypeList: schoolTypeList,
});

export const fetchSchoolTypeList = (authenticationData) => {
    return async function (dispatch) {
        return fetch(BACKEND_URL + CUSTOMLIST_LIST, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.login.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData.login,
                    userId4Hist: undefined,
                },
                idLst: SCHULE_LST_DEF_SCHUL_FORM,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                try {
                    return JSON.parse(text);
                } catch (error) {
                    return Promise.reject(error);
                }
            })
            .then((json) => {
                return json.TpLstDefLstItem.map((listDefItem) => {
                    return {
                        id: listDefItem.ID,
                        name: listDefItem.description,
                    };
                });
            })
            .then((schoolTypeList) => {
                dispatch(setSchoolTypeList(schoolTypeList));
            });
    };
};

export const setSchoolTrackList = (schoolTrackList) => ({
    type: 'SET_SCHOOL_TRACK_LIST',
    schoolTrackList: schoolTrackList,
});

export const fetchSchoolTrackList = (authenticationData) => {
    return async function (dispatch) {
        return fetch(BACKEND_URL + CUSTOMLIST_LIST, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.login.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData.login,
                    userId4Hist: undefined,
                },
                idLst: TEILNEHMER_LST_DEF_SCHUL_ZWEIG,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                try {
                    return JSON.parse(text);
                } catch (error) {
                    return Promise.reject(error);
                }
            })
            .then((json) => {
                return json.TpLstDefLstItem.map((listDefItem) => {
                    return {
                        id: listDefItem.ID,
                        name: listDefItem.description,
                    };
                });
            })
            .then((schoolTrackList) => {
                dispatch(setSchoolTrackList(schoolTrackList));
            });
    };
};

export const setClassLevelList = (classLevelList) => ({
    type: 'SET_CLASS_LEVEL_LIST',
    classLevelList: classLevelList.TpLstDefLstItem.map((item) =>
        (({ ID, description, externId, idLst, orderIdx }) => ({
            ID,
            description,
            externId,
            idLst,
            orderIdx,
        }))(item),
    ),
});

// export const fetchClassLevelList = () => {
// 	// TODO: insert fetch code
// 	const classLevelList = [];
// 	return async function (dispatch) {
// 		return Promise.resolve().then(e=>dispatch(setClassLevelList(classLevelList)))
// 	};
// }

export const setArrivalDayList = (beginn, ende) => {
    const days = getDates(
        new Date(beginn.split('T')[0]),
        new Date(ende.split('T')[0]),
    );
    const dayList = days.map((day, index) => ({
        formValue: `day${index + 1}`,
        label: day.toLocaleString('de-DE', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        }),
        value: `${day.toISOString().slice(0, -5)}Z`,
    }));

    return {
        type: 'SET_ARRIVAL_DAY_LIST',
        arrivalDayList: dayList,
    };
};

export const setEditedAnmeldung = (id, index, anmeldecode) => {
    return {
        type: 'SET_EDITED_ANMELDUNG',
        editedAnmeldung: { id: id, index: index, anmeldecode: anmeldecode },
    };
};

const getDates = (startDate, stopDate) => {
    const dayList = [];
    for (let dt = startDate; dt <= stopDate; dt.setDate(dt.getDate() + 1)) {
        dayList.push(new Date(dt));
    }
    return dayList;
};

export const setTransportList = (transportList) => ({
    type: 'SET_TRANSPORT_LIST',
    transportList: transportList.TpLstDefLstItem.map((item) =>
        (({ ID, description, externId, idLst, orderIdx }) => ({
            ID,
            description,
            externId,
            idLst,
            orderIdx,
        }))(item),
    ),
});

// export const fetchTransportList = () => {
// 	// TODO: insert fetch code
// 	const transportList = [];
// 	return async function (dispatch) {
// 		return Promise.resolve().then(e=>dispatch(setTransportList(transportList)))
// 	};
// }

export const setDepartureTimeSchoolList = (departureTimeSchoolList) => ({
    type: 'SET_DEPARTURE_TIME_SCHOOL_LIST',
    departureTimeSchoolList: departureTimeSchoolList.TpLstDefLstItem.map(
        (item) =>
            (({ ID, description, externId, idLst, orderIdx }) => ({
                ID,
                description,
                externId,
                idLst,
                orderIdx,
            }))(item),
    ),
});

// export const fetchDepartureTimeList = () => {
// 	// TODO: insert fetch code
// 	const departureTimeList = [];
// 	return async function (dispatch) {
// 		return Promise.resolve().then(e=>dispatch(setDepartureTimeList(departureTimeList)))
// 	};
// }

export const setArrivalTimeList = (arrivalTimeList) => ({
    type: 'SET_ARRIVAL_TIME_LIST',
    arrivalTimeList: arrivalTimeList.TpLstDefLstItem.map((item) =>
        (({ ID, description, externId, idLst, orderIdx }) => ({
            ID,
            description,
            externId,
            idLst,
            orderIdx,
        }))(item),
    ),
});

// export const fetchArrivalTimeList = () => {
// 	// TODO: insert fetch code
// 	const arrivalTimeList = [];
// 	return async function (dispatch) {
// 		return Promise.resolve().then(e=>dispatch(setArrivalTimeList(arrivalTimeList)))
// 	};
// }

export const setWorkshopDate = (workshopDate) => ({
    type: 'SET_WORKSHOP_DATE',
    workshopDate: workshopDate,
});

export const fetchWorkshopDate = () => {
    // TODO: insert fetch code
    const workshopDate = '01.01.2020';
    return async function (dispatch) {
        return Promise.resolve().then((_e) =>
            dispatch(setWorkshopDate(workshopDate)),
        );
    };
};

export const setAllowance = (allowanceList) => ({
    type: 'SET_ALLOWANCE',
    allowanceList: allowanceList.TpLstDefLstItem.map((item) =>
        (({ ID, description, externId, idLst, orderIdx }) => ({
            ID,
            description,
            externId,
            idLst,
            orderIdx,
        }))(item),
    ),
});

// export const fetchAllowance = () => {
// 	// TODO: incomplete: insert fetch code
// 	const allowance = 4;
// 	return async function (dispatch) {
// 		return Promise.resolve().then(e=>dispatch(setAllowance(allowance)))
// 	};
// }

export const setSchoolList = (schoolList) => ({
    type: 'SET_SCHOOL_LIST',
    schoolList: schoolList,
});

let fetchSchoolListID = 0;
export const fetchSchoolList = (zipCode, _schoolNumber = '00000') => {
    fetchSchoolListID += 1;
    const fetchSchoolListLocalID = fetchSchoolListID;
    const authenticationData = store.getState().authenticationData;

    return function (dispatch) {
        if (!authenticationData || !zipCode) {
            return dispatch(setSchoolList([]));
        }

        return fetch(BACKEND_URL + SCHOOL_LIST, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.login.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData.login,
                    userId4Hist: undefined,
                },
                plzSchule: zipCode,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                try {
                    return JSON.parse(text);
                } catch (e) {
                    return Promise.reject(e);
                }
            })
            .then((json) => (json.TpSchuleLstItem ? json.TpSchuleLstItem : []))
            .then((schoolList) => {
                return Promise.all(
                    schoolList.map((school) => {
                        return fetch(BACKEND_URL + SCHOOL_GET, {
                            method: 'POST',
                            mode: 'cors',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${authenticationData.login.accessToken}`,
                            },
                            body: JSON.stringify({
                                login: {
                                    // TODO: pick properties manually!
                                    ...authenticationData.login,
                                    userId4Hist: undefined,
                                },
                                ID: school.ID,
                            }),
                        })
                            .then((response) => {
                                if (!response.ok) {
                                    return Promise.reject(response.statusText);
                                } else {
                                    return response.text();
                                }
                            })
                            .then((text) => {
                                try {
                                    return JSON.parse(text);
                                } catch (e) {
                                    return Promise.reject(e);
                                }
                            })
                            .then((school) => {
                                console.log('school details:', school);
                                return school;
                            });
                    }),
                );
            })
            .then((schoolList) => {
                if (fetchSchoolListID === fetchSchoolListLocalID) {
                    return Promise.resolve(schoolList);
                } else {
                    return Promise.reject(
                        "a more recent request ('fetchSchoolList') is currently running",
                    );
                }
            })
            .then((schoolList) => {
                dispatch(
                    setSchoolList(
                        schoolList.map((school) => mapSchool(school)),
                    ),
                );
            })
            .catch((e) => {
                ENV === 'development' && console.error(e);
            });
    };
};

export const setGroupRegistrationList = (groupRegistrationList) => ({
    type: 'SET_GROUP_REGISTRATION_LIST',
    groupRegistrationList: groupRegistrationList,
});

let fetchSchoolRegistrationID = 0;
export const fetchGroupRegistrationList = (authenticationData, schoolID) => {
    fetchSchoolRegistrationID += 1;
    const fetchSchoolRegistrationLocalID = fetchSchoolRegistrationID;
    console.info('fetching registrations for', schoolID);

    return function (dispatch) {
        if (!authenticationData || !schoolID) {
            return dispatch(setGroupRegistrationList([]));
        }

        return fetch(BACKEND_URL + PARTICIPANT_LIST, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.login.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData.login,
                    userId4Hist: undefined,
                },
                idSchule: schoolID,
                idProjekt: authenticationData.TpProjektLstItem?.[0].ID,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                try {
                    return JSON.parse(text);
                } catch (e) {
                    return Promise.reject(e);
                }
            })
            .then((json) => {
                console.info('participation list fetch:', json);
                return json;
            })
            .then((json) =>
                json.TpTeilnehmerLstItem ? json.TpTeilnehmerLstItem : [],
            )
            .then((schoolList) => {
                return Promise.all(
                    schoolList.map(fetchSchoolDetails),
                ); /* ToDo: Prüfen fetchSchoolDetails */
            })
            .then((schoolList) => {
                if (
                    fetchSchoolRegistrationID === fetchSchoolRegistrationLocalID
                ) {
                    return Promise.resolve(schoolList);
                } else {
                    return Promise.reject(
                        "a more recent request ('fetchGroupRegistrationList') currently running",
                    );
                }
            })
            .then((schoolList) => {
                console.log('groupRegistrationList', schoolList);
                return dispatch(setGroupRegistrationList(schoolList));
            });
    };
};

export const setSchoolUpdate = (school) => {
    return {
        type: 'SET_SCHOOL_UPDATE',
        schoolList: mapSchool(school),
    };
};

const mapSchool = (school) => {
    return {
        ID: school.ID,
        idStatus: school.idStatus,
        name: school.nameSchule,
        type: school.idSchulForm || '-',
        city: school.ortSchule,
        address: school.strasseSchule,
        schoolType: school.nameZusatzSchule || '', // TODO: überprüfen der korrekten Zuordnung
        schoolEMail: school.emailSchule || '',
        schoolPhone: `${school.vorwahlSchule} ${school.telefonSchule}`,
        kzOepnv: school.kzOepnv,
    };
};

export const setGroupRegistrationUpdate = (groupRegistrationList) => ({
    type: 'SET_GROUP_REGISTRATION_UPDATE',
    groupRegistrationList: groupRegistrationList,
});

export const fetchZipcodeMapping = (zipCode) => {
    return function (dispatch) {
        if (!zipCode || zipCode.length < 5) {
            return dispatch(updateZipcodeMapping({}));
        }

        return fetch(PLZMAPPING_URL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                PLZ: zipCode,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                try {
                    return JSON.parse(text);
                } catch (e) {
                    return Promise.reject(e);
                }
            })
            .then((json) => {
                console.log(json);
                dispatch(updateZipcodeMapping(json));
            });
    };
};

export const updateZipcodeMapping = (plzMapping) => ({
    type: 'SET_PLZMAPPING',
    plzMapping: plzMapping,
});

export const fetchZipcodeCities = (zipCode) => {
    return async function (dispatch) {
        return fetch(`${BACKEND_URL}zipcode/cities`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${
                    store.getState().authenticationData.login.accessToken
                }`,
            },
            body: JSON.stringify({
                zipCode: zipCode,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                try {
                    return JSON.parse(text);
                } catch (error) {
                    return Promise.reject(error);
                }
            })
            .then((json) => {
                dispatch(updateZipcodeCities(json));
            });
    };
};

export const updateZipcodeCities = (zipCodeCities) => ({
    type: 'SET_ZIPCODE_CITIES',
    zipCodeCities: zipCodeCities,
});
