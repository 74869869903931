import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import FormButtons from '../../../formbuttons';
import style from '../../../../exhibitor/index.module.css';
import StepButtons from '../../../stepbuttons';
import {
    getEntity,
    getCustomLst,
} from '../../../../../actions/exhibitor/exhibitor-actions.js';
import {
    updateClubzukunftList,
    insertClubzukunftList,
} from '../../../../../actions/exhibitor/clubzukunft/index.js';
import {
    showAlert,
    SUCCESS,
    SUCCESS_MSG,
    ERROR,
    ERROR_MSG_BACKEND_SAVE,
    ERROR_MSG_BACKEND_FETCH,
    ERROR_UNAUTHORIZED,
} from '../../../alerts';

class Training extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            submitSuccess: false,
        };
    }
    handleInput(_e) {
        this.setState({ valid: true });
    }
    resetForm() {
        this.setState({
            submitSuccess: false,
        });
    }
    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                if (
                    this.props.trainingDef &&
                    this.props.trainingDef.length > 0
                ) {
                    return Promise.resolve();
                } else {
                    return this.props
                        .dispatch(getCustomLst('trainingdef'))
                        .then(null, (_e) => Promise.reject());
                }
            })
            .then(() => {
                if (this.props.training && this.props.training.length > 0) {
                    return Promise.resolve();
                } else {
                    return this.props
                        .dispatch(getEntity('training'))
                        .then(null, (_e) => Promise.reject());
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    submit(values) {
        Promise.all(
            this.props.trainingDef.map((def) => {
                return new Promise((resolve, reject) => {
                    const valuesMod = {
                        kzPraktikum: values[def.praktikumName],
                        kzAusbildung: values[def.ausbildungName],
                    };
                    if (
                        values[def.praktikumName] !==
                            this.props.initialValues[def.praktikumName] ||
                        values[def.ausbildungName] !==
                            this.props.initialValues[def.ausbildungName]
                    ) {
                        if (def.trainingIndex > -1) {
                            // if updating an existing Training
                            this.props
                                .dispatch(
                                    updateClubzukunftList(
                                        this.props.training[def.trainingIndex],
                                        valuesMod,
                                        'training',
                                    ),
                                )
                                /*
						.then(() => {
							return this.props.dispatch(
								getEntity('training',{ID: this.props.training[def.trainingIndex].ID})
							)
							.then(null, (e) => (Promise.reject({_error: ERROR_MSG_BACKEND_FETCH})))
						})
						*/
                                .then((result) => {
                                    resolve(result);
                                })
                                .catch((e) => {
                                    reject({ _error: e });
                                });
                        } else {
                            // if creating a new Training
                            this.props
                                .dispatch(
                                    insertClubzukunftList(
                                        valuesMod,
                                        def.ID,
                                        'training',
                                    ),
                                )
                                /*
						.then(() => {
							return this.props.dispatch(
								getEntity('training',{ID: this.props.training[def.trainingIndex].ID})
							)
							.then(null, (e) => (Promise.reject({_error: ERROR_MSG_BACKEND_FETCH})))
						})
						*/
                                .then((result) => {
                                    resolve(result);
                                })
                                .catch((e) => {
                                    reject({ _error: e });
                                });
                        }
                    } else {
                        resolve();
                    }
                });
            }),
        )
            .then(() => {
                return this.props
                    .dispatch(getEntity('training'))
                    .then(null, (_e) =>
                        Promise.reject({ _error: ERROR_MSG_BACKEND_FETCH }),
                    );
            })
            .then(() =>
                this.setState({
                    submitSuccess: true,
                }),
            )
            .then(() => showAlert(SUCCESS, SUCCESS_MSG))
            .catch((e) => {
                showAlert(ERROR, ERROR_MSG_BACKEND_SAVE);
                if (e._error === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                this.setState({ submitSuccess: false });
                this.props.dispatch(stopSubmit('careermarkettraining', e));
            });
    }
    render() {
        const { handleSubmit } = this.props;
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>Aus&shy;bild&shy;ung&shy;s&shy;be&shy;rufe</h1>
                    <p>
                        Bitte kennzeichnen Sie (durch Anklicken der Kreuze) in
                        der Liste die Berufe, die Ihr Unternehmen generell
                        ausbildet bzw. für die es Praktikumsplätze anbietet.
                        Sollten Sie einen Beruf vermissen, können Sie uns diesen
                        nachmelden. Bitte senden Sie dazu eine E-Mail an{' '}
                        <a href='mailto:clubzukunft@ideenexpo.de'>
                            clubzukunft@ideenexpo.de
                        </a>
                        .
                    </p>
                </div>

                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <fieldset>
                        <div className='exhibitorTableWrap'>
                            <span className='exhibitorTableHeader' />
                            <div className='schoolTableWrap'>
                                <ul className='schoolTable training-grid'>
                                    <div className='header'>
                                        Be&shy;zeich&shy;nung
                                    </div>
                                    <div className='header'>
                                        Pra&shy;kti&shy;kum
                                    </div>
                                    <div className='header'>
                                        Aus&shy;bild&shy;ung
                                    </div>
                                    <ul>
                                        {Array.isArray(
                                            this.props.trainingDef,
                                        ) && this.props.trainingDef.length > 0
                                            ? this.props.trainingDef.map(
                                                  (def, index) => (
                                                      <li
                                                          key={index}
                                                          className='schoolTable training-grid'
                                                      >
                                                          <label>
                                                              {def.description}
                                                          </label>
                                                          <label>
                                                              <Field
                                                                  component='input'
                                                                  type='checkbox'
                                                                  id={`${def.praktikumName}`}
                                                                  name={`${def.praktikumName}`}
                                                              />
                                                              <span />
                                                          </label>
                                                          <label>
                                                              <Field
                                                                  component='input'
                                                                  type='checkbox'
                                                                  id={`${def.ausbildungName}`}
                                                                  name={`${def.ausbildungName}`}
                                                              />
                                                              <span />
                                                          </label>
                                                      </li>
                                                  ),
                                              )
                                            : null}
                                    </ul>
                                </ul>
                            </div>
                        </div>
                        <FormButtons
                            hideBtn={true}
                            handleSubmit={handleSubmit}
                            submit={this.submit.bind(this)}
                        />
                        <legend />
                    </fieldset>
                </form>
                <StepButtons
                    back={'/clubzukunft/career-market/sites'}
                    to={'/clubzukunft/career-market/dual-study'}
                    labelBack={'Standorte'}
                    labelForward={'Duales Studium'}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, _props) => {
    let initialValues = {};

    let trainingDef =
        state.trainingdef && Array.isArray(state.trainingdef)
            ? state.trainingdef.map((def) => ({
                  ID: def.ID,
                  description: def.description,
                  praktikumName: `${def.description
                      .replace(/[- /()\\ßäöüÄÖÜ]/g, '_')
                      .toLowerCase()}_praktikum`,
                  ausbildungName: `${def.description
                      .replace(/[- /()\\ßäöüÄÖÜ]/g, '_')
                      .toLowerCase()}_ausbildung`,
                  trainingIndex: -1,
              }))
            : [];

    const training = state.training;

    if (
        Array.isArray(training) &&
        training.length > 0 &&
        Array.isArray(trainingDef) &&
        trainingDef.length > 0
    ) {
        trainingDef = trainingDef.map((def) => {
            let result = def;
            training.forEach((study, index) => {
                if (def.ID === study.idAusbildungsberuf) {
                    initialValues = {
                        ...initialValues,
                        [def.praktikumName]: study.kzPraktikum || 0,
                        [def.ausbildungName]: study.kzAusbildung || 0,
                    };
                    result = {
                        ID: def.ID,
                        description: def.description,
                        praktikumName: def.praktikumName,
                        ausbildungName: def.ausbildungName,
                        trainingIndex: index,
                    };
                }
            });
            return result;
        });
    }

    return {
        initialValues: initialValues,
        trainingDef: trainingDef || [],
        training: state.training || [],
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, null, null, {
        areStatesEqual: (next, prev) => {
            return (
                prev.trainingdef === next.trainingdef &&
                prev.training === next.training
            );
        },
    }),
)(
    reduxForm({
        form: 'careermarkettraining',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(Training),
);
