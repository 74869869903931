import { store } from '../../../store';
import {
    BACKEND_URL,
    ENV,
    SHOWSTAGE_SAVE,
    SHOWSTAGE_ADD,
    SHOWSTAGE_NEW,
} from '../../../global_constants';
import { setShowstageUpdate } from '../exhibitor-actions.js';
import {
    showAlert,
    ERROR_UNAUTHORIZED,
    ERROR,
    SUCCESS,
} from '../../../components/exhibitor/alerts';

const mapStages = (values, json = {}) => {
    return {
        kzFreigabeAussteller:
            values.status === 'freigegeben'
                ? 1
                : values.status === 'inbearbeitung'
                ? 0
                : json.kzFreigabeAussteller
                ? json.kzFreigabeAussteller
                : '',
        titelBeitrag:
            typeof values.beitrag_titel !== 'undefined'
                ? values.beitrag_titel
                : json.titelBeitrag || '',
        artBeitrag:
            typeof values.beitrag_art !== 'undefined'
                ? values.beitrag_art
                : json.artBeitrag || '',
        beschreibung:
            typeof values.beitrag_beschreibung !== 'undefined'
                ? values.beitrag_beschreibung
                : json.beschreibung || '',
        wuenscheErgaenzungen:
            typeof values.beitrag_wuensche !== 'undefined'
                ? values.beitrag_wuensche
                : json.wuenscheErgaenzungen || '',
        gefuehrtVon:
            typeof values.ausstellerAgentur !== 'undefined'
                ? values.ausstellerAgentur
                : json.gefuehrtVon || '',
        ausstellerAgentur:
            typeof values.ausstellerAgentur !== 'undefined'
                ? values.ausstellerAgentur
                : json.ausstellerAgentur || '',
        nameAnsprechpartner:
            typeof values.ansprechpartner !== 'undefined'
                ? values.ansprechpartner
                : json.nameAnsprechpartner || '',
        emailAnsprechpartner:
            typeof values.ansprechpartner_email !== 'undefined'
                ? values.ansprechpartner_email
                : json.emailAnsprechpartner || '',
        telefonnummerAnsprechpartner:
            typeof values.ansprechpartner_tel !== 'undefined'
                ? values.ansprechpartner_tel
                : json.telefonnummerAnsprechpartner || '',
        erreichbarkeitAnsprechpartner:
            typeof values.erreichbarkeit !== 'undefined'
                ? values.erreichbarkeit
                : json.erreichbarkeitAnsprechpartner || '',
    };
};

export const approveShowStage = (json) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    return fetch(BACKEND_URL + SHOWSTAGE_SAVE, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authenticationData.accessToken}`,
        },
        body: JSON.stringify(json),
    }).then((response) => {
        if (!response.ok) {
            showAlert(ERROR, 'Die Freigabe konnte nicht gespeichert werden.');
            return 500;
        } else {
            showAlert(SUCCESS, 'Die Freigabe wurde gespeichert.');
            return 200;
        }
    });
};

export const updateStage = (json, values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    return function (dispatch) {
        ENV === 'development' &&
            console.info('updating the showstage with id = ', json.ID);
        const newValues = mapStages(values, json);
        const jsonNew = { ...json, ...newValues };
        ENV === 'development' && console.log(jsonNew);
        return fetch(BACKEND_URL + SHOWSTAGE_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(jsonNew),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('showstage details fetch:', json);
                if (!json.ID) {
                    return Promise.reject(
                        'showstage details fetch validity check: fail',
                    );
                }
                return json;
            })
            .then((json) => {
                return dispatch(setShowstageUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};

export const insertShowstage = (values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    const getState = store.getState();
    const exhibitor =
        Array.isArray(getState.exhibitor) && getState.exhibitor.length >= 1
            ? getState.exhibitor[0]
            : {};
    return function (dispatch) {
        const newValues = mapStages(values);
        // request a new prototype
        return fetch(BACKEND_URL + SHOWSTAGE_NEW, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData,
                    userId4Hist: undefined,
                },
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                // merge/overwrite requested json prototype with user input
                const jsonNew = { ...json, ...newValues };
                jsonNew.idProjekt = getState.authenticationData
                    ?.TpProjektLstItem
                    ? getState.authenticationData.TpProjektLstItem[0].ID
                    : null;
                jsonNew.idFirma = exhibitor.idAussteller;
                jsonNew.txtProjekt = getState.authenticationData
                    ?.TpProjektLstItem
                    ? getState.authenticationData.TpProjektLstItem[0].name
                    : null;
                return fetch(BACKEND_URL + SHOWSTAGE_ADD, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authenticationData.accessToken}`,
                    },
                    body: JSON.stringify({
                        ...jsonNew,
                        login: {
                            // TODO: pick properties manually!
                            ...authenticationData,
                            userId4Hist: undefined,
                        },
                    }),
                });
            })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('showstage details fetch:', json);
                return json;
            })
            .then((json) => {
                return dispatch(setShowstageUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};
