import React, { Component } from 'react';
import { push } from 'connected-react-router';

import Loader from '../loader';

import Footer from '../footer';
import style from '../index.module.css';
/*
Base question with props requirements validation
*/
class Question extends Component {
    // rome-ignore lint/correctness/noUnreachableSuper: <explanation>möglicherweise ein falsch-positiver Treffer bzw. Rome-Bug
    constructor(props, requirements, state) {
        super(props);
        console.log('requirements', requirements);
        this.state = {
            ready: true,
            statusBar: null,
            ...state,
        };
        if (Array.isArray(requirements)) {
            requirements.forEach((reqParam) => {
                let req = [];
                if (typeof reqParam === 'string') {
                    req = reqParam.split('.');
                }
                if (!this.checkRequiredProps(req)) {
                    this.state.ready = false;
                }
            });
        }
    }

    /*
	Check if this.props contains a nested element.
	*/
    checkRequiredProps(requirements) {
        if (!requirements || Object.is({}, requirements)) {
            return true;
        }
        if (Array.isArray(requirements)) {
            let cur = this.props;

            requirements.forEach((requirement) => {
                cur = cur[requirement];
                if (!cur) {
                    return false;
                }
                if (Array.isArray(cur)) {
                    if (cur.length === 0) {
                        return false;
                    }
                    return !cur.some((element) => !element);
                }
            });
        }
        return true;
    }
    componentDidMount() {
        if (!this.state.ready) {
            this.props.dispatch(push('/'));
        } else {
            this.validate();
        }
    }
    validate() {
        /* ... */
    }
    setFooterHtml() {
        return <Footer />;
    }
    renderQuestion() {
        throw new Error('unimplemented renderQuestion');
    }
    positionStatusBar(statusBarParam) {
        const statusBar = statusBarParam || document.getElementById('status');
        const referenceNode = document.getElementsByTagName('h1');
        if (statusBar && referenceNode?.length > 0) {
            process.env.REACT_APP_IDEENEXPO === 'invitation'
                ? referenceNode[0].after(statusBar)
                : referenceNode[0].parentNode.insertBefore(
                      statusBar,
                      referenceNode.nextSibling,
                  );
            if (!this.state.statusBar) {
                this.setState({ statusBar });
            }
        }
    }
    render() {
        if (this.state.ready === false) {
            return <Loader visible={true} />;
        } else {
            const question = this.renderQuestion();
            const footer = this.setFooterHtml();
            this.positionStatusBar(this.state.statusBar);
            return (
                <div className={style.questionContainer}>
                    {question}
                    {footer}
                </div>
            );
        }
    }
}

export default Question;
