import { VERANSTALTUNG__EVENT__HAUPTVERANSTALTUNG } from '../../../backend-id-constants.js';
import {
    ERROR,
    ERROR_UNAUTHORIZED,
    SUCCESS,
    showAlert,
} from '../../../components/exhibitor/alerts';
import {
    BACKEND_URL,
    ENV,
    WORKSHOP_ADD,
    WORKSHOP_NEW,
    WORKSHOP_SAVE,
} from '../../../global_constants';
import { store } from '../../../store';
import { setWorkshopUpdate } from '../exhibitor-actions.js';

const mapWorkshop = (values, authenticationData, json = {}) => {
    const newValues = {
        idStatus:
            values.status === 'inbearbeitung'
                ? 1
                : json.idStatus
                ? json.idStatus
                : '',
        // TODO: Victor's update made idEvent a mandatory field again.
        // But, in exhibitor/Ausstellportal there is no way to select/change the associated event for a workshop.
        // That's why we had previously made idEvent an optional field in the backend and - long-term - that is
        // the prefered solution. Till then, we need to hardcode it. Currently, 161924 is "Hauptveranstaltung
        // IdeenExpo24".
        // TODO: implement a proper solution with the field being mandatory
        // the question is how to determine which entry is the "Hauptveranstaltung IdeenExpoXX" for all future IdeenExpos
        idEvent: VERANSTALTUNG__EVENT__HAUPTVERANSTALTUNG,
        // Same as above: idTyp was mandatory -> we changed to optional -> now it's mandatory again -> prefered solution:
        // Make it optional again.
        // Currently, 23 is "Workshop"
        idTyp: 23,
        titel:
            typeof values.workshop_name !== 'undefined'
                ? values.workshop_name
                : json.titel || '',
        titelInternet:
            typeof values.titel !== 'undefined'
                ? values.titel
                : json.titelInternet || '',
        textInternet:
            typeof values.text !== 'undefined'
                ? values.text
                : json.textInternet || '',
        veranstalter:
            typeof values.veranstalter !== 'undefined'
                ? values.veranstalter
                : json.veranstalter || '',
        nameReferent:
            typeof values.referent_name !== 'undefined'
                ? values.referent_name
                : json.nameReferent || '',
        telefonummerReferent:
            typeof values.referent_tel !== 'undefined'
                ? values.referent_tel
                : json.telefonummerReferent || '',
        emailReferent:
            typeof values.referent_email !== 'undefined'
                ? values.referent_email
                : json.emailReferent || '',
        kzKlasse56: values.zielgruppe56 ? 1 : 0,
        kzKlasse78: values.zielgruppe78 ? 1 : 0,
        kzKlasse910: values.zielgruppe910 ? 1 : 0,
        kzJahrgang111213: values.zielgruppe1112 ? 1 : 0,
        kzZielGruppeSonstige: values.zielgruppe_sonstige ? 1 : 0,
        kzBiologie: values.bz_biologie ? 1 : 0,
        kzChemie: values.bz_chemie ? 1 : 0,
        kzMathematikInformatik: values.bz_mathematik ? 1 : 0,
        kzMedizin: values.bz_medizin ? 1 : 0,
        kzPhysik: values.bz_physik ? 1 : 0,
        kzTechnik: values.bz_technik ? 1 : 0,
        kzUmwelt: values.bz_umwelt ? 1 : 0,
        kzBerufOrientierung: values.bz_berufsorientierung ? 1 : 0,
        kzBezugSonstiges: values.bz_sonstiges ? 1 : 0,
        teilnehmerMin:
            typeof values.teilnehmer_min !== 'undefined'
                ? values.teilnehmer_min
                : json.teilnehmerMin || '',
        teilnehmerMax:
            typeof values.teilnehmer_max !== 'undefined'
                ? values.teilnehmer_max
                : json.teilnehmerMax || '',
        anmerkungenZurZeitplanung:
            typeof values.anmerkungenZeitplanung !== 'undefined'
                ? values.anmerkungenZeitplanung
                : json.anmerkungenZurZeitplanung || '',
        anzahlReferenten:
            typeof values.anz_referenten !== 'undefined'
                ? values.anz_referenten
                : json.anzahlReferenten || '',
        anzahlRefPlatz:
            typeof values.anz_referenten_plaetze !== 'undefined'
                ? values.anz_referenten_plaetze
                : json.anz_referenten_plaetze || '',
        sonstigeZielgruppe:
            typeof values.zielgruppe_sonstige_spez !== 'undefined'
                ? values.zielgruppe_sonstige_spez
                : json.sonstigeZielgruppe || '',
        kzTischMitStuhl:
            values.tisch === 'ja'
                ? 1
                : values.tisch === 'nein'
                ? 0
                : json.kzTischMitStuhl
                ? json.kzTischMitStuhl
                : '',
        kzRednerpult:
            values.pult === 'ja'
                ? 1
                : values.pult === 'nein'
                ? 0
                : json.kzRednerpult
                ? json.kzRednerpult
                : '',
        kzBeamer:
            values.beamer === 'ja'
                ? 1
                : values.beamer === 'nein'
                ? 0
                : json.kzBeamer
                ? json.kzBeamer
                : '',
        kzFlipchart:
            values.flip === 'ja'
                ? 1
                : values.flip === 'nein'
                ? 0
                : json.kzFlipchart
                ? json.kzFlipchart
                : '',
        kzStromAmTisch:
            values.referenten_strom === 'ja'
                ? 1
                : values.referenten_strom === 'nein'
                ? 0
                : json.kzStromAmTisch
                ? json.kzStromAmTisch
                : '',
        kzInternet:
            values.referenten_internet === 'ja'
                ? 1
                : values.referenten_internet === 'nein'
                ? 0
                : json.kzInternet
                ? json.kzInternet
                : '',
        anzahlTeilnehmer:
            typeof values.anz_teilnehmer !== 'undefined'
                ? values.anz_teilnehmer
                : json.anzahlTeilnehmer || '',
        kzBestuhlung:
            values.bestuhlung === 'ja'
                ? 1
                : values.bestuhlung === 'nein'
                ? 0
                : json.kzBestuhlung
                ? json.kzBestuhlung
                : '',
        kzTische:
            values.tische === 'ja'
                ? 1
                : values.tische === 'nein'
                ? 0
                : json.kzTische
                ? json.kzTische
                : '',
        kzStromAmTischTeilnehmer:
            values.teilnehmer_strom === 'ja'
                ? 1
                : values.teilnehmer_strom === 'nein'
                ? 0
                : json.kzStromAmTischTeilnehmer
                ? json.kzStromAmTischTeilnehmer
                : '',
        internetTeilnehmertische:
            values.teilnehmer_internet === 'ja'
                ? 1
                : values.teilnehmer_internet === 'nein'
                ? 0
                : json.internetTeilnehmertische
                ? json.internetTeilnehmertische
                : '',
        idAnordnung:
            values.anordnung_tische === 'reihe'
                ? 1
                : values.anordnung_tische === 'uform'
                ? 2
                : values.anordnung_tische === 'gruppentische'
                ? 3
                : json.idAnordnung
                ? json.idAnordnung
                : '',
        kzDruckluft:
            values.druckluft === 'ja'
                ? 1
                : values.druckluft === 'nein'
                ? 0
                : json.kzDruckluft
                ? json.kzDruckluft
                : '',
        druckInBar: values.druckbar || json.druckInBar || '',
        wasseranschlussRaum:
            values.wasser === 'ja'
                ? 1
                : values.wasser === 'nein'
                ? 0
                : json.wasseranschlussRaum
                ? json.wasseranschlussRaum
                : '',
        wasseranschlussNaehe:
            values.wasser_nah === 'ja'
                ? 1
                : values.wasser_nah === 'nein'
                ? 0
                : json.wasseranschlussNaehe
                ? json.wasseranschlussNaehe
                : '',
        kzGefaehrdungspotenzial:
            values.gefaehrdung === 'ja'
                ? 1
                : values.gefaehrdung === 'nein'
                ? 0
                : json.kzGefaehrdungspotenzial
                ? json.kzGefaehrdungspotenzial
                : '',
        emissionen:
            typeof values.gefaehrdung_beschreibung !== 'undefined'
                ? values.gefaehrdung_beschreibung
                : json.emissionen || '',
        sonstigeRaumausstattung:
            typeof values.ausstattung_sonstiges !== 'undefined'
                ? values.ausstattung_sonstiges
                : json.sonstigeRaumausstattung || '',
        sonstigeHilfsmittel:
            typeof values.hilfsmittel_sonstige !== 'undefined'
                ? values.hilfsmittel_sonstige
                : json.sonstigeHilfsmittel || '',
        dauerVeranstaltung:
            typeof values.workshop_dauer !== 'undefined'
                ? values.workshop_dauer
                : json.dauerVeranstaltung || '',
        dauerAufbau:
            typeof values.workshop_aufbau !== 'undefined'
                ? values.workshop_aufbau
                : json.dauerAufbau || '',
        dauerAbbau:
            typeof values.workshop_abbau !== 'undefined'
                ? values.workshop_abbau
                : json.dauerAbbau || '',
        luftmenge:
            typeof values.luftverbrauch !== 'undefined'
                ? values.luftverbrauch
                : json.luftmenge || '',
        haeufigkeitProTag:
            typeof values.haeufigkeit !== 'undefined'
                ? values.haeufigkeit
                : json.haeufigkeitProTag || '',
        interesseWorkshopWochenende: values.wochenende
            ? 1
            : !values.wochenende
            ? 0
            : json.interesseWorkshopWochenende || '',
        wunschzeiten:
            typeof values.wunschzeiten !== 'undefined'
                ? values.wunschzeiten
                : json.wunschzeiten || '',
        kzStatusFreigabeAussteller: json.kzStatusFreigabeAussteller || 0,
        fruehererAufbau: values.fruehererAufbau
            ? 1
            : !values.fruehererAufbau
            ? 0
            : json.fruehererAufbau || '',
        kurzbeschreibung:
            typeof values.kurzbeschreibung !== 'undefined'
                ? values.kurzbeschreibung
                : json.kurzbeschreibung || '',
        fruehererAufbauAnmerkung:
            typeof values.fruehererAufbauAnmerkung !== 'undefined'
                ? values.fruehererAufbauAnmerkung
                : json.fruehererAufbauAnmerkung || '',
        anmerkungenOrganisatorischerAblauf:
            typeof values.anmerkungenOrganisatorischerAblauf !== 'undefined'
                ? values.anmerkungenOrganisatorischerAblauf
                : json.anmerkungenOrganisatorischerAblauf || '',
    };

    const veranstzpIds = store.getState().veranstzp;
    newValues.tp_Veranstaltung_2_VeranstaltungZeitpunktLst = {
        login: {
            ...authenticationData,
        },
        TpVeranstaltungZeitpunktLstItem: [],
    };

    for (let i = 0; i < veranstzpIds.length; i++) {
        if (values[`day${i}`]) {
            newValues.tp_Veranstaltung_2_VeranstaltungZeitpunktLst.TpVeranstaltungZeitpunktLstItem.push(
                {
                    MANDANT_NR: 1,
                    idVeranstaltung: values.workshop_id,
                    idZeitraum: veranstzpIds[i].ID,
                },
            );
        }
    }
    return newValues;
};

export const updateWorkshop = (json, values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;

    // Wenn anstelle von Values (die normalerweise die geänderten Daten aus der
    // Detaiseite beinhalten) false übergeben wird, dann werden diese Detailänderungen nicht mit dem JSON gemergt,
    // sondern es wird direkt das JSON mit geänderten Kennzeichen für die Freigabe an den Server übermittelt
    if (values === false) {
        return fetch(BACKEND_URL + WORKSHOP_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(json),
        }).then((response) => {
            if (!response.ok) {
                showAlert(
                    ERROR,
                    'Die Freigabe konnte nicht gespeichert werden.',
                );
                return 500;
            } else {
                showAlert(SUCCESS, 'Die Freigabe wurde gespeichert.');
                return 200;
            }
        });
    }

    return function (dispatch) {
        ENV === 'development' &&
            console.info('updating the workshop with id = ', json.ID);
        const newValues = mapWorkshop(values, authenticationData, json);
        const jsonNew = { ...json, ...newValues };
        return fetch(BACKEND_URL + WORKSHOP_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(jsonNew),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('workshop details fetch:', json);
                if (!json.ID) {
                    return Promise.reject(
                        'workshop details fetch validity check: fail',
                    );
                }
                return json;
            })
            .then((json) => {
                return dispatch(setWorkshopUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};

export const insertWorkshop = (values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    const getState = store.getState();
    const exhibitor =
        Array.isArray(getState.exhibitor) && getState.exhibitor.length >= 1
            ? getState.exhibitor[0]
            : {};
    return function (dispatch) {
        const newValues = mapWorkshop(values, authenticationData);
        // request a new exhibit prototype
        return fetch(BACKEND_URL + WORKSHOP_NEW, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData,
                    userId4Hist: undefined,
                },
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                // merge/overwrite requested json prototype with user input
                const jsonNew = { ...json, ...newValues };
                jsonNew.idProjekt = getState.authenticationData
                    ?.TpProjektLstItem
                    ? getState.authenticationData.TpProjektLstItem[0].ID
                    : null;
                jsonNew.idFirma = exhibitor.idAussteller;
                jsonNew.txtProjekt = getState.authenticationData
                    ?.TpProjektLstItem
                    ? getState.authenticationData.TpProjektLstItem[0].name
                    : null;
                // jsonNew.USER_ID_CHANGE = `${authenticationData.userIdLogin}`
                // jsonNew.USER_ID_NEW = `${authenticationData.userIdLogin}`
                // jsonNew.USER_NR_CHANGE = authenticationData.userId
                // jsonNew.USER_NR_NEW = authenticationData.userId
                return fetch(BACKEND_URL + WORKSHOP_ADD, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authenticationData.accessToken}`,
                    },
                    body: JSON.stringify({
                        ...jsonNew,
                        login: {
                            // TODO: pick properties manually!
                            ...authenticationData,
                            userId4Hist: undefined,
                        },
                    }),
                });
            })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('workshop details fetch:', json);
                return json;
            })
            .then((json) => {
                if (json.ID === 0) {
                    return Promise.reject('Speichern war Nicht erfolgreich');
                } else {
                    return json;
                }
            })
            .then((json) => {
                return dispatch(setWorkshopUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};
