export const mapEntriesByKey = (
    input,
    keyTarget = 'externId',
    valueTarget = 'ID',
) => {
    return input.reduce((acc, current) => {
        acc[current[keyTarget]] = current[valueTarget];
        return acc;
    }, {});
};
