import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { destroy, reduxForm } from 'redux-form';

import { setBannerPrintList } from '../../../../actions/exhibitor/exhibitor-actions';
import { createEntityConfig } from '../../../../actions/exhibitor/fetcher/entity-list-detail.fetcher';
import { fetchItemListFromConfig } from '../../../../actions/exhibitor/fetcher/fetcher';
import style from '../../index.module.css';
import { approveBannerPrint, deleteBannerPrint } from './banner-print.actions';
import sx from './banner-print.module.scss';
import { getOrganisation } from '../../../../actions/exhibitor/organisation';
import Loader from '../../../common/loader';
import Stepbuttons from '../../stepbuttons';

const mapStateToProps = (state) => {
    return {
        list: state.bannerPrintList,
    };
};

export const BannerPrintList = ({ dispatch, list }) => {
    const history = useHistory();
    const [isReady, setIsReady] = useState(false);
    const [currentlyApprovingIds, setCurrentlyApprovingIds] = useState({});

    const handleFetch = async () => {
        setIsReady(false);
        await dispatch(getOrganisation());
        const ec = createEntityConfig('bannerPrint');
        const list = await fetchItemListFromConfig(ec);
        await dispatch(setBannerPrintList(list));

        setIsReady(true);
    };

    useEffect(() => {
        handleFetch();
    }, []);

    if (!isReady) {
        return (
            <div style={{ height: '90vh' }}>
                <Loader visible />
            </div>
        );
    }

    return (
        <div className={style.question}>
            <div className={style.header}>
                <h1>Hallenbanner</h1>
            </div>
            <legend>
                Zusätzlich zum Branding Ihres Standmobiliars benötigen wir
                Ihr(e) Logo(s) für das Sponsorenbanner in den Messehallen. Bitte
                laden Sie hier die entsprechenden Dateien in einer druckfähigen
                Auflösung hoch und beschriften Sie Ihr(e) Logo(s) in dem
                Textfeld nach folgender Systematik „Firmenname XY“.
                <br />
                <br />
                <b>Vorgaben für Print-Logo:</b>
                <br />
                Bevorzugtes Format ist eine Vektorgrafik, z.B. EPS-, AI- oder
                PDF-Datei. Bei Dateien im TIFF- oder JPG-Format ist eine
                Mindestauflösung von 300dpi bzw. mindestens eine Breite von 3500
                Pixeln erforderlich.
            </legend>
            <div className={sx.bannerActions} style={{ marginTop: '60px' }}>
                <Link
                    className={sx.action}
                    to={'/organisation/banner-print/edit'}
                    onClick={() => dispatch(destroy('banner'))}
                >
                    <div className={sx.label}>Logo hinzufügen</div>
                    <FontAwesomeIcon icon={faPlus} size='1x' />
                </Link>
            </div>
            <table className={sx.bannerTable}>
                <thead>
                    <tr>
                        <th>Beschreibung</th>
                        <th>Datei</th>
                        <th style={{ width: 0 }}>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map?.((banner) => (
                        <tr
                            key={banner.ID}
                            onClick={() => {
                                history.push(
                                    `/organisation/banner-print/edit?id=${banner.ID}`,
                                );
                            }}
                        >
                            <td>{banner.beschreibung}</td>
                            <td>{banner.bannerPrintDatei}</td>
                            <td>
                                <div className={sx.actions}>
                                    {banner.kzFreigabeAussteller === 1 ? (
                                        <div className={classNames(sx.badge)}>
                                            Freigegeben
                                        </div>
                                    ) : (
                                        <div
                                            className={classNames(
                                                sx.action,
                                                sx.button,
                                            )}
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                setCurrentlyApprovingIds(
                                                    (_) => ({
                                                        ..._,
                                                        [banner.ID]: true,
                                                    }),
                                                );
                                                await approveBannerPrint(
                                                    banner.ID,
                                                );
                                                await handleFetch();
                                                setCurrentlyApprovingIds(
                                                    (_) => ({
                                                        ..._,
                                                        [banner.ID]: false,
                                                    }),
                                                );
                                            }}
                                        >
                                            {currentlyApprovingIds[
                                                banner.ID
                                            ] === true ? (
                                                <Loader visible />
                                            ) : (
                                                'Freigeben'
                                            )}
                                        </div>
                                    )}
                                    {banner.kzFreigabeAussteller === 0 && (
                                        <div
                                            className={classNames(
                                                sx.action,
                                                sx.button,
                                                sx.transparent,
                                            )}
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                await deleteBannerPrint(
                                                    banner.ID,
                                                );
                                                handleFetch();
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                size='1x'
                                            />
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Stepbuttons
                back='/organisation/storage'
                to='/organisation/banner-digital'
                labelBack='Lagerfläche'
                labelForward='Webseite/App'
            />
        </div>
    );
};

export const BannerPrint = compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'organisationBanner',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(BannerPrintList),
);
