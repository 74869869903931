import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { store } from '../../../../../../store.js';
import { withRouter } from 'react-router-dom';
import {
    updateCompany,
    getEntity,
} from '../../../../../../actions/exhibitor/exhibitor-actions.js';
import style from '../../../../index.module.css';
import FormButtons from '../../../../formbuttons';
import StepButtons from '../../../../stepbuttons';
import {
    showAlert,
    SUCCESS,
    SUCCESS_MSG,
    ERROR,
    ERROR_MSG_BACKEND_SAVE,
    ERROR_MSG_BACKEND_FETCH,
} from '../../../../alerts';

class ContactDataInternet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            submitSuccess: false,
        };
    }
    handleInput(_e) {
        this.setState({ valid: true });
    }

    resetForm() {
        this.setState({
            submitSuccess: false,
        });
    }
    submit(values) {
        const pupilsValues =
            store.getState().form.contactdatapupils?.values || {};
        const studentsValues =
            store.getState().form.contactdatastudents?.values || {};
        Promise.resolve()
            .then(() => {
                if (this.props.handle) {
                    this.props
                        .dispatch(
                            updateCompany(this.props.handle, {
                                ...pupilsValues,
                                ...studentsValues,
                                ...values,
                            }),
                        )
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                }
            })
            .then(() =>
                this.props
                    .dispatch(getEntity('company'))
                    .then(null, (_e) =>
                        Promise.reject({ _error: ERROR_MSG_BACKEND_FETCH }),
                    ),
            )
            .then(() =>
                this.setState({
                    submitSuccess: true,
                }),
            )
            .then(() => showAlert(SUCCESS, SUCCESS_MSG))
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_SAVE);
                this.setState({ submitSuccess: false });
                this.props.dispatch(stopSubmit('contactdatainternet', e));
            });
    }
    render() {
        const { handleSubmit } = this.props;
        return (
            <div className={style.header}>
                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <fieldset>
                        <legend />
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='contactdata_id'
                            id='contactdata_id'
                            hidden
                            disabled
                            value={this.props.handle?.ID}
                        />
                        <label htmlFor='firmenname'>
                            <span>Firmennamen für KarriereMarkt</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='firmenname'
                            id='firmenname'
                        />
                        <label htmlFor='webseite'>
                            <span>
                                Karriere-Webseite oder alternativ
                                Unternehmens-Homepage
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='webseite'
                            id='webseite'
                        />
                        <label htmlFor='webseite_schueler'>
                            <span>Webseite für SchülerInnen (optional)</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='webseite_schueler'
                            id='webseite_schueler'
                        />
                        <label htmlFor='webseite_studenten'>
                            <span>Webseite für Studierende (optional)</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='webseite_studenten'
                            id='webseite_studenten'
                        />
                        <label htmlFor='branchenportal'>
                            <span>Branchenportal (optional)</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='branchenportal'
                            id='branchenportal'
                        />
                        <FormButtons
                            hideBtn={true}
                            saveButtonAlignRight={true}
                            handleSubmit={handleSubmit}
                            submit={this.submit.bind(this)}
                        />
                        <legend>(*) = Pflichtfeld</legend>
                    </fieldset>
                </form>
                <StepButtons
                    back={'/clubzukunft/career-market'}
                    toTab={() => this.props.toTab(1)}
                    disabled={!this.state.valid}
                    labelBack={'KarriereMarkt'}
                    labelForward={'Ansprechpartner für Schülerinnen & Schüler'}
                />
            </div>
        );
    }
}

const mapStateToProps = (_state, props) => {
    const found = props.handle;
    let initialValues = {};
    if (found) {
        initialValues = {
            contactdata_id: found.ID || '',
            firmenname: found.firmennameKarriereboerse || '',
            webseite: found.karriereUnternehmensWebsite || '',
            webseite_schueler: found.websiteSchueler || '',
            webseite_studenten: found.websiteStudenten || '',
            branchenportal: found.branchenportal || '',
        };
    }
    return {
        initialValues: initialValues,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'contactdatainternet',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(ContactDataInternet),
);
