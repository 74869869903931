import React from 'react';
import { connect } from 'react-redux';
import ReactCSSTransitionReplace from 'react-css-transition-replace';

import num1 from '../../../images/webp/1.webp';
import num2 from '../../../images/webp/2.webp';
import num3 from '../../../images/webp/3.webp';
import num4 from '../../../images/webp/4.webp';
import num5 from '../../../images/webp/5.webp';
import num6 from '../../../images/webp/6.webp';
import num7 from '../../../images/webp/7.webp';
import num8 from '../../../images/webp/8.webp';
const steps = [num1, num2, num3, num4, num5, num6, num7, num8];

const Counter = (props) => {
    if (
        process.env.REACT_APP_IDEENEXPO !== 'invitation' &&
        props.step.step in Object.keys(steps)
    ) {
        return (
            <div id='counter'>
                <ReactCSSTransitionReplace
                    transitionName='cross-fade'
                    transitionEnterTimeout={100}
                    transitionLeaveTimeout={100}
                    overflowHidden={false}
                >
                    <div
                        key={props.step.step}
                        style={{
                            backgroundImage: `url(./${
                                steps[props.step.step - 1]
                            }`,
                        }}
                    />
                </ReactCSSTransitionReplace>
            </div>
        );
    } else {
        return <div id='counter' />;
    }
};

export default connect((state) => state)(Counter);
