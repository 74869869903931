import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import style from '../index.module.css';
import StepButtons from '../stepbuttons';
import {
    getForwardActionForRoles,
    getBackwardActionForRoles,
    determineAuthForRoles,
} from '../functions';

class LiveProgram extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
        };
    }
    render() {
        const forwardAction = getForwardActionForRoles(
            'stageseven',
            this.props,
        );
        const backwardAction = getBackwardActionForRoles(
            'boothVisits',
            this.props,
        );

        const addtionalCareerStageText = this.props
            .hasCarreerStageAuthorization ? (
            <>
                {' '}
                Ihre Beiträge für die KarriereBühne können Sie unter{' '}
                {<Link to='/clubzukunft'>ClubZukunft</Link>} eintragen.
            </>
        ) : (
            ''
        );

        return (
            <>
                <div className={style.question}>
                    <div className={style.header}>
                        <h1>LiveProgramm auf der {this.props.ie_name}</h1>
                        <p>
                            Hier finden Sie wichtige Informationen zum
                            LiveProgramm auf der {this.props.ie_name}. Für die
                            folgenden Bühnen können Sie Angebote und
                            Beteiligungen hinterlegen.
                            {addtionalCareerStageText}
                        </p>
                    </div>
                    <ul>
                        <li>
                            Ihre Ideen/Beiträge zur BühneSieben{' '}
                            {this.props.stageSevenPlace
                                ? this.props.stageSevenPlace
                                : 'in der IdeenHalle 7'}
                        </li>
                        <li>
                            Ihre Ideen/Beiträge zur ShowBühne{' '}
                            {this.props.showStagePlace
                                ? this.props.showStagePlace
                                : 'auf dem Platz der Ideen im Außenbereich'}
                        </li>
                    </ul>
                    <p>
                        Zur weiteren Abstimmung wird sich die für das
                        LiveProgramm zuständige Agentur mit Ihnen in Verbindung
                        setzen. Ein Info-Blatt erläutert Ihnen detailliert die
                        diversen Formate.
                    </p>
                    <p>
                        Wir werden
                        {this.props.hasLiveProgram ? ' ' : ' keine '}
                        Beiträge zum LiveProgramm anbieten.
                    </p>
                    <Link to='/live-program/noliveprogram'>
                        Hier können Sie diese Einstellung ändern.
                    </Link>
                    <br />
                    <br />
                    <StepButtons
                        back={backwardAction.backwardAction}
                        to={forwardAction.forwardAction}
                        labelBack={backwardAction.backwardText}
                        labelForward={forwardAction.forwardText}
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state, _prop) => {
    // Ermittlung der Rollenberechtigungen des angemeldeten Users
    const userAuth = determineAuthForRoles(state);
    const hasLiveProgram =
        Array.isArray(state.contract) &&
        state.contract?.length > 0 &&
        state.contract[0].liveProgrammDeaktiviert === 0;
    return {
        hasLiveProgram,
        ie_name: (state.authenticationData?.TpProjektLstItem || [
            { name: 'IdeenExpo' },
        ])[0].name,
        hasBoothAuthorization: userAuth.hasBoothAuthorization,
        hasWorkshopAuthorization: userAuth.hasWorkshopAuthorization,
        hasLiveprogramAuthorization: userAuth.hasLiveprogramAuthorization,
        hasClubzukunftAuthorization: userAuth.hasClubzukunftAuthorization,
        hasOrganisationAuthorization: userAuth.hasOrganisationAuthorization,
        hasCarreerStageAuthorization: userAuth.hasCarreerStageAuthorization,
    };
};

export default reduxForm({
    form: 'liveprogram',
    // enableReinitialize: true
})(connect(mapStateToProps, null)(LiveProgram));
