import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import style from '../../exhibitor/index.module.css';
import StepButtons from '../stepbuttons';
import {
    getEntity,
    getCustomLst,
    deleteEntity,
} from '../../../actions/exhibitor/exhibitor-actions.js';
import {
    showAlert,
    ERROR,
    ERROR_MSG_BACKEND_FETCH,
    SUCCESS_DELETE,
    DELETE,
} from '../alerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
    getForwardActionForRoles,
    getBackwardActionForRoles,
    determineAuthForRoles,
} from '../functions';
import { approveItem } from '../functions';
import {
    VERANSTALTUNG__TYP__BUEHNEN_BEITRAG,
    VERANSTALTUNG__TYP__TOUR,
    VERANSTALTUNG__TYP__VORTRAG,
    VERANSTALTUNG__TYP__WORKSHOP,
} from '../../../backend-id-constants';
import {
    statusMapper,
    timeMapper,
    tnMapper,
} from './veranstaltungen-functions';
import Loader from '../../common/loader/index.jsx';

class Veranstaltungen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            valid: true,
        };
    }

    async handleFetch() {
        try {
            await Promise.all([
                await this.props.dispatch(getEntity('workshop')),
                await this.props.dispatch(getCustomLst('datesdef')),
                await this.props.dispatch(getCustomLst('targetgroupworkshop')),
                await this.props.dispatch(getCustomLst('bezugsgruppe')),
            ]);
        } catch (e) {
            showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
            console.error('error checking code, promise rejected:', e);
            this.setState({
                state: e?.state ? e.state : 'invalid',
            });
        } finally {
            this.setState({
                ready: true,
            });
        }
    }

    componentDidMount() {
        this.handleFetch();
    }

    deleteItem = (json) => {
        Promise.resolve()
            .then(() => {
                return this.props.dispatch(deleteEntity(json, 'workshop'));
            })
            .then(() => {
                showAlert(DELETE, SUCCESS_DELETE(`Der Workshop ${json.titel}`));
            })
            .catch((e) => {
                showAlert(ERROR, `Element konnte nicht gelöscht werden. ${e}`);
                alert('Element konnte nicht gelöscht werden.', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    };

    // Pflegbare Liste der Veranstaltungs-Typen muss hier zum Vergleich herangezogen werden. Die sind leider im
    // Backend Live und Test unterschiedlich definiert.
    showFilter(workshop) {
        return (
            workshop.idTyp === VERANSTALTUNG__TYP__BUEHNEN_BEITRAG ||
            workshop.idTyp === VERANSTALTUNG__TYP__TOUR ||
            workshop.idTyp === VERANSTALTUNG__TYP__VORTRAG ||
            workshop.idTyp === VERANSTALTUNG__TYP__WORKSHOP
        );
    }

    render() {
        if (!this.state.ready) {
            return (
                <div style={{ height: '90vh' }}>
                    <Loader visible />
                </div>
            );
        }

        const forwardAction = getForwardActionForRoles('workshops', this.props);
        const backwardAction = getBackwardActionForRoles(
            'furnishings',
            this.props,
        );

        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>
                        <b>Veranstaltungen auf Ihrem Stand/Ständen</b>
                        <br />
                    </h1>
                    <p>
                        Hier finden Sie folgende Eingabemöglichkeiten bzw.
                        Informationen:
                        <ul>
                            <li>Workshops (Eingaben erforderlich)</li>
                            <li>
                                Bühnenbeiträge (nur Information, keine Eingaben
                                möglich)
                            </li>
                            <li>
                                Rundgänge (nur Information, keine Eingaben
                                möglich)
                            </li>
                        </ul>
                    </p>
                    <p>
                        Bitte geben Sie Ihre Daten zu den Workshops bis
                        spätestens {this.props.workshop_deadline} in das System
                        ein. Informationen zu Bühnenbeiträgen und Rundgängen
                        werden an dieser Stelle etwa 2 Wochen vor der
                        Veranstaltung angezeigt.
                    </p>
                </div>
                <div className='exhibitorTableWrap'>
                    {/* {this.props.workshop_deadline_within === true ? (
                        <Link
                            className='exhibitorTableButton small-button'
                            to={'/workshops/edits'}
                            onClick={() =>
                                this.props.dispatch(
                                    destroy(
                                        'workshopeditbasis',
                                        'workshopeditschedule',
                                        'workshopeditequipment',
                                    ),
                                )
                            }
                        >
                            Neuer Workshop
                        </Link>
                    ) : null} */}
                    <div className='schoolTableWrap'>
                        <ul className='schoolTable nine-gridlistpagesworkshops'>
                            <div className='header'>ID</div>
                            <div className='header'>Name</div>
                            <div className='header'>Raum / Stand</div>
                            <div className='header'>Titel Internet</div>
                            <div className='header'>Beginn</div>
                            <div className='header'>Buchungs&shy;status</div>
                            <div className='header'>TN ge&shy;bucht</div>
                            <div className='header'>Freigabe</div>
                            <div className='header' />
                            <ul className='nineCols'>
                                {this.props.workshop.map((workshop) => {
                                    let standnummer = '';
                                    if (
                                        workshop
                                            .tp_Veranstaltung_2_Stand2VeranstaltungLst
                                            .TpStand2VeranstaltungLstItem[0]
                                    ) {
                                        standnummer =
                                            workshop
                                                .tp_Veranstaltung_2_Stand2VeranstaltungLst
                                                .TpStand2VeranstaltungLstItem[0]
                                                .standNrFinal;
                                    }
                                    if (this.showFilter(workshop) === true) {
                                        return (
                                            <Link
                                                key={workshop.ID}
                                                to={{
                                                    pathname:
                                                        '/workshops/edits',
                                                    state: {
                                                        handle: workshop.ID,
                                                    },
                                                    search: `${workshop.ID}`,
                                                }}
                                            >
                                                <li className='schoolTable nine-gridlistpagesworkshops'>
                                                    <label>
                                                        {workshop.ID}{' '}
                                                    </label>
                                                    <label>
                                                        {workshop.titel}
                                                    </label>
                                                    <label>{standnummer}</label>
                                                    <label>
                                                        {workshop.titelInternet}
                                                    </label>
                                                    <label>
                                                        {timeMapper(
                                                            workshop.zeitBeginn,
                                                        )}
                                                    </label>
                                                    <label>
                                                        {statusMapper(
                                                            workshop.kzStatusFreigabeAussteller,
                                                            workshop.idBelegung,
                                                        )}
                                                    </label>
                                                    <label>
                                                        {tnMapper(
                                                            workshop.kzStatusFreigabeAussteller,
                                                            workshop,
                                                        )}
                                                    </label>
                                                    {workshop.kzStatusFreigabeAussteller ===
                                                    1 ? (
                                                        <div>Ja</div>
                                                    ) : (
                                                        <div>
                                                            <a
                                                                className='button approve'
                                                                onClick={(
                                                                    e,
                                                                ) => {
                                                                    e.preventDefault();
                                                                    approveItem(
                                                                        this,
                                                                        'workshop',
                                                                        workshop,
                                                                    );
                                                                }}
                                                            >
                                                                Freigeben
                                                            </a>
                                                        </div>
                                                    )}
                                                    {workshop.kzStatusFreigabeAussteller ===
                                                    1 ? (
                                                        <div />
                                                    ) : (
                                                        <div
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (
                                                                    window.confirm(
                                                                        `Wollen Sie den Eintrag "${workshop.titel}" wirklich löschen?`,
                                                                    )
                                                                ) {
                                                                    this.deleteItem(
                                                                        workshop,
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTrash}
                                                                size='1x'
                                                            />
                                                        </div>
                                                    )}
                                                </li>
                                            </Link>
                                        );
                                    }
                                })}
                            </ul>
                        </ul>
                    </div>
                </div>
                <div className='buttonwrap buttonwrap-end'>
                    {/*<Link className="small-button" to="/pdfgenerator">vollständige Liste als Pdf ausgeben</Link>*/}
                </div>
                <StepButtons
                    back={backwardAction.backwardAction}
                    to={forwardAction.forwardAction}
                    labelBack={backwardAction.backwardText}
                    labelForward={forwardAction.forwardText}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, _prop) => {
    // Ermittlung der Rollenberechtigungen des angemeldeten Users
    const userAuth = determineAuthForRoles(state);

    return {
        workshop: state.workshop || [],
        workshop_deadline: state.deadlines?.workshop_deadline,
        veranstzp: state.veranstzp || [],
        veranstzielgruppe: state.veranstzielgruppe || [],
        bezugsgruppe: state.bezugsgruppe || [],
        workshop_deadline_within: state.deadlines.workshop_deadline_within,
        hasBoothAuthorization: userAuth.hasBoothAuthorization,
        hasWorkshopAuthorization: userAuth.hasWorkshopAuthorization,
        hasLiveprogramAuthorization: userAuth.hasLiveprogramAuthorization,
        hasClubzukunftAuthorization: userAuth.hasClubzukunftAuthorization,
        hasOrganisationAuthorization: userAuth.hasOrganisationAuthorization,
        hasCarreerStageAuthorization: userAuth.hasCarreerStageAuthorization,
    };
};

export default reduxForm({
    form: 'veranstaltungen',
})(connect(mapStateToProps, null)(Veranstaltungen));
