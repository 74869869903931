import { store } from '../../../store';
import { BACKEND_URL } from '../../../global_constants';
import { createEntityConfig } from './entity-list-detail.fetcher';

export const fetchItemListFromConfig = async (config) => {
    const res = await fetchEntityList(
        config.listEndpoint,
        config.filterParameterForList,
    );
    if (Object.getOwnPropertyNames(res).includes(config.tpEntityLstItemName)) {
        return res[config.tpEntityLstItemName];
    }
    console.warn(
        `Could not find ${config.tpEntityLstItemName} in response for ${config.listEndpoint}`,
    );
    return [];
};

export const fetchEntityList = async (endpoint, filterParameter) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    const response = await fetch(BACKEND_URL + endpoint, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authenticationData.accessToken}`,
        },
        body: JSON.stringify({
            login: {
                // TODO: pick properties manually!
                ...authenticationData,
                userId4Hist: undefined,
            },
            ...filterParameter,
        }),
    });
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return response.json();
};

/**
 * @returns response json for entity base (defaults)
 * @throws Error on non-200 response, invalid config, invalid JSON response
 * @param {*} config entity config, see `createEntityConfig`
 */
export const fetchNewEntityFromConfig = async (config) => {
    const authData = store.getState().authenticationDataExhibitor;
    if (!config.newEndpoint) {
        throw new Error('No newEndpoint defined');
    }
    const res = await fetch(BACKEND_URL + config.newEndpoint, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authData.accessToken}`,
        },
        body: JSON.stringify({
            login: {
                ...authData,
                userId4Hist: undefined,
            },
            ...config.filterParameterForList,
        }),
    });
    if (!res.ok) {
        throw new Error(res.statusText);
    }
    const data = await res.json();
    return data;
};

/**
 * @returns response json for inserted entity
 * @throws Error on non-200 response, invalid config, invalid JSON response
 * @param {*} config entity config, see `createEntityConfig`
 * @param {*} values values to insert. New entities should be already merged with respective defaults, see `fetchNewEntityFromConfig`
 */
export const fetchInsertEntityFromConfig = async (config, values) => {
    const authData = store.getState().authenticationDataExhibitor;

    const res = await fetch(BACKEND_URL + config.addEndpoint, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authData.accessToken}`,
        },
        body: JSON.stringify({
            login: {
                ...authData,
                userId4Hist: undefined,
            },
            ...values,
            ...config.filterParameterForList,
        }),
    });
    if (!res.ok) {
        throw new Error(res.statusText);
    }
    const data = await res.json();
    return data;
};

/**
 * @returns response json for updated entity
 * @throws Error on non-200 response, invalid config, invalid JSON response
 * @param {*} config entity config, see `createEntityConfig`
 * @param {*} values values to update.
 * NOTE entity override behavior is not clearly defined, please check!
 */
export const fetchUpdateEntityFromConfig = async (config, values) => {
    const authData = store.getState().authenticationDataExhibitor;

    const res = await fetch(BACKEND_URL + config.updateEndpoint, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authData.accessToken}`,
        },
        body: JSON.stringify({
            login: {
                ...authData,
                userId4Hist: undefined,
            },
            ...values,
            ...config.filterParameterForList,
        }),
    });
    if (!res.ok) {
        throw new Error(res.statusText);
    }
    const data = await res.json();
    return data;
};

/**
 * @returns response json for deleted entity
 * @throws Error on non-200 response, invalid config, invalid JSON response
 * @param {*} config entity config, see `createEntityConfig`
 * @param {*} ID ID of entity to delete
 */
export const fetchDeleteEntityFromConfig = async (config, ID) => {
    const authData = store.getState().authenticationDataExhibitor;

    const res = await fetch(BACKEND_URL + config.deleteEndpoint, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authData.accessToken}`,
        },
        body: JSON.stringify({
            login: {
                ...authData,
                userId4Hist: undefined,
            },
            ID,
        }),
    });
    if (!res.ok) {
        throw new Error(res.statusText);
    }
    const data = await res.json();
    return data;
};

export const fetchSingleEntityFromConfig = async (config, ID, filter) => {
    return fetchSingleEntity(config.getEndpoint, ID, {
        ...config.filterParameterForList,
        ...(filter ? filter : {}),
    });
};

export const fetchSingleEntity = async (endpoint, ID, filter) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    const response = await fetch(BACKEND_URL + endpoint, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authenticationData.accessToken}`,
        },
        body: JSON.stringify({
            login: {
                // TODO: pick properties manually!
                ...authenticationData,
                userId4Hist: undefined,
            },
            ...(ID ? { ID } : {}),
            ...(filter ? filter : {}),
        }),
    });
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return JSON.parse(await response.text());
};

export const fetchListAsEntity = async (entityName, filterCriteria) => {
    const {
        listEndpoint,
        filterParameterForList,
        tpEntityLstItemName,
        // Randfall, hier müssen die bestehenden Daten beibehalten werden. dispatchEntity2 ruft hier z.B. setBoothFeatureUpdate auf.
        dispatchEntity2,
    } = createEntityConfig(entityName);
    const tpEntityLst = await fetchEntityList(listEndpoint, {
        ...filterCriteria,
        ...filterParameterForList,
    });
    const items = tpEntityLst[tpEntityLstItemName] || [];

    // Listeneinträge werden hier implizit als Detaildatensätze behandelt.
    // Das verhindert, dass jedes mal z.B. alle Bilder-Uploads neu geladen werden.
    return dispatchEntity2(items);
};
