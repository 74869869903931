import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createHashHistory } from 'history';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { rootReducer } from './reducers';
import { initialState } from './global_constants';

export const history = createHashHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// import _ from 'lodash'
// let blacklistTransform = createTransform((inboundStateArg, key) => {
// 	var inboundState = inboundStateArg;
// 	if (key === 'person') {
// 		if (Array.isArray(inboundState)) {
// 			inboundState = _.map(inboundState, function (obj) {
// 				return _.omit(obj, ['PLZ', 'nachname', 'type', 'email']);
// 			});
// 		}
// 		}
// 		if (key === 'deadlines') {
// 				inboundState = _.omit(inboundState, ['stages_deadline']);
// 		}
// 		if (key === 'deadlines') {
// 				inboundState = _.omit(inboundState, ['workshop_deadline']);
// 		}
// 	return inboundState;
// })

export const persistConfig = {
    key: `ideenexpo-${process.env.REACT_APP_IDEENEXPO}`,
    storage,
    whitelist: [],
    // whitelist:
    //     process.env.REACT_APP_IDEENEXPO === 'exhibitor' &&
    //     process.env.REACT_APP_ENV !== 'development'
    //         ? [
    //               'authenticationDataExhibitor',
    //               'user2role',
    //               'exhibitor',
    //               'company',
    //               'project',
    //               'site',
    //               'deadlines',
    //               'organisation',
    //               'boothFeatures',
    //           ]
    //         : undefined,
    // The following would be enough if we'd like to blacklist entire objects,
    // not nested properties of objects in arrays.
    // blacklist: ['exhibit', 'deadlines']
    // The following would be necessary if we'd like to omit nested properties
    // of objects in arrays
    // transforms: [blacklistTransform]
};

const combinedReducer = persistReducer(persistConfig, rootReducer(history));

const appReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        clearTimeout(state.authTimeout);
        state = {
            ...state,
            authenticationDataExhibitor: [],
            bezugsgruppe: [],
            board: [],
            booth2feature: [],
            booth: [],
            boothFeatures: [],
            boothList: [],
            careerstage: [],
            catering: [],
            cateringList: [],
            company2person: [],
            company2site: [],
            company: [],
            companyList: [],
            deadlines: [],
            dualstudy: [],
            dualstudyList: [],
            dualstudydef: [],
            event: null,
            invitation: {},
            exhibit: [],
            exhibitList: [],
            exhibitor: [],
            exponatzg: [],
            feature: [],
            featureList: [],
            form: initialState.form || {},
            guests: [],
            infodesk: [],
            organisation: [],
            passes: [],
            person: null,
            presentationmodule: [],
            project: [],
            showstage: [],
            site: [],
            stagelist: [],
            stageseven: [],
            stele: [],
            steletype: [],
            storage: [],
            studyfield: [],
            studyfieldList: [],
            studyfielddef: [],
            substitute: [],
            themeWorld: [],
            training: [],
            trainingdef: [],
            traininglist: [],
            user2role: [],
            veranstzielgruppe: [],
            veranstzp: [],
            workshop: [],
            workshopList: [],
            firmaKategorie: [],
        };
    }
    return combinedReducer(state, action);
};
export const store = createStore(
    appReducer,
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk)),
);

export const persistor = persistStore(store);
