import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import StepButtons from '../../common/stepbuttons';
import style from '../../common/index.module.css';
import Question from '../../common/question';

import { clearStep } from '../../../actions/common/actions';
import dayjs from 'dayjs';

class Attendance extends Question {
    constructor(props) {
        super(
            props,
            [
                ['form', 'login', 'values', 'loginkey'],
                ['person', 'idAnrede'],
                ['person', 'nachname'],
                ['invitation', 'code'],
            ],
            {
                to: '#',
                valid: false,
            },
        );
        this.invitationPreviousReply = null;
        // TODO: max persons dynamisch
        this.substituteAllowed =
            this.props.substituteAllowed === 1
                ? [
                      <Field
                          component='input'
                          type='radio'
                          id='radio-2'
                          name='attendance'
                          value='representative'
                          required
                          onChange={(e) => this.validate(e.target.value)}
                      />,
                  ]
                : [];
        this.options = [
            <Field
                component='input'
                type='radio'
                id='radio-1'
                name='attendance'
                value='self'
                required
                onChange={(e) => this.validate(e.target.value)}
            />,
            ...[this.substituteAllowed],
            <Field
                component='input'
                type='radio'
                id='radio-3'
                name='attendance'
                value='none'
                required
                onChange={(e) => this.validate(e.target.value)}
            />,
        ];
    }

    validate(valueParam) {
        let value = valueParam;
        if (!value) {
            if (
                !this.props.formdata.attendance ||
                !this.props.formdata.attendance.values ||
                !this.props.formdata.attendance.values.attendance
            ) {
                this.setState({
                    valid: false,
                });
                return false;
            }
            value = this.props.formdata.attendance.values.attendance;
        }

        let to = '#';

        if (value === 'self' || value === 'representative') {
            to = 'personals';
        } else if (value === 'none') {
            to = 'cancellation';
            this.props.dispatch(clearStep([1, 2, 3]));
            // NOTE: implement dynamic graph system for step history branches
            // in case of requirement
        }

        this.setState({
            valid: true,
            to: to,
        });
    }
    renderSwitch(invitationPreviousReply) {
        switch (invitationPreviousReply) {
            case 3: {
                this.invitationPreviousReply = 'Ich habe zugesagt';
                break;
            }
            case 4: {
                this.invitationPreviousReply =
                    'Ich kann leider nicht persönlich teilnehmen';
                break;
            }
            case 5: {
                this.invitationPreviousReply = 'Ich habe teilgenommen';
                break;
            }
            case 1:
            case 2:
            case 6: {
                this.invitationPreviousReply = 'ohne Rückmeldung';
                break;
            }
            case 7: {
                this.invitationPreviousReply =
                    'Ich möchte mich vertreten lassen';
                break;
            }
            default:
                break;
        }
        return this.invitationPreviousReply;
    }
    renderQuestion() {
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>
                        Guten Tag, {this.props.personSalutation}{' '}
                        {this.props.personTitle} {this.props.personName}!
                    </h1>
                    <p>
                        {(this.props.event || this.props.currentIE) && (
                            <>
                                <span>
                                    Auf den folgenden Seiten können Sie Ihre
                                    Einladung zur Veranstaltung{' '}
                                </span>
                                <strong>
                                    „
                                    {this.props.event && this.props.eventName
                                        ? this.props.eventName
                                        : this.props.currentIE?.name
                                        ? this.props.currentIE.name
                                        : ''}
                                    “
                                </strong>
                            </>
                        )}{' '}
                        {this.props.event &&
                        this.props.eventDate &&
                        this.props.eventDate.toString() !== 'Invalid Date' ? (
                            <>
                                am{' '}
                                <strong>
                                    {this.props.event &&
                                        dayjs(this.props.eventDate).format(
                                            'DD.MM.YYYY',
                                        )}
                                </strong>{' '}
                            </>
                        ) : this.props.currentIE?.beginn &&
                          this.props.currentIE.beginn.toString() !==
                              'Invalid Date' ? (
                            <>
                                am{' '}
                                <strong>
                                    {dayjs(this.props.currentIE.beginn).format(
                                        'DD.MM.YYYY',
                                    )}
                                </strong>{' '}
                            </>
                        ) : null}
                        verwalten.
                        <br />
                        {this.props.invitationPreviousReply >= 3 ||
                        this.props.invitationPreviousReply !== 6
                            ? `Ihre bisherige Rückmeldung: ${this.renderSwitch(
                                  this.props.invitationPreviousReply,
                              )}.`
                            : null}
                    </p>
                </div>
                <form>
                    <fieldset>
                        <legend>
                            <b>Bitte wählen Sie</b>
                        </legend>
                        <div className='row'>
                            {this.options[0]}
                            <label htmlFor='radio-1'>
                                <span>Ich komme persönlich.</span>
                            </label>
                        </div>
                        {this.options[1].length > 0 ? (
                            <div className='row'>
                                {this.options[1]}
                                <label htmlFor='radio-2'>
                                    <span>
                                        Ich kann leider nicht persönlich
                                        teilnehmen, lasse mich aber vertreten.
                                    </span>
                                </label>
                            </div>
                        ) : null}
                        <div className='row'>
                            {this.options[2]}
                            <label htmlFor='radio-3'>
                                <span>
                                    Ich kann leider nicht persönlich teilnehmen.
                                </span>
                            </label>
                        </div>
                    </fieldset>
                </form>
                <StepButtons
                    back='/'
                    to={this.state.to}
                    disabled={!this.state.valid}
                />
            </div>
        );
    }
}

export default reduxForm({
    form: 'attendance',
    destroyOnUnmount: false,
    enableReinitialize: true,
})(
    connect(
        (s) => ({
            formdata: s.form,
            personSalutation:
                s.person?.idAnrede === 1
                    ? 'Herr'
                    : s.person?.idAnrede === 2
                    ? 'Frau'
                    : '',
            personTitle: s.person?.titel || '',
            personName: s.person?.nachname || '',
            event: s.event,
            eventName: s.event?.name,
            eventDate: s.event?.date,
            substituteAllowed: s.event?.vertretung || 0,
            currentIE: s.authenticationData?.TpProjektLstItem
                ? s.authenticationData.TpProjektLstItem[0]
                : null,
            invitationPreviousReply: s.invitation?.idStatus,
            ...s,
        }),
        null,
    )(Attendance),
);
