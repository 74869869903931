import React, { Component } from 'react';
import { connect } from 'react-redux';
import style from '../../../../exhibitor/index.module.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ContactInternet from './internet';
import ContactPupils from './pupils';
import ContactStudents from './students';

class ContactData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            selectedIndex: 0,
        };
    }
    handleSelect = (index) => {
        this.setState({ selectedIndex: index });
    };

    handleButtonClick = (number) => {
        this.setState({ selectedIndex: number });
    };
    render() {
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>Kontaktdaten</h1>
                    <p>
                        Im KarriereMarkt unterscheiden wir zwischen SchülerInnen
                        und Studierenden. Bitte nennen Sie uns hier die
                        Internetseiten und AnsprechpartnerInnen in Ihrem
                        Unternehmen für diese beiden Zielgruppen. Sie können
                        individuelle Kontaktdaten hinterlegen, eine spezifische
                        Internetseite (alternativ Karriere- Seite oder Homepage)
                        nennen und ein relevantes Internetportal nennen.
                    </p>
                </div>
                <div className='buttonwrap buttonwrap-end'>
                    {/*<Link className="small-button" to="/home">vollständige Liste als Pdf ausgeben</Link>*/}
                </div>
                <Tabs
                    selectedIndex={this.state.selectedIndex}
                    onSelect={this.handleSelect}
                    className={`${style.reactTabs}`}
                >
                    <TabList>
                        <Tab>Internetseiten</Tab>
                        <Tab>
                            AnsprechpartnerIn
                            <br />
                            für SchülerInnen
                        </Tab>
                        <Tab>
                            AnsprechpartnerIn
                            <br />
                            für Studierende
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <ContactInternet
                            handle={this.props.handle}
                            toTab={this.handleButtonClick}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ContactPupils
                            handle={this.props.handle}
                            toTab={this.handleButtonClick}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ContactStudents
                            handle={this.props.handle}
                            toTab={this.handleButtonClick}
                        />
                    </TabPanel>
                </Tabs>
            </div>
        );
    }
}

const mapStateToProps = (state, _prop) => ({
    handle:
        state.company &&
        Array.isArray(state.company) &&
        state.company.length > 0
            ? state.company[0]
            : null,
});
export default connect(mapStateToProps, null)(ContactData);
