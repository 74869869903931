import React from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { reduxForm, Field } from 'redux-form';

import StepButtons from '../../common/stepbuttons';
import style from '../../common/index.module.css';
import Question from '../../common/question';

class Validate extends Question {
    constructor(props) {
        if (
            props.form?.attendance?.values &&
            props.form.attendance.values.attendance === 'none'
        ) {
            super(props, [['form', 'attendance', 'values', 'attendance']], {
                valid: false,
            });
        } else {
            super(
                props,
                [
                    ['form', 'attendance', 'values', 'attendance'],
                    ['form', 'personals', 'values'],
                    ['form', 'guests', 'values'],
                    ['form', 'referrals', 'values'],
                ],
                {
                    valid: false,
                },
            );
        }

        this.gdpr = React.createRef();
    }
    validate() {
        setTimeout(() => {
            if (this.gdpr.current) {
                this.setState({ valid: this.gdpr.current.value === true });
            }
        }, 0);
    }
    renderQuestion() {
        const form = this.props.formdata;
        const attendance =
            form?.attendance?.values?.attendance &&
            form.attendance.values.attendance !== 'none';

        const gdprIeLink = (
            <a
                href='https://www.ideenexpo.de/datenschutz-haftungsausschluss'
                target='_blank'
                rel='noopener noreferrer'
            >
                Datenschutzvereinbarung
            </a>
        );
        const gdprEuLink = (
            <a
                href='https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679'
                target='_blank'
                rel='noopener noreferrer'
            >
                hier
            </a>
        );

        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>Ihre Daten</h1>
                    <p>Bitte bestätigen Sie Ihre Angaben:</p>
                </div>
                <form>
                    {/* test for existence here because we're potentially clearing the form on the way out */}
                    {form.attendance && (
                        <>
                            <fieldset>
                                <div>
                                    <b>Teilnahme:</b>{' '}
                                    <Translate
                                        id={`attendance.${form.attendance.values.attendance}`}
                                    />
                                </div>
                            </fieldset>
                        </>
                    )}
                    {attendance && form.personals.values && (
                        <>
                            <fieldset>
                                <div>
                                    <b>Name:</b>{' '}
                                    {form.personals.values.lastname}
                                </div>
                                <div>
                                    <b>Vorname:</b>{' '}
                                    {form.personals.values.firstname}
                                </div>
                            </fieldset>
                            {attendance && form.substitute?.values && (
                                <>
                                    <fieldset>
                                        <div>
                                            <b>Daten Ihres Vertreters:</b>{' '}
                                        </div>
                                        <div>
                                            <b>Email:</b>{' '}
                                            {form.substitute.values.email}
                                        </div>
                                        <div>
                                            <b>Name:</b>{' '}
                                            {form.substitute.values.lastname}
                                        </div>
                                        <div>
                                            <b>Vorname:</b>{' '}
                                            {form.substitute.values.firstname}
                                        </div>
                                        <div>
                                            <b>Firma:</b>{' '}
                                            {form.substitute.values.company}
                                        </div>
                                        <div>
                                            <b>Telefonnummer:</b>{' '}
                                            {form.substitute.values.phone}
                                        </div>
                                        <div>
                                            <b>Straße:</b>{' '}
                                            {form.substitute.values.street}
                                        </div>
                                        <div>
                                            <b>Postleitzahl:</b>{' '}
                                            {form.substitute.values.zipcode}
                                        </div>
                                        <div>
                                            <b>Ort:</b>{' '}
                                            {form.substitute.values.city}
                                        </div>
                                    </fieldset>
                                </>
                            )}
                            {form.guests?.values?.guests?.map(
                                (guest, index) => (
                                    <fieldset key={`guest${index}`}>
                                        <div>
                                            <b>Begleitung #{index + 1}:</b>
                                        </div>
                                        <div>
                                            <b>Email:</b> {guest.email}
                                        </div>
                                        <div>
                                            <b>Name:</b> {guest.lastname}
                                        </div>
                                        <div>
                                            <b>Vorname:</b> {guest.firstname}
                                        </div>
                                        <div>
                                            <b>Firma:</b> {guest.company}
                                        </div>
                                        <div>
                                            <b>Telefonnummer:</b> {guest.phone}
                                        </div>
                                        <div>
                                            <b>Straße:</b> {guest.street}
                                        </div>
                                        <div>
                                            <b>PLZ:</b> {guest.zipcode}
                                        </div>
                                        <div>
                                            <b>Ort:</b> {guest.city}
                                        </div>
                                    </fieldset>
                                ),
                            )}
                        </>
                    )}
                    <fieldset>
                        <div className='row'>
                            <Field
                                component='input'
                                type='checkbox'
                                name='gdpr'
                                id='gdpr'
                                ref={this.gdpr}
                                onChange={(_e) => this.validate()}
                            />
                            {/*<label htmlFor="gdpr"><span><Translate id="gdpr.confirm" /></span></label>*/}
                            <label htmlFor='gdpr'>
                                <span id='gdpr'>
                                    Ich stimme der {gdprIeLink} und der
                                    Speicherung der oben genannten Daten zu. Den
                                    Text der Datenschutzgrundverordnung können
                                    Sie {gdprEuLink} abrufen.
                                </span>
                            </label>
                        </div>
                    </fieldset>
                    <StepButtons
                        back={
                            this.props.formdata.attendance &&
                            this.props.formdata.attendance.values.attendance ===
                                'none'
                                ? '/attendance'
                                : '/guests'
                        }
                        to='/sending'
                        disabled={!this.state.valid}
                        toText='Absenden'
                    />
                </form>
            </div>
        );
    }
}

export default reduxForm({
    form: 'gdpr',
    destroyOnUnmount: true,
})(connect((s) => ({ formdata: s.form, ...s }))(Validate));
