import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Status extends Component {
    constructor(props) {
        super(props);
        this.steps = props.steps;
    }
    render() {
        let step = 1;
        const steps = Array.apply(null, Array(this.steps)).map(() => {
            const t = (
                <div
                    key={`cstep${String(step)}`}
                    className={`step ${
                        this.props.step.step === step ? 'active' : ''
                    }`}
                >
                    {step}
                </div>
            );
            step += 1;
            if (this.props.step.routes?.[step - 1]) {
                return (
                    <Link
                        key={`step${String(step)}`}
                        to={this.props.step.routes[step - 1]}
                    >
                        {t}
                    </Link>
                );
            }
            return t;
        });
        return (
            <div id='status'>
                <div className='steps'>{steps}</div>
            </div>
        );
    }
}

export default connect((state) => {
    return state;
})(Status);
