import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import FormButtons from '../../../formbuttons';
import style from '../../../index.module.css';
import disableForm from '../../../disableForm.js';
import { getEntity } from '../../../../../actions/exhibitor/exhibitor-actions.js';
import { insertStele } from '../../../../../actions/exhibitor/stele';
import {
    validateForm,
    showAlert,
    SUCCESS,
    SUCCESS_MSG,
    ERROR_MSG_BACKEND_SAVE,
    ERROR,
    ERROR_MSG_BACKEND_FETCH,
} from '../../../alerts';

class StelesNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            submitSuccess: false,
        };
        this.input = React.createRef();
        this.textarea = React.createRef();
    }
    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                if (this.props.boothExists) {
                    return this.props.dispatch(getEntity('booth'));
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                if (
                    this.props.steletype &&
                    !Object.keys(this.props.steletype).length
                ) {
                    return this.props.dispatch(getEntity('steletype'));
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                if (!this.props.exhibit_deadline_within) {
                    disableForm();
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }
    handleInput(_e) {
        this.setState({ valid: true });
    }
    resetForm() {
        this.setState({
            submitSuccess: false,
        });
    }
    submit(values) {
        validateForm(
            this,
            values,
            ['name', 'idStelentyp'],
            this.props.exhibit_deadline_within,
        );
        Promise.resolve()
            .then(() => {
                return this.props
                    .dispatch(
                        insertStele({
                            ...values,
                            idStand: this.props.booth_id || '',
                        }),
                    )
                    .then(null, (_e) =>
                        Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                    );
            })
            .then((json) => {
                showAlert(SUCCESS, SUCCESS_MSG);
                return json;
            })
            .then((json) => {
                this.setState({
                    submitSuccess: true,
                });
                return json;
            })
            .then((json) => {
                this.props.history.push({
                    pathname: '/booths/steles/edit',
                    search: `?${json.idAusstattung}`,
                    state: { booth_id: this.props.booth_id || undefined },
                });
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_SAVE);
                this.setState({ submitSuccess: false });
                this.props.dispatch(stopSubmit('stelesnew', e));
            });
    }
    render() {
        const { handleSubmit } = this.props;
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>Neue Stele anlegen</h1>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <fieldset>
                            <legend />
                            <label htmlFor='name'>
                                <span>
                                    Bitte geben Sie dieser Stele einen
                                    treffenden Namen (nur intern) (*)
                                </span>
                            </label>
                            <Field
                                component='input'
                                placeholder=''
                                type='text'
                                name='name'
                                id='name'
                                maxLength='80'
                                required
                            />
                            <Field
                                component='select'
                                name='idStelentyp'
                                id='idStelentyp'
                                required
                            >
                                <option value='' hidden>
                                    Bitte wählen Sie einen Stelentyp aus (*)
                                </option>
                                {this.props.steletype.map((item) => (
                                    <option key={item.ID} value={item.ID}>
                                        {item.bezeichnung}
                                    </option>
                                ))}
                            </Field>
                            Bitte speichern Sie Ihre Auswahl, um mit der
                            Bearbeitung fortzufahren.
                            <FormButtons
                                showCancel={true}
                                overviewLink='/booths/steles'
                                hideBtn={true}
                                handleSubmit={handleSubmit}
                                submit={this.submit.bind(this)}
                            />
                            <legend>(*) = Pflichtfeld</legend>
                        </fieldset>
                    </form>
                    {/* <StepButtons back={'/booths/steles'} to={'/booths/furnishings'} labelBack={'Liste der Stelen'} labelForward={'Mobiliar'} /> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, _prop) => ({
    steletype: state.steletype || [],
    exhibit_deadline_within: state.deadlines?.exhibit_deadline_within,
    booth_id:
        state.router?.location?.state?.booth_id ||
        parseInt(state.router?.location?.search || '?'),
    boothExists: state.booth && !Object.keys(state.booth).length,
});

export default compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'stelesnew',
        destroyOnUnmount: true,
        enableReinitialize: false,
        keepDirtyOnReinitialize: false,
    })(StelesNew),
);
