import React, { useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { withRouter, useLocation, useHistory } from 'react-router-dom';

import style from '../../index.module.css';
import sx from '../banner-print/banner-print.module.scss';
import FormButtons from '../../formbuttons';
import {
    fetchNewEntityFromConfig,
    fetchSingleEntityFromConfig,
} from '../../../../actions/exhibitor/fetcher/fetcher';
import {
    insertBannerDigital,
    mapBannerDigital,
    updateBannerDigital,
} from './banner-digital.actions';
import { setBannerDigital } from '../../../../actions/exhibitor/exhibitor-actions';
import Loader from '../../../common/loader';
import { ERROR, ERROR_MSG_BACKEND_SAVE, showAlert } from '../../alerts';
import {
    BannerFileInput,
    CurrentFile,
    createFileBody,
} from '../banner-print/banner-print.edit';
import { createEntityConfig } from '../../../../actions/exhibitor/fetcher/entity-list-detail.fetcher';

export const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

const mapStateToProps = (state, props) => {
    return {
        initialValues: {
            ...mapBannerDigital(state.bannerDigital),
        },
        item: state.bannerDigital,
        formValues: state.form?.bannerDigitalEdit?.values,
    };
};

export const BannerDigitalForm = ({
    dispatch,
    handleSubmit,
    initialValues,
    formValues,
    item,
}) => {
    const query = useQuery();
    const history = useHistory();
    const id = query.get('id');
    const isNew = id === null;
    const isDisabled = initialValues.kzFreigabeAussteller === 1;
    const [isReady, setIsReady] = useState(isNew);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmitFinish = () => {
        history.push('/organisation/banner-digital');
    };

    const submit = async ({
        rawFile,
        beschreibung,
        data4BannerDigitalDatei,
        bannerDigitalDatei,
    }) => {
        try {
            setIsLoading(true);

            const fileBody = await createFileBody(rawFile, 'bannerDigital');

            const body = {
                ...(isNew ? {} : item),
                beschreibung,
                ...(fileBody || {
                    data4BannerDigitalDatei,
                    bannerDigitalDatei,
                }),
            };
            if (isNew) {
                await insertBannerDigital(body);
                setIsLoading(false);
                handleSubmitFinish();
                return;
            }

            await updateBannerDigital({
                ...body,
                ID: parseInt(id),
            });
            setIsLoading(false);
            handleSubmitFinish();
        } catch (e) {
            setIsLoading(false);
            console.error(e);
            showAlert(ERROR, ERROR_MSG_BACKEND_SAVE);
        }
    };

    const handleFetch = async () => {
        const ec = createEntityConfig('bannerDigital');

        let banner;
        if (isNew) {
            banner = await fetchNewEntityFromConfig(ec);
        } else {
            banner = await fetchSingleEntityFromConfig(ec, id);
        }

        dispatch(setBannerDigital(banner));
        setIsReady(true);
    };

    useEffect(() => {
        handleFetch();
    }, []);

    if (!isReady) {
        return (
            <div style={{ height: '90vh' }}>
                <Loader visible />
            </div>
        );
    }

    return (
        <div className={style.question}>
            <div className={style.header}>
                {isNew ? (
                    <h1>Neue Datei hochladen</h1>
                ) : (
                    <h1>Datei bearbeiten</h1>
                )}
            </div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <fieldset>
                    <div className={sx.currentFile}>
                        <CurrentFile
                            name={formValues?.bannerDigitalDatei}
                            data={formValues?.data4BannerDigitalDatei}
                            rawInput={formValues?.rawFile}
                        />
                    </div>
                    <Field
                        name='rawFile'
                        component={BannerFileInput}
                        disabled={isDisabled}
                    />
                    <label htmlFor='beschreibung'>Dateibeschreibung</label>
                    <Field
                        component='input'
                        type='text'
                        placeholder='Beschreibung'
                        name='beschreibung'
                        id='beschreibung'
                        disabled={isDisabled}
                    />
                </fieldset>
                <FormButtons
                    showCancel={true}
                    overviewLink='/organisation/banner-digital'
                    hideBtn={true}
                    handleSubmit={handleSubmit}
                    submit={submit}
                    disabled={isDisabled}
                    loading={isLoading}
                />
            </form>
        </div>
    );
};

export const BannerDigitalEdit = compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'bannerDigitalEdit',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: false,
    })(BannerDigitalForm),
);
