import React, { Component } from 'react';
import { destroy } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import style from '../../index.module.css';
import StepButtons from '../../stepbuttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
    getEntity,
    getCustomLst,
    deleteEntity,
} from '../../../../actions/exhibitor/exhibitor-actions.js';
import {
    showAlert,
    ERROR,
    ERROR_MSG_BACKEND_FETCH,
    SUCCESS_DELETE,
    DELETE,
} from '../../alerts';
import { approveItem } from '../../functions';

class Exhibits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
        };
    }
    componentDidMount() {
        Promise.resolve()
            .then(() => {
                this.props.dispatch(destroy('exhibitseditbasis'));
                this.props.dispatch(destroy('exhibitseditdescription'));
                this.props.dispatch(destroy('exhibitseditspecifications'));
            })
            .then(() => {
                if (this.props.booth && !Object.keys(this.props.booth).length) {
                    return this.props
                        .dispatch(getEntity('booth'))
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_FETCH }),
                        );
                }
            })
            .then(() => {
                if (
                    this.props.exhibit &&
                    !Object.keys(this.props.exhibit).length
                ) {
                    return this.props
                        .dispatch(getEntity('exhibit'))
                        .then(null, (_e) => Promise.reject());
                }
            })
            .then(() => {
                if (
                    this.props.themeWorld &&
                    !Object.keys(this.props.themeWorld).length
                ) {
                    return this.props
                        .dispatch(getEntity('themeWorld'))
                        .then(null, (_e) => Promise.reject());
                } else {
                    Promise.resolve();
                }
            })
            .then(() => {
                if (
                    this.props.exponatzg &&
                    !Object.keys(this.props.exponatzg).length
                ) {
                    return this.props
                        .dispatch(getCustomLst('targetgroup'))
                        .then(null, (_e) => Promise.reject());
                }
            })
            .then(() =>
                this.setState({
                    submitSuccess: true,
                }),
            )
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    deleteItem = (json) => {
        Promise.resolve()
            .then(() => {
                return this.props.dispatch(deleteEntity(json, 'exhibit'));
            })
            .then(() => {
                showAlert(
                    DELETE,
                    SUCCESS_DELETE(`Das Exponat ${json.projekttitel}`),
                );
            })
            .catch((e) => {
                showAlert(ERROR, `Element konnte nicht gelöscht werden. ${e}`);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    };

    render() {
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>
                        Liste der Exponate
                        <br />
                    </h1>
                    <p>
                        Für unsere Presse- und Öffentlichkeitsarbeit sowie für
                        die Webseite benötigen wir von Ihnen eine Beschreibung
                        für jedes Ihrer Exponate/Mitmachstationen. Der Text
                        sollte die wichtigsten Informationen zu Ihrem Exponat
                        enthalten und darüber informieren, was die BesucherInnen
                        hier erleben können. Eine Textlänge von 5 bis maximal 10
                        Sätzen ist ausreichend. Der Stil des Textes kann als
                        direkte Ansprache („Hier kannst du erleben, wie …“) oder
                        beschreibend („Die BesucherInnen können erleben, wie …“)
                        formuliert sein.
                    </p>
                    <p>
                        Bitte beachten Sie: Ihre Exponate müssen bis zum{' '}
                        <strong>{this.props.exhibit_deadline}</strong>{' '}
                        <strong>von Ihnen</strong> zur weiteren Abstimmung
                        freigegeben werden.
                    </p>
                </div>
                {this.props.booth.map((booth) => {
                    const themeWorldOfBooth = this.props.themeWorld.find(
                        (item) => item.ID === booth.idThemenWelt,
                    );
                    return (
                        <div key={booth.ID} className='exhibitorTableWrap'>
                            <span className='exhibitorTableHeader'>
                                {booth.bezeichnung}
                                {themeWorldOfBooth
                                    ? ` (${
                                          themeWorldOfBooth.themenWeltBezeichnung ||
                                          'Bezeichnung fehlt!'
                                      })`
                                    : ' (Kein Themenbereich zugewiesen)'}
                            </span>
                            {this.props.exhibit_deadline_within === true ? (
                                <Link
                                    className='exhibitorTableButton small-button'
                                    to={{
                                        pathname: `/booths/exhibits/edit/${booth.ID}`,
                                        state: { booth_id: booth.ID },
                                    }}
                                    onClick={() =>
                                        this.props.dispatch(
                                            destroy(
                                                'exhibitseditbasis',
                                                'exhibitseditdescription',
                                                'exhibitseditspecifications',
                                            ),
                                        )
                                    }
                                >
                                    Neues Exponat
                                </Link>
                            ) : null}
                            <div className='schoolTableWrap'>
                                <ul className='schoolTable four-gridlistpages'>
                                    <div className='header'>Name</div>
                                    <div className='header'>Titel</div>
                                    <div className='header'>Freigabe</div>
                                    <div className='header' />

                                    {this.props.exhibit
                                        .filter((x) => x.idStand === booth.ID)
                                        .map((exhibit) => (
                                            <ul key={exhibit.ID}>
                                                <Link
                                                    to={{
                                                        pathname:
                                                            '/booths/exhibits/edit',
                                                        state: {
                                                            handle: exhibit.ID,
                                                            booth_id: booth.ID,
                                                        },
                                                        search: `${exhibit.ID}`,
                                                    }}
                                                >
                                                    <li className='schoolTable four-gridlistpages'>
                                                        <label>
                                                            {
                                                                exhibit.projekttitel
                                                            }
                                                        </label>
                                                        <label>
                                                            {
                                                                exhibit.titelInternet
                                                            }
                                                        </label>
                                                        {exhibit.kzFreigabeAussteller ? (
                                                            <div>Ja</div>
                                                        ) : (
                                                            <div>
                                                                <a
                                                                    className='button approve'
                                                                    onClick={(
                                                                        e,
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        approveItem(
                                                                            this,
                                                                            'exhibit',
                                                                            exhibit,
                                                                        );
                                                                    }}
                                                                >
                                                                    Freigeben
                                                                </a>
                                                            </div>
                                                        )}
                                                        {exhibit.kzFreigabeAussteller ? (
                                                            <div />
                                                        ) : (
                                                            <div
                                                                onClick={(
                                                                    e,
                                                                ) => {
                                                                    e.preventDefault();
                                                                    if (
                                                                        window.confirm(
                                                                            `Wollen Sie den Eintrag "${exhibit.projekttitel}" wirklich löschen?`,
                                                                        )
                                                                    ) {
                                                                        this.deleteItem(
                                                                            exhibit,
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faTrash
                                                                    }
                                                                    size='1x'
                                                                />
                                                            </div>
                                                        )}
                                                    </li>
                                                </Link>
                                            </ul>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    );
                })}

                <StepButtons
                    back={'/booths'}
                    to={'/booths/exhibit-boards'}
                    labelBack={'Stände'}
                    labelForward={'Exponattafeln'}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, _prop) => {
    return {
        exhibit_deadline: state.deadlines?.exhibit_deadline,
        exhibit_deadline_within: state.deadlines?.exhibit_deadline_within,
        exhibit: state.exhibit || [],
        themeWorld: state.themeWorld || [],
        booth: state.booth || [],
        exponatzg: state.exponatzg || [],
    };
};

export default connect(mapStateToProps, null)(Exhibits);
