import React, { Component } from 'react';
import { reduxForm, destroy } from 'redux-form';
import { connect } from 'react-redux';
import style from '../index.module.css';
import { getEntity } from '../../../actions/exhibitor/exhibitor-actions.js';
import StepButtons from '../stepbuttons';
import { showAlert, ERROR, ERROR_MSG_BACKEND_FETCH } from '../alerts';
import Booth from './booth';

class BoothOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
        };
    }
    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                if (this.props.booth && !Object.keys(this.props.booth).length) {
                    return this.props.dispatch(getEntity('booth'));
                } else {
                    Promise.resolve();
                }
            })
            .then(() => {
                if (
                    this.props.themeWorld &&
                    !Object.keys(this.props.themeWorld).length
                ) {
                    return this.props.dispatch(getEntity('themeWorld'));
                } else {
                    Promise.resolve();
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }
    render() {
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>Ihre Stände auf der {this.props.ie_name}</h1>
                    <p>
                        Die {this.props.ie_name} gliedert sich in folgende
                        Themenbereiche und Sonderflächen:
                    </p>
                </div>
                <div className='containerAussteller'>
                    <div className='item'>
                        <h2>Halle 6</h2>
                        <span className='paragraph'>
                            ErnährungsWelten
                            <br />
                        </span>
                        <span className='paragraph'>
                            RoboCup
                            <br />
                        </span>
                    </div>
                    <div className='item'>
                        <h2>Halle 7</h2>
                        <span className='paragraph'>
                            MediaLab
                            <br />
                        </span>
                        <span className='paragraph'>
                            EnergieFeld
                            <br />
                        </span>
                        <span className='paragraph'>
                            LebensRaum
                            <br />
                        </span>
                        <span className='paragraph'>
                            DigitaleWelten
                            <br />
                        </span>
                    </div>
                    <div className='item'>
                        <h2>Halle 8</h2>
                        <span className='paragraph'>
                            HealthyPlanet
                            <br />
                        </span>
                    </div>
                    <div className='item'>
                        <h2>Halle 9</h2>
                        <span className='paragraph'>
                            ProduktionsArena
                            <br />
                        </span>
                        <span className='paragraph'>
                            MobilitätsMeile
                            <br />
                        </span>
                        <span className='paragraph'>
                            LifeScienceArea
                            <br />
                        </span>
                        <span className='paragraph'>
                            ClubZukunft
                            <br />
                        </span>
                        <span className='paragraph'>
                            GirlsMINTCamp
                            <br />
                        </span>
                        <span className='paragraph'>
                            AeroSpace
                            <br />
                        </span>
                    </div>
                    <div className='item'>
                        <h2>Außengelände</h2>
                        <span className='paragraph'>
                            BlaulichtMeile
                            <br />
                        </span>
                        <span className='paragraph'>
                            OutdoorPark
                            <br />
                        </span>
                    </div>
                </div>
                <div className={style.header}>
                    <p>
                        Hier erhalten Sie eine Übersicht über Ihren Stand bzw.
                        Ihre Stände auf dem Veranstaltungsgelände des kommenden
                        Events.
                    </p>
                </div>
                <div className='schoolTableWrap'>
                    <ul className='schoolTable booths-grid'>
                        <div className='header'>Bezeichnung</div>
                        <div className='header'>Stand-Nr.</div>
                        <div className='header'>Themenbereich</div>
                        <div className='header'>Fläche</div>
                        <div className='header'>Standardlogo</div>
                        <div className='header'>Freigabe Aussteller</div>
                        {this.props.booth.map((booth) => {
                            const themeWorldOfBooth =
                                this.props.themeWorld.find(
                                    (item) => item.ID === booth.idThemenWelt,
                                );
                            return (
                                <Booth
                                    booth={booth}
                                    themeWorld={
                                        themeWorldOfBooth
                                            ? themeWorldOfBooth.themenWeltBezeichnung ||
                                              'Bezeichnung fehlt!'
                                            : 'Kein Themenbereich zugewiesen'
                                    }
                                    onClickHandler={() =>
                                        this.props.dispatch(
                                            destroy('defaultlogo'),
                                        )
                                    }
                                    key={booth.ID}
                                />
                            );
                        })}
                    </ul>
                </div>
                <p>
                    <br />
                    Ihre Standnummer erhalten Sie nach Abschluss der kompletten
                    Aufplanung voraussichtlich ab ca. 08.03.2024. Die angegebene
                    Fläche bezieht sich auf die vertraglich vereinbarte
                    Standgröße. Durch Anklicken der Zeile erhalten Sie weitere
                    Informationen zu den Freigaben und können – sofern gewünscht
                    – ein Standardlogo hochladen, das auf allen Logoflächen
                    (Tresen, Stelen usw.) einheitlich verwendet werden soll.
                </p>
                <StepButtons
                    back={'/home'}
                    to={'/booths/exhibits'}
                    labelBack={'Home'}
                    labelForward={'Exponate'}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, _prop) => {
    return {
        booth: state.booth || [],
        themeWorld: state.themeWorld || [],
        ie_year: state.deadlines?.ie_year,
        exhibit_deadline: state.deadlines?.exhibit_deadline,
        ie_name: (state.authenticationData?.TpProjektLstItem || [
            { name: 'IdeenExpo' },
        ])[0].name,
    };
};

export default reduxForm({
    form: 'booths',
    // enableReinitialize: true
})(connect(mapStateToProps, null)(BoothOverview));
