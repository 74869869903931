import { store } from '../../store';
import {
    PARTICIPANT_GET,
    BACKEND_URL,
    CUSTOMLIST_LIST,
    ENV,
    AUTHENTICATION,
    PROJECT_LIST,
    WORKSHOP_GET,
} from '../../global_constants';
import {
    setArrivalTimeList,
    setTransportList,
    setDepartureTimeSchoolList,
    setClassLevelList,
    setAllowance,
} from '../registration-system/registration-actions.js';

export const setStep = (step, path) => {
    return {
        type: 'SET_STEP',
        step: step,
        route: path,
    };
};

export const clearStep = (whitelist = []) => {
    return {
        type: 'CLEAR_STEP',
        whitelist,
    };
};

export const setAuthentication = (authenticationData) => ({
    type: 'SET_AUTHENTICATION_DATA',
    authenticationData: authenticationData,
});

export const authenticateSystemUser = () => {
    return function (dispatch) {
        // AUTHENTICATE
        return (
            fetch(BACKEND_URL + AUTHENTICATION, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, cors, *same-origin
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization:
                        // rome-ignore lint/suspicious/noDoubleEquals: <explanation> loose comparison with falsy value intentional here
                        store.getState().authenticationData.login == undefined
                            ? ''
                            : `Bearer ${
                                  store.getState().authenticationData.login
                                      .accessToken
                              }`,
                },
                body: 'username=TU_EXTERN&password=123456&idlanguage=49&mandantnr=1&applid=975',
            })
                .then((response) => {
                    if (!response.ok) {
                        return Promise.reject(response.statusText);
                    } else {
                        return response.text();
                    }
                })
                .then((text) => {
                    try {
                        return JSON.parse(text);
                    } catch (e) {
                        return Promise.reject(e);
                    }
                })
                .then((responseData) => {
                    dispatch(setAuthentication(responseData));
                    return responseData;
                })
                // fetch DATA
                .then((responseData) =>
                    fetch(BACKEND_URL + PROJECT_LIST, {
                        method: 'POST', // *GET, POST, PUT, DELETE, etc.
                        mode: 'cors', // no-cors, cors, *same-origin
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${responseData.accessToken}`,
                        },
                        body: JSON.stringify({
                            login: {
                                // TODO: pick properties manually!
                                ...responseData,
                                userId4Hist: undefined,
                            },
                            kzAktiv: 1,
                        }), // body data type must match "Content-Type" header
                    })
                        .then((response) => {
                            if (!response.ok) {
                                return Promise.reject(response.statusText);
                            } else {
                                return response.text();
                            }
                        })
                        .then((text) => {
                            try {
                                return JSON.parse(text);
                            } catch (e) {
                                return Promise.reject(e);
                            }
                        })
                        .then((responseData) => {
                            return dispatch(setAuthentication(responseData));
                        }),
                )
        );
    };
};

export const setSalutation = (salutation) => ({
    type: 'SET_SALUTATION',
    salutation: salutation,
});

export const fetchSalutation = () => {
    // TODO: insert fetch code
    const salutation = [];
    return function (dispatch) {
        return Promise.resolve().then((_e) =>
            dispatch(setSalutation(salutation)),
        );
    };
};

export const fetchSchoolDetails = (school) =>
    fetch(BACKEND_URL + PARTICIPANT_GET, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${
                store.getState().authenticationData.login.accessToken
            }`,
        },
        body: JSON.stringify({
            login: {
                // TODO: pick properties manually!
                ...store.getState().authenticationData.login,
                userId4Hist: undefined,
            },
            ID: school.ID,
        }),
    })
        .then((response) => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            } else {
                return response.text();
            }
        })
        .then((text) => {
            try {
                return JSON.parse(text);
            } catch (e) {
                return Promise.reject(e);
            }
        })
        .then((school) => {
            console.log('registration details:', school);
            return school;
        });

export const fetchVeranstaltungDetails = (buchung) =>
    fetch(BACKEND_URL + WORKSHOP_GET, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${
                store.getState().authenticationData.login.accessToken
            }`,
        },
        body: JSON.stringify({
            login: {
                // TODO: pick properties manually!
                ...buchung.login,
                userId4Hist: undefined,
            },

            ID: buchung.idVeranstaltung,
        }),
    })
        .then((response) => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            } else {
                return response.text();
            }
        })
        .then((text) => {
            try {
                return JSON.parse(text);
            } catch (e) {
                return Promise.reject(e);
            }
        })
        .then((veranstaltung) => {
            console.log('veranstaltung details:', veranstaltung);
            return veranstaltung;
        });

// human readable time
export const timeMapper = (zeitBeginn, mode) => {
    // rome-ignore lint/suspicious/noDoubleEquals: <explanation> loose comparison with falsy value intentional here
    if (zeitBeginn == undefined) {
        return '';
    }

    // Wenn mit der Zulu-Zeit gearbetet wird, addieren die JS-Date-Funktionen - korrekt - zwei Stunden drauf!
    // hier kommt aber nicht wirklich Zuluzeit an!
    const noZuluTime = zeitBeginn.replace(/Z/, '');

    const dd = new Date(noZuluTime);
    const year = dd.getFullYear();
    let month = dd.getMonth();
    let day = dd.getDate();
    let hour = dd.getHours();
    let min = dd.getMinutes();

    month++;

    if (month < 10) {
        month = `0${month}`;
    }
    if (day < 10) {
        day = `0${day}`;
    }
    if (hour < 10) {
        hour = `0${hour}`;
    }
    if (min < 10) {
        min = `0${min}`;
    }

    const dString = `${day}.${month}.${year}`;
    const tString = `${hour}:${min}`;

    if (mode === 'datum') {
        return dString;
    }

    if (mode === 'zeit') {
        return tString;
    }

    return '';
};

/* clear store */
export const logout = () => ({
    type: 'LOGOUT',
});

export const getCustomLst = (list) => {
    let idLst;
    let dispatchAction;
    const authenticationData = store.getState().authenticationData;
    switch (list) {
        case 'arrivaltime': {
            idLst = '4159404';
            dispatchAction = setArrivalTimeList;
            break;
        }
        case 'transport': {
            idLst = '4159406';
            dispatchAction = setTransportList;
            break;
        }
        case 'departureschool': {
            idLst = '4159403';
            dispatchAction = setDepartureTimeSchoolList;
            break;
        }
        case 'classlevel': {
            idLst = '4159402';
            dispatchAction = setClassLevelList;
            break;
        }
        case 'allowance': {
            idLst = '4159407';
            dispatchAction = setAllowance;
            break;
        }
        case 'veranstaltungstypen': {
            idLst = '4149400';
            dispatchAction = undefined; // wird nicht im Store benötigt
            break;
        }
        case 'veranstaltungskategorien': {
            idLst = '4149401';
            dispatchAction = undefined; // wird nicht im Store benötigt
            break;
        }
        default:
    }
    return function (dispatch) {
        return fetch(BACKEND_URL + CUSTOMLIST_LIST, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.login.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData.login,
                    userId4Hist: undefined,
                },
                idLst: idLst,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                // rome-ignore lint/suspicious/noDoubleEquals: <explanation> loose comparison with falsy value intentional here
                if (dispatchAction != undefined) {
                    dispatch(dispatchAction(json));
                }
                return json;
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                return Promise.reject(e);
            });
    };
};
