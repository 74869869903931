const isAusstellerProd = window.location.host === 'aussteller.ideenexpo.de';
const isBackendProd = window.location.host === 'backend.ideenexpo.de';

/* ################ Feste Listenwerte ################ */
/* Vertragstyp des Vertrags für den Aussteller */
export const CONTRACT_TYPE_VERTRAGSPARTNER = 2;

export const EXPONAT_TAFEL__TYP__A3 = 1;
export const EXPONAT_TAFEL__TYP__A4 = 2;

export const TEILNEHMER__ANREDE__FRAU = 2;
export const TEILNEHMER__ANREDE__HERR = 1;
export const TEILNEHMER__ANREDE__KEINE = 3;

export const TEILNEHMER__STATUS_WORKFLOW__MAIL_GESENDET = 2;
export const TEILNEHMER__STATUS_WORKFLOW__FORMBLATT_VERSCHICKT = 3;

export const TEILNEHMER__TYP__GRUPPE = 2;

export const VERTRAG__VERTRAG_LOGO__JA = 1;

/**
 * Erstellt eine Hilfsfunktion, um einen Wert eines Elements einer Festen Liste nach ihrem Wert zu finden.
 * @param {Object} constList - Feste Liste
 * @param {*} def - Standardwert oder `null`
 * @returns {(value: string | number | Record<string, *>) => *}
 */
const createConstListItemMapper = (constList, def = null) => (value) => {
    if (value === undefined || value === null) {
        return def;
    }
    if (typeof value === 'number') {
        const kv = Object.entries(constList).find(
            ([_, v]) => v.value === value,
        );
        if (kv) {
            return kv[1];
        }
    }
    if (typeof value === 'string') {
        const kv = Object.entries(constList).find(
            ([_, v]) => v.label === value,
        );
        if (kv) {
            return kv[1];
        }
    }
    if (typeof value === 'object') {
        const kv = Object.entries(constList).find(([_, v]) =>
            Object.is(v, value),
        );
        if (kv) {
            return kv[1];
        }
    }
    return def;
};

/** Feste Liste: Schule -> Anrede des Antragstellers (Schule.idAnrede) */
export const SCHULE__ANREDE = {
    NONE: {
        value: -1,
        label: '-',
    },
    HERR: {
        value: 1,
        label: 'Herr',
    },
    FRAU: {
        value: 2,
        label: 'Frau',
    },
    KEINE: {
        value: 3,
        label: 'Keine Angabe',
    },
};

export const mapSchuleAnrede = createConstListItemMapper(SCHULE__ANREDE);

/** Feste Liste: Veranstaltung -> Teilnehmer (Teilnehmer2Veranstaltung.idStatus) */
export const TEILNEHMER2VERANSTALTUNG__BUCHUNGSSTATUS = {
    NONE: {
        value: -1,
        label: '-',
    },
    ANGEFRAGT: {
        value: 10,
        label: 'angefragt',
    },
    ABGESAGT: {
        value: 50,
        label: 'VA abgesagt',
    },
    ZUGESAGT: {
        value: 20,
        label: 'zugesagt',
    },
    BESTÄTIGT: {
        value: 30,
        label: 'bestätigt',
    },
    STORNIERT: {
        value: 40,
        label: 'storniert',
    },
    TEILNGENOMMEN: {
        value: 60,
        label: 'teilgenommen',
    },
    NICHT_ERSCHIENEN: {
        value: 70,
        label: 'nicht erschienen',
    },
};

export const mapVeranstaltungBuchungsstatus = createConstListItemMapper(
    TEILNEHMER2VERANSTALTUNG__BUCHUNGSSTATUS,
);

/** Feste Liste: Workshop -> Workshopstatus (idStatusWorkshop) */
export const WORKSHOP__STATUS = {
    NONE: {
        value: -1,
        label: '-',
    },
    OFFEN: {
        value: 10,
        label: 'offen',
    },
    IN_KLAERUNG: {
        value: 20,
        label: 'in Klärung',
    },
    ZUGETEILT: {
        value: 30,
        label: 'zugeteilt',
    },
    BESTÄTIGT: {
        value: 40,
        label: 'bestätigt',
    },
    STORNIERT: {
        value: 50,
        label: 'storniert',
    },
};

export const mapWorkshopStatus = createConstListItemMapper(WORKSHOP__STATUS);

/** Feste Liste: Veranstaltung -> Belegung (idBelegung) */
export const VERANSTALTUNG__BELEGUNG = {
    NONE: {
        value: -1,
        label: '-',
    },
    FREI: {
        value: 1,
        label: 'frei',
    },
    RESERVIERT: {
        value: 2,
        label: 'reserviert',
    },
    TEILBELEGT: {
        value: 3,
        label: 'teilbelegt',
    },
    AUSGEBUCHT: {
        value: 4,
        label: 'ausgebucht',
    },
    AUSGEFALLEN: {
        value: 5,
        label: 'ausgefallen',
    },
};

export const mapVeranstaltungBelegung = createConstListItemMapper(
    VERANSTALTUNG__BELEGUNG,
);

/* ################ IDs der Listendefinitionen ################ */
export const FIRMA_LST_DEF_KATEGORIE = 4009400;
export const SCHULE_LST_DEF_SCHUL_FORM = 4889400;
export const TEILNEHMER_LST_DEF_SCHUL_ZWEIG = 4159408;

/* Listendefinition: Teilnehmer -> Kategorie */
export const TEILNEHMER__KATEGORIE__SCHULANMELDUNG = 419;

/* Listendefinition: Teilnehmer -> Zuschuss */
export const TEILNEHMER__ZUSCHUSS__GVH = 7657;
export const TEILNEHMER__ZUSCHUSS__4EUR = 7659;
export const TEILNEHMER__ZUSCHUSS__KEIN = 7661;

/* Listendefinition: Veranstaltung -> Typ */
const VERANSTALTUNG__TYP__BUEHNEN_BEITRAG_PROD = 4971;
const VERANSTALTUNG__TYP__BUEHNEN_BEITRAG_TEST = 4971;
export const VERANSTALTUNG__TYP__BUEHNEN_BEITRAG = isAusstellerProd
    ? VERANSTALTUNG__TYP__BUEHNEN_BEITRAG_PROD
    : VERANSTALTUNG__TYP__BUEHNEN_BEITRAG_TEST;
const VERANSTALTUNG__TYP__TOUR_PROD = 270;
const VERANSTALTUNG__TYP__TOUR_TEST = 270;
export const VERANSTALTUNG__TYP__TOUR = isAusstellerProd
    ? VERANSTALTUNG__TYP__TOUR_PROD
    : VERANSTALTUNG__TYP__TOUR_TEST;
const VERANSTALTUNG__TYP__VORTRAG_PROD = 24;
const VERANSTALTUNG__TYP__VORTRAG_TEST = 24;
export const VERANSTALTUNG__TYP__VORTRAG = isAusstellerProd
    ? VERANSTALTUNG__TYP__VORTRAG_PROD
    : VERANSTALTUNG__TYP__VORTRAG_TEST;
const VERANSTALTUNG__TYP__WORKSHOP_PROD = 23;
const VERANSTALTUNG__TYP__WORKSHOP_TEST = 23;
export const VERANSTALTUNG__TYP__WORKSHOP = isAusstellerProd
    ? VERANSTALTUNG__TYP__WORKSHOP_PROD
    : VERANSTALTUNG__TYP__WORKSHOP_TEST;

/* ################ Standausstattung -> IdAusstattungTyp ################ */
/* Stelen */
const STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_50_PROD = 168811;
const STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_50_TEST = 55467;
export const STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_50 = isAusstellerProd
    ? STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_50_PROD
    : STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_50_TEST;
const STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_50_MIT_BERUF_INFOS_PROD = 168818;
const STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_50_MIT_BERUF_INFOS_TEST = 55468;
export const STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_50_MIT_BERUF_INFOS =
    isAusstellerProd
        ? STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_50_MIT_BERUF_INFOS_PROD
        : STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_50_MIT_BERUF_INFOS_TEST;
const STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_150_PROD = 168817;
const STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_150_TEST = 55470;
export const STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_150 = isAusstellerProd
    ? STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_150_PROD
    : STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__STELE_150_TEST;
/* Infotresen */
const STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__INFO_TRESEN_PROD = 168812;
const STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__INFO_TRESEN_TEST = 55465;
export const STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__INFO_TRESEN = isAusstellerProd
    ? STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__INFO_TRESEN_PROD
    : STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__INFO_TRESEN_TEST;
const STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__INFO_TRESEN_MIT_BERUF_INFOS_PROD = 168816;
const STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__INFO_TRESEN_MIT_BERUF_INFOS_TEST = 55469;
export const STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__INFO_TRESEN_MIT_BERUF_INFOS =
    isAusstellerProd
        ? STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__INFO_TRESEN_MIT_BERUF_INFOS_PROD
        : STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__INFO_TRESEN_MIT_BERUF_INFOS_TEST;
/* Präsentationsmodule */
const STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__PRAESENTATION_MODUL_PROD = 168810;
const STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__PRAESENTATION_MODUL_TEST = 55466;
export const STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__PRAESENTATION_MODUL =
    isAusstellerProd
        ? STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__PRAESENTATION_MODUL_PROD
        : STAND_AUSSTATTUNG__AUSSTATTUNG_TYP__PRAESENTATION_MODUL_TEST;

/* ################ Veranstaltung -> IdEvent ################ */
const VERANSTALTUNG__EVENT__HAUPTVERANSTALTUNG_PROD = 161924;
const VERANSTALTUNG__EVENT__HAUPTVERANSTALTUNG_TEST = 55571;
export const VERANSTALTUNG__EVENT__HAUPTVERANSTALTUNG = isAusstellerProd
    ? VERANSTALTUNG__EVENT__HAUPTVERANSTALTUNG_PROD
    : VERANSTALTUNG__EVENT__HAUPTVERANSTALTUNG_TEST;
