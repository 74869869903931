import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from '../../common/loader';

/** @extends {Component<Partial<{ saveButtonAlignRight: boolean, showCancel: boolean, handleSubmit: (...args: any) => void, submit: (values: any) => void, submitName: string, hideBtn: boolean, loading: boolean, handleDelete: () => void, showDelete: boolean }>>} */
class FormButtons extends Component {
    render() {
        // Ergänzung der CSS-Klasse, wenn der Speichern-Button rechts ausgerichtet sein soll
        let buttonClass = 'button';

        if (this.props.saveButtonAlignRight) {
            buttonClass = 'button saveButtonRight';
        }

        return (
            <div className='buttonwrap'>
                {/* Button Abbrechen */}
                {this.props.showCancel ? (
                    <Link
                        className='button'
                        to={this.props.overviewLink}
                        onClick={() => {
                            document
                                .querySelector('.simplebar-content-wrapper')
                                .scrollTo(0, 0);
                        }}
                    >
                        Abbrechen
                    </Link>
                ) : null}

                {/* Button Löschen */}
                {this.props.showDelete ? (
                    <a
                        className={buttonClass}
                        // rome-ignore lint/a11y/useValidAnchor: <explanation>
                        onClick={this.props.handleDelete}
                    >
                        Löschen
                    </a>
                ) : null}

                {/* Button Speichern */}
                {this.props.handleSubmit &&
                this.props.submit &&
                !this.props.disabled ? (
                    <a
                        className={buttonClass}
                        // rome-ignore lint/a11y/useValidAnchor: FIXME
                        onClick={this.props.handleSubmit((values) =>
                            this.props.submit({
                                ...values,
                                status: 'inbearbeitung',
                            }),
                        )}
                    >
                        {this.props.loading ? (
                            <Loader visible />
                        ) : this.props.submitName ? (
                            this.props.submitName
                        ) : (
                            'Speichern'
                        )}
                    </a>
                ) : null}

                {/* Button Freigeben */}
                {this.props.handleSubmit &&
                this.props.submit &&
                !this.props.hideBtn ? (
                    <a
                        className='button'
                        // rome-ignore lint/a11y/useValidAnchor: FIXME
                        onClick={this.props.handleSubmit((values) =>
                            this.props.submit({
                                ...values,
                                status: 'freigegeben',
                            }),
                        )}
                    >
                        Freigeben
                    </a>
                ) : null}
            </div>
        );
    }
}

export default connect()(FormButtons);
