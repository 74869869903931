import {
    BANNER_PRINT_LIST,
    BANNER_PRINT_GET,
    BOARD_GET,
    BOARD_LIST,
    BOOTH2FEATURE_GET,
    BOOTH2FEATURE_LIST,
    BOOTH_FEATURE_GET,
    BOOTH_FEATURE_LIST,
    BOOTH_GET,
    BOOTH_LIST,
    CAREERSTAGE_GET,
    CATERING_GET,
    CATERING_LIST,
    COMPANY2PERSON_GET,
    COMPANY2PERSON_LIST,
    COMPANY2SITE_GET,
    COMPANY2SITE_LIST,
    COMPANY_GET,
    COMPANY_LIST,
    CONTRACT_GET,
    CONTRACT_LIST,
    DUALSTUDY_GET,
    DUALSTUDY_LIST,
    ENV,
    EXHIBITOR_GET,
    EXHIBITOR_LIST,
    EXHIBIT_GET,
    EXHIBIT_LIST,
    FEATURE_GET,
    FEATURE_LIST,
    INFODESK_GET,
    INFODESK_LIST,
    ORGANISATION_GET,
    ORGANISATION_LIST,
    PASSES_GET,
    PASSES_LIST,
    PERSON_GET,
    PERSON_LIST,
    PRESENTATIONMODULE_GET,
    PRESENTATIONMODULE_LIST,
    PROJECT_GET,
    PROJECT_LIST,
    SHOWSTAGE_GET,
    SITE_GET,
    SITE_LIST,
    STAGESEVEN_GET,
    STAGE_LIST,
    STELETYPE_GET,
    STELETYPE_LIST,
    STELE_GET,
    STELE_LIST,
    STORAGE_GET,
    STORAGE_LIST,
    STUDYFIELD_GET,
    STUDYFIELD_LIST,
    THEME_WORLD_GET,
    THEME_WORLD_LIST,
    TRAINING_GET,
    TRAINING_LIST,
    USER2ROLE_GET,
    USER2ROLE_LIST,
    WORKSHOP_GET,
    WORKSHOP_LIST,
    BANNER_PRINT_ADD,
    BANNER_PRINT_NEW,
    BANNER_PRINT_SAVE,
    BANNER_PRINT_DELETE,
    BANNER_DIGITAL_ADD,
    BANNER_DIGITAL_NEW,
    BANNER_DIGITAL_SAVE,
    BANNER_DIGITAL_DELETE,
    BANNER_DIGITAL_LIST,
    BANNER_DIGITAL_GET,
    ORGANISATION_SAVE,
    PASSES_DELETE,
    PASSES_SAVE,
} from '../../../global_constants';
import { CONTRACT_TYPE_VERTRAGSPARTNER } from '../../../backend-id-constants';
import {
    setBoard,
    setBoardList,
    setBoardUpdate,
    setBooth,
    setBooth2feature,
    setBooth2featureList,
    setBooth2featureUpdate,
    setBoothFeature,
    setBoothFeatureList,
    setBoothFeatureUpdate,
    setBoothList,
    setBoothUpdate,
    setCareerstage,
    setCareerstageUpdate,
    setCatering,
    setCateringList,
    setCompany,
    setCompany2person,
    setCompany2personList,
    setCompany2site,
    setCompany2siteList,
    setCompanyList,
    setCompanyUpdate,
    setContract,
    setContractList,
    setDualstudy,
    setDualstudyList,
    setExhibit,
    setExhibitList,
    setExhibitUpdate,
    setExhibitor,
    setExhibitorList,
    setFeature,
    setFeatureList,
    setFeatureUpdate,
    setInfodesk,
    setInfodeskList,
    setInfodeskUpdate,
    setOrganisation,
    setOrganisationList,
    setPasses,
    setPassesList,
    setPerson,
    setPersonList,
    setPersonUpdate,
    setPresenationsmodule,
    setPresenationsmoduleList,
    setPresenationsmoduleUpdate,
    setProject,
    setProjectList,
    setShowstage,
    setShowstageUpdate,
    setSite,
    setSiteList,
    setStageList,
    setStageseven,
    setStagesevenUpdate,
    setStele,
    setSteleList,
    setSteleType,
    setSteleTypeList,
    setSteleUpdate,
    setStorage,
    setStorageList,
    setStudyfield,
    setStudyfieldList,
    setThemeWorld,
    setThemeWorldList,
    setTraining,
    setTrainingList,
    setUser2role,
    setUser2roleList,
    setUser2roleUpdate,
    setWorkshop,
    setWorkshopList,
    setWorkshopUpdate,
    setBannerPrintList,
    setBannerPrint,
    setBannerDigitalList,
    setBannerDigital,
} from '../exhibitor-actions';
import { store } from '../../../store';
import { fetchEntityList, fetchSingleEntity } from './fetcher';

/**
 * listEndpoint = endpoint for getLst
 * getEndpoint = endpoint for get
 * dispatchEntityList = dispatch action for getLst
 * dispatchEntity = dispatch action for get
 * dispatchEntity2 = dispatch action for get, when only a specific entity should be updated in Redux (getSpecific)
 * tpEntityLstItemName = name of getLst-List
 * filterParameterForList = filter argument for getLst request
 * idForItem: filter parameter for get request (always ID), only something else for defined lists (endpoint /lstdef/)
 *
 * @param type {'banner' | 'board' | 'booth' | 'boothFeature' | 'booth2feature' | 'careerstage'
 * | 'catering' | 'company' | 'company2person' | 'company2site' | 'contract'
 * | 'dualstudy' | 'exhibit' | 'exhibitor' | 'feature'| 'infodesk' | 'organisation'
 * | 'passes' | 'person' | 'presentationmodule' | 'project' | 'showstage' | 'site'
 * | 'stageseven' | 'stele' | 'steletype' | 'storage' | 'studyfield' | 'themeWorld'
 * | 'training' | 'user2role' | 'workshop'}
 * @returns
 */
export const createEntityConfig = (type) => {
    const storeState = store.getState();
    const authenticationData = storeState.authenticationDataExhibitor;
    const exhibitor =
        Array.isArray(storeState.exhibitor) && storeState.exhibitor.length >= 1
            ? storeState.exhibitor[0]
            : {};
    const organisation =
        Array.isArray(storeState.organisation) &&
        storeState.organisation.length >= 1
            ? storeState.organisation[0]
            : {};
    const project =
        Array.isArray(storeState.project) && storeState.project.length >= 1
            ? storeState.project[0]
            : {};
    const config = {
        bannerPrint: {
            addEndpoint: BANNER_PRINT_ADD,
            newEndpoint: BANNER_PRINT_NEW,
            updateEndpoint: BANNER_PRINT_SAVE,
            deleteEndpoint: BANNER_PRINT_DELETE,
            listEndpoint: BANNER_PRINT_LIST,
            getEndpoint: BANNER_PRINT_GET,
            dispatchEntityList: setBannerPrintList,
            dispatchEntity: setBannerPrint,
            tpEntityLstItemName: 'TpBannerPrintLstItem',
            filterParameterForList: {
                idOrganisation: organisation.ID,
            },
            idForItem: 'ID',
        },
        bannerDigital: {
            addEndpoint: BANNER_DIGITAL_ADD,
            newEndpoint: BANNER_DIGITAL_NEW,
            updateEndpoint: BANNER_DIGITAL_SAVE,
            deleteEndpoint: BANNER_DIGITAL_DELETE,
            listEndpoint: BANNER_DIGITAL_LIST,
            getEndpoint: BANNER_DIGITAL_GET,
            dispatchEntityList: setBannerDigitalList,
            dispatchEntity: setBannerDigital,
            tpEntityLstItemName: 'TpBannerDigitalLstItem',
            filterParameterForList: {
                idOrganisation: organisation.ID,
            },
            idForItem: 'ID',
        },
        board: {
            listEndpoint: BOARD_LIST,
            getEndpoint: BOARD_GET,
            dispatchEntityList: setBoardList,
            dispatchEntity: setBoard,
            dispatchEntity2: setBoardUpdate,
            tpEntityLstItemName: 'TpExponatTafelLstItem',
            filterParameterForList: { idFirma: exhibitor.idAussteller },
            idForItem: 'ID',
        },
        booth: {
            listEndpoint: BOOTH_LIST,
            getEndpoint: BOOTH_GET,
            dispatchEntityList: setBoothList,
            dispatchEntity: setBooth,
            dispatchEntity2: setBoothUpdate,
            tpEntityLstItemName: 'TpStandLstItem',
            filterParameterForList: {
                idFirma: exhibitor.idAussteller,
                idProjekt: project.ID,
            },
            idForItem: 'ID',
        },
        // TpStandAusstattung
        boothFeature: {
            listEndpoint: BOOTH_FEATURE_LIST,
            getEndpoint: BOOTH_FEATURE_GET,
            dispatchEntityList: setBoothFeatureList,
            dispatchEntity: setBoothFeature,
            dispatchEntity2: setBoothFeatureUpdate,
            tpEntityLstItemName: 'TpStandAusstattungLstItem',
            filterParameterForList: {
                idFirma: exhibitor.idAussteller,
                idProjekt: project.ID,
            },
            idForItem: 'ID',
        },
        booth2feature: {
            listEndpoint: BOOTH2FEATURE_LIST,
            getEndpoint: BOOTH2FEATURE_GET,
            dispatchEntityList: setBooth2featureList,
            dispatchEntity: setBooth2feature,
            dispatchEntity2: setBooth2featureUpdate,
            tpEntityLstItemName: 'TpStand2AusstattungLstItem',
            filterParameterForList: {},
            idForItem: 'ID',
        },
        careerstage: {
            listEndpoint: STAGE_LIST,
            getEndpoint: CAREERSTAGE_GET,
            dispatchEntityList: setStageList,
            dispatchEntity: setCareerstage,
            dispatchEntity2: setCareerstageUpdate,
            tpEntityLstItemName: 'TpBuehneLstItem',
            filterParameterForList: {
                idFirma: exhibitor.idAussteller,
                idBuehnenArt: 203,
            },
            idForItem: 'ID',
        },
        catering: {
            listEndpoint: CATERING_LIST,
            getEndpoint: CATERING_GET,
            dispatchEntityList: setCateringList,
            dispatchEntity: setCatering,
            tpEntityLstItemName: 'TpAusstellercateringLstItem',
            filterParameterForList: { idOrganisation: organisation.ID },
            idForItem: 'ID',
        },
        company: {
            listEndpoint: COMPANY_LIST,
            getEndpoint: COMPANY_GET,
            dispatchEntityList: setCompanyList,
            dispatchEntity: setCompany,
            dispatchEntity2: setCompanyUpdate,
            tpEntityLstItemName: 'TpFirmaLstItem',
            filterParameterForList: { ID: exhibitor.idAussteller },
            idForItem: 'ID',
        },
        company2person: {
            listEndpoint: COMPANY2PERSON_LIST,
            getEndpoint: COMPANY2PERSON_GET,
            dispatchEntityList: setCompany2personList,
            dispatchEntity: setCompany2person,
            tpEntityLstItemName: 'TpFirma2PersonLstItem',
            filterParameterForList: { idFirma: exhibitor.idAussteller },
            idForItem: 'ID',
        },
        company2site: {
            listEndpoint: COMPANY2SITE_LIST,
            getEndpoint: COMPANY2SITE_GET,
            dispatchEntityList: setCompany2siteList,
            dispatchEntity: setCompany2site,
            tpEntityLstItemName: 'TpFirmaAusbildungsstandortLstItem',
            filterParameterForList: { idFirma: exhibitor.idAussteller },
            idForItem: 'ID',
        },
        contract: {
            listEndpoint: CONTRACT_LIST,
            getEndpoint: CONTRACT_GET,
            dispatchEntityList: setContractList,
            dispatchEntity: setContract,
            dispatchEntity2: setContract,
            tpEntityLstItemName: 'TpVertragLstItem',
            filterParameterForList: {
                idFirma: exhibitor.idAussteller,
                idProjekt: project.ID,
                idTyp: CONTRACT_TYPE_VERTRAGSPARTNER,
            },
            idForItem: 'ID',
        },
        dualstudy: {
            listEndpoint: DUALSTUDY_LIST,
            getEndpoint: DUALSTUDY_GET,
            dispatchEntityList: setDualstudyList,
            dispatchEntity: setDualstudy,
            tpEntityLstItemName: 'TpDualesStudiumLstItem',
            filterParameterForList: { idFirma: exhibitor.idAussteller },
            idForItem: 'ID',
        },
        exhibit: {
            listEndpoint: EXHIBIT_LIST,
            getEndpoint: EXHIBIT_GET,
            dispatchEntityList: setExhibitList,
            dispatchEntity: setExhibit,
            dispatchEntity2: setExhibitUpdate,
            tpEntityLstItemName: 'TpExponatLstItem',
            filterParameterForList: {
                idFirma: exhibitor.idAussteller,
                idProjekt: project.ID,
            },
            idForItem: 'ID',
        },
        exhibitor: {
            listEndpoint: EXHIBITOR_LIST,
            getEndpoint: EXHIBITOR_GET,
            dispatchEntityList: setExhibitorList,
            dispatchEntity: setExhibitor,
            tpEntityLstItemName: 'TpAusstellerBenutzerLstItem',
            filterParameterForList: { idUser: authenticationData.userId },
            idForItem: 'ID',
        },
        feature: {
            listEndpoint: FEATURE_LIST,
            getEndpoint: FEATURE_GET,
            dispatchEntityList: setFeatureList,
            dispatchEntity: setFeature,
            dispatchEntity2: setFeatureUpdate,
            tpEntityLstItemName: 'TpAusstattungLstItem',
            // feature / Ausstattung cannot properly be filtered by idFirma
            // because only for some kinds of Ausstattung (Stelen) Ausstattung can
            // be associated with Firma. For other kinds, e.g. Exponattafeln / boards,
            // this is not possible. "state.feature" will, defined like this, never
            // contain Exponattafeln / boards
            // filterParameterForList: {"idFirma": exhibitor.idAussteller}
            filterParameterForList: { idProjekt: project.ID },
            idForItem: 'ID',
        },
        infodesk: {
            listEndpoint: INFODESK_LIST,
            getEndpoint: INFODESK_GET,
            dispatchEntityList: setInfodeskList,
            dispatchEntity: setInfodesk,
            dispatchEntity2: setInfodeskUpdate,
            tpEntityLstItemName: 'TpInfotresenLstItem',
            // infodesk / Infotresen cannot properly be filtered by idFirma
            // because only some kinds of Ausstattung (e.g. Stelen) can
            // be associated with Firma. For other kinds, e.g. infodesk / Infotresen,
            // this is not possible. "state.infodesk" will, defined like this, always
            // contain all infodesks, independent of who created them.
            // This could be changed BUT the request to getLst cannot be filtered by
            // anything meaningful (meaningful would be any
            // entity that eventually can be connected to the user who is logged in:
            // Exponat, Stand, Firma), therefore, the problem
            // persists: A request to infotresen/getLst always retrieves all infotresen.
            filterParameterForList: {},
            idForItem: 'ID',
        },
        organisation: {
            listEndpoint: ORGANISATION_LIST,
            getEndpoint: ORGANISATION_GET,
            updateEndpoint: ORGANISATION_SAVE,
            dispatchEntityList: setOrganisationList,
            dispatchEntity: setOrganisation,
            tpEntityLstItemName: 'TpOrganisationLstItem',
            filterParameterForList: {
                idFirma: exhibitor.idAussteller,
                idProjekt: project.ID,
            },
            idForItem: 'ID',
        },
        passes: {
            listEndpoint: PASSES_LIST,
            getEndpoint: PASSES_GET,
            deleteEndpoint: PASSES_DELETE,
            updateEndpoint: PASSES_SAVE,
            dispatchEntityList: setPassesList,
            dispatchEntity: setPasses,
            tpEntityLstItemName: 'TpAusstellerausweisLstItem',
            filterParameterForList: { idOrganisation: organisation.ID },
            idForItem: 'ID',
        },
        person: {
            listEndpoint: PERSON_LIST,
            getEndpoint: PERSON_GET,
            dispatchEntityList: setPersonList,
            dispatchEntity: setPerson,
            dispatchEntity2: setPersonUpdate,
            tpEntityLstItemName: 'TpPersonLstItem',
            filterParameterForList: {},
            idForItem: 'ID',
        },
        presentationmodule: {
            listEndpoint: PRESENTATIONMODULE_LIST,
            getEndpoint: PRESENTATIONMODULE_GET,
            dispatchEntityList: setPresenationsmoduleList,
            dispatchEntity: setPresenationsmodule,
            dispatchEntity2: setPresenationsmoduleUpdate,
            tpEntityLstItemName: 'TpPraesentationsmodulLstItem',
            // presentationmodule / Präsentationsmodul cannot properly
            // be filtered by idFirma
            // because only some kinds of Ausstattung (e.g. Stelen) can
            // be associated with Firma. For other kinds, e.g
            // presentationmodule / Präsentationsmodul, this is not possible.
            // "state.presentationmodule" will, defined like this, always
            // contain all presentationmodules, independent of who created them.
            // This could be changed BUT the request to getLst cannot be filtered by
            // anything meaningful (meaningful would be any
            // entity that eventually can be connected to the user who is logged in:
            // Exponat, Stand, Firma), therefore, the problem
            // persists: A request to praesentationsmodul/getLst always retrieves
            // all presentationmodules.
            filterParameterForList: {},
            idForItem: 'ID',
        },
        project: {
            listEndpoint: PROJECT_LIST,
            getEndpoint: PROJECT_GET,
            dispatchEntityList: setProjectList,
            dispatchEntity: setProject,
            dispatchEntity2: setProject,
            tpEntityLstItemName: 'TpProjektLstItem',
            filterParameterForList: { kzAktiv: 1 },
            idForItem: 'ID',
        },
        showstage: {
            listEndpoint: STAGE_LIST,
            getEndpoint: SHOWSTAGE_GET,
            dispatchEntityList: setStageList,
            dispatchEntity: setShowstage,
            dispatchEntity2: setShowstageUpdate,
            tpEntityLstItemName: 'TpBuehneLstItem',
            filterParameterForList: {
                idFirma: exhibitor.idAussteller,
                idBuehnenArt: 202,
            },
            idForItem: 'ID',
        },
        site: {
            listEndpoint: SITE_LIST,
            getEndpoint: SITE_GET,
            dispatchEntityList: setSiteList,
            dispatchEntity: setSite,
            dispatchEntity2: setSite,
            tpEntityLstItemName: 'TpOrtLstItem',
            filterParameterForList: { idProjekt: project.ID },
            idForItem: 'ID',
        },
        stageseven: {
            listEndpoint: STAGE_LIST,
            getEndpoint: STAGESEVEN_GET,
            dispatchEntityList: setStageList,
            dispatchEntity: setStageseven,
            dispatchEntity2: setStagesevenUpdate,
            tpEntityLstItemName: 'TpBuehneLstItem',
            filterParameterForList: {
                idFirma: exhibitor.idAussteller,
                idBuehnenArt: 201,
            },
            idForItem: 'ID',
        },
        stele: {
            listEndpoint: STELE_LIST,
            getEndpoint: STELE_GET,
            dispatchEntityList: setSteleList,
            dispatchEntity: setStele,
            dispatchEntity2: setSteleUpdate,
            tpEntityLstItemName: 'TpSteleLstItem',
            filterParameterForList: { idFirma: exhibitor.idAussteller },
            idForItem: 'ID',
        },
        steletype: {
            listEndpoint: STELETYPE_LIST,
            getEndpoint: STELETYPE_GET,
            dispatchEntityList: setSteleTypeList,
            dispatchEntity: setSteleType,
            dispatchEntity2: setSteleType,
            tpEntityLstItemName: 'TpStelentypLstItem',
            filterParameterForList: {},
            idForItem: 'ID',
        },
        storage: {
            listEndpoint: STORAGE_LIST,
            getEndpoint: STORAGE_GET,
            dispatchEntityList: setStorageList,
            dispatchEntity: setStorage,
            tpEntityLstItemName: 'TpLagerflaecheLstItem',
            filterParameterForList: { idOrganisation: organisation.ID },
            idForItem: 'ID',
        },
        studyfield: {
            listEndpoint: STUDYFIELD_LIST,
            getEndpoint: STUDYFIELD_GET,
            dispatchEntityList: setStudyfieldList,
            dispatchEntity: setStudyfield,
            tpEntityLstItemName: 'TpStudienrichtungLstItem',
            filterParameterForList: { idFirma: exhibitor.idAussteller },
            idForItem: 'ID',
        },
        themeWorld: {
            listEndpoint: THEME_WORLD_LIST,
            getEndpoint: THEME_WORLD_GET,
            dispatchEntityList: setThemeWorldList,
            dispatchEntity: setThemeWorld,
            tpEntityLstItemName: 'TpThemenWeltLstItem',
            filterParameterForList: { idProjekt: project.ID },
            idForItem: 'ID',
        },
        training: {
            listEndpoint: TRAINING_LIST,
            getEndpoint: TRAINING_GET,
            dispatchEntityList: setTrainingList,
            dispatchEntity: setTraining,
            tpEntityLstItemName: 'TpAusbildungsberufLstItem',
            filterParameterForList: { idFirma: exhibitor.idAussteller },
            idForItem: 'ID',
        },
        user2role: {
            listEndpoint: USER2ROLE_LIST,
            getEndpoint: USER2ROLE_GET,
            dispatchEntityList: setUser2roleList,
            dispatchEntity: setUser2role,
            dispatchEntity2: setUser2roleUpdate,
            tpEntityLstItemName: 'TpUser2RolleLstItem',
            filterParameterForList: { USER_NR: exhibitor.userId },
            idForItem: 'ID',
        },
        workshop: {
            listEndpoint: WORKSHOP_LIST,
            getEndpoint: WORKSHOP_GET,
            dispatchEntityList: setWorkshopList,
            dispatchEntity: setWorkshop,
            dispatchEntity2: setWorkshopUpdate,
            tpEntityLstItemName: 'TpVeranstaltungLstItem',
            filterParameterForList: {
                idFirma: exhibitor.idAussteller,
                idProjekt: project.ID,
            },
            idForItem: 'ID',
        },
    };
    return config[type];
};

/** @throws if response is not ok */
export const fetchEntityTuple = async (
    { listEndpoint, tpEntityLstItemName, getEndpoint, idForItem },
    filterParameter,
) => {
    const tpEntityLst = await fetchEntityList(listEndpoint, filterParameter);
    const itemList = tpEntityLst[tpEntityLstItemName] || [];
    if (!getEndpoint) {
        console.warn(
            'No GET endpoint specified for',
            listEndpoint,
            ', not fetching details.',
        );
        return [tpEntityLst, []];
    }
    const entityList = await Promise.all(
        itemList.map(async (entityItem) => {
            const entity = await fetchSingleEntity(
                getEndpoint,
                entityItem[idForItem],
            );
            // ENV === 'development' && console.log('entity details:', entity);
            return entity;
        }),
    );
    return [tpEntityLst, entityList, itemList];
};
