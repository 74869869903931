import {
    createEntityConfig,
    fetchEntityTuple,
} from '../../../actions/exhibitor/fetcher/entity-list-detail.fetcher';
import { filterSteles } from './featureTypeFilter';

export async function isBoothApproved(booth) {
    return (
        (await areAllExhibitsApproved(booth)) &&
        (await areAllExhibitPanelsApproved(booth)) &&
        (await areAllStelesApproved(booth))
    );
}

export async function areAllExhibitsApproved(booth) {
    const exhibitLstItems = booth.tp_Stand_2_ExponatLst.TpExponatLstItem;
    const exhibitIds = exhibitLstItems.map((exhibit) => exhibit.ID);
    if (exhibitIds.length === 0) {
        return true;
    }
    const filterParameterForList2 = { idArray: exhibitIds };
    const entityConfig = createEntityConfig('exhibit');
    const [_tpEntityLst, exhibits] = await fetchEntityTuple(entityConfig, {
        ...entityConfig.filterParameterForList,
        ...filterParameterForList2,
    });
    return areAllApproved(exhibits);
}

export async function areAllExhibitPanelsApproved(booth) {
    const exhibits = booth.tp_Stand_2_ExponatLst.TpExponatLstItem;
    const exhibitIds = exhibits.map((exhibit) => exhibit.ID);
    if (exhibitIds.length === 0) {
        return true;
    }
    const filterParameterForList2 = { idExponatArray: exhibitIds };
    const entityConfig = createEntityConfig('board');
    const [_tpEntityLst, exhibitPanels] = await fetchEntityTuple(entityConfig, {
        ...entityConfig.filterParameterForList,
        ...filterParameterForList2,
    });
    return areAllApproved(exhibitPanels);
}

export async function areAllStelesApproved(booth) {
    const standAusstattungen =
        booth.tp_Stand_2_StandAusstattungLst.TpStandAusstattungLstItem;
    const stelesStandAusstattung = filterSteles(standAusstattungen);
    const steleIds = stelesStandAusstattung.map((stele) => stele.idStele);
    if (steleIds.length === 0) {
        return true;
    }
    const filterParameterForList2 = { idArray: steleIds };
    const entityConfig = createEntityConfig('stele');
    const [_tpEntityLst, steles] = await fetchEntityTuple(entityConfig, {
        ...entityConfig.filterParameterForList,
        ...filterParameterForList2,
    });
    return areAllApproved(steles);
}

function areAllApproved(approvalObjects) {
    if (!Array.isArray(approvalObjects)) {
        return false;
    }
    return approvalObjects.every(
        (approvalObject) => approvalObject.kzFreigabeAussteller === 1,
    );
}
