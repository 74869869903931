import { store } from '../../../store';
import { getEntity, setExhibitUpdate } from '../exhibitor-actions.js';
import {
    BACKEND_URL,
    ENV,
    EXHIBIT_SAVE,
    EXHIBIT_ADD,
    EXHIBIT_NEW,
} from '../../../global_constants';
import {
    showAlert,
    ERROR_UNAUTHORIZED,
    ERROR,
    SUCCESS,
} from '../../../components/exhibitor/alerts';
import { mapEntriesByKey } from '../../common/extern-id-mapper';

export const updateExhibit = (json, values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;

    // Wenn anstelle von Values (die normalerweise die geänderten Daten aus der
    // Detailseite beinhalten) false übergeben wird, dann werden diese Detailänderungen nicht mit dem JSON gemergt,
    // sondern es wird direkt das JSON mit geänderten Kennzeichen für die Freigabe an den Server übermittelt
    if (values === false) {
        return fetch(BACKEND_URL + EXHIBIT_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(json),
        }).then((response) => {
            if (!response.ok) {
                showAlert(
                    ERROR,
                    'Die Freigabe konnte nicht gespeichert werden.',
                );
                return 500;
            } else {
                showAlert(SUCCESS, 'Die Freigabe wurde gespeichert.');
                return 200;
            }
        });
    }

    return function (dispatch) {
        ENV === 'development' &&
            console.info('updating the exhibit with id = ', json.ID);

        const newValues = mapExhibit(values, authenticationData, json);
        const jsonNew = { ...json, ...newValues };

        return fetch(BACKEND_URL + EXHIBIT_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(jsonNew),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('exhibit details fetch:', json);
                if (!json.ID) {
                    return Promise.reject(
                        'exhibit details fetch validity check: fail',
                    );
                }
                return json;
            })
            .then((json) => {
                return dispatch(setExhibitUpdate(json));
            })
            .then((json) => {
                dispatch(getEntity('booth', { ID: json.exhibit.idStand }));
                return json;
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};

export const insertExhibit = (values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    const getState = store.getState();
    const exhibitor =
        Array.isArray(getState.exhibitor) && getState.exhibitor.length >= 1
            ? getState.exhibitor[0]
            : {};
    return function (dispatch) {
        const newValues = mapExhibit(values, authenticationData);
        // request a new exhibit prototype
        return fetch(BACKEND_URL + EXHIBIT_NEW, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData,
                    userId4Hist: undefined,
                },
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                // merge/overwrite requested json prototype with user input
                const jsonNew = { ...json, ...newValues };
                jsonNew.idStand = values.idStand;
                // jsonNew.USER_ID_CHANGE = `${authenticationData.userIdLogin}`
                // jsonNew.USER_ID_NEW = `${authenticationData.userIdLogin}`
                // jsonNew.USER_NR_CHANGE = authenticationData.userId
                // jsonNew.USER_NR_NEW = authenticationData.userId
                jsonNew.idProjekt = getState.authenticationData
                    ?.TpProjektLstItem
                    ? getState.authenticationData.TpProjektLstItem[0].ID
                    : null;
                jsonNew.idFirma = exhibitor.idAussteller;
                return fetch(BACKEND_URL + EXHIBIT_ADD, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authenticationData.accessToken}`,
                    },
                    body: JSON.stringify({
                        ...jsonNew,
                        login: {
                            // TODO: pick properties manually!
                            ...authenticationData,
                            userId4Hist: undefined,
                        },
                    }),
                });
            })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('exhibit details fetch:', json);
                // TODO: check if already expired or already answered!
                // TODO: check if we get more than one TpEinladungLstItem entry, then the filter is probably wrong or the invite code
                return json;
            })
            .then((json) => {
                return dispatch(setExhibitUpdate(json));
            })
            .then((json) => {
                dispatch(getEntity('booth', { ID: json.exhibit.idStand }));
                return json;
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};

const mapExhibit = (values, authenticationData, json = {}) => {
    const newValues = {
        kzFreigabeAussteller:
            values.status === 'freigegeben'
                ? 1
                : values.status === 'inbearbeitung'
                ? 0
                : json
                ? json.kzFreigabeAussteller
                : '',
        projekttitel:
            typeof values.exponat_titel !== 'undefined'
                ? values.exponat_titel
                : json.projekttitel || '',
        idAnsprechpartner:
            typeof values.ansprechpartner !== 'undefined'
                ? values.ansprechpartner
                : json.idAnsprechpartner || '',
        idTyp:
            values.interaktiv === true
                ? 2
                : values.interaktiv === false
                ? 0
                : json
                ? json.idTyp
                : -1,
        idInteraktionszeit:
            values.vorfuerdauer === '5min'
                ? 1
                : values.vorfuerdauer && values.vorfuerdauer !== '5min'
                ? 2
                : json
                ? json.idInteraktionszeit
                : -1,
        idNeuheit:
            values.wiederholt === 'ja'
                ? 1
                : values.wiederholt === 'nein'
                ? 2
                : json
                ? json.idNeuheit
                : -1,
        titelInternet:
            typeof values.exponat_titel_desc !== 'undefined'
                ? values.exponat_titel_desc
                : json.titelInternet || '',
        textInternet:
            typeof values.exponat_beschreibung !== 'undefined'
                ? values.exponat_beschreibung
                : json.textInternet || '',
        erlaeuterung:
            typeof values.exponat_anmerkungen !== 'undefined'
                ? values.exponat_anmerkungen
                : json.erlaeuterung || '',
        data4Datenblatt:
            typeof values.data4Datenblatt !== 'undefined'
                ? values.data4Datenblatt
                : json.data4Datenblatt || '',
        datenblatt:
            typeof values.datenblatt !== 'undefined'
                ? values.datenblatt
                : json.datenblatt || '',
        datenblattExtension:
            typeof values.datenblattExtension !== 'undefined'
                ? values.datenblattExtension
                : json.datenblattExtension || '',
        masse:
            typeof values.exponat_groesse !== 'undefined'
                ? values.exponat_groesse
                : json.masse || '',
        gewicht:
            typeof values.exponat_gewicht !== 'undefined'
                ? values.exponat_gewicht
                : json.gewicht || '',
        kzAufAbbau2Tage:
            values.aufbauGT2 === true
                ? 1
                : values.aufbauGT === false
                ? 0
                : json
                ? json.kzAufAbbau2Tage
                : '',
        idStrom:
            values.strom400 === true && values.strom230 === true
                ? 3
                : values.strom400 === true
                ? 2
                : values.strom230 === true
                ? 1
                : json
                ? json.idStrom
                : -1,
        kzStrom400V:
            values.strom400 === true
                ? 1
                : values.strom400 === false
                ? 0
                : json
                ? json.kzStrom400V
                : 0,
        idGrundStrom:
            typeof values.strom230_wofuer !== 'undefined'
                ? values.strom230_wofuer
                : json.idGrundStrom || '',
        anzahlStromanschlusspunkte:
            typeof values.strom230_anzahl !== 'undefined'
                ? values.strom230_anzahl
                : json.anzahlStromanschlusspunkte || '',
        leistungsbedarf:
            typeof values.strom230_leistung !== 'undefined'
                ? values.strom230_leistung
                : json.leistungsbedarf || '',
        grundStrom400V:
            typeof values.strom400_wofuer !== 'undefined'
                ? values.strom400_wofuer
                : json.grundStrom400V || '',
        idStecker:
            typeof values.strom400_stecker !== 'undefined'
                ? values.strom400_stecker
                : json.idStecker || '',
        ungefaehreLeistung:
            typeof values.strom400_leistung !== 'undefined'
                ? values.strom400_leistung
                : json.ungefaehreLeistung || '',
        kzWasser:
            values.wasseranschluss === true
                ? 1
                : values.wasseranschluss === false
                ? 0
                : json
                ? json.kzWasser
                : '',
        kzSpuele:
            values.spuele === true
                ? 1
                : values.spuele === false
                ? 0
                : json
                ? json.kzSpuele
                : '',
        kzDruckluft:
            values.druckluft === true
                ? 1
                : values.druckluft === false
                ? 0
                : json
                ? json.kzDruckluft
                : '',
        druck:
            typeof values.druckbar !== 'undefined'
                ? values.druckbar
                : json.druck || '',
        luftmenge:
            typeof values.luftverbrauch !== 'undefined'
                ? values.luftverbrauch
                : json.luftmenge || '',
        idDruckluftKupplung:
            typeof values.kupplung !== 'undefined'
                ? values.kupplung
                : json.idDruckluftKupplung || '',
        kzGefaehrdungspotenzial:
            values.gefaehrdung === true
                ? 1
                : values.gefaehrdung === false
                ? 0
                : json
                ? json.kzGefaehrdungspotenzial
                : '',
        gefaehrdungBeschreibung:
            typeof values.gefaehrdung_beschreibung !== 'undefined'
                ? values.gefaehrdung_beschreibung
                : json.gefaehrdungBeschreibung || '',
        emissionen:
            typeof values.emissionen !== 'undefined'
                ? values.emissionen
                : json.emissionen || '',
        kzSondermobiliar:
            typeof values.sondermobiliar !== 'undefined'
                ? values.sondermobiliar !== ''
                    ? 1
                    : 0
                : json
                ? json.kzSondermobiliar
                : 0,
        sondermobiliar:
            typeof values.sondermobiliar !== 'undefined'
                ? values.sondermobiliar
                : json.sondermobiliar || '',
        idInternet:
            values.mehrere_lan === true
                ? 1
                : values.mehrere_lan === false
                ? 0
                : json
                ? json.idInternet
                : '',
        kzExponatWlan:
            values.wlan === true
                ? 1
                : values.wlan === false
                ? 0
                : json
                ? json.kzExponatWlan
                : '',
        zusaetzlicheInternetverbindung:
            values.internet === true
                ? 1
                : values.internet === false
                ? 0
                : json
                ? json.zusaetzlicheInternetverbindung
                : '',
        sonstigeTechnik:
            typeof values.exponat_anforderungen !== 'undefined'
                ? values.exponat_anforderungen
                : json.sonstigeTechnik || '',
        keyWord1:
            values.keyWord1 != null ? values.keyWord1 : json.keyWord1 || '',
        keyWord2:
            values.keyWord2 != null ? values.keyWord2 : json.keyWord2 || '',
        keyWord3:
            values.keyWord3 != null ? values.keyWord3 : json.keyWord3 || '',
    };
    const zielgruppenIds = mapEntriesByKey(store.getState().exponatzg);
    newValues.tp_Exponat_2_ExponatZielgruppeLst = {
        login: {
            ...authenticationData,
        },
        TpExponatZielgruppeLstItem: [],
    };
    if (values.zielgruppe56) {
        newValues.tp_Exponat_2_ExponatZielgruppeLst.TpExponatZielgruppeLstItem.push(
            {
                MANDANT_NR: 1,
                idExponat: values.exponat_id,
                idZielgruppe: zielgruppenIds.zg56,
            },
        );
    }
    if (values.zielgruppe78) {
        newValues.tp_Exponat_2_ExponatZielgruppeLst.TpExponatZielgruppeLstItem.push(
            {
                MANDANT_NR: 1,
                idExponat: values.exponat_id,
                idZielgruppe: zielgruppenIds.zg78,
            },
        );
    }
    if (values.zielgruppe910) {
        newValues.tp_Exponat_2_ExponatZielgruppeLst.TpExponatZielgruppeLstItem.push(
            {
                MANDANT_NR: 1,
                idExponat: values.exponat_id,
                idZielgruppe: zielgruppenIds.zg910,
            },
        );
    }
    if (values.zielgruppe1112) {
        newValues.tp_Exponat_2_ExponatZielgruppeLst.TpExponatZielgruppeLstItem.push(
            {
                MANDANT_NR: 1,
                idExponat: values.exponat_id,
                idZielgruppe: zielgruppenIds.zgjg1112,
            },
        );
    }
    if (values.zielgruppe_aelter) {
        newValues.tp_Exponat_2_ExponatZielgruppeLst.TpExponatZielgruppeLstItem.push(
            {
                MANDANT_NR: 1,
                idExponat: values.exponat_id,
                idZielgruppe: zielgruppenIds['zgälter'],
            },
        );
    }
    return newValues;
};
