import React from 'react';

import StepButtons from '../../common/stepbuttons';

export default () => (
    <>
        <h1>
            <strong>Fehler</strong>
        </h1>
        <p>
            Der persönliche Zugangscode, den Sie eingegeben haben, ist
            abgelaufen oder wurde bereits beantwortet.
        </p>
        <StepButtons back='/' />
    </>
);
