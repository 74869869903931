import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import style from '../../common/index.module.css';
import StepButtons from '../../common/stepbuttons';
import Question from '../../common/question';
import { getCustomLst } from '../../../actions/common/actions.js';
import {
    fetchMaxGroup,
    fetchSchoolTrackList,
} from '../../../actions/registration-system/registration-actions';
import disableForm from '../../exhibitor/disableForm.js';
import isWorkflowEditable from '../functions.js';
import classNames from 'classnames';

class Group extends Question {
    constructor(props) {
        super(props, null, {
            valid: false,
        });
        this.schoolTrack = React.createRef();
        this.classLevel = React.createRef();
        this.name = React.createRef();
        this.girls = React.createRef();
        this.boys = React.createRef();
        this.leaders = React.createRef();

        this.girlsMin = props.groupDefaults.girls;
        this.boysMin = props.groupDefaults.boys;
        this.leadersMin = props.groupDefaults.leaders;

        this.girlsMax = this.props.maxGroup - this.leadersMin;
        this.boysMax = this.props.maxGroup - this.leadersMin;
        this.leadersMax = this.props.maxGroup;
        this.groupSize = this.girlsMin + this.boysMin + this.leadersMin;
        this.restGroup = this.props.maxGroup - this.groupSize;

        this.normalize = this.normalize.bind(this);
    }
    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                // get MaxGroup, if it's not in Redux yet
                if (this.props.maxGroup < 0) {
                    return this.props
                        .dispatch(fetchMaxGroup())
                        .then(null, (_e) =>
                            Promise.reject('group maximum not available'),
                        );
                }
            })
            .then(() => {
                // get setSchoolTrackList, if it's not in Redux yet
                if (
                    this.props.schoolTrackList &&
                    !Object.keys(this.props.schoolTrackList).length
                ) {
                    return this.props
                        .dispatch(
                            fetchSchoolTrackList(this.props.authenticationData),
                        )
                        .then(null, (_e) =>
                            Promise.reject('school tracks not available'),
                        );
                }
            })
            .then(() => {
                // get setClassLevelList, if it's not in Redux yet
                if (
                    this.props.classLevelList &&
                    !Object.keys(this.props.classLevelList).length
                ) {
                    return this.props
                        .dispatch(getCustomLst('classlevel'))
                        .then(null, (_e) =>
                            Promise.reject('classlevel not available'),
                        );
                }
            })
            .catch((e) => {
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }
    componentDidMount() {
        if (
            !this.props.form.group ||
            !this.props.form.group.values ||
            typeof this.props.form.group.values.girls !== 'number' ||
            typeof this.props.form.group.values.boys !== 'number' ||
            typeof this.props.form.group.values.leaders !== 'number'
        ) {
            this.props.change('girls', this.props.groupDefaults.girls);
            this.props.change('boys', this.props.groupDefaults.boys);
            this.props.change('leaders', this.props.groupDefaults.leaders);
        }

        if (this.props.editedAnmeldung.id > -1) {
            const anmeldung =
                this.props.groupRegistrationList[
                    this.props.editedAnmeldung.index
                ];

            this.props.change('schoolTrack', anmeldung.idSchulZweig);
            this.props.change('classLevel', anmeldung.idKlasseStufe);
            this.props.change('name', anmeldung.gruppeBezeichnung);
            this.props.change('girls', anmeldung.anzahlMaedchen);
            this.props.change('boys', anmeldung.anzahlJungen);
            this.props.change('leaders', anmeldung.anzahlBegleiter);
            this.setState({ valid: true });

            // Bearbeitung nur unter bestimmten Bedingungen erlaubt
            if (!isWorkflowEditable(anmeldung)) {
                disableForm();
            }
        } else {
            super.componentDidMount();
        }
    }
    validate() {
        const valid =
            this.schoolTrack.current.value &&
            this.classLevel.current.value &&
            this.name.current.value &&
            `${this.girls.current.value}` &&
            `${this.boys.current.value}` &&
            `${this.leaders.current.value}` &&
            this.leadersMin <= this.groupSize &&
            this.groupSize <= this.props.maxGroup;
        this.setState({
            valid: valid,
        });
    }
    validateSchulzweig = (value) => {
        const valid = value !== '' ? value : undefined;
        this.setState({ validSchulzweig: !!valid });
        return valid;
    };
    validateGroupname = (value) => {
        const valid = value !== '' ? value : undefined;
        this.setState({ validGroupname: !!valid });
        return valid;
    };
    validateClasslevel = (value) => {
        const valid = value !== '' ? value : undefined;
        this.setState({ validClasslevel: !!valid });
        return valid;
    };

    handleNumberInput(_event) {
        this.restGroup = this.props.maxGroup - this.groupSize;
        this.setState({}, () => {
            this.girlsMax = this.girls.current.value + this.restGroup;
            this.boysMax = this.boys.current.value + this.restGroup;
            this.leadersMax = this.leaders.current.value + this.restGroup;
            this.validate();
        });
    }

    clamp(value, min, max) {
        return Math.min(Math.max(value, min), max);
    }

    getMax(allValues, name) {
        const sumOthers =
            parseInt(allValues.girls) +
            parseInt(allValues.boys) +
            parseInt(allValues.leaders) -
            parseInt(allValues[name]);
        return this.props.maxGroup - sumOthers;
    }

    normalize(value, previousValue, allValues, _previousAllValues, name) {
        if (Number.isNaN(value)) {
            return previousValue;
        }
        return this.clamp(value, 0, this.getMax(allValues, name));
    }

    handleInput() {
        this.setState({}, () => {
            this.validate();
        });
    }
    renderQuestion() {
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>
                        <b>Gruppe</b> definieren
                    </h1>
                    <p>
                        Aus organisatorischen Gründen beträgt die maximale
                        Teilnehmerzahl einer Gruppe <b>{this.props.maxGroup}</b>{' '}
                        Personen (inklusive Begleitungen). Bei größeren Gruppen
                        legen Sie bitte im Anschluss an diese Anmeldung eine
                        weitere Gruppe an. Es empfiehlt sich die Unterteilung
                        nach Klassenstufen.
                        <br />
                        Hinweis: Sie können mithilfe der Pfeiltasten (hoch und
                        runter) die Anzahl der Schülerinnen, Schüler und
                        Begleitpersonen im Formular anpassen.
                    </p>
                </div>
                <fieldset>
                    <label htmlFor='name'>Gruppenname</label>
                    <Field
                        component='input'
                        type='text'
                        name='name'
                        maxLength='30'
                        ref={this.name}
                        validate={this.validateGroupname}
                        className={classNames({
                            invalid: !this.state.validGroupname,
                        })}
                        onChange={(_e) => this.handleInput()}
                    />
                    <label htmlFor='schoolTrack'>Schulzweig der Gruppe</label>
                    <Field
                        component='select'
                        name='schoolTrack'
                        ref={this.schoolTrack}
                        validate={this.validateSchulzweig}
                        className={classNames({
                            invalid: !this.state.validSchulzweig,
                        })}
                        onChange={(_e) => this.handleInput()}
                    >
                        <option value='' hidden>
                            Bitte wählen
                        </option>
                        {this.props.schoolTrackList.map((schoolTrack) => (
                            <option key={schoolTrack.id} value={schoolTrack.id}>
                                {schoolTrack.name}
                            </option>
                        ))}
                    </Field>
                    <label htmlFor='classLevel'>
                        Klassenstufe zum Zeitpunkt der Anreise
                    </label>
                    <Field
                        component='select'
                        name='classLevel'
                        ref={this.classLevel}
                        validate={this.validateClasslevel}
                        className={classNames({
                            invalid: !this.state.validClasslevel,
                        })}
                        onChange={(_e) => this.handleInput()}
                    >
                        <option value='' hidden>
                            Bitte wählen
                        </option>
                        {(this.props.classLevelList || []).map((classLevel) => (
                            <option key={classLevel.ID} value={classLevel.ID}>
                                {classLevel.description}
                            </option>
                        ))}
                    </Field>
                    <label htmlFor='girls'>Voraus. Anzahl Schülerinnen</label>
                    <Field
                        component='input'
                        type='number'
                        min={this.girlsMin}
                        max={this.girlsMax}
                        name='girls'
                        placeholder={`maximal ${this.girlsMax}`}
                        normalize={this.normalize}
                        parse={(value) => parseInt(value, 10)}
                        ref={this.girls}
                        onChange={(e) => this.handleNumberInput(e)}
                    />
                    <label htmlFor='boys'>Voraus. Anzahl Schüler</label>
                    <Field
                        component='input'
                        type='number'
                        min={this.boysMin}
                        max={this.boysMax}
                        name='boys'
                        placeholder={`maximal ${this.boysMax}`}
                        normalize={this.normalize}
                        parse={(value) => parseInt(value, 10)}
                        ref={this.boys}
                        onChange={(e) => this.handleNumberInput(e)}
                    />
                    <label htmlFor='leaders'>
                        Voraus. Anzahl Begleitpersonen (Min. 1 Begleitung)
                    </label>
                    <Field
                        component='input'
                        type='number'
                        min={this.leadersMin}
                        max={this.leadersMax}
                        name='leaders'
                        placeholder={`maximal ${this.leadersMax}`}
                        normalize={this.normalize}
                        parse={(value) => parseInt(value, 10)}
                        ref={this.leaders}
                        onChange={(e) => this.handleNumberInput(e)}
                    />
                </fieldset>
                <StepButtons
                    back='/personals'
                    to='/schedule'
                    disabled={!this.state.valid}
                />
            </div>
        );
    }
}

export default reduxForm({
    form: 'group',
    destroyOnUnmount: false,
})(connect((s) => ({ formdata: s.form, ...s }), null)(Group));
