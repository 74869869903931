import React, { Component } from 'react';
import { PURGE } from 'redux-persist';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import { authenticateSystemUser } from './actions/common/actions';

import 'react-notifications-component/dist/theme.css';
import './App.css';
import './Exhibitor.css';
import { ENV } from './global_constants';
import Loader from './components/common/loader';
import Content from './components/content';
import style from './components/common/index.module.css';

const onMissingTranslation = ({ translationId, languageCode }) => {
    return `Nada for ${translationId} - ${languageCode}`;
};

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            error: false,
        };
        this.props.initialize({
            languages: [{ name: 'German', code: 'de' }],
            options: {
                onMissingTranslation: onMissingTranslation,
                defaultLanguage: 'de',
                renderToStaticMarkup: false,
            },
            translation: {
                'attendance.none': ['Ich nehme nicht teil'],
                'attendance.self': ['Ich komme persönlich'],
                'attendance.representative': ['Ich lasse mich vertreten'],
                'gdpr.confirm': [
                    'Ich stimme der Datenschutzvereinbarung und der Speicherung der oben genannten Daten zu.',
                ],
                project: [''],
            },
        });
    }
    show() {
        this.setState((_state, _props) => ({
            visible: true,
        }));
    }
    // Purge the Redux store on unload, except when unload is caused by refresh
    removeItemsOnUnload() {
        const perfEntries = performance.getEntriesByType('navigation');
        for (let i = 0; i < perfEntries.length; i++) {
            const p = perfEntries[i];
            if (p.type !== 'reload') {
                if (!ENV || ENV === 'development') {
                } else {
                    this.props.dispatch({
                        type: PURGE,
                        key: `persist: ideenexpo-${process.env.REACT_APP_IDEENEXPO}`,
                        result: () => null,
                    });
                    this.props.dispatch({ type: 'LOGOUT' });
                    window.location.reload();
                }
            }
        }
    }
    componentDidMount() {
        window.addEventListener('beforeunload', this.removeItemsOnUnload());

        // 2022-01-22, BT: An dieser Stelle ist die Applikation geladen und hier wird sichergestellt,
        // dass wir von vorne beginnen und sich die Applikation richtig initialisiert. Zuvor
        // routete die App auf die Seite, auf der der Browser zuvor geschlossen
        // wurde. Dieser Einstieg in die Tiefe führte dazu, dass Teile der Daten (Schulzweige
        // und Klassenstufen) nicht geladen und dann auf der betreffenden Site nicht zur
        // Auswahl standen.
        this.props.history.replace('/');

        this.props.dispatch(authenticateSystemUser()).then(() => this.show());
        //TODO: show message on error! + Backend-Logging
        // .then(Promise.all[fethSchoolZweig, fetchKlassStufes]).then(enableThisComponent)
    }
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.removeItemsOnUnload());
    }
    componentDidCatch(error, errorInfo) {
        console.log('error:', error);
        console.log('errorInfo:', errorInfo);
    }
    render() {
        const appClassName =
            process.env.REACT_APP_IDEENEXPO === 'invitation'
                ? 'app app-background-invitation'
                : 'app';
        return (
            <ConnectedRouter
                history={this.props.history}
                context={this.props.context}
            >
                <div id='app' className={appClassName}>
                    <Loader
                        id='loader'
                        visible={!this.state.visible && !this.state.error}
                    />
                    {this.state.visible && <Content />}
                    {this.state.error && (
                        <div className={style.question}>
                            <h1>Fehler</h1>
                            <p>
                                Es ist ein Fehler aufgetreten. Bitte laden Sie
                                die Seite neu und versuchen es erneut.
                            </p>
                            <p>
                                Wenn dies zum wiederholten Mal auftritt, zögern
                                Sie nicht uns unter idee@ideenexpo.de zu
                                kontaktieren.
                            </p>
                        </div>
                    )}
                </div>
            </ConnectedRouter>
        );
    }
}

export default withLocalize(connect()(App));
