import React from 'react';

const NumberField = ({
    input,
    label,
    placeholder,
    type,
    meta: { error, warning },
}) => (
    <div>
        <label>{label}</label>
        <div>
            <input {...input} placeholder={placeholder} type={type} />
            {(error && <span>{error}</span>) ||
                (warning && <span>{warning}</span>)}
        </div>
    </div>
);

export default NumberField;
