import { store } from '../../../store';
import {
    BACKEND_URL,
    ENV,
    ORGANISATION_SAVE,
    ORGANISATION_ADD,
    ORGANISATION_NEW,
    PASSES_SAVE,
    PASSES_ADD,
    PASSES_NEW,
    STORAGE_SAVE,
    STORAGE_ADD,
    STORAGE_NEW,
    CATERING_SAVE,
    CATERING_ADD,
    CATERING_NEW,
} from '../../../global_constants';
import {
    getEntity,
    setPassesUpdate,
    setStorageUpdate,
    setCateringUpdate,
    setOrganisation,
} from '../exhibitor-actions.js';
import {
    showAlert,
    ERROR_UNAUTHORIZED,
    ERROR,
} from '../../../components/exhibitor/alerts';
import UploadFile from '../../../components/exhibitor/uploadfile';
import {
    createEntityConfig,
    fetchEntityTuple,
} from '../fetcher/entity-list-detail.fetcher.js';
import { fetchUpdateEntityFromConfig } from '../fetcher/fetcher.js';

const mapExhibitorPass = (values) => ({
    vornameNachname: values.vornameNachname || '',
    kzFreigabeAussteller: values.status === 'freigegeben' ? 1 : 0,
});

/** Loads the organisation. If none is found, inserts a new one. */
export const getOrganisation = () => {
    return async (dispatch) => {
        const ec = createEntityConfig('organisation');
        // fetch the organisation
        const [lst, entity, items] = await fetchEntityTuple(ec, {
            ...ec.filterParameterForList,
        });
        // if any organisation was found, dispatch it
        if (items?.length > 0) {
            dispatch(ec.dispatchEntityList(lst));
            dispatch(ec.dispatchEntity(entity));
            return;
        }
        // else, insert a new organisation
        return dispatch(insertOrganisation());
    };
};

export const insertOrganisation = (_values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    const projectID =
        (store.getState().project || []).length > 0
            ? store.getState().project[0].ID
            : undefined;
    const companyID =
        (store.getState().company || []).length > 0
            ? store.getState().company[0].ID
            : undefined;
    return function (dispatch) {
        if (!projectID) {
            return Promise.reject('Keine Verknüpfung zum Projekt angelegt.');
        }
        if (!companyID) {
            return Promise.reject('Keine Verknüpfung zum Aussteller angelegt');
        }
        // request a new Organisation prototype
        return fetch(BACKEND_URL + ORGANISATION_NEW, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData,
                    userId4Hist: undefined,
                },
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                json.idProjekt = projectID;
                json.idFirma = companyID;
                return fetch(BACKEND_URL + ORGANISATION_ADD, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authenticationData.accessToken}`,
                    },
                    body: JSON.stringify({
                        ...json,
                        login: {
                            // TODO: pick properties manually!
                            ...authenticationData,
                            userId4Hist: undefined,
                        },
                    }),
                });
            })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('organisation details fetch:', json);
                return json;
            })
            .then((json) => {
                return dispatch(setOrganisation([json]));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};

export const updateExhibitorPass = (json, values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    return function (dispatch) {
        ENV === 'development' &&
            console.info('updating the Ausstellerausweise with id = ', json.ID);
        const newValues = mapExhibitorPass(values);
        const jsonNew = { ...json, ...newValues };
        return fetch(BACKEND_URL + PASSES_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(jsonNew),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('Ausstellerausweises details fetch:', json);
                if (!json.ID) {
                    return Promise.reject(
                        'Ausstellerausweises details fetch validity check: fail',
                    );
                }
                return json;
            })
            .then((json) => {
                return dispatch(setPassesUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                return Promise.reject(e);
            });
    };
};

export const insertExhibitorPass = (values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    const getState = store.getState();
    const organisation =
        Array.isArray(getState.organisation) &&
        getState.organisation.length >= 1
            ? getState.organisation[0]
            : {};
    return function (dispatch) {
        const newValues = mapExhibitorPass(values);
        // request a new Ausstellerausweises prototype
        return fetch(BACKEND_URL + PASSES_NEW, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData,
                    userId4Hist: undefined,
                },
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                // merge/overwrite requested json prototype with user input
                const jsonNew = { ...json, ...newValues };
                jsonNew.idStand = values.idStand; /*
			jsonNew.USER_ID_CHANGE = `${authenticationData.userIdLogin}`
			jsonNew.USER_ID_NEW = `${authenticationData.userIdLogin}`
			jsonNew.USER_NR_CHANGE = authenticationData.userId
			jsonNew.USER_NR_NEW = authenticationData.userId*/
                jsonNew.idOrganisation = organisation.ID;
                return fetch(BACKEND_URL + PASSES_ADD, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authenticationData.accessToken}`,
                    },
                    body: JSON.stringify({
                        ...jsonNew,
                        login: {
                            // TODO: pick properties manually!
                            ...authenticationData,
                            userId4Hist: undefined,
                        },
                    }),
                });
            })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('Ausstellerausweis details fetch:', json);
                // TODO: check if already expired or already answered!
                return json;
            })
            .then((json) => {
                return dispatch(setPassesUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                return Promise.reject(e);
            });
    };
};

const mapStorage = (values) => ({
    anmerkungen: values.anmerkungen || '',
    anzahlPaletten: values.anzahlPaletten || '0',
    kzLagerbedarfGefahrstoffe: values.kzLagerbedarfGefahrstoffe || '0',
    platzNummer: values.platzNummer || '',
});

export const updateStorage = (json, values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    return function (dispatch) {
        ENV === 'development' &&
            console.info('updating the Lagerflaeche with id = ', json.ID);
        const newValues = mapStorage(values);
        const jsonNew = { ...json, ...newValues };
        return fetch(BACKEND_URL + STORAGE_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(jsonNew),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('Lagerflaeche details fetch:', json);
                if (!json.ID) {
                    return Promise.reject(
                        'Lagerflaeche details fetch validity check: fail',
                    );
                }
                return json;
            })
            .then((json) => {
                return dispatch(setStorageUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};

export const insertStorage = (values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    const getState = store.getState();
    const organisation =
        Array.isArray(getState.organisation) &&
        getState.organisation.length >= 1
            ? getState.organisation[0]
            : {};
    return function (dispatch) {
        const newValues = mapStorage(values);
        // request a new Lagerflaeche prototype
        return fetch(BACKEND_URL + STORAGE_NEW, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData,
                    userId4Hist: undefined,
                },
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                // merge/overwrite requested json prototype with user input
                const jsonNew = { ...json, ...newValues };
                /*
			jsonNew.USER_ID_CHANGE = `${authenticationData.userIdLogin}`
			jsonNew.USER_ID_NEW = `${authenticationData.userIdLogin}`
			jsonNew.USER_NR_CHANGE = authenticationData.userId
			jsonNew.USER_NR_NEW = authenticationData.userId*/
                jsonNew.idOrganisation = organisation.ID;
                return fetch(BACKEND_URL + STORAGE_ADD, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authenticationData.accessToken}`,
                    },
                    body: JSON.stringify({
                        ...jsonNew,
                        login: {
                            // TODO: pick properties manually!
                            ...authenticationData,
                            userId4Hist: undefined,
                        },
                    }),
                });
            })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('Lagerflaeche details fetch:', json);
                // TODO: check if already expired or already answered!
                return json;
            })
            .then((json) => {
                return dispatch(setStorageUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};

const mapCatering = (values) => ({
    anzahlEssen: values.anzahlEssen || '0',
    kzFreigabeAussteller: values.status || '0',
    tag: values.tag,
});

export const updateCatering = (json, values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    return function (dispatch) {
        ENV === 'development' &&
            console.info('updating the Ausstellercatering with id = ', json.ID);
        const newValues = mapCatering(values);
        const jsonNew = { ...json, ...newValues };
        return fetch(BACKEND_URL + CATERING_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(jsonNew),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('Ausstellercatering details fetch:', json);
                if (!json.ID) {
                    return Promise.reject(
                        'Ausstellercatering details fetch validity check: fail',
                    );
                }
                return json;
            })
            .then((json) => {
                return dispatch(setCateringUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                return Promise.reject(e);
            });
    };
};

export const insertCatering = (values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    const getState = store.getState();
    const organisation =
        Array.isArray(getState.organisation) &&
        getState.organisation.length >= 1
            ? getState.organisation[0]
            : {};
    return function (dispatch) {
        const newValues = mapCatering(values);
        // request a new Lagerflaeche prototype
        return fetch(BACKEND_URL + CATERING_NEW, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData,
                    userId4Hist: undefined,
                },
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                // merge/overwrite requested json prototype with user input
                const jsonNew = { ...json, ...newValues };
                /*
			jsonNew.USER_ID_CHANGE = `${authenticationData.userIdLogin}`
			jsonNew.USER_ID_NEW = `${authenticationData.userIdLogin}`
			jsonNew.USER_NR_CHANGE = authenticationData.userId
			jsonNew.USER_NR_NEW = authenticationData.userId*/
                jsonNew.idOrganisation = organisation.ID;
                return fetch(BACKEND_URL + CATERING_ADD, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authenticationData.accessToken}`,
                    },
                    body: JSON.stringify({
                        ...jsonNew,
                        login: {
                            // TODO: pick properties manually!
                            ...authenticationData,
                            userId4Hist: undefined,
                        },
                    }),
                });
            })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('Ausstellercatering details fetch:', json);
                // TODO: check if already expired or already answered!
                return json;
            })
            .then((json) => {
                return dispatch(setCateringUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                return Promise.reject(e);
            });
    };
};
