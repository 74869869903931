import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
    updateStage,
    insertShowstage,
} from '../../../../../../actions/exhibitor/showstage';
import FormButtons from '../../../../formbuttons';
import style from '../../../../index.module.css';
import { store } from '../../../../../../store.js';
import disableForm from '../../../../disableForm.js';
import {
    showAlert,
    SUCCESS,
    SUCCESS_MSG,
    ERROR,
    validateForm,
    ERROR_MSG_BACKEND_SAVE,
} from '../../../../alerts';

class ShowStageEditContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            submitSuccess: false,
        };
    }

    handleInput(_e) {
        this.setState({ valid: true });
    }

    resetForm() {
        this.setState({
            submitSuccess: false,
        });
    }

    componentDidMount() {
        if (
            this.props.initialValues['status'] === 'freigegeben' ||
            this.props.noLiveProgram
        ) {
            disableForm();
        }
    }

    submit(values) {
        const storeState = store.getState().form;
        const showstageeditbasis = storeState.showstageeditbasis?.values || {};
        validateForm(
            this,
            { ...showstageeditbasis, ...values },
            ['status', 'beitrag_titel'],
            this.props.stages_deadline_within,
            this.props.noLiveProgram,
        );
        Promise.resolve()
            .then(() => {
                if (this.props.handle) {
                    return this.props
                        .dispatch(
                            updateStage(this.props.found, {
                                ...showstageeditbasis,
                                ...values,
                            }),
                        )
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                } else {
                    // if creating a new showstage
                    return this.props
                        .dispatch(
                            insertShowstage({
                                ...showstageeditbasis,
                                ...values,
                            }),
                        )
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                }
            })
            .then((json) => {
                if (!this.props.handle) {
                    this.props.history.push(
                        `/live-program/show-stage/edit?${json.showstage.ID}`,
                    );
                }
            })
            .then(() =>
                this.setState({
                    submitSuccess: true,
                }),
            )
            .then(() => showAlert(SUCCESS, SUCCESS_MSG))
            .then(() => {
                if (
                    this.props.initialValues['status'] === 'freigegeben' ||
                    this.props.noLiveProgram
                ) {
                    disableForm();
                }
            })
            // .then(() => {
            // 	// gewünscht war nach dem Speichern zur Übersichtsseite zurückzukommen
            // 	this.props.dispatch(push("/live-program/show-stage"))
            // 	})
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_SAVE);
                this.setState({ submitSuccess: false });
                this.props.dispatch(stopSubmit('showstageeditcontact', e));
            });
    }

    render() {
        const { handleSubmit } = this.props;
        const maxLengthShowStageAustellerAgentur = 200;
        const maxLengthShowStageNameAnsprechpartner = 200;
        const maxLengthShowStageEmailAnsprechpartner = 200;
        const maxLengthShowStageTelefonnummerAnsprechpartner = 200;
        const maxLengthShowStageErreichbarkeitAnsprechpartner = 800;
        return (
            <div className={style.header}>
                {this.props.initialValues.status === 'freigegeben' ? (
                    <div>Sie haben diese Daten bereits freigegeben</div>
                ) : null}
                {!this.props.stages_deadline_within ? (
                    <div>
                        Sie können die Daten nur innerhalb des
                        Bearbeitungszeitraums bearbeiten
                    </div>
                ) : null}
                {this.props.noLiveProgram ? (
                    <div>Sie haben das LiveProgramm deaktiviert</div>
                ) : null}
                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <fieldset>
                        <legend />
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='showstage_id'
                            id='showstage_id'
                            hidden
                            disabled
                            value={this.props.handle || ''}
                            ref={this.input}
                        />
                        <label htmlFor='ausstellerAgentur'>
                            <span className={style.bold}>
                                Aussteller/Agentur
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='ausstellerAgentur'
                            id='ausstellerAgentur'
                            ref={this.input}
                            maxLength={maxLengthShowStageAustellerAgentur}
                        />
                        <label htmlFor='ansprechpartner'>
                            <span className={style.bold}>
                                Name des Ansprechpartners
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='ansprechpartner'
                            id='ansprechpartner'
                            ref={this.input}
                            maxLength={maxLengthShowStageNameAnsprechpartner}
                        />
                        <label htmlFor='ansprechpartner_email'>
                            <span className={style.bold}>E-Mail-Adresse</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='ansprechpartner_email'
                            id='ansprechpartner_email'
                            ref={this.input}
                            maxLength={maxLengthShowStageEmailAnsprechpartner}
                        />
                        <label htmlFor='ansprechpartner_tel'>
                            <span className={style.bold}>Telefonnummer</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='ansprechpartner_tel'
                            id='ansprechpartner_tel'
                            ref={this.input}
                            maxLength={
                                maxLengthShowStageTelefonnummerAnsprechpartner
                            }
                        />
                        <label htmlFor='erreichbarkeit'>
                            <span className={style.bold}>
                                Erreichbarkeit (Wann und wie)
                            </span>
                        </label>
                        <Field
                            component='textarea'
                            placeholder=''
                            type='textarea'
                            name='erreichbarkeit'
                            rows='8'
                            maxLength={
                                maxLengthShowStageErreichbarkeitAnsprechpartner
                            }
                            style={{
                                resize: 'none',
                            }}
                            id='erreichbarkeit'
                            ref={this.input}
                        />
                        <FormButtons
                            showCancel={true}
                            overviewLink='/live-program/show-stage'
                            hideBtn={true}
                            handleSubmit={handleSubmit}
                            submit={this.submit.bind(this)}
                        />
                        <legend>(*) = Pflichtfeld</legend>
                    </fieldset>
                </form>
                {/* <StepButtons backTab={() => this.props.toTab(0)} to={'/clubzukunft'} disabled={!this.state.valid} labelBack={'Beitrag'} labelForward={'ClubZukunft'} /> */}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const found =
        state.showstage && Array.isArray(state.showstage)
            ? state.showstage.find((x) => x.ID === props.handle)
            : [];
    const noLiveProgram =
        state.contract &&
        Array.isArray(state.contract) &&
        state.contract.length > 0 &&
        state.contract[0].liveProgrammDeaktiviert === 1
            ? true
            : false;
    let initialValues = { status: '' };
    if (found && found.ID === props.handle) {
        initialValues = {
            showstage_id: props.handle || '',
            ausstellerAgentur: found.ausstellerAgentur || '',
            ansprechpartner: found.nameAnsprechpartner || '',
            ansprechpartner_email: found.emailAnsprechpartner || '',
            ansprechpartner_tel: found.telefonnummerAnsprechpartner || '',
            erreichbarkeit: found.erreichbarkeitAnsprechpartner || '',
            status:
                found.kzFreigabeAussteller === 1
                    ? 'freigegeben'
                    : found.kzFreigabeAussteller === 0
                    ? 'inbearbeitung'
                    : '',
        };
    }
    return {
        initialValues: initialValues,
        hasLiveProgram: !noLiveProgram,
        noLiveProgram,
        stages_deadline_within: state.deadlines?.stages_deadline_within,
        found,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'showstageeditcontact',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(ShowStageEditContact),
);
