import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import disableForm from '../../exhibitor/disableForm.js';
import isWorkflowEditable from '../functions.js';

import StepButtons from '../../common/stepbuttons';
import style from '../../common/index.module.css';
import Question from '../../common/question';
import {
    getCustomLst,
    fetchVeranstaltungDetails,
    timeMapper,
} from '../../../actions/common/actions.js';
import { setArrivalDayList } from '../../../actions/registration-system/registration-actions';
import classNames from 'classnames';
import {
    TEILNEHMER2VERANSTALTUNG__BUCHUNGSSTATUS,
    mapVeranstaltungBuchungsstatus,
} from '../../../backend-id-constants.js';

import tableSx from '../../../style/table.module.scss';

class Schedule extends Question {
    constructor(props) {
        super(
            props,
            // TODO: Anmeldedatum für Workshops als Requirement hinzufügen, nachdem das aus dem BE geladen wird
            null,
            {
                valid: false,
                buchungen: [],
                veranstaltungstypen: new Map(),
                veranstaltungskategorien: new Map(),
            },
        );
        this.arrivalDay = React.createRef();
        this.transport = React.createRef();
        this.departureTime = React.createRef();
        this.arrivalTime = React.createRef();

        this.workshopDate = '26.02.2024';
    }

    validate() {
        this.setState({
            valid:
                this.arrivalDay.current.value &&
                this.transport.current.value &&
                this.departureTime.current.value &&
                this.arrivalTime.current.value,
        });
    }

    validateArrivalday = (value) => {
        const valid = value !== '' ? value : undefined;
        this.setState({ validArrivalday: !!valid });
        return valid;
    };

    validateTransport = (value) => {
        const valid = value !== '' ? value : undefined;
        this.setState({ validTransport: !!valid });
        return valid;
    };

    validateDeparture = (value) => {
        const valid = value !== '' ? value : undefined;
        this.setState({ validDeparture: !!valid });
        return valid;
    };

    validateArrivaltime = (value) => {
        const valid = value !== '' ? value : undefined;
        this.setState({ validArrivaltime: !!valid });
        return valid;
    };

    handleInput() {
        this.setState({}, () => {
            this.validate();
        });
    }

    getVaType(num) {
        return this.state.veranstaltungstypen.get(num);
    }

    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                // get arrivaltime list, if it's not in Redux yet
                if (
                    this.props.arrivalTimeList &&
                    !Object.keys(this.props.arrivalTimeList).length
                ) {
                    return this.props
                        .dispatch(getCustomLst('arrivaltime'))
                        .then(null, (_e) =>
                            Promise.reject('arrivaltimes not available'),
                        );
                }
            })
            .then(() => {
                // get transportList, if it's not in Redux yet
                if (
                    this.props.transportList &&
                    !Object.keys(this.props.transportList).length
                ) {
                    return this.props
                        .dispatch(getCustomLst('transport'))
                        .then(null, (_e) =>
                            Promise.reject('transport not available'),
                        );
                }
            })
            .then(() => {
                // get departureTimeSchoolList, if it's not in Redux yet
                if (
                    this.props.departureTimeSchoolList &&
                    !Object.keys(this.props.departureTimeSchoolList).length
                ) {
                    return this.props
                        .dispatch(getCustomLst('departureschool'))
                        .then(null, (_e) =>
                            Promise.reject(
                                'departure time school list not available',
                            ),
                        );
                }
            })
            .then(() => {
                // get arrival day list, if it's not in Redux yet
                if (
                    this.props.arrivalDayList &&
                    !Object.keys(this.props.arrivalDayList).length
                ) {
                    const projekt = (this.props.authenticationData
                        ?.TpProjektLstItem || [{}])[0];
                    if (projekt) {
                        return this.props
                            .dispatch(
                                setArrivalDayList(projekt.beginn, projekt.ende),
                            )
                            .then(null, (_e) =>
                                Promise.reject('arrivaldays not available'),
                            );
                    }
                }
            })
            .catch((e) => {
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    componentDidMount() {
        this.handleFetch();
    }

    async handleFetch() {
        // Liste Veranstaltungstypen aus dem Backend laden, await, weil die Daten nun
        // gebraucht werden, um die Map zu füllen und die Buchungen anzureichern
        const typesJson = await this.props.dispatch(
            getCustomLst('veranstaltungstypen'),
        );

        const types = typesJson.TpLstDefLstItem;

        types.forEach((lstItem) => {
            this.state.veranstaltungstypen.set(lstItem.ID, lstItem.description);
        });

        // Liste Veranstaltungskategorien aus dem Backend laden
        const categoriesJson = await this.props.dispatch(
            getCustomLst('veranstaltungskategorien'),
        );

        const categories = categoriesJson.TpLstDefLstItem;

        categories.forEach((lstItem) => {
            this.state.veranstaltungskategorien.set(
                lstItem.ID,
                lstItem.description,
            );
        });

        // Falls das eine nachbearbeitete Teilnehmer-Registrierung ist ...
        if (this.props.editedAnmeldung?.id > -1) {
            const anmeldung =
                this.props.groupRegistrationList[
                    this.props.editedAnmeldung.index
                ];

            const arrivalDayZuluTime = `${anmeldung.anreisetag.split('+')[0]}Z`;
            this.props.change('arrivalDay', arrivalDayZuluTime);

            this.props.change('transport', anmeldung.idVerkehrsmittel);
            this.props.change('departureTime', anmeldung.idAbfahrtzeitSchule);
            this.props.change('arrivalTime', anmeldung.idAnkunftszeit);
            this.props.change('guidance', anmeldung.kzFuehrung);
            this.props.change('workshop', anmeldung.kzWorkshop);
            this.props.change('clubZukunft', anmeldung.kzClubZukunft);
            this.setState({ valid: true });

            // ... sind das die Veranstaltungsbuchungen aus dem Teilnehmerdatensatz
            const unfilteredBuchungen =
                anmeldung.tp_Teilnehmer_2_Teilnehmer2VeranstaltungLst
                    .TpTeilnehmer2VeranstaltungLstItem;

            const filteredBuchungen = unfilteredBuchungen.filter((b) => {
                const containsValid = [
                    TEILNEHMER2VERANSTALTUNG__BUCHUNGSSTATUS.ANGEFRAGT,
                    TEILNEHMER2VERANSTALTUNG__BUCHUNGSSTATUS.ZUGESAGT,
                    TEILNEHMER2VERANSTALTUNG__BUCHUNGSSTATUS.BESTÄTIGT,
                    TEILNEHMER2VERANSTALTUNG__BUCHUNGSSTATUS.TEILNGENOMMEN,
                ].some((status) => status.value === b.idStatus);

                const containsInvalid = [
                    TEILNEHMER2VERANSTALTUNG__BUCHUNGSSTATUS.NONE,
                    TEILNEHMER2VERANSTALTUNG__BUCHUNGSSTATUS.ABGESAGT,
                    TEILNEHMER2VERANSTALTUNG__BUCHUNGSSTATUS.STORNIERT,
                    TEILNEHMER2VERANSTALTUNG__BUCHUNGSSTATUS.NICHT_ERSCHIENEN,
                ].some((status) => status.value === b.idStatus);

                return containsValid && !containsInvalid;
            });

            const completeBuchungen = await Promise.all(
                filteredBuchungen.map(async (buchung) => {
                    const veranstaltung = await fetchVeranstaltungDetails(
                        buchung,
                    );
                    return {
                        ...buchung,
                        vaTyp: this.state.veranstaltungstypen.get(
                            veranstaltung.idTyp,
                        ),
                        vaDatum: timeMapper(veranstaltung.zeitBeginn, 'datum'),
                        vaBeginn: timeMapper(veranstaltung.zeitBeginn, 'zeit'),
                        vaEnde: timeMapper(veranstaltung.zeitEnde, 'zeit'),
                        vaKategorie:
                            this.state.veranstaltungskategorien.get(
                                veranstaltung.idKategorie,
                            ) || '-',
                        status:
                            mapVeranstaltungBuchungsstatus(buchung.idStatus)
                                ?.label || '',
                    };
                }),
            );

            this.setState({
                buchungen: completeBuchungen,
            });

            // Bearbeitung nur unter bestimmten Bedingungen erlaubt
            if (!isWorkflowEditable(anmeldung)) {
                disableForm();
            }
        } else {
            super.componentDidMount();
        }
    }

    renderQuestion() {
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>
                        <b>Reise</b> planen
                    </h1>
                    <p>Teilen Sie uns bitte folgende Angaben mit.</p>
                </div>
                <fieldset>
                    <label htmlFor='arrivalDay'>Anreisetag</label>
                    <Field
                        component='select'
                        name='arrivalDay'
                        ref={this.arrivalDay}
                        validate={this.validateArrivalday}
                        className={classNames({
                            invalid: !this.state.validArrivalday,
                        })}
                        onChange={(_e) => this.handleInput()}
                    >
                        <option value='' hidden>
                            Bitte wählen
                        </option>
                        {this.props.arrivalDayList.map((arrivalDay) => (
                            <option
                                key={arrivalDay.label}
                                value={arrivalDay.value}
                            >
                                {arrivalDay.label}
                            </option>
                        ))}
                    </Field>
                    <label htmlFor='transport'>Verkehrsmittel</label>
                    <Field
                        component='select'
                        name='transport'
                        ref={this.transport}
                        validate={this.validateTransport}
                        className={classNames({
                            invalid: !this.state.validTransport,
                        })}
                        onChange={(_e) => this.handleInput()}
                    >
                        <option value='' hidden>
                            Bitte wählen
                        </option>
                        {(this.props.transportList || []).map((transport) => (
                            <option key={transport.ID} value={transport.ID}>
                                {transport.description}
                            </option>
                        ))}
                    </Field>
                    <p>
                        Die folgenden Abfragen dienen unserer Planung und sind
                        nicht verbindlich, um an der IdeenExpo teilnehmen zu
                        dürfen.
                    </p>
                    <label htmlFor='departureTime'>
                        Abfahrtszeit am Schulort
                    </label>
                    <Field
                        component='select'
                        name='departureTime'
                        ref={this.departureTime}
                        validate={this.validateDeparture}
                        className={classNames({
                            invalid: !this.state.validDeparture,
                        })}
                        onChange={(_e) => this.handleInput()}
                    >
                        <option value='' hidden>
                            Bitte wählen
                        </option>
                        {(this.props.departureTimeSchoolList || []).map(
                            (departureTime) => (
                                <option
                                    key={departureTime.ID}
                                    value={departureTime.ID}
                                >
                                    {departureTime.description}
                                </option>
                            ),
                        )}
                    </Field>
                    <label htmlFor='arrivalTime'>Ankunft IdeenExpo</label>
                    <Field
                        component='select'
                        name='arrivalTime'
                        ref={this.arrivalTime}
                        validate={this.validateArrivaltime}
                        className={classNames({
                            invalid: !this.state.validArrivaltime,
                        })}
                        onChange={(_e) => this.handleInput()}
                    >
                        <option value='' hidden>
                            Bitte wählen
                        </option>
                        {(this.props.arrivalTimeList || []).map(
                            (arrivalTime) => (
                                <option
                                    key={arrivalTime.ID}
                                    value={arrivalTime.ID}
                                >
                                    {arrivalTime.description}
                                </option>
                            ),
                        )}
                    </Field>
                    <div className='row'>
                        <Field
                            component='input'
                            type='checkbox'
                            id='guidance'
                            name='guidance'
                            onChange={(_e) => this.handleInput()}
                        />
                        <label htmlFor='guidance'>
                            <span>
                                Ich möchte für diese Lerngruppe gerne eine
                                Führung. <b>Hinweis</b>: Diese können Sie direkt
                                bei der Ankunft buchen.
                            </span>
                        </label>
                    </div>
                    <div className='row'>
                        <Field
                            component='input'
                            type='checkbox'
                            id='workshop'
                            name='workshop'
                            onChange={(_e) => this.handleInput()}
                        />
                        <label htmlFor='workshop'>
                            <span>
                                Ich möchte mit dieser Lerngruppe gerne am
                                Workshopprogramm teilnehmen. <b>Hinweis</b>:
                                Eine Anmeldung hierzu ist ab dem{' '}
                                <b>{this.workshopDate}</b> möglich.
                                Informationen zum Workshop-Programm finden Sie
                                im Internet unter:{' '}
                                <a href='https://www.ideenexpo.de/'>
                                    www.ideenexpo.de
                                </a>
                            </span>
                        </label>
                    </div>
                    <div className='row'>
                        <Field
                            component='input'
                            type='checkbox'
                            id='clubZukunft'
                            name='clubZukunft'
                            onChange={(_e) => this.handleInput()}
                        />
                        <label htmlFor='clubZukunft'>
                            <span>
                                Wir haben Interesse am Berufsbildungsprogramm im
                                ClubZukunft.
                            </span>
                        </label>
                    </div>
                    {this.state.buchungen?.length > 0 && (
                        <>
                            <table className={tableSx.table}>
                                <thead>
                                    <tr>
                                        <th style={{ width: '0' }}>ID</th>
                                        <th>Typ</th>
                                        <th>Titel</th>
                                        <th>Datum</th>
                                        <th>Beginn</th>
                                        <th>Ende</th>
                                        <th>Kategorie</th>
                                        <th>Gebuchte Plätze</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.buchungen.map((buchung) => (
                                        <tr key={buchung.ID}>
                                            <td>{buchung.idVeranstaltung}</td>
                                            <td>{buchung.vaTyp}</td>
                                            <td>{buchung.txtVeranstaltung}</td>
                                            <td>{buchung.vaDatum}</td>
                                            <td>{buchung.vaBeginn}</td>
                                            <td>{buchung.vaEnde}</td>
                                            <td>{buchung.vaKategorie}</td>
                                            <td>{buchung.anzahlTeilnehmer}</td>
                                            <td>{buchung.status}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </fieldset>
                <StepButtons
                    back='/group'
                    to='/allowance'
                    disabled={!this.state.valid}
                />
            </div>
        );
    }
}

export default reduxForm({
    form: 'schedule',
    destroyOnUnmount: false,
})(connect((s) => ({ formdata: s.form, ...s }), null)(Schedule));
