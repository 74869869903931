import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import style from '../../index.module.css';
import FormButtons from '../../formbuttons';
import StepButtons from '../../stepbuttons';
import { getEntity } from '../../../../actions/exhibitor/exhibitor-actions.js';
import {
    updateCatering,
    insertCatering,
} from '../../../../actions/exhibitor/organisation/index.js';
import disableForm from '../../disableForm.js';
import { getOrganisation } from '../../../../actions/exhibitor/organisation';
import {
    validateForm,
    showAlert,
    SUCCESS,
    SUCCESS_MSG,
    ERROR,
    ERROR_MSG_BACKEND_FETCH,
    ERROR_MSG_BACKEND_SAVE,
    ERROR_UNAUTHORIZED,
} from '../../alerts';
import Loader from '../../../common/loader/index.jsx';

class ExhibitorCatering extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            valid: true,
        };
        this.input = React.createRef();
    }

    handleFetch = async () => {
        this.setState({
            isReady: false,
        });
        try {
            await this.props.dispatch(getOrganisation());
            await this.props.dispatch(getEntity('catering'));
        } catch (e) {
            showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
            console.error('error checking code, promise rejected:', e);
            this.setState({
                state: e?.state ? e.state : 'invalid',
            });
        } finally {
            this.setState({
                isReady: true,
            });
        }
    };

    UNSAFE_componentWillMount() {
        this.handleFetch();
    }

    submit(values) {
        this.setState({
            isReady: false,
        });
        validateForm(this, values, [
            'status',
            ...this.props.dayList.map((day) => day.formValue),
        ]);
        Promise.all(
            this.props.dayList.map((day) => {
                return new Promise((resolve, reject) => {
                    const valuesMod = {
                        anzahlEssen: values[day.formValue],
                        status: values['status'] === 'freigegeben' ? 1 : 0,
                        tag: day.name,
                    };
                    if (day.index > -1) {
                        // if updating an existing Austellercatering
                        this.props
                            .dispatch(
                                updateCatering(
                                    this.props.catering[day.index],
                                    valuesMod,
                                ),
                            )
                            .then((result) => {
                                resolve(result);
                            })
                            .catch((e) => {
                                reject({ _error: e });
                            });
                    } else {
                        // if creating a new Austellercatering
                        this.props
                            .dispatch(insertCatering(valuesMod))
                            .then((result) => {
                                resolve(result);
                            })
                            .catch((e) => {
                                reject({ _error: e });
                            });
                    }
                });
            }),
        )
            .then(() =>
                this.setState({
                    submitSuccess: true,
                }),
            )
            .then(() => showAlert(SUCCESS, SUCCESS_MSG))
            .then(() => {
                // nicht mehr disabled, weil Deadline noch offen
                // disableForm();

                if (this.props.initialValues['status'] === 'freigegeben') {
                    disableForm();
                }
            })
            .catch((e) => {
                showAlert(ERROR, ERROR_MSG_BACKEND_SAVE);
                console.log('catch');
                console.log(e);
                if (e._error === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                this.setState({ submitSuccess: false });
                this.props.dispatch(stopSubmit('catering', e));
            })
            .finally(() => {
                this.handleFetch();
            });
    }

    resetForm() {
        this.setState({
            submitSuccess: false,
        });
    }

    render() {
        const disabled = this.props.initialValues['status'] === 'freigegeben';
        const { handleSubmit } = this.props;

        if (!this.state.isReady) {
            return (
                <div style={{ height: '90vh' }}>
                    <Loader visible />
                </div>
            );
        }

        return (
            <div className={style.question}>
                <div className={style.header}>
                    {this.props.initialValues.status === 'freigegeben'
                        ? 'Sie haben diese Daten bereits freigegeben'
                        : null}
                    <h1>Ausstellercatering bearbeiten</h1>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <fieldset>
                            <legend>
                                Das Standpersonal wird auf der IdeenExpo mit
                                einem warmen Mittagessen versorgt. Zusätzlich
                                werden ganztägig Getränke angeboten – jedoch
                                kein Abendessen. Zeitweise gibt es Obst und
                                Kuchen als Snack für zwischendurch. Bitte nennen
                                Sie uns die Anzahl der benötigten Mittagessen
                                pro Tag. Wenn Sie für einen Tag kein Catering
                                benötigen, tragen Sie bitte „0“ in das
                                entsprechende Feld ein. Deadline für die
                                Bestellung ist der 19. April 2024.
                            </legend>
                            {Array.isArray(this.props.dayList) &&
                            this.props.dayList.length > 0
                                ? this.props.dayList.map((element) => (
                                      <div key={element.formValue}>
                                          <label
                                              htmlFor={`${element.formValue}`}
                                              className={style.bold}
                                          >
                                              <span>
                                                  Essen für {element.label} (*)
                                              </span>
                                          </label>
                                          <Field
                                              component='input'
                                              type='text'
                                              id={`${element.formValue}`}
                                              name={`${element.formValue}`}
                                              ref={this.input}
                                              disabled={disabled}
                                              required
                                          />
                                      </div>
                                  ))
                                : null}
                            {/* Die Deadline für die Eingabe von Cateringbedarfen
                            ist abgelaufen. Bitte besprechen Sie Ihre
                            Cateringbedarfe direkt mit Ihrem Ansprechpartner bei
                            der IdeenExpo GmbH. */}
                            <FormButtons
                                hideBtn={disabled}
                                disabled={disabled}
                                handleSubmit={handleSubmit}
                                submit={this.submit.bind(this)}
                            />
                            <legend>(*) = Pflichtfeld</legend>
                        </fieldset>
                    </form>
                    <StepButtons
                        back={'/organisation/exhibitor-passes'}
                        to={'/organisation/storage'}
                        disabled={!this.state.valid}
                        labelBack={'Ausstellerausweise'}
                        labelForward={'Lagerfläche'}
                    />
                </div>
            </div>
        );
    }
}

/**
 * @param {string | Date} d Raw date
 * @returns Normalized Date String ignoring milliseconds
 */
const normalizeDate = (d) =>
    new Date(d).toISOString().split('.')[0].concat('Z');

const mapStateToProps = (state, _props) => {
    const projekt = state.authenticationData.TpProjektLstItem[0];

    let dayList = getDateRange(
        new Date(projekt.beginn),
        new Date(projekt.ende),
    ).map((day) => ({
        name: normalizeDate(day),
        formValue: normalizeDate(day),
        label: day.toLocaleString('de-DE', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        }),
        index: -1,
    }));

    let initialValues = {};

    if (
        Array.isArray(state.catering) &&
        state.catering.length > 0 &&
        Array.isArray(dayList) &&
        dayList.length > 0
    ) {
        const statusCatering = state.catering.some(
            (cater) => cater.kzFreigabeAussteller === 1,
        )
            ? 'freigegeben'
            : 'inbearbeitung';

        dayList = state.catering.reduce((acc, curr, index) => {
            const day = dayList.find((d) => d.name === normalizeDate(curr.tag));
            if (day) {
                acc.push({
                    ...day,
                    index,
                });
            }
            return acc;
        }, []);

        initialValues = {
            status: statusCatering,
            ...dayList.reduce((acc, curr) => {
                acc[curr.formValue] =
                    state.catering[curr.index]?.anzahlEssen || '';
                return acc;
            }, {}),
        };
    }

    return {
        initialValues: initialValues || {},
        catering: state.catering || [],
        organisation: state.organisation,
        ie_begin: state.deadlines?.ie_begin,
        dayList: dayList || [],
    };
};

const getDateRange = (startDate, stopDate) => {
    const dayList = [];
    for (let dt = startDate; dt <= stopDate; dt.setDate(dt.getDate() + 1)) {
        dayList.push(new Date(dt));
    }
    return dayList;
};

export default compose(
    withRouter,
    connect(mapStateToProps, null, null, {
        areStatesEqual: (next, prev) => {
            return (
                prev.organisation === next.organisation &&
                prev.catering === next.catering
            );
        },
    }),
)(
    reduxForm({
        form: 'catering',
        destroyOnUnmount: true,
        enableReinitialize: true,
    })(ExhibitorCatering),
);
