import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import FormButtons from '../../../../formbuttons';
import style from '../../../../index.module.css';
import { store } from '../../../../../../store.js';
import disableForm from '../../../../disableForm.js';
import exhibitSubmit from '../submit.js';
import { validateForm } from '../../../../alerts';

class ExhibitEditSpecification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            // showIndividuelleAusstellerformatText: props.initialValues && props.initialValues.format_individuell,
            showStrom230:
                props.found &&
                (props.found.idStrom === 1 || props.found.idStrom === 3)
                    ? true
                    : false,
            showStrom400:
                props.found && props.found.kzStrom400V === 1 ? true : false,
            showSpuele:
                props.found && props.found.kzWasser === 1 ? true : false,
            showDruckluft:
                props.found && props.found.kzDruckluft === 1 ? true : false,
            showGefaehrdung:
                props.found && props.found.kzGefaehrdungspotenzial === 1
                    ? true
                    : false,
            submitSuccess: false,
            values: { files: this.props.files || {} }, // Files
        };
    }
    handleInput(e) {
        this.setState({ valid: true });
        if (e && e.target.name === 'zielgruppe_sonstige') {
            this.toggleSonstige();
        } else if (e && e.target.name === 'strom230') {
            this.setState((state) => ({ showStrom230: !state.showStrom230 }));
        } else if (e && e.target.name === 'strom400') {
            this.setState((state) => ({ showStrom400: !state.showStrom400 }));
        } else if (e && e.target.name === 'wasseranschluss') {
            this.setState((state) => ({ showSpuele: !state.showSpuele }));
        } else if (e && e.target.name === 'druckluft') {
            this.setState((state) => ({ showDruckluft: !state.showDruckluft }));
        } else if (e && e.target.name === 'gefaehrdung') {
            this.setState((state) => ({
                showGefaehrdung: !state.showGefaehrdung,
            }));
        }
    }
    resetForm() {
        this.setState((_state, props) => ({
            showStrom230:
                props.found &&
                (props.found.idStrom === 1 || props.found.idStrom === 3)
                    ? true
                    : false,
            showStrom400:
                props.found && props.found.kzStrom400V === 1 ? true : false,
            showSpuele:
                props.found && props.found.kzWasser === 1 ? true : false,
            showDruckluft:
                props.found && props.found.kzDruckluft === 1 ? true : false,
            showGefaehrdung:
                props.found && props.found.kzGefaehrdungspotenzial === 1
                    ? true
                    : false,
            submitSuccess: false,
        }));
    }

    componentDidMount() {
        if (this.props.initialValues['status'] === 'freigegeben') {
            disableForm();
        }
    }

    submit(values) {
        const storeState = store.getState();
        const exhibitseditbasisValues =
            storeState.form?.exhibitseditbasis?.values || {};
        let exhibitseditdescriptionValues =
            storeState.form?.exhibitseditdescription?.values || {};

        exhibitseditdescriptionValues = {
            //Files
            ...exhibitseditdescriptionValues,
            ...this.state.values,
        };

        validateForm(
            this,
            {
                ...exhibitseditbasisValues,
                ...exhibitseditdescriptionValues,
                ...values,
            },
            ['status', 'exponat_titel'],
        );

        exhibitSubmit(
            exhibitseditbasisValues,
            exhibitseditdescriptionValues,
            values,
            this,
            'home',
        );
    }

    render() {
        const stromWofuer = [
            { id: 1, name: 'Laptop/Computer' },
            { id: 2, name: 'Handy' },
            { id: 3, name: 'Monitor/Screen' },
            { id: 4, name: 'Beleuchtung' },
            { id: 5, name: 'Betrieb des Exponats' },
            { id: 6, name: 'Sonstiges' },
        ];
        const kupplung = [
            { id: 1, name: '1/2 Zoll' },
            { id: 2, name: '1/4 Zoll' },
            { id: 3, name: '3/4 Zoll' },
        ];
        const stecker = [
            { id: 1, name: '16 A' },
            { id: 2, name: '32A' },
            { id: 3, name: '64A' },
            { id: 4, name: 'Direktanschluss am Exponat' },
            { id: 5, name: 'sonstiges' },
        ];
        const { handleSubmit } = this.props;
        const showStrom230 =
            this.state.showStrom230 === true
                ? { display: 'inline-block' }
                : { display: 'none' };
        const showStrom400 =
            this.state.showStrom400 === true
                ? { display: 'inline-block' }
                : { display: 'none' };
        const showSpuele =
            this.state.showSpuele === true
                ? { display: 'inline-block' }
                : { display: 'none' };
        const showDruckluft =
            this.state.showDruckluft === true
                ? { display: 'inline-block' }
                : { display: 'none' };
        const showGefaehrdung =
            this.state.showGefaehrdung === true
                ? { display: 'inline-block' }
                : { display: 'none' };
        return (
            <div className={style.header}>
                {this.props.initialValues.status === 'freigegeben' ? (
                    <div>Sie haben diese Daten bereits freigegeben</div>
                ) : null}
                {!this.props.exhibit_deadline_within ? (
                    <div>
                        Sie können die Daten nur innerhalb des
                        Bearbeitungszeitraums bearbeiten
                    </div>
                ) : null}
                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <fieldset>
                        <legend />
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='exponat_id'
                            id='exponat_id'
                            hidden
                            disabled
                            value={this.props.handle || ''}
                        />
                        <label htmlFor='exponat_groesse'>
                            <span className={style.bold}>
                                Größe des Exponats (BxHxT) in cm
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='exponat_groesse'
                            id='exponat_groesse'
                        />
                        <label htmlFor='exponat_gewicht'>
                            <span className={style.bold}>Gewicht in kg</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='exponat_gewicht'
                            id='exponat_gewicht'
                        />
                        <Field
                            component='input'
                            type='checkbox'
                            id='aufbauGT2'
                            name='aufbauGT2'
                        />
                        <label htmlFor='aufbauGT2'>
                            <span className={style.bold}>
                                Aufbau dauert mehr als zwei Tage
                            </span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='strom230'
                            name='strom230'
                            onChange={(e) => this.handleInput(e)}
                        />
                        <label htmlFor='strom230'>
                            <span className={style.bold}>Strombedarf 230V</span>
                        </label>
                        <div style={showStrom230}>
                            <label htmlFor='strom230_wofuer'>
                                <span className={style.bold}>Wofür?</span>
                            </label>
                            <Field
                                component='select'
                                name='strom230_wofuer'
                                id='strom230_wofuer'
                            >
                                <option value='' />
                                {stromWofuer.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </Field>
                            <label htmlFor='strom230_anzahl'>
                                <span className={style.bold}>
                                    Wie viele Stromanschlüsse werden benötigt?
                                </span>
                            </label>
                            <Field
                                component='input'
                                type='text'
                                placeholder=''
                                id='strom230_anzahl'
                                name='strom230_anzahl'
                            />
                            <label htmlFor='strom230_leistung'>
                                <span className={style.bold}>
                                    ca. Leistung [Watt]
                                </span>
                            </label>
                            <Field
                                component='input'
                                type='text'
                                placeholder=''
                                id='strom230_leistung'
                                name='strom230_leistung'
                            />
                        </div>
                        <Field
                            component='input'
                            type='checkbox'
                            id='strom400'
                            name='strom400'
                            onChange={(e) => this.handleInput(e)}
                        />
                        <label htmlFor='strom400'>
                            <span className={style.bold}>Strombedarf 400V</span>
                        </label>
                        <div style={showStrom400}>
                            <label htmlFor='strom400_wofuer'>
                                <span className={style.bold}>Wofür?</span>
                            </label>
                            <Field
                                component='input'
                                type='text'
                                placeholder=''
                                name='strom400_wofuer'
                                id='strom400_wofuer'
                            />
                            <label htmlFor='strom400_stecker'>
                                <span className={style.bold}>CEE Stecker</span>
                            </label>
                            <Field
                                component='select'
                                type='select'
                                id='strom400_stecker'
                                name='strom400_stecker'
                            >
                                <option value='' />
                                {stecker.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </Field>
                            <label htmlFor='strom400_leistung'>
                                <span className={style.bold}>
                                    ca. Leistung [Watt]
                                </span>
                            </label>
                            <Field
                                component='input'
                                type='text'
                                placeholder=''
                                id='strom400_leistung'
                                name='strom400_leistung'
                            />
                        </div>
                        <Field
                            component='input'
                            type='checkbox'
                            id='wasseranschluss'
                            name='wasseranschluss'
                            onChange={(e) => this.handleInput(e)}
                        />
                        <label htmlFor='wasseranschluss'>
                            <span className={style.bold}>Wasseranschluss</span>
                        </label>
                        <div style={showSpuele}>
                            <Field
                                component='input'
                                type='checkbox'
                                id='spuele'
                                name='spuele'
                            />
                            <label htmlFor='spuele'>
                                <span className={style.bold}>
                                    Spüle erforderlich (Aufpreis)
                                </span>
                            </label>
                        </div>
                        <Field
                            component='input'
                            type='checkbox'
                            id='druckluft'
                            name='druckluft'
                            onChange={(e) => this.handleInput(e)}
                        />
                        <label htmlFor='druckluft'>
                            <span className={style.bold}>Druckluft</span>
                        </label>
                        <div style={showDruckluft}>
                            <label htmlFor='druckbar'>
                                <span className={style.bold}>Druck [bar]</span>
                            </label>
                            <Field
                                component='input'
                                type='text'
                                placeholder=''
                                id='druckbar'
                                name='druckbar'
                            />
                            <label htmlFor='luftverbrauch'>
                                <span className={style.bold}>
                                    Verbauch [l/min]
                                </span>
                            </label>
                            <Field
                                component='input'
                                type='text'
                                placeholder=''
                                id='luftverbrauch'
                                name='luftverbrauch'
                            />
                            <label htmlFor='kupplung'>
                                <span className={style.bold}>
                                    Druckluft Kupplung
                                </span>
                            </label>
                            <Field
                                component='select'
                                name='kupplung'
                                id='kupplung'
                            >
                                <option value='' />
                                {kupplung.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </Field>
                        </div>
                        <Field
                            component='input'
                            type='checkbox'
                            name='gefaehrdung'
                            id='gefaehrdung'
                            onChange={(e) => this.handleInput(e)}
                        />
                        <label htmlFor='gefaehrdung'>
                            <span className={style.bold}>
                                Gefährdungspotential
                            </span>
                        </label>
                        <div style={showGefaehrdung}>
                            <label htmlFor='gefaehrdung_beschreibung'>
                                <span className={style.bold}>
                                    Gefährdungspotential (falls vorhanden)
                                </span>
                            </label>
                            <Field
                                component='input'
                                placeholder=''
                                type='text'
                                name='gefaehrdung_beschreibung'
                                id='gefaehrdung_beschreibung'
                            />
                            <label htmlFor='emissionen'>
                                <span className={style.bold}>
                                    Emissionen (falls vorhanden)
                                </span>
                            </label>
                            <Field
                                component='input'
                                placeholder=''
                                type='text'
                                name='emissionen'
                                id='emissionen'
                            />
                        </div>
                        <label htmlFor='sondermobiliar'>
                            <span className={style.bold}>
                                Sondermobiliar (falls benötigt)
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='sondermobiliar'
                            id='sondermobiliar'
                        />
                        <Field
                            component='input'
                            type='checkbox'
                            id='internet'
                            name='internet'
                        />
                        <label htmlFor='internet'>
                            <span className={style.bold}>
                                zusätzliche Internetverbindung (nur wenn für
                                Exponatbetrieb erforderlich, Hallen-WLAN ist
                                vorhanden
                            </span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='wlan'
                            name='wlan'
                        />
                        <label htmlFor='wlan'>
                            <span className={style.bold}>
                                Exponat benötigt eigenes WLAN
                                (zustimmungspflichtig)
                            </span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='mehrere_lan'
                            name='mehrere_lan'
                        />
                        <label htmlFor='mehrere_lan'>
                            <span className={style.bold}>
                                mehrere LAN Anschlüsse für den Betrieb des
                                Exponats auf dem Stand erforderlich
                            </span>
                        </label>
                        <label htmlFor='exponat_anforderungen'>
                            <span className={style.bold}>
                                besondere bauliche / technische Anforderungen
                                des Exponats
                            </span>
                        </label>
                        <Field
                            component='textarea'
                            placeholder=''
                            type='textarea'
                            name='exponat_anforderungen'
                            rows='8'
                            maxLength='800'
                            style={{
                                resize: 'none',
                            }}
                            id='exponat_anforderungen'
                        />

                        <FormButtons
                            showCancel={true}
                            overviewLink='/booths/exhibits'
                            hideBtn={true}
                            backOnSubmit={true}
                            handleSubmit={handleSubmit}
                            submit={this.submit.bind(this)}
                        />
                        <label>(*) = Pflichtfeld</label>
                    </fieldset>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const Props = {
        idStand: props.booth_id,
    };
    const handle = props.handle;
    if (handle == null) {
        return { ...Props, initialValues: { status: '' } };
    }
    const found = state.exhibit.find((x) => x.ID === handle);

    let initialValues = { status: '' };
    if (found && found.ID === handle) {
        initialValues = {
            exponat_id: handle || '',
            exponat_groesse: found.masse || '',
            exponat_gewicht: found.gewicht || '',
            aufbauGT2: found.kzAufAbbau2Tage === 1 ? true : false,
            strom230: found.idStrom === 1 || found.idStrom === 3 || false,
            strom230_wofuer: found.idGrundStrom || '',
            strom230_anzahl: found.anzahlStromanschlusspunkte || '',
            strom230_leistung: found.leistungsbedarf || '',
            strom400: found.kzStrom400V === 1 || false,
            strom400_wofuer: found.grundStrom400V || '',
            strom400_stecker: found.idStecker || '',
            strom400_leistung: found.ungefaehreLeistung || '',
            wasseranschluss: found.kzWasser === 1 || false,
            spuele: found.kzSpuele === 1 || false,
            druckluft: found.kzDruckluft === 1 || false,
            druckbar: found.druck || '',
            luftverbrauch: found.luftmenge || '',
            kupplung: found.idDruckluftKupplung || false,
            gefaehrdung: found.kzGefaehrdungspotenzial === 1 || false,
            gefaehrdung_beschreibung:
                found.kzGefaehrdungspotenzial === 1
                    ? found.gefaehrdungBeschreibung
                    : '',
            emissionen: found.emissionen || '',
            sondermobiliar:
                found.kzSondermobiliar === 1 ? found.sondermobiliar : '',
            internet: found.zusaetzlicheInternetverbindung === 1 ? true : false,
            wlan: found.kzExponatWlan === 1 ? true : false,
            mehrere_lan: found.idInternet === 1 || false,
            exponat_anforderungen: found.sonstigeTechnik || '',
            status:
                found.kzFreigabeAussteller === 1
                    ? 'freigegeben'
                    : found.kzFreigabeAussteller === 0
                    ? 'inbearbeitung'
                    : '',
        };
    }
    return {
        ...Props,
        initialValues: initialValues,
        exhibit_deadline_within: state.deadlines?.exhibit_deadline_within,
        found,
        handle: handle,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'exhibitseditspecifications',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(ExhibitEditSpecification),
);
