import { store } from '../../../store';
import {
    BACKEND_URL,
    ENV,
    CAREERSTAGE_SAVE,
    CAREERSTAGE_ADD,
    CAREERSTAGE_NEW,
} from '../../../global_constants';
import { setCareerstageUpdate } from '../exhibitor-actions.js';
import {
    showAlert,
    ERROR_UNAUTHORIZED,
    ERROR,
    SUCCESS,
} from '../../../components/exhibitor/alerts';

const mapStages = (values, json = {}) => {
    const newValues = Object.assign(
        {},
        {
            kzFreigabeAussteller:
                values.status === 'freigegeben'
                    ? 1
                    : values.status === 'inbearbeitung'
                    ? 0
                    : json.kzFreigabeAussteller
                    ? json.kzFreigabeAussteller
                    : '',
            kzFitFuer:
                values.format_fit === true
                    ? 1
                    : values.format_fit === false
                    ? 0
                    : json.kzFitFuer
                    ? json.kzFitFuer
                    : '',
            kzReimEinfach:
                values.format_reim === true
                    ? 1
                    : values.format_reim === false
                    ? 0
                    : json.kzReimEinfach
                    ? json.kzReimEinfach
                    : '',
            kzDasKarriereQuiz:
                values.format_karriere === true
                    ? 1
                    : values.format_karriere === false
                    ? 0
                    : json.kzDasKarriereQuiz
                    ? json.kzDasKarriereQuiz
                    : '',
            artBeitrag:
                typeof values.beitrag_art !== 'undefined'
                    ? values.beitrag_art
                    : json.artBeitrag || '',
            wuenscheErgaenzungen:
                typeof values.beitrag_wuensche !== 'undefined'
                    ? values.beitrag_wuensche
                    : json.wuenscheErgaenzungen || '',
            titelBeitrag:
                typeof values.beitrag_titel !== 'undefined'
                    ? values.beitrag_titel
                    : json.titelBeitrag || '',
            beschreibung:
                typeof values.beitrag_beschreibung !== 'undefined'
                    ? values.beitrag_beschreibung
                    : json.beschreibung || '',
            kzIndividuellesAusstellerformat:
                values.format_individuell === true
                    ? 1
                    : values.format_individuell === false
                    ? 0
                    : json.kzIndividuellesAusstellerformat
                    ? json.kzIndividuellesAusstellerformat
                    : '',
            individuellesAusstellerformatBeschreibung:
                typeof values.format_individuell_text !== 'undefined'
                    ? values.format_individuell_text
                    : json.individuellesAusstellerformatBeschreibung || '',
            gefuehrtVon:
                typeof values.ausstellerAgentur !== 'undefined'
                    ? values.ausstellerAgentur
                    : json.gefuehrtVon || '',
            ausstellerAgentur:
                typeof values.ausstellerAgentur !== 'undefined'
                    ? values.ausstellerAgentur
                    : json.ausstellerAgentur || '',
            nameAnsprechpartner:
                typeof values.ansprechpartner !== 'undefined'
                    ? values.ansprechpartner
                    : json.nameAnsprechpartner || '',
            emailAnsprechpartner:
                typeof values.ansprechpartner_email !== 'undefined'
                    ? values.ansprechpartner_email
                    : json.emailAnsprechpartner || '',
            telefonnummerAnsprechpartner:
                typeof values.ansprechpartner_tel !== 'undefined'
                    ? values.ansprechpartner_tel
                    : json.telefonnummerAnsprechpartner || '',
            erreichbarkeitAnsprechpartner:
                typeof values.erreichbarkeit !== 'undefined'
                    ? values.erreichbarkeit
                    : json.erreichbarkeitAnsprechpartner || '',
        },
    );
    return newValues;
};

export const approveCareerStage = (json) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    return fetch(BACKEND_URL + CAREERSTAGE_SAVE, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authenticationData.accessToken}`,
        },
        body: JSON.stringify(json),
    }).then((response) => {
        if (!response.ok) {
            showAlert(ERROR, 'Die Freigabe konnte nicht gespeichert werden.');
            return 500;
        } else {
            showAlert(SUCCESS, 'Die Freigabe wurde gespeichert.');
            return 200;
        }
    });
};

export const updateStage = (json, values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    return function (dispatch) {
        ENV === 'development' &&
            console.info('updating the careerstage with id = ', json.ID);
        const newValues = mapStages(values, json);
        const jsonNew = { ...json, ...newValues };
        return fetch(BACKEND_URL + CAREERSTAGE_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(jsonNew),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                ENV === 'development' && console.log(JSON.parse(text));
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('careerstage details fetch:', json);
                if (!json.ID) {
                    return Promise.reject(
                        'careerstage details fetch validity check: fail',
                    );
                }
                return json;
            })
            .then((json) => {
                return dispatch(setCareerstageUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};

export const insertCareerstage = (values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    const getState = store.getState();
    const exhibitor =
        Array.isArray(getState.exhibitor) && getState.exhibitor.length >= 1
            ? getState.exhibitor[0]
            : {};
    return function (dispatch) {
        const newValues = mapStages(values);
        // request a new prototype
        return fetch(BACKEND_URL + CAREERSTAGE_NEW, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData,
                    userId4Hist: undefined,
                },
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                // merge/overwrite requested json prototype with user input
                const jsonNew = { ...json, ...newValues };
                jsonNew.idProjekt = getState.authenticationData
                    ?.TpProjektLstItem
                    ? getState.authenticationData.TpProjektLstItem[0].ID
                    : null;
                jsonNew.idFirma = exhibitor.idAussteller;
                jsonNew.txtProjekt = getState.authenticationData
                    ?.TpProjektLstItem
                    ? getState.authenticationData.TpProjektLstItem[0].name
                    : null;
                return fetch(BACKEND_URL + CAREERSTAGE_ADD, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authenticationData.accessToken}`,
                    },
                    body: JSON.stringify({
                        ...jsonNew,
                        login: {
                            // TODO: pick properties manually!
                            ...authenticationData,
                            userId4Hist: undefined,
                        },
                    }),
                });
            })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('careerstage details fetch:', json);
                return json;
            })
            .then((json) => {
                return dispatch(setCareerstageUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};
