import { store } from '../../../store';
import { getEntity, setSteleUpdate } from '../exhibitor-actions.js';
import {
    BACKEND_URL,
    ENV,
    STELE_NEW,
    STELE_ADD,
    STELE_SAVE,
} from '../../../global_constants';
import {
    showAlert,
    ERROR_UNAUTHORIZED,
    ERROR,
    SUCCESS,
} from '../../../components/exhibitor/alerts';

export const updateStele = (json, values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;

    // Wenn anstelle von Values (die normalerweise die geänderten Daten aus der
    // Detailseite beinhalten) false übergeben wird, dann werden diese Detailänderungen nicht mit dem JSON gemergt,
    // sondern es wird direkt das JSON mit geänderten Kennzeichen für die Freigabe an den Server übermittelt
    if (values === false) {
        return fetch(BACKEND_URL + STELE_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(json),
        }).then((response) => {
            if (!response.ok) {
                showAlert(
                    ERROR,
                    'Die Freigabe konnte nicht gespeichert werden.',
                );
                return 500;
            } else {
                showAlert(SUCCESS, 'Die Freigabe wurde gespeichert.');
                return 200;
            }
        });
    }

    return function (dispatch) {
        const newValues = mapStele(values);
        const jsonNew = { ...json, ...newValues };
        ENV === 'development' &&
            console.info('updating the stele with id = ', json.ID);
        return fetch(BACKEND_URL + STELE_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(jsonNew),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('stele details fetch:', json);
                if (!json.ID) {
                    return Promise.reject(
                        'stele details fetch validity check: wrong code',
                    );
                }
                // TODO: check if already expired or already answered!
                // TODO: check if we get more than one TpEinladungLstItem entry, then the filter is probably wrong or the invite code
                return json;
            })
            .then((json) => {
                // debugger;
                return dispatch(setSteleUpdate(json));
            })
            .then((_json) => {
                if (values.idStand) {
                    dispatch(getEntity('booth', { idStand: values.idStand }));
                }
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};

const mapStele = (values) => {
    // debugger;

    // if (window.SteleLogosToDelete.get(1) == true) {
    // 	values.logo1 = '';
    // 	values.data4Logo1 = '';
    // 	window.SteleLogosToDelete.delete(1);
    // }

    // if (window.SteleLogosToDelete.get(2) == true) {
    // 	values.logo2 = '';
    // 	values.data4Logo2 = '';
    // 	window.SteleLogosToDelete.delete(2);
    // }

    // if (window.SteleLogosToDelete.get(3) == true) {
    // 	values.logo3 = '';
    // 	values.data4Logo3 = '';
    // 	window.SteleLogosToDelete.delete(3);
    // }

    // if (window.SteleLogosToDelete.get(4) == true) {
    // 	values.logo4 = '';
    // 	values.data4Logo4 = '';
    // 	window.SteleLogosToDelete.delete(4);
    // }

    // if (window.SteleLogosToDelete.get(5) == true) {
    // 	values.seite1Bild = '';
    // 	values.data4Seite1Bild = '';
    // 	window.SteleLogosToDelete.delete(5);
    // }

    // if (window.SteleLogosToDelete.get(6) == true) {
    // 	values.seite2Bild = '';
    // 	values.data4Seite2Bild = '';
    // 	window.SteleLogosToDelete.delete(6);
    // }

    // if (window.SteleLogosToDelete.get(7) == true) {
    // 	values.seite3Bild = '';
    // 	values.data3Seite1Bild = '';
    // 	window.SteleLogosToDelete.delete(7);
    // }

    // if (window.SteleLogosToDelete.get(8) == true) {
    // 	values.seite4Bild = '';
    // 	values.data4Seite4Bild = '';
    // 	window.SteleLogosToDelete.delete(8);
    // }

    // debugger;
    return {
        bezeichnung: values.name || '',
        // TODO: Backend doesn't provide stelentyp as either 4xtext or 2xtext/2ximage
        idStelentyp: values.idStelentyp || '',
        // TODO: Backend doesn't provide stelentyp as either 4xtext or 2xtext/2ximage
        seite1UeberschriftZ1: values.ueberschrift1 || '',
        seite1UeberschriftZ2: values.ueberschrift2 || '',
        seite2UeberschriftZ1: values.seite2_ueberschrift_z1 || '',
        seite2UeberschriftZ2: values.seite2_ueberschrift_z2 || '',
        seite3UeberschriftZ1: values.seite3_ueberschrift_z1 || '',
        seite3UeberschriftZ2: values.seite3_ueberschrift_z2 || '',
        seite4UeberschriftZ1: values.seite4_ueberschrift_z1 || '',
        seite4UeberschriftZ2: values.seite4_ueberschrift_z2 || '',
        seite1Fliesstext: values.text_stele || '',
        seite2Fliesstext: values.seite2_text || '',
        seite3Fliesstext: values.seite3_text || '',
        seite4Fliesstext: values.seite4_text || '',
        data4Seite1Bild: values.data4Seite1Bild || '',
        data4Seite2Bild: values.data4Seite2Bild || '',
        data4Seite3Bild: values.data4Seite3Bild || '',
        data4Seite4Bild: values.data4Seite4Bild || '',
        seite1Bild: values.seite1Bild || '',
        seite2Bild: values.seite2Bild || '',
        seite3Bild: values.seite3Bild || '',
        seite4Bild: values.seite4Bild || '',
        seite1BildExtension: values.seite1BildExtension || '',
        seite2BildExtension: values.seite2BildExtension || '',
        seite3BildExtension: values.seite3BildExtension || '',
        seite4BildExtension: values.seite4BildExtension || '',
        kzFreigabeAussteller:
            values.status === 'freigegeben'
                ? 1
                : values.status === 'inbearbeitung'
                ? 0
                : '0',
        logo1: values.logo1 || '',
        logo2: values.logo2 || '',
        logo3: values.logo3 || '',
        logo4: values.logo4 || '',
        data4Logo1: values.data4Logo1 || '',
        data4Logo2: values.data4Logo2 || '',
        data4Logo3: values.data4Logo3 || '',
        data4Logo4: values.data4Logo4 || '',
        idPositionierungLogo: values.idPositionierungLogo || -1,
    };
};

export const insertStele = (values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    const getState = store.getState();
    const exhibitor =
        Array.isArray(getState.exhibitor) && getState.exhibitor.length >= 1
            ? getState.exhibitor[0]
            : {};
    return function (dispatch) {
        // request a new 'Stele' prototype
        return fetch(BACKEND_URL + STELE_NEW, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData,
                    userId4Hist: undefined,
                },
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                // merge/overwrite requested json prototype with user input
                const newValues = mapStele(values);
                const jsonNew = { ...json, ...newValues };
                const hasProjekt =
                    getState.authenticationData?.TpProjektLstItem &&
                    getState.authenticationData.TpProjektLstItem.length > 0;
                jsonNew.idProjekt = hasProjekt
                    ? getState.authenticationData.TpProjektLstItem[0].ID
                    : null;
                jsonNew.txtProjekt = hasProjekt
                    ? getState.authenticationData.TpProjektLstItem[0].name
                    : null;
                jsonNew.idFirma = exhibitor.idAussteller;
                return fetch(BACKEND_URL + STELE_ADD, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authenticationData.accessToken}`,
                    },
                    body: JSON.stringify({
                        ...jsonNew,
                        login: {
                            // TODO: pick properties manually!
                            ...authenticationData,
                            userId4Hist: undefined,
                        },
                    }),
                });
            })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('stele details fetch:', json);
                return json;
            })
            .then((json) => {
                return dispatch(setSteleUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};
