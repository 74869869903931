import { store } from '../../../store';
import { BACKEND_URL, ENV, BOOTH_SAVE } from '../../../global_constants';
import { setBoothUpdate } from '../exhibitor-actions.js';
import {
    showAlert,
    ERROR_UNAUTHORIZED,
    ERROR,
} from '../../../components/exhibitor/alerts';

const mapDefaultLogo = (values) => {
    return {
        kzStandardlogos: values.standardlogo === 'yes' ? 1 : 0,
        logo1: values.logo1 || '',
        logo2: values.logo2 || '',
        logo3: values.logo3 || '',
        logo4: values.logo4 || '',
        data4Logo1: values.data4Logo1 || '',
        data4Logo2: values.data4Logo2 || '',
        data4Logo3: values.data4Logo3 || '',
        data4Logo4: values.data4Logo4 || '',
        idLogopositionierung: values.idPositionierungLogo || -1,
    };
};

export const updateDefaultLogo = (json, values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    return function (dispatch) {
        ENV === 'development' &&
            console.info('updating the Booth with id = ', json.ID);
        const newValues = mapDefaultLogo(values);
        const jsonNew = { ...json, ...newValues };
        return fetch(BACKEND_URL + BOOTH_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(jsonNew),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('Booth details fetch:', json);
                if (!json.ID) {
                    return Promise.reject(
                        'Booth details fetch validity check: fail',
                    );
                }
                return json;
            })
            .then((json) => {
                return dispatch(setBoothUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};
