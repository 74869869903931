import React from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { connect } from 'react-redux';

import classNames from 'classnames';

import StepButtons from '../../common/stepbuttons';
import style from '../../common/index.module.css';
import { ENV, MAIL_PATTERN, ZIPCODE_PATTERN } from '../../../global_constants';
import Question from '../../common/question';
import { getGuests } from '../../../actions/invitation-system/invitation-actions';

const renderField = ({
    input,
    label,
    placeholder,
    info,
    type,
    options,
    className,
    meta: { touched, error },
    style,
    disabled,
    hidden,
}) => (
    <div style={style}>
        {ENV === 'development' && console.log('in', input)}
        <label>{label}</label>
        <div>
            {type === 'select' ? (
                <select {...input} className={className}>
                    {options.map((option) => (
                        <option
                            key={input.name + option[1]}
                            hidden={option.length > 1 ? option[2] : false}
                            value={option[1]}
                        >
                            {option[0]}
                        </option>
                    ))}
                </select>
            ) : (
                <>
                    <input
                        {...input}
                        disabled={disabled}
                        hidden={hidden}
                        type={type}
                        placeholder={placeholder || label}
                        className={className}
                    />
                    {info && (
                        <span className='form-input-infotext'>{info}</span>
                    )}
                </>
            )}
        </div>
    </div>
);

const renderGuests = ({ fields, meta: { error, submitFailed }, ...props }) => {
    setTimeout(props.validate(fields));
    return (
        <ul className='guestlist'>
            {fields.map((guest, index) => (
                <li key={index} className='guest'>
                    <h4>Begleitung #{index + 1}</h4>
                    <Field
                        component={renderField}
                        type='text'
                        name={`${guest}.ID`}
                        key={`${guest}.ID`}
                        hidden
                        disabled
                    />
                    <Field
                        name={`${guest}.salutation`}
                        type='select'
                        component={renderField}
                        label='Anrede (*)'
                        key={`${guest}.salutation`}
                        options={[
                            ['Bitte auswählen', '', true],
                            ['Herr', 'sir'],
                            ['Frau', 'madam'],
                        ]}
                        defaultValue='Herr'
                    />
                    <Field
                        name={`${guest}.title`}
                        type='text'
                        component={renderField}
                        label='Titel'
                        placeholder='Titel'
                        key={`${guest}.title`}
                    />
                    <Field
                        name={`${guest}.firstname`}
                        type='text'
                        component={renderField}
                        label='Vorname (*)'
                        placeholder='Vorname'
                        key={`${guest}.firstname`}
                    />
                    <Field
                        name={`${guest}.lastname`}
                        type='text'
                        component={renderField}
                        label='Nachname (*)'
                        placeholder='Nachname'
                        key={`${guest}.lastname`}
                    />
                    <Field
                        component={renderField}
                        type='text'
                        ref={props.parent.email}
                        validate={props.parent.validateEmail}
                        name={`${guest}.email`}
                        label='E-Mail-Adresse (*)'
                        placeholder='E-Mail-Adresse'
                        key={`${guest}.email`}
                    />
                    <Field
                        name={`${guest}.phone`}
                        type='text'
                        component={renderField}
                        label='Telefonnummer'
                        placeholder='Telefonnummer'
                        key={`${guest}.phone`}
                    />
                    <Field
                        name={`${guest}.company`}
                        type='text'
                        component={renderField}
                        label='Firma (*)'
                        placeholder='Firma'
                        info='Privatpersonen tragen hier bitte „privat“ ein.'
                        key={`${guest}.company`}
                    />
                    <Field
                        name={`${guest}.street`}
                        type='text'
                        component={renderField}
                        label='Straße (*)'
                        placeholder='Straße'
                        key={`${guest}.street`}
                    />
                    <Field
                        name={`${guest}.zipcode`}
                        type='text'
                        ref={props.parent.zipcode}
                        className={classNames({
                            invalid: !props.parent.state.validZipcode,
                        })}
                        validate={props.parent.validateZipcode}
                        component={renderField}
                        label='Postleitzahl (*)'
                        placeholder='Postleitzahl'
                        key={`${guest}.zipcode`}
                    />
                    <Field
                        name={`${guest}.city`}
                        type='text'
                        component={renderField}
                        label='Ort (*)'
                        placeholder='Ort'
                        key={`${guest}.city`}
                    />
                    <button type='button' onClick={() => fields.remove(index)}>
                        Entfernen
                    </button>
                </li>
            ))}
            {!(fields.length >= props.max) && (
                <li className='addguest'>
                    <button
                        type='button'
                        onClick={() => fields.push({})}
                        disabled={fields.length >= props.max}
                    >
                        Begleitung hinzufügen
                    </button>
                    {submitFailed && error && <span>{error}</span>}
                </li>
            )}
        </ul>
    );
};

class Guests extends Question {
    constructor(props) {
        super(props, ['formdata.login.values.loginkey']);
        if (this.state.ready) {
            // TODO: dynamically get
            this.max = this.props.maxBegleiter;
            // TODO: use for rendering too
            this.data = [
                'salutation',
                'title',
                'email',
                'lastname',
                'firstname',
                'company',
                'phone',
                'street',
                'zipcode',
                'city',
            ];

            this.email = React.createRef();
            this.zipcode = React.createRef();
        }
    }

    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                // get guests, when there are none in Redux
                if (
                    this.props.invitation_id &&
                    this.props.guests &&
                    !Object.keys(this.props.guests).length
                ) {
                    return this.props
                        .dispatch(getGuests(this.props.invitation_id, 1))
                        .then(null, (_e) =>
                            Promise.reject({ e: 'BE did not provide data' }),
                        );
                }
            })
            .then(() => {
                this.validate();
            })
            .catch((e) => {
                console.error('error, promise rejected:', e);
                // this.setState({
                // 	state: e && e.state ? e.state : "invalid",
                // });
            });
    }

    validate = (fields) => {
        if (!fields || !fields.getAll()) {
            return;
        }
        let valid = true;
        fields.getAll().forEach((field, _index) => {
            for (const d of this.data) {
                if (
                    d !== 'title' &&
                    d !== 'phone' &&
                    (!field[d] || field[d].length < 2)
                ) {
                    valid = false;
                } else if (d === 'email') {
                    valid =
                        field[d] && MAIL_PATTERN.test(field[d])
                            ? valid
                            : undefined;
                } else if (d === 'zipcode') {
                    valid =
                        field[d] && ZIPCODE_PATTERN.test(field[d])
                            ? valid
                            : undefined;
                }
                if (!valid) {
                    return;
                }
            }
        });
        if (valid !== this.state.valid) {
            this.setState({
                valid: valid,
            });
        }
    };

    toggleClass = (elementName, addInvalid) => {
        const element = document.getElementsByName(elementName);
        element.forEach((item) => {
            // remove class and add later, if necessary
            item.classList.remove('invalid');
            if (addInvalid) {
                item.classList.add('invalid');
            }
        });
    };

    validateEmail = (value, _guests, _form, nameField) => {
        const valid = value && MAIL_PATTERN.test(value) ? value : undefined;
        // ToDo: statt false
        this.toggleClass(nameField, !valid);
        return valid;
    };
    validateZipcode = (value, _guests, _form, nameField) => {
        const valid = value && ZIPCODE_PATTERN.test(value) ? value : undefined;
        this.toggleClass(nameField, !valid);
        return valid;
    };

    renderQuestion() {
        if (this.max === 0) {
            return (
                <div className={style.question}>
                    <div className={style.header}>
                        <h1>
                            Für dieses Event wurden keine Begleitpersonen
                            vorgesehen.
                        </h1>
                        <p>Bitte gehen Sie zum nächsten Schritt.</p>
                    </div>
                    <StepButtons
                        back={
                            this.props.formdata.attendance?.values
                                ?.attendance === 'self'
                                ? '/personals'
                                : '/substitute'
                        }
                        to='/validate'
                        disabled={false}
                    />
                </div>
            );
        }
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>Begleitung hinzufügen</h1>
                    <p>
                        Sie können
                        {this.max === 1
                            ? ' 1 Begleitperson '
                            : ` ${this.max} Begleitpersonen `}
                        mitnehmen.
                    </p>
                </div>
                <form>
                    <fieldset>
                        <FieldArray
                            name='guests'
                            component={renderGuests}
                            props={{
                                max: this.max,
                                validate: this.validate,
                                parent: this,
                            }}
                            rerenderOnEveryChange={true}
                        />
                        <span>(*) = Pflichtfeld</span>
                    </fieldset>
                </form>
                <StepButtons
                    back={
                        this.props.formdata.attendance?.values?.attendance ===
                        'representative'
                            ? '/substitute'
                            : '/personals'
                    }
                    to='/validate'
                    disabled={!this.state.valid}
                />
            </div>
        );
    }
}
const mapStateToProps = (s) => {
    let maxBegleiter = s.event?.begleiter || 0;
    maxBegleiter = maxBegleiter === -1 ? 0 : maxBegleiter;
    const guests = s.guests || [];
    const invitation_id = s.invitation?.ID;
    const initialValuesGuests = [];
    guests.map((item) =>
        initialValuesGuests.push({
            ID: item.ID || 0,
            salutation: item.idAnrede === 1 ? 'sir' : 'madam',
            title: item.titel || '',
            email: item.email || '',
            lastname: item.name || '',
            firstname: item.vorname || '',
            company: item.firma || '',
            phone: item.telefon || '',
            street: item.strasse || '',
            zipcode: item.PLZ || '',
            city: item.ort || '',
        }),
    );
    const initialValues = { guests: initialValuesGuests };
    return {
        invitation_id,
        guests,
        initialValues,
        formdata: s.form,
        maxBegleiter,
    };
};

// export default reduxForm({
// 	form: 'guests',
// 	destroyOnUnmount: true
// })(connect(mapStateToProps, null)(Guests))
export default connect(
    mapStateToProps,
    null,
)(
    reduxForm({
        form: 'guests',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(Guests),
);
