import { store } from '../../../store';
import { setBoardUpdate } from '../exhibitor-actions.js';
import {
    BACKEND_URL,
    BOARD_SAVE,
    BOARD_NEW,
    BOARD_ADD,
    ENV,
} from '../../../global_constants';
import {
    showAlert,
    ERROR_UNAUTHORIZED,
    ERROR,
    SUCCESS,
} from '../../../components/exhibitor/alerts';

export const updateBoard = (json, values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;

    // Wenn anstelle von Values (die normalerweise die geänderten Daten aus der
    // Detaiseite beinhalten) false übergeben wird, dann werden diese Detailänderungen nicht mit dem JSON gemergt,
    // sondern es wird direkt das JSON mit geänderten Kennzeichen für die Freigabe an den Server übermittelt
    if (values === false) {
        return fetch(BACKEND_URL + BOARD_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(json),
        }).then((response) => {
            if (!response.ok) {
                showAlert(
                    ERROR,
                    'Die Freigabe konnte nicht gespeichert werden.',
                );
                return 500;
            } else {
                showAlert(SUCCESS, 'Die Freigabe wurde gespeichert.');
                return 200;
            }
        });
    }

    return function (dispatch) {
        const newValues = mapBoard(values);
        const jsonNew = { ...json, ...newValues };
        ENV === 'development' &&
            console.info('updating the board with id = ', json.ID);
        return (
            fetch(BACKEND_URL + BOARD_SAVE, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authenticationData.accessToken}`,
                },
                body: JSON.stringify(jsonNew),
            })
                .then((response) => {
                    if (!response.ok) {
                        return Promise.reject(response.statusText);
                    } else {
                        return response.text();
                    }
                })
                .then((text) => {
                    return JSON.parse(text);
                })
                .then((json) => {
                    ENV === 'development' &&
                        console.info('board details fetch:', json);
                    if (!json.ID) {
                        return Promise.reject(
                            'board details fetch validity check: wrong code',
                        );
                    }
                    // TODO: check if already expired or already answered!
                    // TODO: check if we get more than one TpEinladungLstItem entry, then the filter is probably wrong or the invite code
                    return json;
                })
                .then((json) => {
                    return dispatch(setBoardUpdate(json));
                })
                // BT 2022-02-07: Sehe nicht, warum an der Stelle der Stand noch mal
                // geladen werden sollte. Führt so auch zu einem Fehler, weil Exponattafel
                // nicht mit Stand, sondern mit Exponat verknüpft ist.
                // .then(json => {
                // 	return dispatch(getEntity("booth", {"ID":json.exhibit.idStand}));
                // })
                .catch((e) => {
                    ENV === 'development' && console.log('error occured', e);
                    if (e === 'Unauthorized') {
                        showAlert(ERROR, ERROR_UNAUTHORIZED);
                    }
                    return Promise.reject(e);
                })
        );
    };
};

const mapBoard = (values, _part) => {
    const newValues = Object.assign(
        {},
        {
            kzFreigabeAussteller:
                values.status === 'freigegeben'
                    ? 1
                    : values.status === 'inbearbeitung'
                    ? 0
                    : null,
            bezeichnung: values.name || '',
            exponattafelText: values.text_tafel || '',
            idTyp: values.aufsteller || '',
            idExponat: values.exponate || '',
            logo1: values.logo1 || '',
            logo2: values.logo2 || '',
            logo3: values.logo3 || '',
            logo4: values.logo4 || '',
            data4Logo1: values.data4Logo1 || '',
            data4Logo2: values.data4Logo2 || '',
            data4Logo3: values.data4Logo3 || '',
            data4Logo4: values.data4Logo4 || '',
            idLogopositionierung: values.idPositionierungLogo || -1,
            ueberschriftZeile1: values.ueberschrift1 || '',
            ueberschriftZeile2: values.ueberschrift2 || '',
        },
    );
    return newValues;
};

export const insertBoard = (values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    const getState = store.getState();
    const _exhibitor =
        Array.isArray(getState.exhibitor) && getState.exhibitor.length >= 1
            ? getState.exhibitor[0]
            : {};
    return function (_dispatch) {
        const newValues = mapBoard(values);
        // request a new exhibit prototype
        return fetch(BACKEND_URL + BOARD_NEW, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData,
                    userId4Hist: undefined,
                },
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                // merge/overwrite requested json prototype with user input
                const jsonNew = { ...json, ...newValues };
                jsonNew.idProjekt = getState.authenticationData
                    ?.TpProjektLstItem
                    ? getState.authenticationData.TpProjektLstItem[0].ID
                    : null;
                jsonNew.txtProjekt = getState.authenticationData
                    ?.TpProjektLstItem
                    ? getState.authenticationData.TpProjektLstItem[0].name
                    : null;
                return fetch(BACKEND_URL + BOARD_ADD, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authenticationData.accessToken}`,
                    },
                    body: JSON.stringify({
                        ...jsonNew,
                        login: {
                            // TODO: pick properties manually!
                            ...authenticationData,
                            userId4Hist: undefined,
                        },
                    }),
                });
            })
            .then((response) => {
                return response.text();
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('board details fetch:', json);
                return json;
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};
