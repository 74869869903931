export default function UploadFile(file) {
    return new Promise((resolve, _reject) => {
        const fr = new FileReader();
        fr.onload = () => {
            const result = fr.result;
            const filename = file.name;
            resolve({
                file: result.split(',')[1],
                name: filename,
                extension: filename.slice(
                    ((filename.lastIndexOf('.') - 1) >>> 0) + 2,
                ),
            });
        };
        fr.readAsDataURL(file);
    });
}
