import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
    updateWorkshop,
    insertWorkshop,
} from '../../../../../../actions/exhibitor/workshop';
import style from '../../../../index.module.css';
import FormButtons from '../../../../formbuttons';
import { store } from '../../../../../../store.js';
import disableForm from '../../../../disableForm.js';
import {
    showAlert,
    SUCCESS,
    SUCCESS_MSG,
    ERROR,
    validateForm,
    ERROR_MSG_BACKEND_SAVE,
} from '../../../../alerts';

class WorkshopEditsEquipment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            showDruckluft:
                this.props.initialValues &&
                this.props.initialValues.druckluft === 'ja'
                    ? true
                    : false,
            submitSuccess: false,
        };
    }

    handleInput(e) {
        this.setState({ valid: true });
        if (e && e.target.name === 'druckluft') {
            this.setState((_state) => ({
                showDruckluft: e.target.value === 'ja' ? true : false,
            }));
        }
    }

    resetForm() {
        this.setState({
            submitSuccess: false,
            showDruckluft:
                this.props.initialValues &&
                this.props.initialValues.druckluft === 'ja'
                    ? true
                    : false,
        });
    }

    componentDidMount() {
        if (this.props.initialValues['kzStatusFreigabeAussteller'] === 1) {
            disableForm();
        }
    }

    submit(values) {
        const storeState = store.getState();
        const workshopeditbasisValues =
            storeState.form?.workshopeditbasis?.values || {};
        const workshopeditscheduleValues =
            storeState.form?.workshopeditschedule?.values || {};
        validateForm(
            this,
            {
                ...workshopeditbasisValues,
                ...workshopeditscheduleValues,
                ...values,
            },
            ['status', 'workshop_name'],
            this.props.workshop_deadline_within,
        );
        Promise.resolve()
            .then(() => {
                // if updating an existing workshop
                if (this.props.handle) {
                    return this.props
                        .dispatch(
                            updateWorkshop(this.props.found, {
                                ...workshopeditscheduleValues,
                                ...workshopeditbasisValues,
                                ...values,
                            }),
                        )
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                } else {
                    // if creating a new workshop
                    return this.props
                        .dispatch(
                            insertWorkshop({
                                ...workshopeditscheduleValues,
                                ...workshopeditbasisValues,
                                ...values,
                            }),
                        )
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                }
            })
            .then((json) => {
                if (!this.props.handle) {
                    this.props.history.push(
                        `/workshops/edits?${json.workshop.ID}`,
                    );
                }
            })
            .then(() =>
                this.setState({
                    submitSuccess: true,
                }),
            )
            .then(() => showAlert(SUCCESS, SUCCESS_MSG))
            .then(() => {
                if (
                    this.props.initialValues['kzStatusFreigabeAussteller'] === 1
                ) {
                    disableForm();
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_SAVE);
                this.setState({ submitSuccess: false });
                this.props.dispatch(stopSubmit('workshopeditequipment', e));
            });
    }
    render() {
        const { handleSubmit } = this.props;
        const showDruckluft =
            this.state.showDruckluft === true
                ? { display: 'inline-block' }
                : { display: 'none' };
        const maxLengthWorkshopAnzahlReferenten = 200;
        const maxLengthWorkshopAnzahlReferentenPlaetze = 100;
        const maxLengthWorkshopEmissionen = 100;
        const maxLengthWorkshopSonstigeAusstattung = 800;
        const maxLengthWorkshopSonstigeHilfsmittel = 1000;
        const maxLengthWorkshopDruckInBar = 100;
        const maxLengthWorkshopLuftmenge = 10;

        return (
            <div className={style.header}>
                {this.props.initialValues.kzStatusFreigabeAussteller === 1 ? (
                    <div>Sie haben diese Daten bereits freigegeben</div>
                ) : null}
                {!this.props.workshop_deadline_within ? (
                    <div>
                        Sie können die Daten nur innerhalb des
                        Bearbeitungszeitraums bearbeiten
                    </div>
                ) : null}
                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <fieldset>
                        <legend />
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='workshop_id'
                            id='workshop_id'
                            hidden
                            disabled
                            value={this.props.handle || ''}
                        />
                        <label htmlFor='anz_referenten_plaetze'>
                            <span className={style.bold}>
                                Anzahl Referentenplätze
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='anz_referenten_plaetze'
                            id='anz_referenten_plaetze'
                            maxLength={maxLengthWorkshopAnzahlReferentenPlaetze}
                        />
                        <legend className={style.bold}>Referententisch</legend>
                        <Field
                            component='input'
                            type='radio'
                            id='referenten_tisch_ja'
                            name='tisch'
                            value='ja'
                            required
                        />
                        <label htmlFor='referenten_tisch_ja'>
                            <span>Ja</span>
                        </label>
                        <Field
                            component='input'
                            type='radio'
                            id='referenten_tisch_nein'
                            name='tisch'
                            value='nein'
                            required
                        />
                        <label htmlFor='referenten_tisch_nein'>
                            <span>Nein</span>
                        </label>
                        <legend className={style.bold}>Rednerpult</legend>
                        <Field
                            component='input'
                            type='radio'
                            id='referenten_pult_ja'
                            name='pult'
                            value='ja'
                            required
                        />
                        <label htmlFor='referenten_pult_ja'>
                            <span>Ja</span>
                        </label>
                        <Field
                            component='input'
                            type='radio'
                            id='referenten_pult_nein'
                            name='pult'
                            value='nein'
                            required
                        />
                        <label htmlFor='referenten_pult_nein'>
                            <span>Nein</span>
                        </label>
                        <legend className={style.bold}>
                            Beamer/LCD-Monitor
                        </legend>
                        <Field
                            component='input'
                            type='radio'
                            id='referenten_beamer_ja'
                            name='beamer'
                            value='ja'
                            required
                        />
                        <label htmlFor='referenten_beamer_ja'>
                            <span>Ja</span>
                        </label>
                        <Field
                            component='input'
                            type='radio'
                            id='referenten_beamer_nein'
                            name='beamer'
                            value='nein'
                            required
                        />
                        <label htmlFor='referenten_beamer_nein'>
                            <span>Nein</span>
                        </label>
                        <legend className={style.bold}>Flipchart</legend>
                        <Field
                            component='input'
                            type='radio'
                            id='referenten_flip_ja'
                            name='flip'
                            value='ja'
                            required
                        />
                        <label htmlFor='referenten_flip_ja'>
                            <span>Ja</span>
                        </label>
                        <Field
                            component='input'
                            type='radio'
                            id='referenten_flip_nein'
                            name='flip'
                            value='nein'
                            required
                        />
                        <label htmlFor='referenten_flip_nein'>
                            <span>Nein</span>
                        </label>
                        <legend className={style.bold}>
                            Stromanschluss für Referenten
                        </legend>
                        <Field
                            component='input'
                            type='radio'
                            id='referenten_strom_ja'
                            name='referenten_strom'
                            value='ja'
                            required
                        />
                        <label htmlFor='referenten_strom_ja'>
                            <span>Ja</span>
                        </label>
                        <Field
                            component='input'
                            type='radio'
                            id='referenten_strom_nein'
                            name='referenten_strom'
                            value='nein'
                            required
                        />
                        <label htmlFor='referenten_strom_nein'>
                            <span>Nein</span>
                        </label>
                        <legend className={style.bold}>
                            Internet für Referenten
                        </legend>
                        <Field
                            component='input'
                            type='radio'
                            id='referenten_internet_ja'
                            name='referenten_internet'
                            value='ja'
                            required
                        />
                        <label htmlFor='referenten_internet_ja'>
                            <span>Ja</span>
                        </label>
                        <Field
                            component='input'
                            type='radio'
                            id='referenten_internet_nein'
                            name='referenten_internet'
                            value='nein'
                            required
                        />
                        <label htmlFor='referenten_internet_nein'>
                            <span>Nein</span>
                        </label>
                        <label htmlFor='anz_teilnehmer'>
                            <span className={style.bold}>
                                Anzahl Teilnehmerplätze
                            </span>
                        </label>
                        <Field
                            component='input'
                            type='number'
                            id='anz_teilnehmer'
                            min='0'
                            name='anz_teilnehmer'
                            required
                        />
                        <legend className={style.bold}>Bestuhlung</legend>
                        <Field
                            component='input'
                            type='radio'
                            id='referenten_bestuhlung_ja'
                            name='bestuhlung'
                            value='ja'
                            required
                        />
                        <label htmlFor='referenten_bestuhlung_ja'>
                            <span>Ja</span>
                        </label>
                        <Field
                            component='input'
                            type='radio'
                            id='referenten_bestuhlung_nein'
                            name='bestuhlung'
                            value='nein'
                            required
                        />
                        <label htmlFor='referenten_bestuhlung_nein'>
                            <span>Nein</span>
                        </label>
                        <legend className={style.bold}>
                            Tische für Teilnehmer
                        </legend>
                        <Field
                            component='input'
                            type='radio'
                            id='teilnehmer_tische_ja'
                            name='tische'
                            value='ja'
                            required
                        />
                        <label htmlFor='teilnehmer_tische_ja'>
                            <span>Ja</span>
                        </label>
                        <Field
                            component='input'
                            type='radio'
                            id='teilnehmer_tische_nein'
                            name='tische'
                            value='nein'
                            required
                        />
                        <label htmlFor='teilnehmer_tische_nein'>
                            <span>Nein</span>
                        </label>
                        <label htmlFor='anordnung_tische'>
                            <span>Anordnung der Stühle/Tische</span>
                        </label>
                        <Field
                            component='select'
                            name='anordnung_tische'
                            id='anordnung_tische'
                            required
                        >
                            <option value='' hidden>
                                Bitte wählen Sie eine Stühle/Tische-Anordnung
                            </option>
                            <option value='reihe'>Reihe</option>
                            <option value='uform'>U-Form/Block</option>
                            <option value='gruppentische'>Gruppentische</option>
                        </Field>
                        <legend className={style.bold}>
                            Strom an den Teilnehmertischen
                        </legend>
                        <Field
                            component='input'
                            type='radio'
                            id='teilnehmer_strom_ja'
                            name='teilnehmer_strom'
                            value='ja'
                            required
                        />
                        <label htmlFor='teilnehmer_strom_ja'>
                            <span>Ja</span>
                        </label>
                        <Field
                            component='input'
                            type='radio'
                            id='teilnehmer_strom_nein'
                            name='teilnehmer_strom'
                            value='nein'
                            required
                        />
                        <label htmlFor='teilnehmer_strom_nein'>
                            <span>Nein</span>
                        </label>
                        <legend className={style.bold}>
                            Internet an den Teilnehmertischen
                        </legend>
                        <Field
                            component='input'
                            type='radio'
                            id='teilnehmer_internet_ja'
                            name='teilnehmer_internet'
                            value='ja'
                            required
                        />
                        <label htmlFor='teilnehmer_internet_ja'>
                            <span>Ja</span>
                        </label>
                        <Field
                            component='input'
                            type='radio'
                            id='teilnehmer_internet_nein'
                            name='teilnehmer_internet'
                            value='nein'
                            required
                        />
                        <label htmlFor='teilnehmer_internet_nein'>
                            <span>Nein</span>
                        </label>
                        <legend className={style.bold}>Druckluft</legend>
                        <Field
                            component='input'
                            type='radio'
                            id='druckluft_ja'
                            name='druckluft'
                            value='ja'
                            required
                            onChange={(e) => this.handleInput(e)}
                        />
                        <label htmlFor='druckluft_ja'>
                            <span>Ja</span>
                        </label>
                        <Field
                            component='input'
                            type='radio'
                            id='druckluft_nein'
                            name='druckluft'
                            value='nein'
                            required
                            onChange={(e) => this.handleInput(e)}
                        />
                        <label htmlFor='druckluft_nein'>
                            <span>Nein</span>
                        </label>
                        <div style={showDruckluft}>
                            <label htmlFor='druckbar'>
                                <span className={style.bold}>Druck [bar]</span>
                            </label>
                            <Field
                                component='input'
                                type='text'
                                placeholder=''
                                id='druckbar'
                                name='druckbar'
                                maxLength={maxLengthWorkshopDruckInBar}
                            />
                            <label htmlFor='luftverbrauch'>
                                <span className={style.bold}>
                                    Verbrauch [l/min]
                                </span>
                            </label>
                            <Field
                                component='input'
                                type='text'
                                placeholder=''
                                id='luftverbrauch'
                                name='luftverbrauch'
                                maxLength={maxLengthWorkshopLuftmenge}
                            />
                        </div>
                        <legend className={style.bold}>
                            Wasseranschluss im Raum
                        </legend>
                        <Field
                            component='input'
                            type='radio'
                            id='wasser_ja'
                            name='wasser'
                            value='ja'
                            required
                        />
                        <label htmlFor='wasser_ja'>
                            <span>Ja</span>
                        </label>
                        <Field
                            component='input'
                            type='radio'
                            id='wasser_nein'
                            name='wasser'
                            value='nein'
                            required
                        />
                        <label htmlFor='wasser_nein'>
                            <span>Nein</span>
                        </label>
                        <legend className={style.bold}>
                            Wasseranschluss in der Nähe
                        </legend>
                        <Field
                            component='input'
                            type='radio'
                            id='wasser_nah_ja'
                            name='wasser_nah'
                            value='ja'
                            required
                        />
                        <label htmlFor='wasser_nah_ja'>
                            <span>Ja</span>
                        </label>
                        <Field
                            component='input'
                            type='radio'
                            id='wasser_nah_nein'
                            name='wasser_nah'
                            value='nein'
                            required
                        />
                        <label htmlFor='wasser_nah_nein'>
                            <span>Nein</span>
                        </label>
                        <label htmlFor='gefaehrdung_beschreibung'>
                            <span className={style.bold}>
                                Gefährdungspotenzial/Emissionen
                            </span>
                        </label>
                        <Field
                            component='input'
                            type='text'
                            placeholder=''
                            id='gefaehrdung_beschreibung'
                            name='gefaehrdung_beschreibung'
                            maxLength={maxLengthWorkshopEmissionen}
                        />
                        <label htmlFor='hilfsmittel_sonstige'>
                            <span className={style.bold}>
                                Sonstige Hilfsmittel
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='hilfsmittel_sonstige'
                            id='hilfsmittel_sonstige'
                            maxLength={maxLengthWorkshopSonstigeHilfsmittel}
                        />
                        <label htmlFor='ausstattung_sonstiges'>
                            <span className={style.bold}>
                                Sonstige Raumausstattung
                            </span>
                        </label>
                        <Field
                            component='textarea'
                            placeholder=''
                            type='text'
                            name='ausstattung_sonstiges'
                            rows='8'
                            maxLength={maxLengthWorkshopSonstigeAusstattung}
                            style={{
                                resize: 'none',
                            }}
                            id='ausstattung_sonstiges'
                        />
                        <FormButtons
                            showCancel={true}
                            overviewLink='/workshops'
                            hideBtn={true}
                            handleSubmit={handleSubmit}
                            submit={this.submit.bind(this)}
                        />
                        <legend>(*) = Pflichtfeld</legend>
                    </fieldset>
                </form>
                {/* <StepButtons backTab={() => this.props.toTab(1)} to={'/live-program'} disabled={!this.state.valid} labelBack={'Zeitplanung'} labelForward={'LiveProgramm'} /> */}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const Props = {
        workshop_deadline_within: state.deadlines?.workshop_deadline_within,
    };
    let initialValues = {};
    if (!props.handle) {
        return { ...Props, initialValues };
    } else {
        const found =
            state.workshop && Array.isArray(state.workshop)
                ? state.workshop.find((x) => x.ID === props.handle)
                : [];
        if (found && found.ID === props.handle) {
            initialValues = {
                workshop_id: props.handle || '',
                anz_referenten_plaetze: found.anzahlRefPlatz || '',
                tisch: found.kzTischMitStuhl === 1 ? 'ja' : 'nein',
                pult: found.kzRednerpult === 1 ? 'ja' : 'nein',
                beamer: found.kzBeamer === 1 ? 'ja' : 'nein',
                flip: found.kzFlipchart === 1 ? 'ja' : 'nein',
                referenten_strom: found.kzStromAmTisch === 1 ? 'ja' : 'nein',
                referenten_internet: found.kzInternet === 1 ? 'ja' : 'nein',
                anz_teilnehmer: found.anzahlTeilnehmer || 0,
                bestuhlung: found.kzBestuhlung === 1 ? 'ja' : 'nein',
                tische: found.kzTische === 1 ? 'ja' : 'nein',
                anordnung_tische:
                    found.idAnordnung === 1
                        ? 'reihe'
                        : found.idAnordnung === 2
                        ? 'uform'
                        : found.idAnordnung === 3
                        ? 'gruppentische'
                        : '',
                teilnehmer_strom:
                    found.kzStromAmTischTeilnehmer === 1 ? 'ja' : 'nein',
                teilnehmer_internet:
                    found.internetTeilnehmertische === 1 ? 'ja' : 'nein',
                druckluft: found.kzDruckluft === 1 ? 'ja' : 'nein',
                druckbar: found.druckInBar || '',
                gefaehrdung_beschreibung: found.emissionen || '',
                hilfsmittel_sonstige: found.sonstigeHilfsmittel || '',
                ausstattung_sonstiges: found.sonstigeRaumausstattung || '',
                wasser: found.wasseranschlussRaum === 1 ? 'ja' : 'nein',
                wasser_nah: found.wasseranschlussNaehe === 1 ? 'ja' : 'nein',
                luftverbrauch: found.luftmenge || '',
                status: found.idStatus === 1 ? 'inbearbeitung' : '',
                kzStatusFreigabeAussteller:
                    found.kzStatusFreigabeAussteller || 0,
            };
        }
        return {
            ...Props,
            initialValues: initialValues,
            found,
        };
    }
};

export default compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'workshopeditequipment',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(WorkshopEditsEquipment),
);
