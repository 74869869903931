import { store } from '../../../store';
import {
    BACKEND_URL,
    ENV,
    STAGESEVEN_SAVE,
    STAGESEVEN_ADD,
    STAGESEVEN_NEW,
} from '../../../global_constants';
import { setStagesevenUpdate } from '../exhibitor-actions.js';
import {
    showAlert,
    ERROR_UNAUTHORIZED,
    ERROR,
    SUCCESS,
} from '../../../components/exhibitor/alerts';

const mapStages = (values, json = {}) => {
    return {
        kzFreigabeAussteller:
            values.status === 'freigegeben'
                ? 1
                : values.status === 'inbearbeitung'
                ? 0
                : json.kzFreigabeAussteller
                ? json.kzFreigabeAussteller
                : '',
        titelBeitrag:
            typeof values.beitrag_titel !== 'undefined'
                ? values.beitrag_titel
                : json.titelBeitrag || '',
        tagUhrzeit:
            typeof values.beitrag_hfk !== 'undefined'
                ? values.beitrag_hfk
                : json.tagUhrzeit || '',
        kzDasQuiz:
            values.format_quiz === true
                ? 1
                : values.format_quiz === false
                ? 0
                : json.kzDasQuiz
                ? json.kzDasQuiz
                : '',
        beschreibung:
            typeof values.beitrag_beschreibung !== 'undefined'
                ? values.beitrag_beschreibung
                : json.beschreibung || '',
        weitereInformationen:
            typeof values.beitrag_infos !== 'undefined'
                ? values.beitrag_infos
                : json.weitereInformationen || '',
        kz360Grad:
            values.format_360 === true
                ? 1
                : values.format_360 === false
                ? 0
                : json.kz360Grad
                ? json.kz360Grad
                : '',
        kzXponat:
            values.format_xponat === true
                ? 1
                : values.format_xponat === false
                ? 0
                : json.kzXponat
                ? json.kzXponat
                : '',
        kzDigital:
            values.format_digital === true
                ? 1
                : values.format_digital === false
                ? 0
                : json.kzDigital
                ? json.kzDigital
                : '',
        kzMixIt:
            values.format_mixit === true
                ? 1
                : values.kzMixIt === false
                ? 0
                : json.kzMixIt
                ? json.kzMixIt
                : '',
        kzMusicBy:
            values.format_musicby === true
                ? 1
                : values.format_musicby === false
                ? 0
                : json.kzMusicBy
                ? json.kzMusicBy
                : '',
        kzIndividuellesAusstellerformat:
            values.format_individuell === true
                ? 1
                : values.format_individuell === false
                ? 0
                : json.kzIndividuellesAusstellerformat
                ? json.kzIndividuellesAusstellerformat
                : '',
        individuellesAusstellerformatBeschreibung:
            typeof values.format_individuell_text !== 'undefined'
                ? values.format_individuell_text
                : json.individuellesAusstellerformatBeschreibung || '',
        gefuehrtVon:
            typeof values.ausstellerAgentur !== 'undefined'
                ? values.ausstellerAgentur
                : json.gefuehrtVon || '',
        ausstellerAgentur:
            typeof values.ausstellerAgentur !== 'undefined'
                ? values.ausstellerAgentur
                : json.ausstellerAgentur || '',
        nameAnsprechpartner:
            typeof values.ansprechpartner !== 'undefined'
                ? values.ansprechpartner
                : json.nameAnsprechpartner || '',
        emailAnsprechpartner:
            typeof values.ansprechpartner_email !== 'undefined'
                ? values.ansprechpartner_email
                : json.emailAnsprechpartner || '',
        telefonnummerAnsprechpartner:
            typeof values.ansprechpartner_tel !== 'undefined'
                ? values.ansprechpartner_tel
                : json.telefonnummerAnsprechpartner || '',
        erreichbarkeitAnsprechpartner:
            typeof values.erreichbarkeit !== 'undefined'
                ? values.erreichbarkeit
                : json.erreichbarkeitAnsprechpartner || '',
        wuenscheErgaenzungen:
            typeof values.beitrag_wuensche !== 'undefined'
                ? values.beitrag_wuensche
                : json.wuenscheErgaenzungen || '',
        artBeitrag:
            typeof values.beitrag_art !== 'undefined'
                ? values.beitrag_art
                : json.artBeitrag || '',
    };
};

export const updateStage = (json, values, part) => {
    const authenticationData = store.getState().authenticationDataExhibitor;

    // Wenn anstelle von Values (die normalerweise die geänderten Daten aus der
    // Detaiseite beinhalten) false übergeben wird, dann werden diese Detailänderungen nicht mit dem JSON gemergt,
    // sondern es wird direkt das JSON mit geänderten Kennzeichen für die Freigabe an den Server übermittelt
    if (values === false) {
        return fetch(BACKEND_URL + STAGESEVEN_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(json),
        }).then((response) => {
            if (!response.ok) {
                showAlert(
                    ERROR,
                    'Die Freigabe konnte nicht gespeichert werden.',
                );
                return 500;
            } else {
                showAlert(SUCCESS, 'Die Freigabe wurde gespeichert.');
                return 200;
            }
        });
    }

    return function (dispatch) {
        ENV === 'development' &&
            console.info('updating the stageseven with id = ', json.ID);
        const newValues = mapStages(values, part);
        const jsonNew = { ...json, ...newValues };
        return fetch(BACKEND_URL + STAGESEVEN_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(jsonNew),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('stageseven details fetch:', json);
                if (!json.ID) {
                    return Promise.reject(
                        'stageseven details fetch validity check: fail',
                    );
                }
                return json;
            })
            .then((json) => {
                return dispatch(setStagesevenUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};

export const insertStageseven = (values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    const getState = store.getState();
    const exhibitor =
        Array.isArray(getState.exhibitor) && getState.exhibitor.length >= 1
            ? getState.exhibitor[0]
            : {};
    return function (dispatch) {
        const newValues = mapStages(values);
        // request a new prototype
        return fetch(BACKEND_URL + STAGESEVEN_NEW, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData,
                    userId4Hist: undefined,
                },
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                // merge/overwrite requested json prototype with user input
                const jsonNew = { ...json, ...newValues };
                jsonNew.idProjekt = getState.authenticationData
                    ?.TpProjektLstItem
                    ? getState.authenticationData.TpProjektLstItem[0].ID
                    : null;
                jsonNew.idFirma = exhibitor.idAussteller;
                jsonNew.txtProjekt = getState.authenticationData
                    ?.TpProjektLstItem
                    ? getState.authenticationData.TpProjektLstItem[0].name
                    : null;
                return fetch(BACKEND_URL + STAGESEVEN_ADD, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authenticationData.accessToken}`,
                    },
                    body: JSON.stringify({
                        ...jsonNew,
                        login: {
                            // TODO: pick properties manually!
                            ...authenticationData,
                            userId4Hist: undefined,
                        },
                    }),
                });
            })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('stageseven details fetch:', json);
                return json;
            })
            .then((json) => {
                return dispatch(setStagesevenUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};
