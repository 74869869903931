import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { store } from '../../../../../../store.js';
import { withRouter } from 'react-router-dom';
import {
    updateCompany,
    getEntity,
} from '../../../../../../actions/exhibitor/exhibitor-actions.js';
import style from '../../../../index.module.css';
import FormButtons from '../../../../formbuttons';
import StepButtons from '../../../../stepbuttons';
import {
    showAlert,
    SUCCESS,
    SUCCESS_MSG,
    ERROR,
    ERROR_MSG_BACKEND_SAVE,
    ERROR_MSG_BACKEND_FETCH,
} from '../../../../alerts';

class ContactDataPupils extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            submitSuccess: false,
        };
    }
    handleInput(e) {
        this.setState({ valid: true });
        if (e && e.target.name === 'text_stele') {
            this.setState((_state, _props) => ({}));
        }
    }

    resetForm() {
        this.setState({
            submitSuccess: false,
        });
    }
    submit(values) {
        const internetValues =
            store.getState().form.contactdatainternet?.values || {};
        const studentsValues =
            store.getState().form.contactdatastudents?.values || {};
        Promise.resolve()
            .then(() => {
                if (this.props.handle) {
                    this.props
                        .dispatch(
                            updateCompany(this.props.handle, {
                                ...internetValues,
                                ...studentsValues,
                                ...values,
                            }),
                        )
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                }
            })
            .then(() => {
                return this.props
                    .dispatch(getEntity('company'))
                    .then(null, (_e) =>
                        Promise.reject({ _error: ERROR_MSG_BACKEND_FETCH }),
                    );
            })
            .then(() =>
                this.setState({
                    submitSuccess: true,
                }),
            )
            .then(() => showAlert(SUCCESS, SUCCESS_MSG))
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_SAVE);
                this.setState({ submitSuccess: false });
                this.props.dispatch(stopSubmit('contactdatapupils', e));
            });
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <div className={style.header}>
                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <fieldset>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='contactdata_idPupils'
                            id='contactdata_idPupils'
                            hidden
                            disabled
                            value={this.props.handle?.ID}
                        />
                        <legend />
                        <label htmlFor='name'>
                            <span>Name</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='namePupils'
                            id='namePupils'
                        />
                        <label htmlFor='telefon'>
                            <span>Telefon</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='telefonPupils'
                            id='telefonPupils'
                        />
                        <label htmlFor='fax'>
                            <span>Fax</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='faxPupils'
                            id='faxPupils'
                        />
                        <label htmlFor='email'>
                            <span>E-Mail</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='emailPupils'
                            id='emailPupils'
                        />
                        <FormButtons
                            hideBtn={true}
                            saveButtonAlignRight={true}
                            handleSubmit={handleSubmit}
                            submit={this.submit.bind(this)}
                        />
                        <legend>(*) = Pflichtfeld</legend>
                    </fieldset>
                </form>
                <StepButtons
                    backTab={() => this.props.toTab(0)}
                    toTab={() => this.props.toTab(2)}
                    disabled={!this.state.valid}
                    labelBack={'Internetseiten'}
                    labelForward={'Ansprechpartner für Studierende'}
                />
            </div>
        );
    }
}

const mapStateToProps = (_state, props) => {
    const found = props.handle;
    let initialValues = {};
    if (found) {
        initialValues = {
            contactdata_idPupils: props.handle || '',
            namePupils: found.ansprSchName || '',
            telefonPupils: found.ansprSchTelefon || '',
            faxPupils: found.ansprSchFax || '',
            emailPupils: found.ansprSchEmail || '',
        };
    }
    return {
        initialValues: initialValues,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'contactdatapupils',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(ContactDataPupils),
);
