import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';

import StepButtons from '../../common/stepbuttons';
import style from '../../common/index.module.css';
import { MAIL_PATTERN, ZIPCODE_PATTERN } from '../../../global_constants';
import Question from '../../common/question';
import { getGuests } from '../../../actions/invitation-system/invitation-actions';

class Substitute extends Question {
    constructor(props) {
        super(
            props,
            ['form.login.values.loginkey', 'form.attendance.values.attendance'],
            { valid: false },
        );
        if (this.state.ready) {
            // TODO: dynamically
            this.readOnly =
                this.props.formdata.attendance.values.attendance === 'self';
            this.email = React.createRef();
            this.lastname = React.createRef();
            this.firstname = React.createRef();
            this.company = React.createRef();
            this.street = React.createRef();
            this.zipcode = React.createRef();
            this.city = React.createRef();
            this.doMapping();
        }
    }
    doMapping = () => {
        const values = this.props.formdata.substitute?.values;
        [
            ['salutation', 'idAnrede'],
            ['title', 'titel'],
            ['firstname', 'vorname'],
            ['lastname', 'name'],
            ['company', 'firma'],
            ['email', 'email'],
            ['phone', 'telefon'],
            ['street', 'strasse'],
            ['zipcode', 'PLZ'],
            ['city', 'ort'],
        ].forEach((mapping) => {
            if (!values || typeof values[mapping[0]] !== 'undefined') {
                if (
                    this.props.substitute[mapping[1]] != null &&
                    this.props.substitute[mapping[1]] !== ''
                ) {
                    this.props.change(
                        mapping[0],
                        this.props.substitute[mapping[1]],
                    );
                }
            }
        });
    };

    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                // get substitutes, when there are none in Redux
                if (
                    this.props.invitation_id &&
                    this.props.substitute &&
                    !Object.keys(this.props.substitute).length
                ) {
                    return this.props
                        .dispatch(getGuests(this.props.invitation_id, 2))
                        .then(null, (_e) =>
                            Promise.reject({ e: 'BE did not provide data' }),
                        );
                }
            })
            .then(() => {
                this.doMapping();
            })
            .then(() => this.validate())
            .catch((e) => {
                console.error('error, promise rejected:', e);
                // this.setState({
                // 	state: e && e.state ? e.state : "invalid",
                // });
            });
    }
    validate() {
        setTimeout(() => {
            if (
                this.lastname.current?.value &&
                this.firstname.current?.value &&
                this.company.current?.value &&
                this.street.current?.value &&
                this.city.current?.value &&
                this.validateZipcode(this.zipcode.current?.value) &&
                this.validateEmail(this.email.current?.value)
            ) {
                this.setState({
                    valid: true,
                });
            } else {
                this.setState({
                    valid: false,
                });
            }
        }, 0);
    }

    validateEmail = (value) => {
        const valid = value && MAIL_PATTERN.test(value) ? value : undefined;
        this.setState({ validEmail: !!valid });
        return valid;
    };
    validateZipcode = (value) => {
        const valid = value && ZIPCODE_PATTERN.test(value) ? value : undefined;
        this.setState({ validZipcode: !!valid });
        return valid;
    };
    renderQuestion() {
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>Daten Ihrer Vertretung</h1>
                    <p>
                        Vervollständigen Sie bitte nachfolgend die Daten Ihrer
                        Vertretung.
                    </p>
                </div>
                <form>
                    <fieldset>
                        <label htmlFor='salutation'>Anrede (*)</label>
                        <Field
                            component='select'
                            type='select'
                            name='salutation'
                            onChange={(_e) => this.validate()}
                        >
                            <option hidden value=''>
                                Bitte auswählen
                            </option>
                            <option value='1'>Herr</option>
                            <option value='2'>Frau</option>
                        </Field>
                        <label htmlFor='title'>Titel</label>
                        <Field
                            component='input'
                            type='text'
                            name='title'
                            onChange={(_e) => this.validate()}
                        />
                        <label htmlFor='firstname'>Vorname (*)</label>
                        <Field
                            component='input'
                            type='text'
                            name='firstname'
                            ref={this.firstname}
                            onChange={(_e) => this.validate()}
                        />
                        <label htmlFor='lastname'>Nachname (*)</label>
                        <Field
                            component='input'
                            type='text'
                            name='lastname'
                            ref={this.lastname}
                            onChange={(_e) => this.validate()}
                        />
                        <label htmlFor='email'>E-Mail-Adresse (*)</label>
                        <Field
                            component='input'
                            type='email'
                            name='email'
                            validate={this.validateEmail}
                            className={classNames({
                                invalid: !this.state.validEmail,
                            })}
                            ref={this.email}
                            onChange={(_e) => this.validate()}
                        />
                        <label htmlFor='phone'>Telefonnummer</label>
                        <Field
                            component='input'
                            type='tel'
                            name='phone'
                            onChange={(_e) => this.validate()}
                        />
                        <label htmlFor='company'>Firma (*)</label>
                        <Field
                            name='company'
                            type='text'
                            component='input'
                            label='Firma'
                            ref={this.company}
                            key='company'
                            onChange={(_e) => this.validate()}
                        />
                        <label htmlFor='street'>
                            Straße &amp; Hausnummer (*)
                        </label>
                        <Field
                            component='input'
                            type='text'
                            name='street'
                            ref={this.street}
                            onChange={(_e) => this.validate()}
                        />
                        <label htmlFor='zipcode'>Postleitzahl (*)</label>
                        <Field
                            component='input'
                            type='text'
                            name='zipcode'
                            className={classNames({
                                invalid: !this.state.validZipcode,
                            })}
                            validate={this.validateZipcode}
                            ref={this.zipcode}
                            onChange={(_e) => this.validate()}
                        />
                        <label htmlFor='city'>Ort (*)</label>
                        <Field
                            component='input'
                            type='text'
                            name='city'
                            ref={this.city}
                            onChange={(_e) => this.validate()}
                        />
                        <span>(*) = Pflichtfeld</span>
                    </fieldset>
                </form>
                <StepButtons
                    back='/personals'
                    to='/guests'
                    disabled={!this.state.valid}
                />
            </div>
        );
    }
}

const mapStateToProps = (s) => {
    const invitation_id = s.invitation?.ID;
    const substitute = s.substitute || [];
    return {
        invitation_id,
        substitute,
        formdata: s.form,
        ...s,
    };
};

export default reduxForm({
    form: 'substitute',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
})(connect(mapStateToProps, null)(Substitute));
