import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import {
    insertClubzukunftList,
    updateClubzukunftList,
} from '../../../../../actions/exhibitor/clubzukunft/index.js';
import {
    getCustomLst,
    getEntity,
} from '../../../../../actions/exhibitor/exhibitor-actions.js';
import Loader from '../../../../common/loader';
import style from '../../../../exhibitor/index.module.css';
import { shy } from '../../../../utils/html-entity.jsx';
import {
    ERROR,
    ERROR_MSG_BACKEND_FETCH,
    ERROR_MSG_BACKEND_SAVE,
    ERROR_UNAUTHORIZED,
    showAlert,
} from '../../../alerts';
import FormButtons from '../../../formbuttons';
import {
    determineAuthForRoles,
    getBackwardActionForRoles,
    getForwardActionForRoles,
} from '../../../functions';
import StepButtons from '../../../stepbuttons';
import sx from './study-field.module.scss';
import bannerSx from '../../../organisation/banner-print/banner-print.module.scss';

const EXTERN_ID_WISSENSCHAFT = 'Wissenschaft';
const specialCharsRx = /[- /()\\ßäöüÄÖÜ]/g;
const createReduxFieldName = (def, key) =>
    `${def.description.replace(specialCharsRx, '_').toLowerCase()}_${key}`;

const mapStateToProps = (state, _props) => {
    let initialValues = {};

    // Ermittlung der Rollenberechtigungen des angemeldeten Users
    const userAuth = determineAuthForRoles(state);

    let studyfieldDef = (state.studyfielddef || []).map((def) => ({
        ID: def.ID,
        description: def.description,
        praktikumName: createReduxFieldName(def, 'praktikum'),
        abschlussarbeitName: createReduxFieldName(def, 'abschlussarbeit'),
        einstiegName: createReduxFieldName(def, 'einstieg'),
        studiengangName: createReduxFieldName(def, 'studiengang'),
        studyIndex: -1,
    }));

    const studyfield = state.studyfield;

    if (studyfield?.length > 0 && studyfieldDef?.length > 0) {
        studyfieldDef = studyfieldDef.map((def) => {
            let result = def;
            studyfield.forEach((study, index) => {
                if (def.ID === study.idStudienrichtung) {
                    initialValues = {
                        ...initialValues,
                        [def.praktikumName]: study.kzPraktikum || 0,
                        [def.abschlussarbeitName]: study.kzAbschlussarbeit || 0,
                        [def.einstiegName]: study.kzEinstiegsposition || 0,
                        [def.studiengangName]: study.kzStudiengang || 0,
                    };
                    result = {
                        ID: def.ID,
                        description: def.description,
                        praktikumName: def.praktikumName,
                        abschlussarbeitName: def.abschlussarbeitName,
                        einstiegName: def.einstiegName,
                        studiengangName: def.studiengangName,
                        studyIndex: index,
                    };
                }
            });
            return result;
        });
    }

    const isUniversity = Boolean(
        (state.firmaKategorie || []).find(
            (cat) =>
                cat.ID === state.company?.[0].idKategorie &&
                cat.externId === EXTERN_ID_WISSENSCHAFT,
        ),
    );

    return {
        initialValues,
        studyfieldDef: studyfieldDef || [],
        studyfield: state.studyfield || [],
        hasBoothAuthorization: userAuth.hasBoothAuthorization,
        hasWorkshopAuthorization: userAuth.hasWorkshopAuthorization,
        hasLiveprogramAuthorization: userAuth.hasLiveprogramAuthorization,
        hasClubzukunftAuthorization: userAuth.hasClubzukunftAuthorization,
        hasOrganisationAuthorization: userAuth.hasOrganisationAuthorization,
        hasCarreerStageAuthorization: userAuth.hasCarreerStageAuthorization,
        isUniversity,
    };
};

/**
 *
 * @param {ReturnType<mapStateToProps>
 * 	& {
 * 		initialValues: Record<string, any>,
 * 		handleSubmit: () => void,
 * 		dispatch: (action: any) => any
 * }} props
 */
export const StudyFieldList = (props) => {
    const [ready, setReady] = useState(false);
    const [valid, setValid] = useState(true);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const handleFetch = async () => {
        setReady(false);
        try {
            await Promise.all([
                props.dispatch(getCustomLst('studyfielddef')),
                props.dispatch(getEntity('studyfield')),
                props.dispatch(getCustomLst('firmakategoriedef')),
            ]);
            setReady(true);
        } catch (e) {
            showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
            console.error('error checking code, promise rejected:', e);
            setValid(false);
        } finally {
            setReady(true);
        }
    };

    useEffect(() => {
        handleFetch();
    }, []);

    const submit = async (values) => {
        setReady(false);
        const submitDef = async (def) => {
            const modified = {
                kzPraktikum: values[def.praktikumName],
                kzAbschlussarbeit: values[def.abschlussarbeitName],
                kzEinstiegsposition: values[def.einstiegName],
                kzStudiengang: values[def.studiengangName],
            };

            // existing
            if (def.studyIndex > -1) {
                await props.dispatch(
                    updateClubzukunftList(
                        props.studyfield[def.studyIndex],
                        modified,
                        'studyfield',
                    ),
                );
                return;
            }

            // creating new
            await props.dispatch(
                insertClubzukunftList(modified, def.ID, 'studyfield'),
            );
            return;
        };
        // reject unchanged values
        const keys = [
            'praktikumName',
            'abschlussarbeitName',
            'einstiegName',
            'studiengangName',
        ];
        const changed = props.studyfieldDef.filter((def) =>
            keys.some(
                (key) => values[def[key]] !== props.initialValues[def[key]],
            ),
        );
        // submit changed values
        try {
            await Promise.all(changed.map(submitDef));
            await props.dispatch(getEntity('studyfield'));
            setSubmitSuccess(true);
        } catch (e) {
            showAlert(ERROR, ERROR_MSG_BACKEND_SAVE);
            if (e._error === 'Unauthorized') {
                showAlert(ERROR, ERROR_UNAUTHORIZED);
            }
            submitSuccess(false);
            props.dispatch(stopSubmit('studyfields', e));
        } finally {
            setReady(true);
        }
    };

    if (!ready) {
        return (
            <div style={{ height: '90vh' }}>
                <Loader visible />
            </div>
        );
    }

    const forwardAction = getForwardActionForRoles('organisation', props);
    const backwardAction = getBackwardActionForRoles('dualstudy', props);

    return (
        <div className={style.question}>
            <div className={style.header}>
                <h1>Angebote für Studierende</h1>
                {props.isUniversity ? (
                    <p>
                        Als Hochschule können Sie in der unten stehenden Liste
                        die Studiengänge anklicken, die in Ihrer Hochschule
                        angeboten werden. Aufgrund des vielfältigen
                        Studienangebotes haben wir uns auf allgemeine
                        MINT-Studiengänge beschränkt.
                    </p>
                ) : (
                    <p>
                        Wenn Ihr Unternehmen Studierenden Möglichkeiten zum
                        Berufseinstieg, Abschlussarbeiten oder Praktika
                        anbietet, können Sie in der unten stehenden Liste die
                        Studienrichtungen anklicken, die für Ihr Unternehmen
                        besonders interessant sind. Aufgrund des vielfältigen
                        Studienangebotes haben wir uns auf allgemeine
                        MINT-Studienrichtungen beschränkt.
                    </p>
                )}
            </div>

            <form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <table
                    className={bannerSx.bannerTable}
                    style={{
                        marginTop: '60px',
                    }}
                >
                    <thead>
                        {props.isUniversity ? (
                            <tr>
                                <th>{shy`Be|zeich|nung`}</th>
                                <th
                                    style={{ width: '120px' }}
                                >{shy`Stu|di|en|gang`}</th>
                            </tr>
                        ) : (
                            <tr>
                                <th>{shy`Be|zeich|nung`}</th>
                                <th
                                    style={{ width: '82px' }}
                                >{shy`Prak|ti|kum`}</th>
                                <th
                                    style={{ width: '82px' }}
                                >{shy`Ab|schluss|ar|beit`}</th>
                                <th
                                    style={{ width: '82px' }}
                                >{shy`Ein|stiegs|po|si|tion`}</th>
                            </tr>
                        )}
                    </thead>
                    <tbody>
                        {Array.isArray(props.studyfieldDef) &&
                            props.studyfieldDef.length > 0 &&
                            props.studyfieldDef.map((def, i) => (
                                <StudyFieldItem
                                    // rome-ignore lint/suspicious/noArrayIndexKey: quick fix
                                    key={i}
                                    def={def}
                                    isUniversity={props.isUniversity}
                                />
                            ))}
                    </tbody>
                </table>

                <FormButtons
                    hideBtn={true}
                    handleSubmit={props.handleSubmit}
                    submit={submit}
                />
                <legend />
            </form>
            <StepButtons
                back={backwardAction.backwardAction}
                to={forwardAction.forwardAction}
                labelBack={backwardAction.backwardText}
                labelForward={forwardAction.forwardText}
            />
        </div>
    );
};

export const StudyField = compose(
    withRouter,
    connect(mapStateToProps, null, null, {
        areStatesEqual: (next, prev) =>
            prev.studyfielddef === next.studyfielddef &&
            prev.studyfield === next.studyfield,
    }),
)(
    reduxForm({
        form: 'studyfields',
        destroyOnUnmount: true,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(StudyFieldList),
);

const StudyFieldItem = ({ def, isUniversity }) => (
    <tr>
        <td>{def.description}</td>
        {isUniversity ? (
            <td>
                <Field
                    className={sx.checkbox}
                    component='input'
                    type='checkbox'
                    id={`${def.studiengangName}`}
                    name={`${def.studiengangName}`}
                />
            </td>
        ) : (
            <>
                <td>
                    <Field
                        className={sx.checkbox}
                        component='input'
                        type='checkbox'
                        id={`${def.praktikumName}`}
                        name={`${def.praktikumName}`}
                    />
                </td>
                <td>
                    <Field
                        className={sx.checkbox}
                        component='input'
                        type='checkbox'
                        id={`${def.abschlussarbeitName}`}
                        name={`${def.abschlussarbeitName}`}
                    />
                </td>
                <td>
                    <Field
                        className={sx.checkbox}
                        component='input'
                        type='checkbox'
                        id={`${def.einstiegName}`}
                        name={`${def.einstiegName}`}
                    />
                </td>
            </>
        )}
    </tr>
);
