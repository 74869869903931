import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import bannerSx from '../../organisation/banner-print/banner-print.module.scss';
import sx from './exhibitor-passes.module.scss';
import classNames from 'classnames';

export const PassItem = ({ index, onChange, onDelete, pass, hasFreigabe }) => {
    return (
        <tr>
            <td>
                <form onSubmit={(e) => e.preventDefault()}>
                    <fieldset>
                        <input
                            disabled={hasFreigabe}
                            className={sx.fullWidth}
                            value={pass?.vornameNachname}
                            onChange={(e) => {
                                onChange({
                                    ...pass,
                                    vornameNachname: e.target.value,
                                });
                            }}
                        />
                    </fieldset>
                </form>
            </td>
            <td>
                {hasFreigabe ? (
                    <div>
                        ja&nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon icon={faCheck} size='1x' />
                    </div>
                ) : (
                    <div>nein</div>
                )}
            </td>
            <td>
                <div className={bannerSx.actions}>
                    {!hasFreigabe && (
                        <div
                            className={classNames(
                                bannerSx.action,
                                bannerSx.button,
                                bannerSx.transparent,
                            )}
                            onClick={async (e) => {
                                e.stopPropagation();
                                const skip = !pass?.ID;

                                if (
                                    skip ||
                                    window.confirm(
                                        `Wollen Sie den Eintrag "${pass.vornameNachname}" wirklich löschen?`,
                                    )
                                ) {
                                    onDelete(pass, index);
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} size='1x' />
                        </div>
                    )}
                </div>
            </td>
        </tr>
    );
};
