import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import disableForm from '../../exhibitor/disableForm.js';
import isWorkflowEditable from '../functions.js';

import StepButtons from '../../common/stepbuttons';
import style from '../../common/index.module.css';
import Question from '../../common/question';
import { getCustomLst } from '../../../actions/common/actions.js';

class Validate extends Question {
    constructor(props) {
        super(
            props,
            [
                // "form.school_selection.values.school",
                'form.registration_personals.values',
                'form.group.values',
                'form.schedule.values',
                'form.allowance.values',
            ],
            {
                valid: false,
            },
        );
        this.gdpr = React.createRef();
    }
    handleInput() {
        this.setState({}, () => {
            this.validate();
        });
    }
    validate() {
        this.setState({
            valid: this.gdpr.current?.value,
        });
    }
    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                // get setClassLevelList, when it's not in Redux yet
                if (
                    this.props.allowanceList &&
                    !Object.keys(this.props.allowanceList).length
                ) {
                    return this.props
                        .dispatch(getCustomLst('allowance'))
                        .then(null, (_e) =>
                            Promise.reject('allowance not available'),
                        );
                }
            })
            .catch((e) => {
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }
    componentDidMount() {
        if (this.props.editedAnmeldung.id > -1) {
            const anmeldung =
                this.props.groupRegistrationList[
                    this.props.editedAnmeldung.index
                ];
            console.log('anmeldung', anmeldung);

            this.props.change('newsletter', anmeldung.kzNewsletter);

            // Bearbeitung nur unter bestimmten Bedingungen erlaubt
            if (!isWorkflowEditable(anmeldung)) {
                disableForm();
                this.noSend = true;
            }
        }

        super.componentDidMount();
    }
    renderQuestion() {
        const form = this.props.formdata;
        const school = this.props.schoolList.find(
            (school) =>
                school.ID.toString() ===
                form.school_selection.values.school_selection,
        );
        const isOepnv = school.kzOepnv;
        const personalValues = form.registration_personals.values;
        const groupValues = form.group.values;
        const scheduleValues = form.schedule.values;
        const allowanceValues = form.allowance.values;
        const allowanceYes = allowanceValues.allowance === 'yes';
        const hasDeclaredSalutation =
            personalValues.salutation !== 'KeineAngabe';

        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>
                        Ihre <b>Daten</b>
                    </h1>
                    <p>
                        Folgende Daten haben Sie im Verlauf der Anmeldung
                        eingegeben. Wenn alles korrekt ist, bestätigen Sie bitte
                        die
                        Da&shy;ten&shy;schutz&shy;ver&shy;ein&shy;ba&shy;rung am
                        Ende der Seite und klicken dann auf „Absenden“! Die
                        Anmeldebestätigung wird an Ihre Mailadresse sowie an die
                        Schul-Mailadresse gesendet.
                    </p>
                </div>
                <form>
                    <fieldset>
                        {/* Schuldaten */}
                        <div className='validate-entry'>
                            <span>
                                <b>Schulname:</b>
                            </span>
                            <span>{school.name}</span>
                        </div>
                        <div className='validate-entry'>
                            <span>
                                <b>Schulzweig:</b>
                            </span>
                            <span>
                                {(this.props.schoolTrackList || []).find(
                                    (item) =>
                                        parseInt(item.id) ===
                                        parseInt(groupValues.schoolTrack),
                                )?.name || 'n/a'}
                            </span>
                        </div>
                        <div className='validate-entry'>
                            <span>
                                <b>E-Mail (Schule):</b>
                            </span>
                            <span>{personalValues.emailSchool}</span>
                        </div>
                        <div className='validate-entry'>
                            <span>
                                <b>Telefon (Schule):</b>
                            </span>
                            <span>{personalValues.phoneSchool}</span>
                        </div>
                        <hr />
                        {/* Anmeldende Person / Antragsteller */}
                        <div className='validate-entry'>
                            <span>
                                <b>Name:</b>
                            </span>
                            <span>
                                {hasDeclaredSalutation
                                    ? `${personalValues.salutation} `
                                    : ''}
                                {`${personalValues.firstname} ${personalValues.lastname}`}
                            </span>
                        </div>
                        <div className='validate-entry'>
                            <span>
                                <b>E-Mail:</b>
                            </span>
                            <span>{personalValues.email}</span>
                        </div>
                        <div className='validate-entry'>
                            <span>
                                <b>Telefon:</b>
                            </span>
                            <span>{personalValues.phone}</span>
                        </div>
                        <hr />
                        {/* Gruppe */}
                        <div className='validate-entry'>
                            <span>
                                <b>Klasse oder Kursbezeichnung:</b>
                            </span>
                            <span>{groupValues.name}</span>
                        </div>
                        <div className='validate-entry'>
                            <span>
                                <b>Klassenstufe:</b>
                            </span>
                            <span>
                                {(this.props.classLevelList || []).find(
                                    (item) =>
                                        parseInt(item.ID) ===
                                        parseInt(groupValues.classLevel),
                                )?.description || 'n/a'}
                            </span>
                        </div>
                        <div className='validate-entry'>
                            <span>
                                <b>Anzahl Schülerinnen:</b>
                            </span>
                            <span>{groupValues.girls}</span>
                        </div>
                        <div className='validate-entry'>
                            <span>
                                <b>Anzahl Schüler:</b>
                            </span>
                            <span>{groupValues.boys}</span>
                        </div>
                        <div className='validate-entry'>
                            <span>
                                <b>Anzahl der Begleitpersonen:</b>
                            </span>
                            <span>{groupValues.leaders}</span>
                        </div>
                        <hr />
                        {/* Anreise */}
                        <div className='validate-entry'>
                            <span>
                                <b>Anreisetag:</b>
                            </span>
                            <span>
                                {(this.props.arrivalDayList || []).find(
                                    (item) =>
                                        item.value ===
                                        scheduleValues.arrivalDay,
                                )?.label || 'n/a'}
                            </span>
                        </div>
                        <div className='validate-entry'>
                            <span>
                                <b>Verkehrsmittel:</b>
                            </span>
                            <span>
                                {(this.props.transportList || []).find(
                                    (item) =>
                                        parseInt(item.ID) ===
                                        parseInt(scheduleValues.transport),
                                )?.description || 'n/a'}
                            </span>
                        </div>
                        <div className='validate-entry'>
                            <span>
                                <b>Abfahrt am Schulort:</b>
                            </span>
                            <span>
                                {(
                                    this.props.departureTimeSchoolList || []
                                ).find(
                                    (item) =>
                                        parseInt(item.ID) ===
                                        parseInt(scheduleValues.departureTime),
                                )?.description || 'n/a'}
                            </span>
                        </div>
                        <div className='validate-entry'>
                            <span>
                                <b>Ankunft IdeenExpo:</b>
                            </span>
                            <span>
                                {(this.props.arrivalTimeList || []).find(
                                    (item) =>
                                        parseInt(item.ID) ===
                                        parseInt(scheduleValues.arrivalTime),
                                )?.description || 'n/a'}
                            </span>
                        </div>
                        {scheduleValues.guidance && (
                            <div className='validate-entry'>
                                <span>
                                    <b>Führung gewünscht:</b>
                                </span>
                                <span>
                                    {scheduleValues.guidance ? 'Ja' : 'Nein'}
                                </span>
                            </div>
                        )}
                        {scheduleValues.workshop && (
                            <div className='validate-entry'>
                                <span>
                                    <b>Workshop gewünscht:</b>
                                </span>
                                <span>
                                    {scheduleValues.workshop ? 'Ja' : 'Nein'}
                                </span>
                            </div>
                        )}
                        {scheduleValues.clubZukunft && (
                            <div className='validate-entry'>
                                <span>
                                    <b>ClubZukunft gewünscht:</b>
                                </span>
                                <span>
                                    {scheduleValues.clubZukunft ? 'Ja' : 'Nein'}
                                </span>
                            </div>
                        )}
                        <hr />
                        {/* Anreisekostenzuschuss */}
                        <div className='validate-entry'>
                            <span>
                                <b>
                                    {isOepnv === 1 ? (
                                        <>GVH-Gruppenfahrschein beantragt:</>
                                    ) : (
                                        <>Anreisekostenzuschuss beantragt:</>
                                    )}
                                </b>
                            </span>
                            <span>{allowanceYes ? 'Ja' : 'Nein'}</span>
                        </div>
                        {allowanceYes && isOepnv === 0 && (
                            <div>
                                <div className='validate-entry'>
                                    <span>
                                        <b>Kontoinhaber:</b>
                                    </span>
                                    <span>{allowanceValues.accountHolder}</span>
                                </div>
                                <div className='validate-entry'>
                                    <span>
                                        <b>IBAN:</b>
                                    </span>
                                    <span>{allowanceValues.IBAN}</span>
                                </div>
                                <div className='validate-entry'>
                                    <span>
                                        <b>Verwendungszweck:</b>
                                    </span>
                                    <span>
                                        {allowanceValues.paymentReference}
                                    </span>
                                </div>
                            </div>
                        )}

                        <hr />
                        <div className='row'>
                            <Field
                                component='input'
                                type='checkbox'
                                id='gdpr'
                                name='gdpr'
                                ref={this.gdpr}
                                onChange={(_e) => this.handleInput()}
                            />
                            <label htmlFor='gdpr'>
                                <span>
                                    Ich habe die{' '}
                                    <a
                                        href='https://ideenexpo.de/datenschutz-haftungsausschluss'
                                        target='blank'
                                    >
                                        [Datenschutzbestimmungen]
                                    </a>{' '}
                                    gelesen und bin damit einverstanden.
                                </span>
                            </label>
                        </div>
                        <div className='row'>
                            <Field
                                component='input'
                                type='checkbox'
                                id='newsletter'
                                name='newsletter'
                            />
                            {/* TODO: insert popup or actual link TBD */}
                            <label htmlFor='newsletter'>
                                <span>
                                    Ich möchte über den Termin der nächsten
                                    IdeenExpo informiert werden und stimme der
                                    Zusendung weiterer Informationen zur
                                    IdeenExpo an meine persönliche
                                    E-Mail-Adresse zu. Ich kann die Zusendung
                                    jederzeit abbestellen.{' '}
                                    <a
                                        href='https://www.ideenexpo.de/datenschutz-haftungsausschluss'
                                        target='blank'
                                    >
                                        [Bedingungen]
                                    </a>
                                </span>
                            </label>
                        </div>
                    </fieldset>

                    {/* rome-ignore lint/suspicious/noDoubleEquals: <explanation> loose comparison with falsy value intentional here */}
                    {this.noSend == true ? (
                        <StepButtons back='/allowance' />
                    ) : (
                        <StepButtons
                            back='/allowance'
                            to='/sending'
                            toText='Absenden'
                            disabled={!this.state.valid}
                        />
                    )}
                </form>
            </div>
        );
    }
}

export default reduxForm({
    form: 'validate',
    destroyOnUnmount: false,
})(connect((s) => ({ formdata: s.form, ...s }))(Validate));
