import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/string';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();
