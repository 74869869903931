import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
    updateWorkshop,
    insertWorkshop,
} from '../../../../../../actions/exhibitor/workshop';
import style from '../../../../index.module.css';
import FormButtons from '../../../../formbuttons';
import { store } from '../../../../../../store.js';
import disableForm from '../../../../disableForm.js';
import {
    showAlert,
    SUCCESS,
    SUCCESS_MSG,
    ERROR,
    validateForm,
    ERROR_MSG_BACKEND_SAVE,
} from '../../../../alerts';
import { areMaximumNumberOfCheckboxesChecked } from '../../../../functions';

const maxLengthWorkshopBezeichnung = 80;
const maxLengthWorkshopTitelInternet = 60;
const maxLengthWorkshopTextInternet = 800;
const maxLengthWorkshopVeranstalter = 100;
const maxLengthWorkshopNameReferenten = 100;
const maxLengthWorkshopTelefonReferent = 200;
const maxLengthWorkshopEmailReferent = 200;
const maxLengthWorkshopSonstigeZielgruppe = 100;
const maxLengthWorkshopTeilnehmerMin = 11;
const maxLengthWorkshopTeilnehmerMax = 11;
const maxLengthAnmerkungenAblauf = 800;

class WorkshopEditsBasis extends Component {
    constructor(props) {
        super(props);

        this.state = {
            valid: true,
            showSonstige: props.found && props.found.kzZielGruppeSonstige === 1,
            submitSuccess: false,
        };
    }
    resetForm() {
        this.setState((_state, props) => ({
            submitSuccess: false,
            showSonstige:
                props.found && props.found.sonstigeZielgruppe !== ''
                    ? true
                    : false,
        }));
    }

    handleInput(e) {
        this.setState({ valid: true });
        if (
            e &&
            e.target.type === 'checkbox' &&
            e.target.name.startsWith('zielgruppe')
        ) {
            if (e.target.checked) {
                const workshopValues =
                    store.getState().form.workshopeditbasis?.values;
                const checkboxValues = [
                    workshopValues.zielgruppe56,
                    workshopValues.zielgruppe78,
                    workshopValues.zielgruppe910,
                    workshopValues.zielgruppe1112,
                    workshopValues.zielgruppe_sonstige,
                ];
                if (areMaximumNumberOfCheckboxesChecked(checkboxValues, 2)) {
                    e.preventDefault();
                    return;
                }
            }
            if (e.target.name === 'zielgruppe_sonstige') {
                this.setState((state) => ({
                    showSonstige: !state.showSonstige,
                }));
            }
        }
    }

    componentDidMount() {
        if (this.props.initialValues['kzStatusFreigabeAussteller'] === 1) {
            disableForm();
        }
    }

    submit(values) {
        const storeState = store.getState();
        const workshopeditequipmentValues =
            storeState.form?.workshopeditequipment?.values || {};
        const workshopeditscheduleValues =
            storeState.form?.workshopeditschedule?.values || {};
        validateForm(
            this,
            {
                ...workshopeditscheduleValues,
                ...workshopeditequipmentValues,
                ...values,
            },
            ['status', 'workshop_name'],
            this.props.workshop_deadline_within,
        );
        Promise.resolve()
            .then(() => {
                // if updating an existing workshop
                if (this.props.handle) {
                    return this.props
                        .dispatch(
                            updateWorkshop(this.props.found, {
                                ...workshopeditscheduleValues,
                                ...workshopeditequipmentValues,
                                ...values,
                            }),
                        )
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                } else {
                    // if creating a new workshop
                    return this.props
                        .dispatch(
                            insertWorkshop({
                                ...workshopeditscheduleValues,
                                ...workshopeditequipmentValues,
                                ...values,
                            }),
                        )
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                }
            })
            .then((json) => {
                if (!this.props.handle) {
                    this.props.history.push(
                        `/workshops/edits?${json.workshop.ID}`,
                    );
                }
            })
            .then(() => {
                if (!this.props.handle) {
                    this.props.history.push('/workshops');
                }
            })
            .then(() =>
                this.setState({
                    submitSuccess: true,
                }),
            )
            .then(() => showAlert(SUCCESS, SUCCESS_MSG))
            .then(() => {
                if (
                    this.props.initialValues['kzStatusFreigabeAussteller'] === 1
                ) {
                    disableForm();
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_SAVE);
                this.setState({ submitSuccess: false });
                this.props.dispatch(stopSubmit('workshopeditbasis', e));
            });
    }
    render() {
        const showSonstige =
            this.state.showSonstige === true
                ? { display: 'inline-block' }
                : { display: 'none' };
        const { handleSubmit } = this.props;

        const remainingChars = (maxLength, field) => {
            console.log('remaining chars on: ', this.props);
            return Math.max(
                maxLength -
                    Number(
                        this.props?.workshopeditbasis?.values?.[field]
                            ?.length || 0,
                    ),
                0,
            );
        };

        return (
            <div className={style.header}>
                {this.props.initialValues.kzStatusFreigabeAussteller === 1 ? (
                    <div>Sie haben diese Daten bereits freigegeben</div>
                ) : null}
                {!this.props.workshop_deadline_within ? (
                    <div>
                        Sie können die Daten nur innerhalb des
                        Bearbeitungszeitraums bearbeiten
                    </div>
                ) : null}
                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <fieldset>
                        <legend />
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='workshop_id'
                            id='workshop_id'
                            hidden
                            disabled
                            value={this.props.handle || ''}
                            ref={this.input}
                        />
                        <label htmlFor='workshop_name'>
                            <span className={style.bold}>
                                Workshopbezeichnung (*)
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='workshop_name'
                            id='workshop_name'
                            maxLength={maxLengthWorkshopBezeichnung}
                            ref={this.input}
                        />
                        <label htmlFor='titel'>
                            <span className={style.bold}>
                                Titel (für Pressearbeit und Webseite)
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='titel'
                            id='titel'
                            maxLength={maxLengthWorkshopTitelInternet}
                            ref={this.input}
                        />
                        <legend>
                            Zeichen: Bitte geben Sie maximal{' '}
                            {maxLengthWorkshopTitelInternet} Zeichen ein. Es
                            bleiben noch{' '}
                            {remainingChars(
                                maxLengthWorkshopTitelInternet,
                                'titel',
                            )}{' '}
                            Zeichen.{' '}
                        </legend>
                        <label htmlFor='text'>
                            <span className={style.bold}>
                                Text (für Pressearbeit und Webseite)
                            </span>
                        </label>
                        <Field
                            component='textarea'
                            placeholder=''
                            type='text'
                            name='text'
                            rows='8'
                            maxLength={maxLengthWorkshopTextInternet}
                            style={{
                                resize: 'none',
                            }}
                            id='text'
                            ref={this.input}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <legend>
                            Zeichen: Bitte geben Sie maximal{' '}
                            {maxLengthWorkshopTextInternet} Zeichen ein. Es
                            bleiben noch{' '}
                            {remainingChars(
                                maxLengthWorkshopTextInternet,
                                'text',
                            )}{' '}
                            Zeichen.{' '}
                        </legend>
                        <label htmlFor='veranstalter'>
                            <span className={style.bold}>
                                Veranstalter (für Pressearbeit und Webseite)
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='veranstalter'
                            id='veranstalter'
                            ref={this.input}
                            maxLength={maxLengthWorkshopVeranstalter}
                        />
                        <label htmlFor='referent_name'>
                            <span className={style.bold}>
                                Name des Referenten
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='referent_name'
                            id='referent_name'
                            ref={this.input}
                            maxLength={maxLengthWorkshopNameReferenten}
                        />
                        <label htmlFor='referent_tel'>
                            <span className={style.bold}>
                                Tel. des Referenten
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='referent_tel'
                            id='referent_tel'
                            ref={this.input}
                            maxLength={maxLengthWorkshopTelefonReferent}
                        />
                        <label htmlFor='referent_email'>
                            <span className={style.bold}>
                                eMail des Referenten
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='email'
                            name='referent_email'
                            id='referent_email'
                            ref={this.input}
                            maxLength={maxLengthWorkshopEmailReferent}
                        />
                        <legend className={style.bold}>
                            Zielgruppe (max. 2 auswählbar)
                        </legend>
                        <p>
                            Sollten weitere Altersgruppen möglich sein, dann
                            wenden Sie sich bitte an Ihre/Ihren
                            AnsprechpartnerIn bei der IdeenExpo.
                        </p>
                        <Field
                            component='input'
                            type='checkbox'
                            id='zielgruppe56'
                            name='zielgruppe56'
                            onChange={(e) => this.handleInput(e)}
                        />
                        <label htmlFor='zielgruppe56'>
                            <span>5./6. Klasse</span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='zielgruppe78'
                            name='zielgruppe78'
                            onChange={(e) => this.handleInput(e)}
                        />
                        <label htmlFor='zielgruppe78'>
                            <span>7./8. Klasse</span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='zielgruppe910'
                            name='zielgruppe910'
                            onChange={(e) => this.handleInput(e)}
                        />
                        <label htmlFor='zielgruppe910'>
                            <span>9./10. Klasse</span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='zielgruppe1112'
                            name='zielgruppe1112'
                            onChange={(e) => this.handleInput(e)}
                        />
                        <label htmlFor='zielgruppe1112'>
                            <span>Jg. 11/12(13)</span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='zielgruppe_sonstige'
                            name='zielgruppe_sonstige'
                            onChange={(e) => this.handleInput(e)}
                        />
                        <label htmlFor='zielgruppe_sonstige'>
                            <span>Sonstige</span>
                        </label>
                        <div style={showSonstige}>
                            <label htmlFor='zielgruppe_sonstige_spez'>
                                <span>(bitte angeben)</span>
                            </label>
                            <Field
                                component='input'
                                placeholder=''
                                type='text'
                                name='zielgruppe_sonstige_spez'
                                id='zielgruppe_sonstige_spez'
                                maxLength={maxLengthWorkshopSonstigeZielgruppe}
                            />
                        </div>
                        <label htmlFor='teilnehmer_min'>
                            <span className={style.bold}>Teilnehmer min</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='number'
                            min='0'
                            name='teilnehmer_min'
                            id='teilnehmer_min'
                            ref={this.input}
                            max={maxLengthWorkshopTeilnehmerMin}
                        />
                        <label htmlFor='teilnehmer_max'>
                            <span className={style.bold}>Teilnehmer max</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='number'
                            min='0'
                            name='teilnehmer_max'
                            id='teilnehmer_max'
                            ref={this.input}
                            maxLength={maxLengthWorkshopTeilnehmerMax}
                        />
                        <legend className={style.bold}>Bezug zu</legend>
                        <Field
                            component='input'
                            type='checkbox'
                            id='bz_biologie'
                            name='bz_biologie'
                        />
                        <label htmlFor='bz_biologie'>
                            <span>Biologie</span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='bz_chemie'
                            name='bz_chemie'
                        />
                        <label htmlFor='bz_chemie'>
                            <span>Chemie</span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='bz_mathematik'
                            name='bz_mathematik'
                        />
                        <label htmlFor='bz_mathematik'>
                            <span>Mathematik/Informatik</span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='bz_medizin'
                            name='bz_medizin'
                        />
                        <label htmlFor='bz_medizin'>
                            <span>Medizin</span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='bz_physik'
                            name='bz_physik'
                        />
                        <label htmlFor='bz_physik'>
                            <span>Physik</span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='bz_technik'
                            name='bz_technik'
                        />
                        <label htmlFor='bz_technik'>
                            <span>Technik</span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='bz_umwelt'
                            name='bz_umwelt'
                        />
                        <label htmlFor='bz_umwelt'>
                            <span>Umwelt</span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='bz_berufsorientierung'
                            name='bz_berufsorientierung'
                        />
                        <label htmlFor='bz_berufsorientierung'>
                            <span>Berufsorientierung</span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='bz_sonstiges'
                            name='bz_sonstiges'
                        />
                        <label htmlFor='bz_sonstiges'>
                            <span>Sonstiges</span>
                        </label>
                        <label htmlFor='anmerkungenOrganisatorischerAblauf'>
                            <span className={style.bold}>
                                Anmerkungen zum organisatorischen Ablauf
                            </span>
                        </label>
                        <Field
                            component='textarea'
                            placeholder=''
                            type='text'
                            name='anmerkungenOrganisatorischerAblauf'
                            rows='8'
                            maxLength={maxLengthAnmerkungenAblauf}
                            style={{
                                resize: 'none',
                            }}
                            id='anmerkungenOrganisatorischerAblauf'
                            ref={this.input}
                        />
                        <FormButtons
                            showCancel={true}
                            overviewLink='/workshops'
                            hideBtn={true}
                            handleSubmit={handleSubmit}
                            submit={this.submit.bind(this)}
                        />
                        <legend>(*) = Pflichtfeld</legend>
                    </fieldset>
                </form>
                {/* <StepButtons back={'/workshops'} toTab={() => this.props.toTab(1)} disabled={!this.state.valid}  labelBack={'Liste der Workshops'} labelForward={'Zeitplanung'} /> */}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const Props = {
        veranstzielgruppe: state.veranstzielgruppe || [],
    };
    let initialValues = {};
    if (!props.handle) {
        return {
            ...Props,
            initialValues,
            workshop_deadline_within: state.deadlines?.workshop_deadline_within,
            workshopeditbasis: state.form?.workshopeditbasis,
        };
    } else {
        const found =
            state.workshop && Array.isArray(state.workshop)
                ? state.workshop.find((x) => x.ID === props.handle)
                : [];
        if (found && found.ID === props.handle) {
            initialValues = {
                workshop_id: props.handle || '',
                workshop_name: found.titel || '',
                titel: found.titelInternet || '',
                text: found.textInternet || '',
                veranstalter: found.veranstalter || '',
                referent_name: found.nameReferent || '',
                referent_tel: found.telefonummerReferent || '', //sic: telefonummer
                referent_email: found.emailReferent || '',
                zielgruppe56: found.kzKlasse56 === 1,
                zielgruppe78: found.kzKlasse78 === 1,
                zielgruppe910: found.kzKlasse910 === 1,
                zielgruppe1112: found.kzJahrgang111213 === 1,
                zielgruppe_sonstige: found.kzZielGruppeSonstige === 1,
                zielgruppe_sonstige_spez: found.sonstigeZielgruppe || '',
                bz_biologie: found.kzBiologie === 1,
                bz_chemie: found.kzChemie === 1,
                bz_mathematik: found.kzMathematikInformatik === 1,
                bz_medizin: found.kzMedizin === 1,
                bz_physik: found.kzPhysik === 1,
                bz_technik: found.kzTechnik === 1,
                bz_umwelt: found.kzUmwelt === 1,
                bz_berufsorientierung: found.kzBerufOrientierung === 1,
                bz_sonstiges: found.kzBezugSonstiges === 1,
                teilnehmer_min: found.teilnehmerMin || '',
                teilnehmer_max: found.teilnehmerMax || '',
                anmerkungen: found.sonstigeAnmerkungen || '',
                status: found.idStatus === 1 ? 'inbearbeitung' : '',
                kzStatusFreigabeAussteller:
                    found.kzStatusFreigabeAussteller || 0,
                anmerkungenOrganisatorischerAblauf:
                    found.anmerkungenOrganisatorischerAblauf || '',
            };
        }
        return {
            ...Props,
            initialValues: initialValues,
            workshop_deadline_within: state.deadlines?.workshop_deadline_within,
            found,
            workshopeditbasis: state.form?.workshopeditbasis,
        };
    }
};

export default compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'workshopeditbasis',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(WorkshopEditsBasis),
);
