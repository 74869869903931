import React from 'react';
import { Translate } from 'react-localize-redux';
import dayjs from 'dayjs';

import style from '../../common/index.module.css';
import { connect } from 'react-redux';
import StepButtons from '../../common/stepbuttons';

import Question from '../../common/question';
import {
    fetchMaxGroup,
    fetchSchoolTrackList,
    fetchSchoolTypeList,
} from '../../../actions/registration-system/registration-actions';
import { MAINTENANCE } from '../../../global_constants';

class Welcome extends Question {
    componentDidMount() {
        Promise.resolve().then(() => {
            this.props.dispatch(
                fetchSchoolTypeList(this.props.authenticationData),
            );
            this.props.dispatch(
                fetchSchoolTrackList(this.props.authenticationData),
            );
            return this.props.dispatch(fetchMaxGroup());
        });
    }

    renderQuestion() {
        if (MAINTENANCE.registration?.status === true) {
            return (
                <div className={style.question}>
                    <p>{MAINTENANCE.registration?.message}</p>
                </div>
            );
        }

        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>
                        <b>Mach doch einfach!</b>
                    </h1>
                    <p>
                        <strong>
                            Willkommen im Anmeldeportal
                            {this.props.authenticationData
                                ?.TpProjektLstItem?.[0] && (
                                <>
                                    {' der '}
                                    {
                                        this.props.authenticationData
                                            .TpProjektLstItem[0].name
                                    }{' '}
                                    (
                                    {dayjs(
                                        this.props.authenticationData
                                            .TpProjektLstItem[0].beginn,
                                    ).format('DD.')}{' '}
                                    bis{' '}
                                    {dayjs(
                                        this.props.authenticationData
                                            .TpProjektLstItem[0].ende,
                                    ).format('DD.MM.YYYY')}
                                    )
                                </>
                            )}
                        </strong>
                    </p>
                </div>
                <p>
                    Liebe Lehrerinnen und Lehrer, über dieses Anmeldeportal
                    können Sie Ihre Schulgruppen für einen Besuch auf der{' '}
                    <Translate id=''>
                        {this.props.authenticationData.TpProjektLstItem[0].name}
                    </Translate>{' '}
                    anmelden. Diese Anmeldung ist u.a. notwendig für
                    Workshopanmeldungen oder die Fahrtkostenunterstützung.
                    <br />
                    Dazu halten Sie bitte folgende Informationen bereit:
                </p>
                <ul className={style.indentedList}>
                    <li>PLZ der Schule</li>
                    <li>
                        Angaben zur Lerngruppe (max. {this.props.maxGroup}{' '}
                        Personen pro Gruppe, mehrere Anmeldungen möglich)
                    </li>
                    <li>
                        Reiseplanung (Reisetag, Verkehrsmittel, Ankunftszeit an
                        der Messe)
                    </li>
                    <li>
                        Bei Schulen mit Adresse außerhalb des GVH-Tarifgebiets
                        (Zonen ABC){' '}
                        <strong>bitte Bankdaten bereithalten</strong>. Hier
                        zählt die <u>Schuladresse</u>, nicht die
                        Starthaltestelle.
                    </li>
                </ul>
                <p>
                    Im Anschluss können Sie Ihre Daten noch einmal prüfen, bevor
                    Sie die Anmeldung absenden.
                </p>
                <StepButtons to='/school-selection' />
            </div>
        );
    }
}

export default connect((state) => state)(Welcome);
