import React from 'react';
import { connect } from 'react-redux';
import StepButtons from '../../common/stepbuttons';

const Deadline = (_props) => (
    <>
        <h1>
            <strong>Fehler</strong>
        </h1>
        <p>Sie können sich nur innerhalb des Anmeldezeitraums anmelden.</p>
        <StepButtons back='/' />
    </>
);
export default connect(
    (s) => ({
        invitationEditDeadline: s.invitation?.invitationEditDeadline || false,
    }),
    null,
)(Deadline);
