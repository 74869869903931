import React from 'react';

export default (specifics, ..._props) => {
    let faqHtml = null;
    if (specifics.faq === true) {
        faqHtml = (
            <a
                href='https://www.ideenexpo.de/faq'
                target='_blank'
                rel='noopener noreferrer'
            >
                FAQ
            </a>
        );
    }
    let dsgvoHtml = null;
    if (specifics.dsgvo === true) {
        dsgvoHtml = (
            <a
                href='https://dsgvo-gesetz.de/'
                target='_blank'
                rel='noopener noreferrer'
            >
                DSGVO
            </a>
        );
    }
    return (
        <div className='footer' id='footer'>
            <p>
                <a
                    href='https://www.ideenexpo.de/impressum'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    Impressum
                </a>
                <a
                    href='https://www.ideenexpo.de/datenschutz-haftungsausschluss'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    Datenschutz
                </a>
                {faqHtml}
                {dsgvoHtml}
            </p>
        </div>
    );
};
