import './polyfills';

import React from 'react';
import { AppContainer } from 'react-hot-loader';
import { LocalizeProvider } from 'react-localize-redux';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store, history, persistor } from './store';
import { Provider, ReactReduxContext } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReactNotification from 'react-notifications-component';

import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));
root.render(
    <AppContainer>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <LocalizeProvider store={store}>
                    <ReactNotification />
                    <App history={history} context={ReactReduxContext} />
                </LocalizeProvider>
            </PersistGate>
        </Provider>
    </AppContainer>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
