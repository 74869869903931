import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import Loader from '../../common/loader';
import { saveInvitation } from '../../../actions/invitation-system/invitation-actions.js';
import style from '../../common/index.module.css';

class Send extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invalid: false,
        };
        if (
            !this.props.event &&
            !this.props.formdata.attendance?.values &&
            !(
                this.props.formdata.attendance?.values?.attendance !== 'none' &&
                this.props.formdata.personals &&
                this.props.formdata.personals.values
            ) && // personals exists unless you chose not to come
            !(
                this.props.formdata.attendance?.values?.attendance ===
                    'representative' &&
                this.props.formdata.substitute &&
                this.props.formdata.substitute.values
            ) && // substitute exists if you chose to be represented
            !this.props.invitation?.ID
        ) {
            this.props.dispatch(push('/invalid'));
        } else {
            this.send();
        }
    }

    send() {
        saveInvitation(this);
    }

    render() {
        if (!this.state.invalid) {
            return (
                <div className={style.questionContainer}>
                    <div className={style.question}>
                        <Loader visible={true} />
                    </div>
                </div>
            );
        } else {
            return (
                <div className={style.questionContainer}>
                    <div className={style.question}>
                        <h1>
                            <strong>Fehler</strong> beim Absenden
                        </h1>
                        <p>
                            Es gab einen Fehler beim Speichern der Daten. Bitte
                            laden Sie die Seite neu und versuchen es erneut.
                        </p>
                    </div>
                </div>
            );
        }
    }
}

export default connect((s) => ({ formdata: s.form, ...s }))(Send);
