import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import style from '../index.module.css';

//import LeftArrow from "../../../images/right-arrow-b.svg";
//import RightArrow from "../../../images/left-arrow-b.svg";

class StepButtons extends Component {
    scrollToTop = () => {
        document.querySelector('.simplebar-content-wrapper').scrollTo(0, 0);
    };
    render() {
        const RightArrow = (
            <svg
                className='arrow-svg arrow--left'
                viewBox='0 0 492.004 492.004'
            >
                <title>Pfeil nach rechts</title>
                <path
                    d='M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136
									c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002
									v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864
									c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872
									l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z'
                />
            </svg>
        );

        const LeftArrow = (
            <svg
                className='arrow-svg arrow--right'
                viewBox='0 0 492.004 492.004'
            >
                <title>Pfeil nach links</title>
                <path
                    d='M464.344,207.418l0.768,0.168H135.888l103.496-103.724c5.068-5.064,7.848-11.924,7.848-19.124
									c0-7.2-2.78-14.012-7.848-19.088L223.28,49.538c-5.064-5.064-11.812-7.864-19.008-7.864c-7.2,0-13.952,2.78-19.016,7.844
									L7.844,226.914C2.76,231.998-0.02,238.77,0,245.974c-0.02,7.244,2.76,14.02,7.844,19.096l177.412,177.412
									c5.064,5.06,11.812,7.844,19.016,7.844c7.196,0,13.944-2.788,19.008-7.844l16.104-16.112c5.068-5.056,7.848-11.808,7.848-19.008
									c0-7.196-2.78-13.592-7.848-18.652L134.72,284.406h329.992c14.828,0,27.288-12.78,27.288-27.6v-22.788
									C492,219.198,479.172,207.418,464.344,207.418z'
                />
            </svg>
        );
        return (
            <div className={[style.buttonwrap, 'buttonwrap'].join(' ')}>
                {this.props.back ? (
                    <Link
                        className='button-left'
                        to={this.props.back}
                        onClick={() => {
                            document
                                .querySelector('.simplebar-content-wrapper')
                                .scrollTo(0, 0);
                        }}
                    >
                        {LeftArrow}Zurück zu {this.props.labelBack}
                    </Link>
                ) : null}

                {this.props.backTab ? (
                    <a
                        className='button-left'
                        onClick={() => {
                            this.scrollToTop();
                            this.props.backTab();
                        }}
                    >
                        {LeftArrow}Zurück zum Reiter {this.props.labelBack}
                    </a>
                ) : null}

                {this.props.to ? (
                    this.props.disabled ? (
                        <Link
                            className={`button-${
                                this.props.toText
                                    ? 'disabled'
                                    : 'right disabled'
                            }`}
                            to='#'
                        >
                            Weiter zu {this.props.labelForward}
                            {RightArrow}
                        </Link>
                    ) : (
                        <Link
                            className={`button-${
                                this.props.toText ? '' : 'right'
                            }`}
                            to={this.props.to}
                            onClick={() => {
                                document
                                    .querySelector('.simplebar-content-wrapper')
                                    .scrollTo(0, 0);
                            }}
                        >
                            Weiter zu {this.props.labelForward}
                            {RightArrow}
                        </Link>
                    )
                ) : null}

                {this.props.toTab ? (
                    this.props.disabled ? (
                        <a
                            className={`button-${
                                this.props.toText
                                    ? 'disabled'
                                    : 'right disabled'
                            }`}
                        >
                            Weiter zum Reiter {this.props.labelForward}
                            {RightArrow}
                        </a>
                    ) : (
                        <a
                            className={`button-${
                                this.props.toText ? '' : 'right'
                            }`}
                            onClick={() => {
                                this.scrollToTop();
                                this.props.toTab();
                            }}
                        >
                            Weiter zum Reiter {this.props.labelForward}
                            {RightArrow}
                        </a>
                    )
                ) : null}
            </div>
        );
    }
}

export default connect(null, null)(StepButtons);
