import React from 'react';
import { StateIndicator } from '../../state-indicator';

export const BoothStateEntry = (props) => {
    return (
        <ul className='sixCols'>
            <li className='schoolTable two-grid-approval booth-state'>
                <label>{props.label}</label>
                <label>
                    <StateIndicator state={props.status} label='' />
                </label>
            </li>
        </ul>
    );
};
