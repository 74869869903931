import React, { Component } from 'react';
import style from '../../../../exhibitor/index.module.css';
import {
    getEntity,
    deleteEntity,
} from '../../../../../actions/exhibitor/exhibitor-actions.js';
import { reduxForm, destroy } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import StepButtons from '../../../stepbuttons';
import {
    showAlert,
    ERROR,
    ERROR_MSG_BACKEND_FETCH,
    SUCCESS_DELETE,
    DELETE,
} from '../../../alerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

class Site extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
        };
    }

    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                if (
                    this.props.company &&
                    !Object.keys(this.props.company).length
                ) {
                    return this.props
                        .dispatch(getEntity('company'))
                        .then(null, (_e) => Promise.reject());
                }
            })
            .then(() => {
                if (
                    this.props.company2site &&
                    !Object.keys(this.props.company2site).length
                ) {
                    return this.props
                        .dispatch(getEntity('company2site'))
                        .then(null, (_e) => Promise.reject());
                }
            })
            .then(() =>
                this.setState({
                    submitSuccess: true,
                }),
            )
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    deleteItem = (json) => {
        Promise.resolve()
            .then(() => {
                return this.props.dispatch(deleteEntity(json, 'company2site'));
            })
            .then(() => {
                showAlert(DELETE, SUCCESS_DELETE(`Der Standort ${json.name}`));
            })
            .catch((e) => {
                showAlert(ERROR, `Element konnte nicht gelöscht werden. ${e}`);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    };
    render() {
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>Standorte</h1>
                </div>
                {Array.isArray(this.props.exhibitor) &&
                this.props.exhibitor.length >= 1
                    ? this.props.company
                          .filter(
                              (item) =>
                                  item.ID ===
                                  this.props.exhibitor[0].idAussteller,
                          )
                          .map((exhibitor, index) => (
                              <div key={index} className='exhibitorTableWrap'>
                                  <Link
                                      className='exhibitorTableButton small-button'
                                      to={{
                                          pathname: `/clubzukunft/career-market/sites/edit/${exhibitor.ID}`,
                                          state: { company_id: exhibitor.ID },
                                      }}
                                      onClick={() =>
                                          this.props.dispatch(
                                              destroy('siteedit'),
                                          )
                                      }
                                  >
                                      Neuer Standort
                                  </Link>
                                  <div className='schoolTableWrap'>
                                      <ul className='schoolTable sites-grid'>
                                          <div className='header'>Name</div>
                                          <div className='header'>Straße</div>
                                          <div className='header'>Ort</div>
                                          <div className='header' />
                                          <ul>
                                              {this.props.company2site &&
                                              Array.isArray(
                                                  this.props.company2site,
                                              )
                                                  ? this.props.company2site.map(
                                                          (site, ind) => (
                                                              <Link
                                                                  key={ind}
                                                                  to={{
                                                                      pathname:
                                                                          '/clubzukunft/career-market/sites/edit',
                                                                      state: {
                                                                          handle: site.ID,
                                                                      },
                                                                      search: `${site.ID}`,
                                                                  }}
                                                              >
                                                                  <li className='schoolTable sites-grid'>
                                                                      <label>
                                                                          {
                                                                              site.name
                                                                          }
                                                                      </label>
                                                                      <label>
                                                                          {
                                                                              site.strasse
                                                                          }
                                                                      </label>
                                                                      <label>
                                                                          {
                                                                              site.ort
                                                                          }
                                                                      </label>
                                                                      <div
                                                                          onClick={(
                                                                              e,
                                                                          ) => {
                                                                              e.preventDefault();
                                                                              if (
                                                                                  window.confirm(
                                                                                      `Wollen Sie den Eintrag "${site.name}" wirklich löschen?`,
                                                                                  )
                                                                              ) {
                                                                                  this.deleteItem(
                                                                                      site,
                                                                                  );
                                                                              }
                                                                          }}
                                                                      >
                                                                          <FontAwesomeIcon
                                                                              icon={
                                                                                  faTrash
                                                                              }
                                                                              size='1x'
                                                                          />
                                                                      </div>
                                                                  </li>
                                                              </Link>
                                                          ),
                                                      )
                                                  : null}
                                          </ul>
                                      </ul>
                                  </div>
                              </div>
                          ))
                    : null}
                <StepButtons
                    back={'/clubzukunft/career-market/contact'}
                    to={'/clubzukunft/career-market/training'}
                    labelBack={'Kontaktdaten'}
                    labelForward={'Ausbildungsberufe'}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, _prop) => ({
    company: state.company || [],
    exhibitor: state.exhibitor || [],
    company2site: state.company2site || [],
});

export default reduxForm({
    form: 'site',
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(connect(mapStateToProps, null)(Site));
