import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import StepButtons from '../../common/stepbuttons';
import style from '../../common/index.module.css';
import Question from '../../common/question';

class Personals extends Question {
    // rome-ignore lint/correctness/noUnreachableSuper: <explanation>möglicherweise ein falsch-positiver Treffer bzw. Rome-Bug
    constructor(props) {
        super(props, ['form.login.values.loginkey'], {});
        if (this.state.ready) {
            this.title = React.createRef();
            this.firstname = React.createRef();
            this.lastname = React.createRef();
            this.mainCompany = React.createRef();
            this.location = React.createRef();

            this.readOnly =
                this.props.formdata.attendance.values.attendance === 'self';
            if (!this.props.anonymous) {
                this.changeProps();
            }
        }
    }

    changeProps() {
        const values = this.props.formdata.personals.values;
        const personDataMap = [
            ['salutation', 'idAnrede'],
            ['title', 'titel'],
            ['firstname', 'vorname'],
            ['lastname', 'nachname'],
        ];
        personDataMap.forEach((mapping) => {
            if (!values || typeof values[mapping[0]] !== 'undefined') {
                if (
                    this.props.person[mapping[1]] != null &&
                    this.props.person[mapping[1]] !== ''
                ) {
                    this.props.change(
                        mapping[0],
                        this.props.person[mapping[1]],
                    );
                }
            }
        });
        const firma2PersonLst =
            this.props.person?.tp_Person_2_Firma2PersonLst
                ?.TpFirma2PersonLstItem;
        const isFirma2PersonLstNotEmpty =
            Array.isArray(firma2PersonLst) && firma2PersonLst.length > 0;
        if (isFirma2PersonLstNotEmpty) {
            const mainCompany2PersonLst = firma2PersonLst.filter(
                this.isMainCompany,
            );
            const isMainCompany2PersonLstNotEmpty =
                mainCompany2PersonLst.length > 0;
            const firma2PersonData = isMainCompany2PersonLstNotEmpty
                ? mainCompany2PersonLst[0]
                : firma2PersonLst[0];
            if (firma2PersonData) {
                if (isMainCompany2PersonLstNotEmpty) {
                    this.props.change('mainCompany', firma2PersonData.firma);
                }
                this.props.change('location', firma2PersonData.txtStandort);
            }
        }
    }

    isMainCompany(mainCompanyCandidate) {
        return mainCompanyCandidate?.kzHauptFirma === 1;
    }

    renderQuestion() {
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>Persönliche Daten</h1>
                    <p>
                        Folgende Daten haben wir von Ihnen hinterlegt. Falls
                        dies nicht mehr aktuell ist, bitten wir um eine
                        entsprechende Info-Mail an info@ideenexpo.de
                    </p>
                    {this.props.formdata?.attendance?.values?.attendance !==
                    'self' ? (
                        <p>
                            Im nächsten Schritt können Sie dann die Daten Ihrer
                            Vertretung angeben.
                        </p>
                    ) : null}
                </div>
                <form>
                    <fieldset>
                        <label htmlFor='salutation'>Anrede</label>
                        <Field
                            component='select'
                            type='select'
                            name='salutation'
                            disabled='true'
                        >
                            <option hidden value=''>
                                Bitte auswählen
                            </option>
                            <option value='1'>Herr</option>
                            <option value='2'>Frau</option>
                        </Field>
                        <label htmlFor='title'>Titel</label>
                        <Field
                            component='input'
                            type='text'
                            name='title'
                            disabled='true'
                            ref={this.title}
                        />
                        <label htmlFor='firstname'>Vorname</label>
                        <Field
                            component='input'
                            type='text'
                            name='firstname'
                            disabled='true'
                            ref={this.firstname}
                        />
                        <label htmlFor='lastname'>Nachname</label>
                        <Field
                            component='input'
                            type='text'
                            name='lastname'
                            disabled='true'
                            ref={this.lastname}
                        />
                        <label htmlFor='mainCompany'>Hauptfirma</label>
                        <Field
                            component='input'
                            type='text'
                            name='mainCompany'
                            disabled='true'
                            ref={this.mainCompany}
                        />
                        <label htmlFor='location'>Standort / Anschrift</label>
                        <Field
                            component='input'
                            type='text'
                            name='location'
                            disabled='true'
                            ref={this.location}
                        />
                    </fieldset>
                </form>
                <StepButtons
                    back='/attendance'
                    to={
                        this.props.formdata.attendance?.values?.attendance ===
                        'self'
                            ? '/guests'
                            : '/substitute'
                    }
                />
            </div>
        );
    }
}

export default reduxForm({
    form: 'personals',
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(connect((s) => ({ formdata: s.form, ...s }))(Personals));
