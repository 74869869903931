import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import style from '../../../../index.module.css';
import FormButtons from '../../../../formbuttons';
import {
    updateSite,
    insertSite,
} from '../../../../../../actions/exhibitor/site';
import {
    showAlert,
    SUCCESS,
    SUCCESS_MSG,
    ERROR,
    ERROR_MSG_BACKEND_SAVE,
    ERROR_MSG_BACKEND_FETCH,
    validateForm,
    ERROR_ZIPCODE_FORMAT,
    ERROR_LOCATION_FORMAT,
} from '../../../../alerts';
import { getEntity } from '../../../../../../actions/exhibitor/exhibitor-actions.js';
import {
    LOCATION_PATTERN,
    ZIPCODE_PATTERN,
} from '../../../../../../global_constants';
import { push } from 'connected-react-router';

class SitesEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            submitSuccess: false,
        };
    }
    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                if (this.props.company) {
                    return this.props
                        .dispatch(getEntity('company'))
                        .then(null, (_e) => Promise.reject());
                }
            })
            .then(() => {
                if (this.props.company2site) {
                    return this.props
                        .dispatch(getEntity('company2site'))
                        .then(null, (_e) => Promise.reject());
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    handleInput(_e) {
        this.setState({ valid: true });
    }

    resetForm() {
        this.setState({
            submitSuccess: false,
        });
    }

    validate(values) {
        if (!ZIPCODE_PATTERN.test(values.plz)) {
            showAlert(ERROR, ERROR_ZIPCODE_FORMAT);
            throw new SubmissionError({});
        }
        if (!LOCATION_PATTERN.test(values.ort)) {
            showAlert(ERROR, ERROR_LOCATION_FORMAT);
            throw new SubmissionError({});
        }
    }

    submit(values) {
        this.validate(values);
        validateForm(this, values, [
            'name',
            'street',
            'hnr',
            'plz',
            'ort',
            'webseite',
        ]);
        Promise.resolve()
            .then(() => {
                if (this.props.handle) {
                    return this.props
                        .dispatch(updateSite(this.props.found, values))
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                } else {
                    // if creating a new site
                    return this.props
                        .dispatch(insertSite(values))
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                }
            })
            .then((json) => {
                if (!this.props.handle) {
                    this.props.history.push(
                        `/clubzukunft/career-market/sites/edit?${json.company2site.ID}`,
                    );
                }
            })
            .then(() =>
                this.setState({
                    submitSuccess: true,
                }),
            )
            .then(() => showAlert(SUCCESS, SUCCESS_MSG))
            .then(() => {
                // gewünscht war nach dem Speichern zur Übersichtsseite zurückzukommen
                this.props.dispatch(push('/clubzukunft/career-market/sites/'));
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_SAVE);
                this.setState({ submitSuccess: false });
                this.props.dispatch(stopSubmit('siteedit', e));
            });
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <div className={style.question}>
                <div className={style.header}>
                    {this.props.found ? (
                        <h1>Standort {this.props.found.name} bearbeiten</h1>
                    ) : (
                        <h1>Standorte neu anlegen</h1>
                    )}
                </div>
                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <fieldset>
                        <legend />
                        <label htmlFor='name'>
                            <span>Name des Standorts (*)</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='name'
                            id='name'
                            ref={this.input}
                        />
                        <label htmlFor='name'>
                            <span>Straße (*)</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='street'
                            id='street'
                            ref={this.input}
                        />
                        <label htmlFor='hnr'>
                            <span>Hausnummer (*)</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='hnr'
                            id='hnr'
                            ref={this.input}
                        />
                        <label htmlFor='hnr'>
                            <span>Plz (*)</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='plz'
                            id='plz'
                            ref={this.input}
                        />
                        <label htmlFor='ort'>
                            <span>Ort (*)</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='ort'
                            id='ort'
                            ref={this.input}
                        />
                        <label htmlFor='ort'>
                            <span>Webseite (*)</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='webseite'
                            id='webseite'
                            ref={this.input}
                        />
                        <FormButtons
                            showCancel={true}
                            overviewLink='/clubzukunft/career-market/sites'
                            hideBtn={true}
                            handleSubmit={handleSubmit}
                            submit={this.submit.bind(this)}
                        />
                        <legend>(*) = Pflichtfeld</legend>
                    </fieldset>
                </form>
                {/* <StepButtons back={'/clubzukunft/career-market/sites'} to={'/clubzukunft/career-market/training'} labelBack={'Liste der Standorte'} labelForward={'Ausbildungsberufe'} /> */}
            </div>
        );
    }
}

const mapStateToProps = (state, _props) => {
    const Props = {
        handle:
            state.router?.location?.state?.handle ||
            parseInt((state.router?.location?.search || '?').substring(1)),
        company: state.company && !Object.keys(state.company).length,
        company2site:
            state.company2site && !Object.keys(state.company2site).length,
    };
    if (typeof Props.handle === 'undefined') {
        return { ...Props };
    } else {
        const found =
            state.company2site && Array.isArray(state.company2site)
                ? (state.company2site || []).find((x) => x.ID === Props.handle)
                : [];
        let initialValues = {};
        if (found) {
            initialValues = {
                name: found.name || '',
                street: found.strasse || '',
                hnr: found.hausnummer || '',
                plz: found.PLZ || '',
                ort: found.ort || '',
                webseite: found.websiteUrl || '',
            };
        }
        return {
            ...Props,
            initialValues: initialValues,
            found,
        };
    }
};

export default compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'siteedit',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(SitesEdit),
);
