import { ENV } from '../../../../global_constants';
import { store } from '../../../../store';

import { createEntityConfig } from '../../../../actions/exhibitor/fetcher/entity-list-detail.fetcher';
import {
    fetchDeleteEntityFromConfig,
    fetchInsertEntityFromConfig,
    fetchNewEntityFromConfig,
    fetchSingleEntityFromConfig,
    fetchUpdateEntityFromConfig,
} from '../../../../actions/exhibitor/fetcher/fetcher';
import { ERROR, ERROR_UNAUTHORIZED, SUCCESS, showAlert } from '../../alerts';

export const mapBannerPrint = ({
    ID,
    beschreibung,
    bannerPrintDatei,
    data4BannerPrintDatei,
    kzFreigabeAussteller,
    idOrganisation,
}) => {
    return {
        ID,
        beschreibung,
        bannerPrintDatei,
        data4BannerPrintDatei,
        kzFreigabeAussteller,
        idOrganisation,
    };
};

export const deleteBannerPrint = async (id) => {
    const config = createEntityConfig('bannerPrint');

    try {
        await fetchDeleteEntityFromConfig(config, id);
        showAlert(SUCCESS, 'Banner wurde gelöscht.');
    } catch (e) {
        ENV === 'development' && console.log('error occured', e);
        if (e === 'Unauthorized') {
            showAlert(ERROR, ERROR_UNAUTHORIZED);
            return;
        }
        showAlert(ERROR, 'Banner konnte nicht gelöscht werden.');
    }
};

export const approveBannerPrint = async (id) => {
    const config = createEntityConfig('bannerPrint');

    try {
        const obj = await fetchSingleEntityFromConfig(config, id);

        await fetchUpdateEntityFromConfig(config, {
            ...obj,
            kzFreigabeAussteller: 1,
        });
        showAlert(SUCCESS, 'Banner wurde freigegeben.');
    } catch (e) {
        ENV === 'development' && console.log('error occured', e);
        if (e === 'Unauthorized') {
            showAlert(ERROR, ERROR_UNAUTHORIZED);
            return;
        }
        showAlert(ERROR, 'Banner konnte nicht freigegeben werden.');
    }
};

export const updateBannerPrint = async (obj) => {
    const config = createEntityConfig('bannerPrint');

    try {
        await fetchUpdateEntityFromConfig(config, {
            ...obj,
        });
        showAlert(SUCCESS, 'Banner wurde aktualisiert.');
    } catch (e) {
        ENV === 'development' && console.log('error occured', e);
        if (e === 'Unauthorized') {
            showAlert(ERROR, ERROR_UNAUTHORIZED);
            return;
        }
        showAlert(ERROR, 'Banner konnte nicht aktualisiert werden.');
    }
};

export const insertBannerPrint = async (values) => {
    const config = createEntityConfig('bannerPrint');
    const authData = store.getState().authenticationDataExhibitor;

    try {
        const newEntity = fetchNewEntityFromConfig(config, authData);
        const merged = { ...newEntity, ...values };

        const res = await fetchInsertEntityFromConfig(config, merged);
        console.log('insert response:', res);
        showAlert(SUCCESS, 'Neues Banner wurde gespeichert.');
    } catch (e) {
        ENV === 'development' && console.log('error occured', e);
        if (e === 'Unauthorized') {
            showAlert(ERROR, ERROR_UNAUTHORIZED);
            return;
        }
        showAlert(ERROR, 'Neues Banner konnte nicht gespeichert werden.');
    }
};
