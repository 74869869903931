import { store } from '../../../store';
import {
    BACKEND_URL,
    ENV,
    STUDYFIELD_SAVE,
    STUDYFIELD_ADD,
    STUDYFIELD_NEW,
    TRAINING_SAVE,
    TRAINING_ADD,
    TRAINING_NEW,
    DUALSTUDY_SAVE,
    DUALSTUDY_ADD,
    DUALSTUDY_NEW,
} from '../../../global_constants';

const mapValues = (values, list) => {
    let newValues;
    switch (list) {
        case 'training': {
            newValues = Object.assign(
                {},
                {
                    kzAusbildung: values.kzAusbildung ? 1 : 0,
                    kzPraktikum: values.kzPraktikum ? 1 : 0,
                },
            );
            break;
        }
        case 'studyfield': {
            newValues = Object.assign(
                {},
                {
                    kzAbschlussarbeit: values.kzAbschlussarbeit ? 1 : 0,
                    kzEinstiegsposition: values.kzEinstiegsposition ? 1 : 0,
                    kzPraktikum: values.kzPraktikum ? 1 : 0,
                    kzStudiengang: values.kzStudiengang ? 1 : 0,
                },
            );
            break;
        }
        case 'dualstudy': {
            newValues = Object.assign(
                {},
                {
                    kzAngeboten: values.kzAngeboten ? 1 : 0,
                },
            );
            break;
        }
        default:
            break;
    }
    return newValues;
};

export const insertClubzukunftList = (values, id, list) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    const getState = store.getState();
    const exhibitor =
        Array.isArray(getState.exhibitor) && getState.exhibitor.length >= 1
            ? getState.exhibitor[0]
            : {};
    return function (_dispatch) {
        const newValues = mapValues(values, list);

        let newEndpoint;
        let addEndpoint;
        let listName;
        let idName;
        switch (list) {
            case 'training': {
                newEndpoint = TRAINING_NEW;
                addEndpoint = TRAINING_ADD;
                listName = 'Training';
                idName = 'idAusbildungsberuf';
                break;
            }
            case 'studyfield': {
                newEndpoint = STUDYFIELD_NEW;
                addEndpoint = STUDYFIELD_ADD;
                listName = 'Studyfield';
                idName = 'idStudienrichtung';
                break;
            }
            case 'dualstudy': {
                newEndpoint = DUALSTUDY_NEW;
                addEndpoint = DUALSTUDY_ADD;
                listName = 'Dualstudy';
                idName = 'idDualesStudium';
                break;
            }
            default:
                break;
        }
        // request a new (Studienrichtung|Ausbildungsberuf) prototype
        return fetch(BACKEND_URL + newEndpoint, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData,
                    userId4Hist: undefined,
                },
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                // merge/overwrite requested json prototype with user input
                const jsonNew = { ...json, ...newValues };
                /*
			jsonNew.USER_ID_CHANGE = `${authenticationData.userIdLogin}`
			jsonNew.USER_ID_NEW = `${authenticationData.userIdLogin}`
			jsonNew.USER_NR_CHANGE = authenticationData.userId
			jsonNew.USER_NR_NEW = authenticationData.userId*/
                jsonNew.idFirma = exhibitor.idAussteller;
                jsonNew[idName] = id;
                return fetch(BACKEND_URL + addEndpoint, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authenticationData.accessToken}`,
                    },
                    body: JSON.stringify({
                        ...jsonNew,
                        login: {
                            // TODO: pick properties manually!
                            ...authenticationData,
                            userId4Hist: undefined,
                        },
                    }),
                });
            })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info(`${listName} details fetch:`, json);
                // TODO: check if already expired or already answered!
                return json;
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                return Promise.reject(e);
            });
    };
};

export const updateClubzukunftList = (json, values, list) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    return function (_dispatch) {
        const newValues = mapValues(values, list);
        const jsonNew = { ...json, ...newValues };

        let saveEndpoint;
        let listName;
        switch (list) {
            case 'training': {
                saveEndpoint = TRAINING_SAVE;
                listName = 'Training';
                break;
            }
            case 'studyfield': {
                saveEndpoint = STUDYFIELD_SAVE;
                listName = 'Studyfield';
                break;
            }
            case 'dualstudy': {
                saveEndpoint = DUALSTUDY_SAVE;
                listName = 'DualStudy';
                break;
            }
            default:
                break;
        }
        return fetch(BACKEND_URL + saveEndpoint, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(jsonNew),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info(`${listName} details fetch:`, json);
                if (!json.ID) {
                    return Promise.reject(
                        `${listName} details fetch validity check: fail`,
                    );
                }
                return json;
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                return Promise.reject(e);
            });
    };
};
