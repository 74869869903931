import { stopSubmit } from 'redux-form';

import {
    updateExhibit,
    insertExhibit,
} from '../../../../../actions/exhibitor/exhibit';
import UploadFile from '../../../uploadfile';
import disableForm from '../../../disableForm.js';
import {
    SUCCESS,
    showAlert,
    SUCCESS_MSG,
    ERROR,
    ERROR_MSG_BACKEND_SAVE,
    ERROR_MSG_UPLOAD,
} from '../../../alerts';

export default function submit(
    valuesBasis,
    valuesDescriptionParam,
    valuesSpecifications,
    that,
    home,
) {
    let valuesDescription = { ...valuesDescriptionParam };
    // validateForm(that, {...valuesBasis, ...valuesSpecifications, ...valuesDescription}, ["status", "exponat_titel" ], that.props.exhibit_deadline_within);

    Promise.all(
        Object.entries(valuesDescription.files).map((file) => {
            //logos
            // resolve logo images
            return new Promise((resolve, reject) => {
                if (file[1].file && typeof file[1].file === 'object') {
                    return UploadFile(file[1].file)
                        .then((img) => {
                            valuesDescription = {
                                ...valuesDescription,
                                [file[1].imageField]: img.file,
                                [file[1].nameField]: img.name,
                            };
                            resolve(img);
                        })
                        .catch((_result) => {
                            reject({ _error: ERROR_MSG_UPLOAD });
                        });
                } else {
                    resolve();
                }
            });
        }),
    )
        .then(() => {
            // if updating an existing exhibit
            if (that.props.handle) {
                return that.props
                    .dispatch(
                        updateExhibit(that.props.found, {
                            ...valuesBasis,
                            ...valuesSpecifications,
                            ...valuesDescription,
                            idStand: that.props.idStand,
                        }),
                    )
                    .then(null, (_e) =>
                        Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                    );
            } else {
                // if creating a new exhibit
                return that.props
                    .dispatch(
                        insertExhibit({
                            ...valuesBasis,
                            ...valuesSpecifications,
                            ...valuesDescription,
                            idStand: that.props.idStand,
                        }),
                    )
                    .then(null, (_e) =>
                        Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                    );
            }
        })
        .then((json) => {
            if (!that.props.handle) {
                // BT 2022-01-04: So muss die Adresszeile aussehen!
                that.props.history.push(
                    `/booths/exhibits/edit?${json.exhibit.ID}`,
                );
            }
        })
        .then(() =>
            that.setState({
                submitSuccess: true,
                reset: that.state.reset + 1 || 0, //logos
            }),
        )
        .then(() => {
            showAlert(SUCCESS, SUCCESS_MSG);
        })
        .then(() => {
            if (that.props.initialValues['status'] === 'freigegeben') {
                disableForm();
            }
        })
        .then(() => {
            if (home) {
                const url = window.location.href;
                const newUrl = url.substring(0, url.indexOf('edit'));
                window.location.href = newUrl;
            }
        })
        .catch((e) => {
            showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_SAVE);
            that.setState({ submitSuccess: false });
            that.props.dispatch(stopSubmit('exhibitseditdescription', e));
        });
}
