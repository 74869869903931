import React from 'react';
import style from '../../exhibitor/index.module.css';
import Invalid from '../check/invalid.jsx';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
    }

    render() {
        if (this.state.errorInfo) {
            // You can render any custom fallback UI
            return (
                <div className={style.question}>
                    <Invalid
                        reason='Ein interner Fehler ist aufgetreten! Wenn dies zum wiederholten Mal auftritt, zögern Sie nicht uns unter idee@ideenexpo.de zu kontaktieren.'
                        forwardTo='/home'
                    />
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
