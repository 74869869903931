import React from 'react';
import style from '../../index.module.css';
import StepButtons from '../../stepbuttons';
import { connect } from 'react-redux';
import {
    getForwardActionForRoles,
    getBackwardActionForRoles,
    determineAuthForRoles,
} from '../../functions';

const CareerMarket = (props) => {
    const forwardAction = getForwardActionForRoles('contactdata', props);
    const backwardAction = getBackwardActionForRoles('careerstage', props);

    return (
        <div className={style.question}>
            <h1>KarriereMarkt</h1>
            <p>
                Der KarriereMarkt bietet BesucherInnen die Möglichkeit, sich
                über die Ausbildungsberufe und Studienmöglichkeiten der
                Aussteller zu informieren.
            </p>
            <p>
                Sowohl auf der Homepage der IdeenExpo, als auch im ClubZukunft
                auf der Veranstaltung, haben BesucherInnen die Möglichkeit, sich
                die Ausbildungsberufe und Studiengänge unserer Aussteller und
                deren Standorte auf der Veranstaltung anzeigen zu lassen.
            </p>
            <p>BesucherInnen können nach:</p>
            <ul>
                <li>Ausbildungsberufen</li>
                <li>Studiengängen</li>
                <li>Institutionen</li>
                <li>Unternehmens- und Hochschulstandorten</li>
            </ul>
            <p>
                filtern und Kontakte zu den Ausstellern aufnehmen. Auch
                Studierende profitieren vom KarriereMarkt. Hier erhalten Sie
                eine Übersicht über Praktika, Abschlussarbeiten und
                Einstiegspositionen.
            </p>
            <StepButtons
                back={backwardAction.backwardAction}
                to={forwardAction.forwardAction}
                labelBack={backwardAction.backwardText}
                labelForward={forwardAction.forwardText}
            />
        </div>
    );
};

const mapStateToProps = (state, _prop) => {
    // Ermittlung der Rollenberechtigungen des angemeldeten Users
    const userAuth = determineAuthForRoles(state);
    return {
        hasBoothAuthorization: userAuth.hasBoothAuthorization,
        hasWorkshopAuthorization: userAuth.hasWorkshopAuthorization,
        hasLiveprogramAuthorization: userAuth.hasLiveprogramAuthorization,
        hasClubzukunftAuthorization: userAuth.hasClubzukunftAuthorization,
        hasOrganisationAuthorization: userAuth.hasOrganisationAuthorization,
        hasCarreerStageAuthorization: userAuth.hasCarreerStageAuthorization,
    };
};

export default connect(mapStateToProps, null)(CareerMarket);
