import { store } from '../../../store';
import {
    BACKEND_URL,
    ENV,
    COMPANY2SITE_SAVE,
    COMPANY2SITE_ADD,
    COMPANY2SITE_NEW,
} from '../../../global_constants';
import { setCompany2siteUpdate } from '../exhibitor-actions.js';
import {
    showAlert,
    ERROR_UNAUTHORIZED,
    ERROR,
} from '../../../components/exhibitor/alerts';

const mapSite = (values, part) => {
    let newValues;
    switch (part) {
        default: {
            newValues = Object.assign(
                {},
                {
                    name: values.name || '',
                    strasse: values.street || '',
                    hausnummer: values.hnr || '',
                    PLZ: values.plz || '',
                    ort: values.ort || '',
                    websiteUrl: values.webseite || '',
                },
            );
            break;
        }
    }
    return newValues;
};

export const updateSite = (json, values, _part) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    return function (dispatch) {
        ENV === 'development' &&
            console.info('updating the site with id = ', json.ID);
        const newValues = mapSite(values);
        const jsonNew = { ...json, ...newValues };
        return fetch(BACKEND_URL + COMPANY2SITE_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(jsonNew),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('site details fetch:', json);
                if (!json.ID) {
                    return Promise.reject(
                        'site details fetch validity check: fail',
                    );
                }
                return json;
            })
            .then((json) => {
                return dispatch(setCompany2siteUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};

export const insertSite = (values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    const getState = store.getState();
    const exhibitor =
        Array.isArray(getState.exhibitor) && getState.exhibitor.length >= 1
            ? getState.exhibitor[0]
            : {};
    return function (dispatch) {
        const newValues = mapSite(values);
        // request a new prototype
        return fetch(BACKEND_URL + COMPANY2SITE_NEW, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData,
                    userId4Hist: undefined,
                },
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                // merge/overwrite requested json prototype with user input
                const jsonNew = { ...json, ...newValues };
                jsonNew.idProjekt = getState.authenticationData
                    ?.TpProjektLstItem
                    ? getState.authenticationData.TpProjektLstItem[0].ID
                    : null;
                jsonNew.idFirma = exhibitor.idAussteller;
                jsonNew.txtProjekt = getState.authenticationData
                    ?.TpProjektLstItem
                    ? getState.authenticationData.TpProjektLstItem[0].name
                    : null;
                return fetch(BACKEND_URL + COMPANY2SITE_ADD, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authenticationData.accessToken}`,
                    },
                    body: JSON.stringify({
                        ...jsonNew,
                        login: {
                            // TODO: pick properties manually!
                            ...authenticationData,
                            userId4Hist: undefined,
                        },
                    }),
                });
            })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('site details fetch:', json);
                return json;
            })
            .then((json) => {
                return dispatch(setCompany2siteUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};
