import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import style from '../../index.module.css';
import StepButtons from '../../stepbuttons';
import {
    getEntity,
    deleteEntity,
} from '../../../../actions/exhibitor/exhibitor-actions.js';
import {
    showAlert,
    ERROR,
    ERROR_MSG_BACKEND_FETCH,
    SUCCESS_DELETE,
    DELETE,
} from '../../alerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { approveItem } from '../../functions';

class CareerStage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
        };
    }
    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                if (
                    this.props.careerstage &&
                    Object.keys(this.props.careerstage).length
                ) {
                    return Promise.resolve();
                } else {
                    return this.props.dispatch(getEntity('careerstage'));
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }
    deleteItem = (json) => {
        Promise.resolve()
            .then(() => {
                return this.props.dispatch(deleteEntity(json, 'careerstage'));
            })
            .then(() => {
                showAlert(
                    DELETE,
                    SUCCESS_DELETE(`Die KarriereBühne ${json.titelBeitrag}`),
                );
            })
            .catch((e) => {
                showAlert(ERROR, `Element konnte nicht gelöscht werden. ${e}`);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    };
    render() {
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>
                        KarriereBühne im ClubZukunft (
                        {this.props.careerStagePlace
                            ? this.props.careerStagePlace
                            : 'IdeenHalle 9'}
                        )
                    </h1>
                    <p>
                        Auf der KarriereBühne im ClubZukunft (
                        {this.props.careerStagePlace
                            ? this.props.careerStagePlace
                            : 'IdeenHalle 9'}
                        ) können Sie Beiträge rund um die Themen
                        Berufsorientierung, Ausbildungsmöglichkeiten, Karriere
                        etc. präsentieren.
                    </p>
                    <p>
                        Bitte beachten Sie, dass wir Ihre Wünsche{' '}
                        <strong>bis zum {this.props.stages_deadline}</strong>{' '}
                        benötigen. Nur so können wir gewährleisten, dass Ihre
                        Angebote am 21. Februar 2024 mit allen weiteren
                        Angeboten (Workshops, Exponate, LiveProgramm) auf
                        unserer Homepage veröffentlicht werden. Anmeldungen, die
                        uns später erreichen, werden nicht bei der ersten
                        Veröffentlichung dabei sein. BesucherInnen der
                        IdeenExpo-Website können Ihre Bühnenangebote dann erst
                        zu einem späteren Zeitpunkt sehen. Falls es sich um ein
                        von Schulklassen buchbares Angebot handelt, ist die
                        Buchung auch erst zu einem späteren Zeitpunkt möglich.
                    </p>
                </div>
                <div className='exhibitorTableWrap'>
                    <span className='exhibitorTableHeader'>Beitrag</span>
                    {this.props.stages_deadline_within === true ? (
                        <Link
                            className='exhibitorTableButton small-button'
                            to={'/clubzukunft/career-stage/edit/'}
                            destroy={
                                'careerstageeditbasis, careerstageeditcontact'
                            }
                        >
                            Neuer Eintrag
                        </Link>
                    ) : null}
                    <div className='schoolTableWrap'>
                        <ul className='schoolTable four-gridlistpages'>
                            <div className='header'>Titel</div>
                            <div className='header'>Ansprechpartner</div>
                            <div className='header'>Freigabe</div>
                            <div className='header' />

                            {this.props.careerstage.map((item) => (
                                <ul key={item.ID}>
                                    <Link
                                        to={{
                                            pathname:
                                                '/clubzukunft/career-stage/edit',
                                            state: {
                                                handle: item.ID,
                                            },
                                            search: `${item.ID}`,
                                        }}
                                    >
                                        <li className='schoolTable four-gridlistpages'>
                                            <label>{item.titelBeitrag}</label>
                                            <label>
                                                {item.nameAnsprechpartner}
                                            </label>
                                            {item.kzFreigabeAussteller ? (
                                                <div>Ja</div>
                                            ) : (
                                                <div>
                                                    <a
                                                        className='button approve'
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            approveItem(
                                                                this,
                                                                'karrierebühne',
                                                                item,
                                                            );
                                                        }}
                                                    >
                                                        Freigeben
                                                    </a>
                                                </div>
                                            )}
                                            {item.kzFreigabeAussteller ? (
                                                <div />
                                            ) : (
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (
                                                            window.confirm(
                                                                `Wollen Sie den Eintrag "${item.titelBeitrag}" wirklich löschen?`,
                                                            )
                                                        ) {
                                                            this.deleteItem(
                                                                item,
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                        size='1x'
                                                    />
                                                </div>
                                            )}
                                        </li>
                                    </Link>
                                </ul>
                            ))}
                        </ul>
                    </div>
                </div>
                <StepButtons
                    back={'/clubzukunft'}
                    to={'/clubzukunft/career-market'}
                    labelBack={'ClubZukunft'}
                    labelForward={'KarriereMarkt'}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, _prop) => ({
    careerstage: state.careerstage || [],
    stages_deadline: state.deadlines?.stages_deadline,
    stages_deadline_within: state.deadlines?.stages_deadline_within,
});
export default connect(
    mapStateToProps,
    null,
)(
    reduxForm({
        form: 'careerstage',
        destroyOnUnmount: false,
        enableReinitialize: true,
    })(CareerStage),
);
