import { store } from '../../store';
import { push } from 'connected-react-router';
import {
    BACKEND_URL,
    INVITATION_LIST,
    INVITATION_GET,
    INVITATION_SAVE,
    PERSON_GET,
    PERSON_SAVE,
    PERSON_ADD,
    DATETIME_PATTERN,
    ENV,
    GUEST_GET,
    GUEST_LIST,
    GUEST_ADD,
    GUEST_SAVE,
    GUEST_DELETE,
} from '../../global_constants';

export const setAnonymous = (anonymous) => {
    return {
        type: 'SET_ANONYMOUS',
        anonymous: anonymous,
    };
};

export const setInvitation = (invitation) => ({
    type: 'SET_INVITATION',
    invitation: invitation,
});

export const setPerson = (person) => ({
    type: 'SET_PERSON',
    person: person,
});

export const setEvent = (event) => ({
    type: 'SET_EVENT',
    event: event,
});

export const setDeadlineInvitation = (deadline) => ({
    type: 'SET_INVITATION_DEADLINE',
    deadline: deadline,
});

export const setGuests = (guests) => ({
    type: 'SET_GUESTS',
    guests: guests,
});

export const setSubstitute = (substitute) => ({
    type: 'SET_SUBSTITUTE',
    substitute: substitute,
});

export const updateInvitation = (loginkey) => {
    const authenticationData = store.getState().authenticationData;
    return async function (dispatch) {
        return fetch(BACKEND_URL + INVITATION_LIST, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.login.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData.login,
                    userId4Hist: undefined,
                },
                code: loginkey,
                idProjekt: authenticationData.TpProjektLstItem?.[0].ID,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                try {
                    return JSON.parse(text);
                } catch (e) {
                    return Promise.reject(e);
                }
            })
            .then((json) => {
                if (!json.TpEinladungLstItem.length) {
                    return Promise.reject(
                        'no invitation with this code exists',
                    );
                }
                ENV === 'development' &&
                    console.info('invitation list fetch:', json);
                return fetch(BACKEND_URL + INVITATION_GET, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authenticationData.login.accessToken}`,
                    },
                    body: JSON.stringify({
                        login: {
                            // TODO: pick properties manually!
                            ...authenticationData.login,
                            userId4Hist: undefined,
                        },
                        ID: json.TpEinladungLstItem[0].ID,
                        idProjekt: authenticationData.TpProjektLstItem?.[0].ID,
                    }),
                });
            })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                try {
                    return JSON.parse(text);
                } catch (e) {
                    return Promise.reject(e);
                }
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('invitation details fetch:', json);
                if (!json.ID) {
                    return Promise.reject(
                        'invitation details fetch validity check: wrong code',
                    );
                }
                // TODO: check if already expired or already answered!
                // TODO: check if we get more than one TpEinladungLstItem entry, then the filter is probably wrong or the invite code
                return json;
            })
            .then((json) => {
                dispatch(setInvitation(json));
                return json;
            });
    };
};

export const updatePerson = (idPerson) => {
    ENV === 'development' && console.info('fetching person', idPerson);
    const authenticationData = store.getState().authenticationData;
    return async function (dispatch) {
        return Promise.resolve()
            .then(() =>
                fetch(BACKEND_URL + PERSON_GET, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authenticationData.login.accessToken}`,
                    },
                    body: JSON.stringify({
                        login: {
                            // TODO: pick properties manually!
                            ...authenticationData.login,
                            userId4Hist: undefined,
                        },
                        ID: idPerson,
                    }),
                })
                    .then((response) => {
                        if (!response.ok) {
                            return Promise.reject(response.statusText);
                        } else {
                            return response.text();
                        }
                    })
                    .then((text) => {
                        try {
                            return JSON.parse(text);
                        } catch (e) {
                            return Promise.reject(e);
                        }
                    }),
            )
            .then((person) => {
                ENV === 'development' &&
                    console.info('person details:', person);
                if (!person.ID) {
                    return Promise.reject('person validity check: wrong code');
                }
                return person;
            })
            .then((person) => dispatch(setPerson(person)));
    };
};

export const saveInvitation = (that) => {
    const invitation = that.props.invitation;
    const login = invitation.login;

    const status = that.props.formdata.attendance?.values?.attendance;
    const event = that.props.event?.name;
    const event_date = that.props.event?.date;
    const invitationEditDeadline = invitation?.invitationEditDeadline;
    const statusnr = status === 'self' ? 3 : status === 'none' ? 4 : 7;

    let first_send;

    const personEndpoint = that.props.anonymous ? PERSON_ADD : PERSON_SAVE;

    // update the person information, whether they come personally or are represented
    if (status === 'self' || status === 'representative') {
        first_send = fetch(BACKEND_URL + personEndpoint, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${login.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...that.props.authenticationData.login,
                    userId4Hist: undefined,
                },
                ...that.props.person,
                email: that.props.formdata.personals.values.email,
                PLZ: that.props.formdata.personals.values.zipcode,
                idAnrede: that.props.formdata.personals.values.salutation || 0,
                vorname: that.props.formdata.personals.values.firstname,
                nachname: that.props.formdata.personals.values.lastname,
                titel: that.props.formdata.personals.values.title,
                telefon: that.props.formdata.personals.values.phone,
                strasse: that.props.formdata.personals.values.street,
                ort: that.props.formdata.personals.values.city,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                try {
                    return JSON.parse(text);
                } catch (e) {
                    return Promise.reject(e);
                }
            })
            .then((json) => {
                // TODO: robustness: validate if person saved correctly
                ENV === 'development' && console.info('sent person', json);
                return json;
            });
    } else {
        first_send = Promise.resolve(that.props.person);
    }

    first_send.then((json) => {
        // save the invitation
        fetch(BACKEND_URL + INVITATION_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${login.accessToken}`,
            },
            body: JSON.stringify({
                ...invitation,
                idPerson: json.ID,
                idStatus: statusnr,
                rueckmeldung: new Date().toISOString(),
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                try {
                    return JSON.parse(text);
                } catch (e) {
                    return Promise.reject(e);
                }
            })
            .then((json) => {
                // TODO: robustness: validate if invitation saved correctly
                ENV === 'development' && console.info('sent invitation', json);
                return json;
            })
            .then((json) => {
                that.props.dispatch(setInvitation(json));
                return json;
            })
            // if the person wants to be represented by someone else, save/create the representative
            .then((json) => {
                if (!json || !json.ID) {
                    return Promise.reject(
                        'invitation was not saved successfully',
                    );
                }
                if (status === 'representative') {
                    const currentSubstitute = that.props.substitute || {};
                    // save representative if one already exists or create a new one
                    const isSubstituteKnown = currentSubstitute?.ID;
                    const endpointSubstitute = isSubstituteKnown
                        ? GUEST_SAVE
                        : GUEST_ADD;
                    const idSubstitute = isSubstituteKnown
                        ? { ID: currentSubstitute.ID }
                        : {};
                    let body = {
                        login: {
                            // TODO: pick properties manually!
                            ...that.props.authenticationData.login,
                            userId4Hist: undefined,
                        },
                        ...currentSubstitute,
                        PLZ:
                            that.props.formdata.substitute.values.zipcode ||
                            currentSubstitute.PLZ ||
                            '',
                        email:
                            that.props.formdata.substitute.values.email ||
                            currentSubstitute.email ||
                            '',
                        idAnrede: that.props.formdata.substitute.values
                            .salutation
                            ? that.props.formdata.substitute.values.salutation
                            : currentSubstitute.idAnrede
                            ? currentSubstitute.idAnrede
                            : 2,
                        firma:
                            that.props.formdata.substitute.values.company ||
                            currentSubstitute.firma ||
                            '',
                        idEinladung:
                            json.ID || currentSubstitute.idEinladung || '',
                        name:
                            that.props.formdata.substitute.values.lastname ||
                            currentSubstitute.name ||
                            '',
                        vorname:
                            that.props.formdata.substitute.values.firstname ||
                            currentSubstitute.vorname ||
                            '',
                        ort:
                            that.props.formdata.substitute.values.city ||
                            currentSubstitute.ort ||
                            '',
                        strasse:
                            that.props.formdata.substitute.values.street ||
                            currentSubstitute.strasse ||
                            '',
                        telefon:
                            that.props.formdata.substitute.values.phone ||
                            currentSubstitute.telefon ||
                            '',
                        titel:
                            typeof that.props.formdata.substitute.values
                                .title !== 'undefined'
                                ? that.props.formdata.substitute.values.title
                                : currentSubstitute.titel || '',
                        idTyp: 2,
                    };
                    body = { ...body, ...idSubstitute };
                    return fetch(BACKEND_URL + endpointSubstitute, {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${login.accessToken}`,
                        },
                        body: JSON.stringify({
                            ...body,
                        }),
                    })
                        .then((response) => {
                            if (!response.ok) {
                                return Promise.reject(response.statusText);
                            } else {
                                return response.text();
                            }
                        })
                        .then((text) => {
                            return JSON.parse(text);
                        })
                        .then(() => {
                            return Promise.resolve(json);
                        });
                } else {
                    return Promise.resolve(json);
                }
            })
            .then((json) => {
                // if guests are created/modified/deleted, save these modifications in the backend
                if (!json || !json.ID) {
                    return Promise.reject(
                        'invitation was not saved successfully',
                    );
                }
                if (
                    !(
                        (that.props.form.guests?.values?.guests || []).length >
                            0 ||
                        (that.props.form.guests?.initial?.guests || []).length >
                            0
                    )
                ) {
                    return Promise.resolve(json);
                }
                const currentValuesGuests =
                    that.props.form.guests.values.guests;
                const initialValuesGuests =
                    that.props.form.guests.initial.guests;
                const backendValuesGuests = that.props.guests;
                // updatedItems need to be saved
                const updatedItems = [];
                // newItems need to be created
                const newItems = [];
                // existingItems need to be deleted
                const existingItems = [];
                const arr = new Array(currentValuesGuests.length);
                initialValuesGuests.forEach((itemInState) => {
                    let found = false;
                    currentValuesGuests.forEach((itemInPayload, ind) => {
                        if (itemInPayload.ID === itemInState.ID) {
                            updatedItems.push(itemInPayload);
                            found = true;
                            arr[ind] = true;
                        }
                    });
                    if (!found) {
                        existingItems.push(itemInState);
                    }
                });
                for (let i = 0; i < arr.length; i++) {
                    if (!arr[i]) {
                        newItems.push(currentValuesGuests[i]);
                    }
                }
                ENV === 'development' &&
                    console.info('updated guests: ', updatedItems);
                ENV === 'development' &&
                    console.info('newly created guests: ', newItems);
                ENV === 'development' &&
                    console.info(
                        'guests that no longer are coming: ',
                        existingItems,
                    );
                // send all modifications to backend
                Promise.all(
                    [...newItems, ...updatedItems, ...existingItems].map(
                        (item) => {
                            let endpointGuest = '';
                            let body = {};
                            if (newItems.includes(item)) {
                                endpointGuest = GUEST_ADD;
                                body = {
                                    login: {
                                        // TODO: pick properties manually!
                                        ...that.props.authenticationData.login,
                                        userId4Hist: undefined,
                                    },
                                    PLZ: item.zipcode || '',
                                    email: item.email || '',
                                    idAnrede: item.salutation === 'sir' ? 1 : 2,
                                    firma: item.company || '',
                                    idEinladung: json.ID || '',
                                    name: item.lastname || '',
                                    vorname: item.firstname || '',
                                    ort: item.city || '',
                                    strasse: item.street || '',
                                    telefon: item.phone || '',
                                    titel: item.title || '',
                                    idTyp: 1,
                                };
                            } else if (updatedItems.includes(item)) {
                                endpointGuest = GUEST_SAVE;
                                // the previous values for that guest (as they came from the backend)
                                const previousValues = backendValuesGuests.find(
                                    (previous) => previous.ID === item.ID,
                                );
                                body = {
                                    login: {
                                        // TODO: pick properties manually!
                                        ...that.props.authenticationData.login,
                                        userId4Hist: undefined,
                                    },
                                    ...previousValues,
                                    PLZ:
                                        item.zipcode ||
                                        previousValues.PLZ ||
                                        '',
                                    email:
                                        item.email ||
                                        previousValues.email ||
                                        '',
                                    idAnrede: item.salutation
                                        ? item.salutation === 'sir'
                                            ? 1
                                            : 2
                                        : previousValues.idAnrede
                                        ? previousValues.idAnrede
                                        : 2,
                                    firma:
                                        item.company ||
                                        previousValues.firma ||
                                        '',
                                    idEinladung:
                                        json.ID ||
                                        previousValues.idEinladung ||
                                        '',
                                    name:
                                        item.lastname ||
                                        previousValues.name ||
                                        '',
                                    vorname:
                                        item.firstname ||
                                        previousValues.vorname ||
                                        '',
                                    ort: item.city || previousValues.ort || '',
                                    strasse:
                                        item.street ||
                                        previousValues.strasse ||
                                        '',
                                    telefon:
                                        item.phone ||
                                        previousValues.telefon ||
                                        '',
                                    titel:
                                        typeof item.title !== 'undefined'
                                            ? item.title
                                            : previousValues.titel || '',
                                    idTyp: 1,
                                };
                            } else if (existingItems.includes(item)) {
                                endpointGuest = GUEST_DELETE;
                                const previousValues = backendValuesGuests.find(
                                    (previous) => previous.ID === item.ID,
                                );
                                body = {
                                    login: {
                                        // TODO: pick properties manually!
                                        ...that.props.authenticationData.login,
                                        userId4Hist: undefined,
                                    },
                                    ...previousValues,
                                };
                            }
                            if (endpointGuest === '') {
                                return Promise.reject('error saving guests');
                            }
                            ENV === 'development' &&
                                console.log(
                                    'endpoint for this guest: ',
                                    endpointGuest,
                                );
                            ENV === 'development' &&
                                console.log(
                                    'body of request for this guest: ',
                                    body,
                                );
                            ENV === 'development' && console.log('item', item);
                            return fetch(BACKEND_URL + endpointGuest, {
                                method: 'POST',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${login.accessToken}`,
                                },
                                body: JSON.stringify(body),
                            })
                                .then((response) => {
                                    if (!response.ok) {
                                        return Promise.reject(
                                            response.statusText,
                                        );
                                    } else {
                                        return response.text();
                                    }
                                })
                                .then((text) => {
                                    return JSON.parse(text);
                                })
                                .then((item) => {
                                    ENV === 'development' &&
                                        console.log('entity details:', item);
                                    return item;
                                });
                        },
                    ),
                );
            })
            .then(() => {
                that.props.dispatch(
                    push({
                        pathname: '/success',
                        state: {
                            status: status,
                            event: event,
                            event_date: event_date,
                            deadline: invitationEditDeadline,
                        },
                    }),
                );
            })
            .catch((e) => {
                console.error('error sending invitation, promise rejected:', e);
                that.setState({
                    invalid: true,
                });
            });
    });
};

// TODO remove? This is not used anywhere.
export const readDeadline = () => {
    const project = (store.getState().authenticationData || [])
        .TpProjektLstItem;
    const activeProject =
        Array.isArray(project) && project.length > 0 ? project[0] : null;

    // TODO: Test if current date is between
    // a) the starting date to make changes (property/date does not yet exist)
    // and b) the respective deadline.
    // Fake it for now: starting date is 12 months before IE starts, end date is 2 months before
    // TODO: We need a "start date" and "end date" in Backend
    let invitationEdit_deadline_within = true;
    let invitationEditDeadline = '(Projektdeadline nicht spezifiziert)';
    if (
        ENV !== 'development' &&
        activeProject &&
        activeProject.beginn &&
        DATETIME_PATTERN.test(activeProject.beginn)
    ) {
        const deadline_date_end = new Date(activeProject.beginn);
        deadline_date_end.setMonth(deadline_date_end.getMonth() - 2);

        const deadline_date_start = new Date(activeProject.beginn);
        deadline_date_start.setMonth(deadline_date_start.getMonth() - 12);

        invitationEdit_deadline_within =
            DATETIME_PATTERN.test(deadline_date_end) &&
            DATETIME_PATTERN.test(deadline_date_start)
                ? deadline_date_start < deadline_date_end &&
                  deadline_date_end > new Date().setHours(0, 0, 0, 0)
                : false;
        invitationEditDeadline = new Date(activeProject.beginn).toLocaleString(
            'de-DE',
            { day: 'numeric', month: 'long', year: 'numeric' },
        );
    }

    Promise.resolve()
        .then(() => {
            return store.dispatch(
                setDeadlineInvitation({
                    invitationEditDeadline,
                    invitationEdit_deadline_within,
                }),
            );
        })
        .catch((e) => {
            ENV === 'development' && console.log(e);
        });
};

export const getGuests = (invitation_id, type = 1) => {
    const authenticationData = store.getState().authenticationData;
    return async function (dispatch) {
        return fetch(BACKEND_URL + GUEST_LIST, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.login.accessToken}`,
            },
            body: JSON.stringify({
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData.login,
                    userId4Hist: undefined,
                },
                idEinladung: invitation_id,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                // dispatch(dispatchEntityList(json));
                return json;
            })
            .then((json) =>
                json['TpBegleiterLstItem'] ? json['TpBegleiterLstItem'] : [],
            )
            .then((itemList) => {
                return Promise.all(
                    itemList.map((entityInPromise) => {
                        ENV === 'development' &&
                            console.info(
                                'fetching guest(s)',
                                entityInPromise.ID,
                            );
                        return fetch(BACKEND_URL + GUEST_GET, {
                            method: 'POST',
                            mode: 'cors',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${authenticationData.login.accessToken}`,
                            },
                            body: JSON.stringify({
                                login: {
                                    // TODO: pick properties manually!
                                    ...authenticationData.login,
                                    userId4Hist: undefined,
                                },
                                ID: entityInPromise.ID,
                            }),
                        })
                            .then((response) => {
                                if (!response.ok) {
                                    return Promise.reject(response.statusText);
                                } else {
                                    return response.text();
                                }
                            })
                            .then((text) => {
                                return JSON.parse(text);
                            })
                            .then((entityItem) => {
                                ENV === 'development' &&
                                    console.log('entity details:', entityItem);
                                return entityItem;
                            });
                    }),
                );
            })
            .then((guestList) => {
                return Promise.resolve(
                    guestList.filter((item) => item.idTyp === type),
                );
            })
            .then((person) => {
                type === 1
                    ? dispatch(setGuests(person))
                    : dispatch(setSubstitute(person));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                return Promise.reject(e);
            });
    };
};
