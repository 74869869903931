import React from 'react';
import StepButtons from '../../common/stepbuttons';
import Footer from '../../common/footer';
import style from '../../common/index.module.css';

import Question from '../../common/question';

import { connect } from 'react-redux';
import dayjs from 'dayjs';

class Success extends Question {
    constructor(props) {
        super(props);
        this.state = {
            code: '',
        };
    }
    setFooterHtml() {
        return <Footer faq={true} />;
    }
    positionStatusBar(statusBarParam) {
        const statusBar = statusBarParam || document.getElementById('status');
        if (statusBar) {
            statusBar.remove();
        }
    }

    /**
     * Do not remove - overrides componentDidMount() in parent class Question.
     * Inherited functionality of parent is unwanted here.
     * Avoids automatically redirecting to '/' route on mount.
     */
    componentDidMount() {}

    renderQuestion() {
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>
                        {this.props.router?.location?.state?.event &&
                        this.props.router?.location?.state?.event_date ? (
                            <span>
                                {this.props.router.location.state.event} -{' '}
                                {dayjs(
                                    this.props.router.location.state.event_date,
                                ).format('DD.MM.YYYY')}
                            </span>
                        ) : this.props.authenticationData.TpProjektLstItem ? (
                            <span>
                                {
                                    this.props.authenticationData
                                        .TpProjektLstItem[0].name
                                }
                                {' - '}
                                {dayjs(
                                    this.props.authenticationData
                                        .TpProjektLstItem[0].beginn,
                                ).format('DD.MM.YYYY')}
                            </span>
                        ) : (
                            'Anmeldung erfolgt'
                        )}
                    </h1>
                    <p>
                        Vielen Dank, Ihre Angaben wurden gespeichert.
                        {this.props.router?.location?.state?.deadline ? (
                            <span>
                                {' '}
                                Sie haben bis zum{' '}
                                {this.props.router.location.state.deadline} die
                                Möglichkeit, Ihre Angaben erneut zu bearbeiten.
                            </span>
                        ) : null}
                    </p>
                    {this.props.router?.location?.state?.status === 'self' ? (
                        <p>Wir freuen uns auf Ihren Besuch!</p>
                    ) : null}
                    <br />
                    <p>
                        Informationen zur Anfahrt erhalten Sie{' '}
                        <a
                            href='https://www.ideenexpo.de/event-2024#anfahrt'
                            target=''
                        >
                            <u>hier</u>
                        </a>
                        .
                    </p>
                    <br />
                    <p>
                        Besuchen Sie unsere Website auf{' '}
                        <a href='https://www.ideenexpo.de' target=''>
                            <u>www.ideenexpo.de</u>
                        </a>
                        !
                    </p>
                </div>
                <StepButtons back='/' />
            </div>
        );
    }
}

export default connect((state) => state)(Success);
