import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { Field, change, reduxForm } from 'redux-form';
import {
    deleteEntity,
    setBoothFeature,
    setStele,
} from '../../../../actions/exhibitor/exhibitor-actions';
import { createEntityConfig } from '../../../../actions/exhibitor/fetcher/entity-list-detail.fetcher';
import {
    fetchListAsEntity,
    fetchSingleEntityFromConfig,
} from '../../../../actions/exhibitor/fetcher/fetcher';
import { updateStele } from '../../../../actions/exhibitor/stele';
import Loader from '../../../common/loader';
import {
    DELETE,
    ERROR,
    ERROR_MSG_BACKEND_SAVE,
    SUCCESS_DELETE,
    showAlert,
} from '../../alerts';
import FileInput from '../../fileinput';
import FormButtons from '../../formbuttons';
import { blockScreen } from '../../functions';
import style from '../../index.module.css';
import Logos from '../../logos';
import UploadFile from '../../uploadfile';

export const createBiMap = (obj) => {
    const map = new Map();
    for (const key in obj) {
        map.set(obj[key], key);
        map.set(key, obj[key]);
    }
    return map;
};

const mapStateToProps = (state) => {
    const params = new URLSearchParams(state.router.location.search);

    const steleId = params.get('steleId');
    const stele = state.stele;
    const boothFeatureId = params.get('boothFeatureId');
    /* TODO: In den ersten Render-Zyklen ist boothFeatures ein Array von Stand-Ausstattungen.
     * Nach ein paar Render-Zyklen ist es ein einzelnes Stand-Ausstatung-Objekt. */
    const boothFeature = Array.isArray(state.boothFeatures)
        ? undefined
        : state.boothFeatures;
    const boothId = boothFeature?.idStand || undefined;
    const booth =
        state.booth && Array.isArray(state.booth)
            ? state.booth.find((x) => x.ID === boothId)
            : {};

    const initialValues = {
        ...(stele ? stele : {}),
        steletype: String(stele.idStelentyp ?? 0),
        name: stele.bezeichnung || '',
        ueberschrift1: stele.seite1UeberschriftZ1 || '',
        ueberschrift2: stele.seite1UeberschriftZ2 || '',
        seite2_ueberschrift_z1: stele.seite2UeberschriftZ1 || '',
        seite2_ueberschrift_z2: stele.seite2UeberschriftZ2 || '',
        seite3_ueberschrift_z1: stele.seite3UeberschriftZ1 || '',
        seite3_ueberschrift_z2: stele.seite3UeberschriftZ2 || '',
        seite4_ueberschrift_z1: stele.seite4UeberschriftZ1 || '',
        seite4_ueberschrift_z2: stele.seite4UeberschriftZ2 || '',
        text_stele: stele.seite1Fliesstext || '',
        seite2_text: stele.seite2Fliesstext || '',
        seite3_text: stele.seite3Fliesstext || '',
        seite4_text: stele.seite4Fliesstext || '',
        idPositionierungLogo: stele.idPositionierungLogo
            ? stele.idPositionierungLogo !== -1
                ? stele.idPositionierungLogo
                : 0
            : 0,
        standardLogoExists: booth?.kzStandardlogos || 1,
    };

    const isExhibitDeadlineWithin = state.deadlines?.exhibit_deadline_within;

    // TODO auslagern
    const steletypePairIdMap = createBiMap({
        745: '776',
        2128: '2129',
    });

    return {
        steletype: state.steletype,
        steleId,
        stele,
        boothFeatureId,
        boothFeature: state.boothFeatures, // TODO rename to boothFeature
        isExhibitDeadlineWithin,
        initialValues,
        formValues: state.form?.stelesedit?.values || {},
        steletypePairIdMap,
    };
};

const PageText = (
    /** @type {{formValues?: Record<string, string>, fields: { label: string, name: string, maxLength?: number, type?: 'text'|'textarea', rows?: number }[]} */
    props,
) => {
    return (
        <div>
            {props.fields.map((field, index) => (
                <Fragment key={index + field.name}>
                    <label htmlFor={field.name}>
                        <span>{field.label}</span>
                    </label>
                    <Field
                        component={
                            field.type === 'textarea' ? 'textarea' : 'input'
                        }
                        placeholder=''
                        type={field.type || 'text'}
                        name={field.name}
                        id={field.name}
                        maxLength={field.maxLength}
                        {...(field.type === 'textarea'
                            ? {
                                  rows: field.rows,
                              }
                            : {})}
                    />
                    {field.type === 'textarea' && (
                        <legend>
                            Bitte geben Sie maximal {field.maxLength} Zeichen
                            ein. Es bleiben noch{' '}
                            {field.maxLength -
                                (props?.formValues?.[field.name]?.length ??
                                    0)}{' '}
                            Zeichen.
                        </legend>
                    )}
                </Fragment>
            ))}
        </div>
    );
};

export const StelesEditForm = (
    /** @type {ReturnType<typeof mapStateToProps>} */
    props,
) => {
    const [isReady, setIsReady] = useState(false);
    const [values, setValues] = useState(props.initialValues);
    /** full steletype object, not just the name! */
    const [currentSteletypeId, setCurrentSteletypeId] = useState(
        String(props.stele.idStelentyp),
    );
    useEffect(() => {
        if (props.stele) {
            setCurrentSteletypeId(String(props.stele.idStelentyp));
            props.dispatch(
                change(
                    'stelesedit',
                    'steletype',
                    String(props.stele.idStelentyp),
                ),
            );
        }
    }, [props.stele]);

    const currentSteletype = useMemo(
        () =>
            props.steletype.find(
                (item) => Number(item.ID) === Number(currentSteletypeId),
            ),
        [props.steletype, currentSteletypeId],
    );

    const isApproved = props.stele.kzFreigabeAussteller === 1;
    const isFormDisabled = isApproved || !props.isExhibitDeadlineWithin;

    const showSteletypeSelect = true;
    // const showSteletypeSelect = ![
    //     undefined,
    //     STELETYPE_BERUFSINFOSTELE,
    //     STELETYPE_INFOTRESENBERUFSINFOS,
    //     STELETYPE_50CM_BERUFSINFOS_2XTEXT,
    // ].includes(props.formValues.steletype?.bezeichnung);

    const handleLogoInput = (e) => {
        if (e?.target) {
            if (e.currentTarget?.name.startsWith('position-')) {
                const positionIndex = e.currentTarget.selectedIndex;
                setValues((prev) => ({
                    ...prev,
                    idPositionierungLogo: positionIndex,
                }));
                return;
            }
            if (e.target.name === 'delete') {
                setValues((prev) => ({
                    ...prev,
                    [e.target.nameField]: '',
                    [e.target.imageField]: '',
                }));
                return;
            }
            if (e.target.id.startsWith('datasheet')) {
                const id = e.target.id.charAt(e.target.id.length - 1);
                setValues((prev) => ({
                    ...prev,
                    files: {
                        ...prev.files,
                        [id]: {
                            file: e.target.files[0],
                            nameField: e.nameField || '',
                            imageField: e.imageField || '',
                        },
                    },
                }));
                return;
            }
        }
    };

    const handleFetch = async () => {
        const boothFeature = fetchSingleEntityFromConfig(
            createEntityConfig('boothFeature'),
            props.boothFeatureId,
            {
                idMobiliarTyp: 4, // TODO auslagern
            },
        );

        const stele = fetchSingleEntityFromConfig(
            createEntityConfig('stele'),
            props.steleId,
        );

        props.dispatch(setBoothFeature(await boothFeature));
        props.dispatch(setStele(await stele));
        props.dispatch(await fetchListAsEntity('steletype'));
    };

    // TODO
    const handleDelete = async () => {
        if (
            window.confirm(
                `Wollen Sie den Eintrag "${props.boothFeature.bezeichnung}" wirklich löschen?`,
            )
        ) {
            try {
                await this.props.dispatch(deleteEntity(props.stele, 'stele'));
                showAlert(
                    DELETE,
                    SUCCESS_DELETE(
                        `Die Stele ${props.boothFeature.bezeichnung}`,
                    ),
                );
            } catch (e) {
                showAlert(ERROR, `Element konnte nicht gelöscht werden. ${e}`);
            }
        }
    };

    const submit = async (formValues) => {
        const v = {
            ...formValues,
            idStelentyp: currentSteletypeId,
        };

        blockScreen(true);
        try {
            if (values.files) {
                await Promise.all(
                    Object.entries(values.files).map(async (file) => {
                        if (
                            !file[1]?.file ||
                            typeof file[1].file !== 'object'
                        ) {
                            return;
                        }
                        const img = await UploadFile(file[1].file);
                        v[file[1].imageField] = img.file;
                        v[file[1].nameField] = img.name;
                    }),
                );
            }

            await props.dispatch(
                updateStele(props.stele, {
                    ...v,
                }),
            );
            props.history.push('/booths/steles');
        } catch (e) {
            console.error(e);
            showAlert(ERROR, ERROR_MSG_BACKEND_SAVE);
        } finally {
            blockScreen(false);
        }
    };

    useEffect(() => {
        handleFetch();
    }, []);

    useEffect(() => {
        if (
            props.steletype?.length > 0 &&
            props.boothFeature?.ID &&
            props.stele?.ID &&
            !Array.isArray(props.stele) &&
            !Array.isArray(props.boothFeature)
        ) {
            setIsReady(true);
        }
    }, [props.steletype, props.boothFeature, props.stele]);

    if (!isReady) {
        return (
            <div style={{ height: '90vh' }}>
                <Loader
                    visible
                    infoDelayMs={1000 * 3}
                    info='Bitte warten Sie, während die Bilder geladen werden.'
                />
            </div>
        );
    }

    return (
        <div className={style.question}>
            <div className={style.header}>
                {isApproved && (
                    <div>Sie haben diese Daten bereits freigegeben.</div>
                )}
                {!props.isExhibitDeadlineWithin && (
                    <div>
                        Sie können die Daten nur innerhalb des
                        Bearbeitungszeitraums bearbeiten.
                    </div>
                )}
                {props.boothFeature && (
                    <h1>
                        Infostele {props.boothFeature.bezeichnung} bearbeiten
                    </h1>
                )}
                <form onSubmit={(e) => e.preventDefault()}>
                    <fieldset disabled={isFormDisabled}>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='stele_id'
                            id='stele_id'
                            hidden
                            disabled
                            value={props.boothFeature?.ID || ''}
                        />{' '}
                        <label htmlFor='name'>
                            <span>
                                Bitte geben Sie dieser Stele einen treffenden
                                Namen (nur intern) (*)
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='name'
                            id='name'
                            maxLength='80'
                        />
                        {showSteletypeSelect && (
                            <SelectSteletype
                                currentSteletype={currentSteletype}
                                currentSteletypeId={currentSteletypeId}
                                steletypeList={props.steletype}
                                pairs={props.steletypePairIdMap}
                                onChange={(value) => {
                                    setCurrentSteletypeId(value);
                                    // dispatch redux form value "steletype"
                                    // props.dispatch(
                                    //     change(
                                    //         'stelesedit',
                                    //         'steletype',
                                    //         +value,
                                    //     ),
                                    // );
                                }}
                            />
                        )}
                        {currentSteletype?.kzSeite1 === 1 && (
                            <PageText
                                formValues={props.formValues}
                                fields={[
                                    {
                                        label: 'Seite 1: Überschrift Zeile 1 (maximal 20 Zeichen)',
                                        name: 'ueberschrift1',
                                        maxLength: 20,
                                    },
                                    {
                                        label: 'Seite 1: Überschrift Zeile 2 (maximal 20 Zeichen)',
                                        name: 'ueberschrift2',
                                        maxLength: 20,
                                    },
                                    {
                                        label: 'Seite 1: Text (11 Zeilen à ca. 30 Zeichen)',
                                        name: 'text_stele',
                                        type: 'textarea',
                                        rows: 11,
                                        maxLength: 360,
                                    },
                                ]}
                            />
                        )}
                        {currentSteletype?.kzSeite2 === 1 && (
                            <PageText
                                formValues={props.formValues}
                                fields={[
                                    {
                                        label: 'Seite 2: Überschrift Zeile 1 (maximal 20 Zeichen) (optional)',
                                        name: 'seite2_ueberschrift_z1',
                                        maxLength: 20,
                                    },
                                    {
                                        label: 'Seite 2: Überschrift Zeile 2 (maximal 20 Zeichen) (optional)',
                                        name: 'seite2_ueberschrift_z2',
                                        maxLength: 20,
                                    },
                                    {
                                        label: 'Seite 2: Text (11 Zeilen à ca. 30 Zeichen) (optional)',
                                        name: 'seite2_text',
                                        type: 'textarea',
                                        rows: 11,
                                        maxLength: 360,
                                    },
                                ]}
                            />
                        )}
                        {currentSteletype?.kzSeite3 === 1 && (
                            <PageText
                                formValues={props.formValues}
                                fields={[
                                    {
                                        label: 'Seite 3: Überschrift Zeile 1 (maximal 20 Zeichen) (optional)',
                                        name: 'seite3_ueberschrift_z1',
                                        maxLength: 20,
                                    },
                                    {
                                        label: 'Seite 3: Überschrift Zeile 2 (maximal 20 Zeichen) (optional)',
                                        name: 'seite3_ueberschrift_z2',
                                        maxLength: 20,
                                    },
                                    {
                                        label: 'Seite 3: Text (11 Zeilen à ca. 30 Zeichen) (optional)',
                                        name: 'seite3_text',
                                        type: 'textarea',
                                        rows: 11,
                                        maxLength: 360,
                                    },
                                ]}
                            />
                        )}
                        {currentSteletype?.kzSeite4 === 1 && (
                            <PageText
                                formValues={props.formValues}
                                fields={[
                                    {
                                        label: 'Seite 4: Überschrift Zeile 1 (maximal 20 Zeichen) (optional)',
                                        name: 'seite4_ueberschrift_z1',
                                        maxLength: 20,
                                    },
                                    {
                                        label: 'Seite 4: Überschrift Zeile 2 (maximal 20 Zeichen) (optional)',
                                        name: 'seite4_ueberschrift_z2',
                                        maxLength: 20,
                                    },
                                    {
                                        label: 'Seite 4: Text (11 Zeilen à ca. 30 Zeichen) (optional)',
                                        name: 'seite4_text',
                                        type: 'textarea',
                                        rows: 11,
                                        maxLength: 360,
                                    },
                                ]}
                            />
                        )}
                        {currentSteletype?.kzSeite1Bild === 1 && (
                            <FileInput
                                label='Grafik für Bildfläche'
                                i={5}
                                name={props.initialValues.seite1Bild}
                                link={`data:image/png;base64,${props.initialValues.data4Seite1Bild}`}
                                handleInput={handleLogoInput}
                                imageField='data4Seite1Bild'
                                nameField='seite1Bild'
                            />
                        )}
                        {currentSteletype?.kzSeite2Bild === 1 && (
                            <FileInput
                                label='Grafik für zweite Bildfläche'
                                i={6}
                                name={props.initialValues.seite2Bild}
                                link={`data:image/png;base64,${props.initialValues.data4Seite2Bild}`}
                                handleInput={handleLogoInput}
                                imageField='data4Seite2Bild'
                                nameField='seite2Bild'
                            />
                        )}
                        {currentSteletype?.kzSeite3Bild === 1 && (
                            <FileInput
                                label='Grafik für dritte Bildfläche'
                                i={7}
                                name={props.initialValues.seite3Bild}
                                link={`data:image/png;base64,${props.initialValues.data4Seite3Bild}`}
                                handleInput={handleLogoInput}
                                imageField='data4Seite3Bild'
                                nameField='seite3Bild'
                            />
                        )}
                        {currentSteletype?.kzSeite4Bild === 1 && (
                            <FileInput
                                label='Grafik für vierte Bildfläche'
                                i={8}
                                name={props.initialValues.seite4Bild}
                                link={`data:image/png;base64,${props.initialValues.data4Seite4Bild}`}
                                handleInput={handleLogoInput}
                                imageField='data4Seite4Bild'
                                nameField='seite4Bild'
                            />
                        )}
                        {props.boothFeature && (
                            <>
                                <h2>Gestaltung der Logofläche</h2>
                                <hr />
                                <p>
                                    Sofern erforderlich, können Sie auf den
                                    Stelen und Tresen bis zu vier verschiedene
                                    Logos platzieren. Bitte wählen Sie dazu eine
                                    der sechs möglichen Anordnungen. Alle
                                    Logodateien sollten als Vektorgrafik im
                                    eps-Format hochgeladen werden.
                                </p>
                                <Logos
                                    positionLogos={
                                        props.initialValues
                                            .idPositionierungLogo || 0
                                    }
                                    datasheets={[
                                        {
                                            name:
                                                props.initialValues.logo1 || '',
                                            link:
                                                `data:image/png;base64,${props.initialValues.data4Logo1}` ||
                                                '',
                                        },
                                        {
                                            name:
                                                props.initialValues.logo2 || '',
                                            link:
                                                `data:image/png;base64,${props.initialValues.data4Logo2}` ||
                                                '',
                                        },
                                        {
                                            name:
                                                props.initialValues.logo3 || '',
                                            link:
                                                `data:image/png;base64,${props.initialValues.data4Logo3}` ||
                                                '',
                                        },
                                        {
                                            name:
                                                props.initialValues.logo4 || '',
                                            link:
                                                `data:image/png;base64,${props.initialValues.data4Logo4}` ||
                                                '',
                                        },
                                    ]}
                                    handleInput={handleLogoInput}
                                    standardLogoExists={
                                        props.initialValues.standardLogoExists
                                    }
                                />
                            </>
                        )}
                        <FormButtons
                            showCancel={true}
                            overviewLink='/booths/steles'
                            hideBtn={false}
                            handleSubmit={props.handleSubmit}
                            // showDelete
                            // handleDelete={handleDelete}
                            submit={submit}
                        />
                    </fieldset>
                </form>
            </div>
        </div>
    );
};

export const SelectSteletype = (
    /** @type {{ onChange: (steletype: any) => void, steletypeList: any[], currentSteletype: any, currentSteletypeId: number, pairs: Map<number, number> }} */
    props,
) => {
    const [firstType] = useState(props.currentSteletype);

    const [secondTypeId] = useState(
        props.pairs.get(`${props.currentSteletypeId}`),
    );
    const secondType = useMemo(() => {
        if (secondTypeId && props.steletypeList) {
            return props.steletypeList.find(
                (item) => +item.ID === +secondTypeId,
            );
        }
    }, [props.steletypeList, secondTypeId]);

    return (
        <div>
            <legend>Stelentyp</legend>
            {firstType && (
                <>
                    <Field
                        component='input'
                        type='radio'
                        name='steletype'
                        id={String(firstType?.ID)}
                        value={String(firstType?.ID)}
                        required
                        onChange={(e) => props.onChange(e.target.value)}
                    />
                    <label htmlFor={firstType.ID}>
                        <span>{firstType.bezeichnung}</span>
                    </label>
                </>
            )}
            {secondType && (
                <>
                    <Field
                        component='input'
                        type='radio'
                        name='steletype'
                        id={String(secondType?.ID)}
                        value={String(secondType?.ID)}
                        required
                        onChange={(e) => props.onChange(e.target.value)}
                    />
                    <label htmlFor={secondType.ID.toString()}>
                        <span>{secondType.bezeichnung}</span>
                    </label>
                </>
            )}
        </div>
    );
};

export const StelesEdit = compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'stelesedit',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(StelesEditForm),
);
