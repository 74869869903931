import {
    TEILNEHMER2VERANSTALTUNG__BUCHUNGSSTATUS,
    mapVeranstaltungBelegung,
} from '../../../backend-id-constants';

/**
 * Formatiert den Status einer Veranstaltung, falls sie freigegeben ist
 * @param {boolean} freigabe
 * @param {number} idBelegung
 * @returns {string} Lesbarer Status
 */
export function statusMapper(freigabe, idBelegung) {
    if (freigabe === 0) {
        return '';
    }
    return mapVeranstaltungBelegung(idBelegung)?.label || '';
}

/**
 * Formatiert ein Datum.
 * @param {string} zeitBeginn
 * @returns {string} Lesbares Datum
 */
export function timeMapper(zeitBeginn) {
    if (!zeitBeginn) {
        return '';
    }

    // Wenn mit der Zulu-Zeit gearbeitet wird, addieren die JS-Date-Funktionen - korrekt - zwei Stunden drauf!
    const noZuluTime = zeitBeginn.replace(/Z/, '');

    const dd = new Date(noZuluTime);
    const year = dd.getFullYear();
    // Leading-Zero-Padding für Zahlen < 10
    const month = `${dd.getMonth() + 1}`.padStart(2, '0');
    const day = `${dd.getDate()}`.padStart(2, '0');
    const hour = `${dd.getHours()}`.padStart(2, '0');
    const min = `${dd.getMinutes()}`.padStart(2, '0');

    return `${day}.${month}.${year} - ${hour}:${min}`;
}

/**
 * Teilnehmer aus allen Gruppen im Workshop summieren
 * @param {number} freigabe
 * @param {*} workshop
 * @returns Anzahl Teilnehmer
 */
export function tnMapper(freigabe, workshop) {
    if (freigabe === 0) {
        return '';
    }

    const buchungsList =
        workshop.tp_Veranstaltung_2_Teilnehmer2VeranstaltungLst
            .TpTeilnehmer2VeranstaltungLstItem;

    const count = buchungsList.reduce((sum, buchung) => {
        if (
            [
                TEILNEHMER2VERANSTALTUNG__BUCHUNGSSTATUS.ANGEFRAGT,
                TEILNEHMER2VERANSTALTUNG__BUCHUNGSSTATUS.ZUGESAGT,
                TEILNEHMER2VERANSTALTUNG__BUCHUNGSSTATUS.BESTÄTIGT,
                TEILNEHMER2VERANSTALTUNG__BUCHUNGSSTATUS.TEILNGENOMMEN,
            ].some((status) => status.value === buchung.idStatus)
        ) {
            return sum + buchung.anzahlTeilnehmer;
        }
        return sum;
    }, 0);

    return count;
}
