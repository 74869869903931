import React, { Component, Fragment } from 'react';
import { destroy } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import style from '../../../exhibitor/index.module.css';
import {
    getEntity,
    deleteEntity,
} from '../../../../actions/exhibitor/exhibitor-actions.js';
import StepButtons from '../../stepbuttons';
import {
    showAlert,
    ERROR,
    ERROR_MSG_BACKEND_FETCH,
    SUCCESS_DELETE,
    DELETE,
} from '../../alerts';
import { approveItem } from '../../functions';

class ExhibitBoards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            isNeueExponatTafelAllowed: false,
        };
    }
    componentDidMount() {
        Promise.resolve()
            .then(() => {
                if (this.props.booth && !Object.keys(this.props.booth).length) {
                    return this.props.dispatch(getEntity('booth'));
                } else {
                    Promise.resolve();
                }
            })
            .then(() => {
                if (
                    this.props.themeWorld &&
                    !Object.keys(this.props.themeWorld).length
                ) {
                    return this.props.dispatch(getEntity('themeWorld'));
                } else {
                    Promise.resolve();
                }
            })
            // get exhibits (Exponate) because boards (Exponattafeln) cannot be
            // filtered by idFirma (property exists but doesn't filter getLst)
            // Instead: Filter by idExponat
            .then(() => {
                if (
                    this.props.exhibit &&
                    !Object.keys(this.props.exhibit).length
                ) {
                    return this.props.dispatch(getEntity('exhibit'));
                } else {
                    Promise.resolve();
                }
            })
            // Filter by idExponat.
            .then(() => {
                if (this.props.board && !Object.keys(this.props.board).length) {
                    const neueExponatTafelDeadline = new Date(
                        2024,
                        3,
                        12,
                        0,
                        0,
                        0,
                        -1,
                    );
                    const now = new Date();
                    this.setState({
                        isNeueExponatTafelAllowed:
                            now <= neueExponatTafelDeadline,
                    });
                    Promise.all(
                        this.props.exhibit.map((item) => {
                            return new Promise((resolve, reject) => {
                                return this.props
                                    .dispatch(
                                        getEntity('board', {
                                            idExponat: item.ID,
                                        }),
                                    )
                                    .then((result) => {
                                        resolve(result);
                                    })
                                    .catch((result) => {
                                        reject(result);
                                    });
                            });
                        }),
                    );
                } else {
                    Promise.resolve();
                }
            })
            .catch((e) => {
                showAlert(ERROR, ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    deleteItem = (json) => {
        Promise.resolve()
            .then(() => {
                return this.props.dispatch(deleteEntity(json, 'board'));
            })
            .then(() => {
                showAlert(
                    DELETE,
                    SUCCESS_DELETE(`Die Exponattafel ${json.bezeichnung}`),
                );
            })
            .catch((e) => {
                showAlert(ERROR, `Element konnte nicht gelöscht werden. ${e}`);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    };
    render() {
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>Liste der Exponattafeln</h1>
                    {/* TODO: Deadline für die Beschreibungstexte dynamisch aus Projekt auslesen */}
                    <p>
                        Für Exponatbeschreibungen stehen vor Ort kleine
                        Exponattafeln zur Verfügung, die direkt neben dem
                        Exponat aufgestellt werden. Mit einem kurzen Text können
                        Sie den interessierten BesucherInnen die Funktionsweise
                        Ihres Exponates vermitteln und die Mitmachaktion kurz
                        erläutern. Es gibt zwei unterschiedliche Varianten der
                        Exponattafeln (die Textmenge ist in beiden Fällen
                        gleich):
                    </p>
                </div>
                <ul>
                    <li>
                        großer Aufsteller (ca. 1 m hoch) mit einer DIN
                        A3-Textfläche
                    </li>
                    <li>
                        kleiner Plexiglas-Aufsteller zur Nutzung am Tresen mit
                        einer DIN A4-Textfläche
                    </li>
                </ul>
                <p>
                    Der Text muss sich nach unseren Zeichenvorgaben richten. Der
                    Stil des Textes sollte als direkte Ansprache formuliert sein
                    (Hier kannst du erfahren, wie …).
                </p>
                {/* TODO: Deadline für die Freigabe dynamisch aus Projekt auslesen */}
                <p>
                    Die Beschreibungen müssen von Ihnen bis zum{' '}
                    <b>18. März 2024</b> hinterlegt und freigegeben werden.
                    Anschließend erhalten Sie die gesetzten Layouts per Mail,
                    diese müssen bis zum <b>22. April</b> freigegeben sein.
                </p>

                {this.props.booth.map((booth) => {
                    const themeWorldOfBooth = this.props.themeWorld.find(
                        (item) => item.ID === booth.idThemenWelt,
                    );
                    return (
                        <div key={booth.ID} className='exhibitorTableWrap'>
                            <span className='exhibitorTableHeader'>
                                {booth.bezeichnung}
                                {themeWorldOfBooth
                                    ? ` (${
                                          themeWorldOfBooth.themenWeltBezeichnung ||
                                          'Bezeichnung fehlt!'
                                      })`
                                    : ' (Kein Themenbereich zugewiesen)'}
                            </span>
                            {this.state.isNeueExponatTafelAllowed && (
                                <Link
                                    className='exhibitorTableButton small-button'
                                    to={{
                                        pathname: `/booths/exhibit-boards/edit/${booth.ID}`,
                                        state: { booth_id: booth.ID },
                                    }}
                                    onClick={() =>
                                        this.props.dispatch(
                                            destroy('boardsedit'),
                                        )
                                    }
                                >
                                    Neue Exponattafel
                                </Link>
                            )}
                            <div className='schoolTableWrap'>
                                <ul className='schoolTable four-gridlistpages'>
                                    <div className='header'>Name</div>
                                    <div className='header'>Titel</div>
                                    <div className='header'>Freigabe</div>
                                    <div className='header' />

                                    {Array.isArray(this.props.exhibit) &&
                                    this.props.exhibit.length > 0
                                        ? this.props.exhibit
                                              .filter(
                                                  (exhibit) =>
                                                      exhibit.idStand ===
                                                      booth.ID,
                                              )
                                              .map((exhibit) => (
                                                  <Fragment key={exhibit.ID}>
                                                      {Array.isArray(
                                                          this.props.board,
                                                      ) &&
                                                      this.props.board.length >
                                                          0
                                                          ? this.props.board
                                                                  .filter(
                                                                      (item) =>
                                                                          item.idExponat ===
                                                                          exhibit.ID,
                                                                  )
                                                                  .map(
                                                                      (
                                                                          board,
                                                                      ) => (
                                                                          <ul
                                                                              key={
                                                                                  board.ID
                                                                              }
                                                                          >
                                                                              <Link
                                                                                  to={{
                                                                                      pathname:
                                                                                          '/booths/exhibit-boards/edit',
                                                                                      state: {
                                                                                          handle: board.ID,
                                                                                          booth_id:
                                                                                              booth.ID,
                                                                                          exhibit_id:
                                                                                              exhibit.ID,
                                                                                      },
                                                                                      search: `${booth.ID}?${board.ID}`,
                                                                                  }}
                                                                              >
                                                                                  <li className='schoolTable four-gridlistpages'>
                                                                                      <label>
                                                                                          {
                                                                                              board.bezeichnung
                                                                                          }
                                                                                      </label>
                                                                                      <label>
                                                                                          {
                                                                                              board.ueberschriftZeile1
                                                                                          }
                                                                                      </label>
                                                                                      {board.kzFreigabeAussteller ? (
                                                                                          <div>
                                                                                              Ja
                                                                                          </div>
                                                                                      ) : (
                                                                                          <div>
                                                                                              <a
                                                                                                  className='button approve'
                                                                                                  onClick={(
                                                                                                      e,
                                                                                                  ) => {
                                                                                                      e.preventDefault();
                                                                                                      approveItem(
                                                                                                          this,
                                                                                                          'board',
                                                                                                          board,
                                                                                                      );
                                                                                                  }}
                                                                                              >
                                                                                                  Freigeben
                                                                                              </a>
                                                                                          </div>
                                                                                      )}
                                                                                      {board.kzFreigabeAussteller ? (
                                                                                          <div />
                                                                                      ) : (
                                                                                          <div
                                                                                              onClick={(
                                                                                                  e,
                                                                                              ) => {
                                                                                                  e.preventDefault();
                                                                                                  if (
                                                                                                      window.confirm(
                                                                                                          `Wollen Sie den Eintrag "${board.bezeichnung}" wirklich löschen?`,
                                                                                                      )
                                                                                                  ) {
                                                                                                      this.deleteItem(
                                                                                                          board,
                                                                                                      );
                                                                                                  }
                                                                                              }}
                                                                                          >
                                                                                              <FontAwesomeIcon
                                                                                                  icon={
                                                                                                      faTrash
                                                                                                  }
                                                                                                  size='1x'
                                                                                              />
                                                                                          </div>
                                                                                      )}
                                                                                  </li>
                                                                              </Link>
                                                                          </ul>
                                                                      ),
                                                                  )
                                                          : null}
                                                  </Fragment>
                                              ))
                                        : null}
                                </ul>
                            </div>
                        </div>
                    );
                })}
                <div className='buttonwrap buttonwrap-end'>
                    {/*<Link className="small-button" to="/home">vollständige Liste als Pdf ausgeben</Link>*/}
                </div>
                <StepButtons
                    back={'/booths/exhibits'}
                    to={'/booths/steles'}
                    labelBack={'Exponate'}
                    labelForward={'Stelen'}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, _prop) => ({
    booth: state.booth || [],
    exhibit_deadline: state.deadlines?.exhibit_deadline,
    themeWorld: state.themeWorld || [],
    board: state.board || [],
    exhibit: state.exhibit || [],
});

export default connect(mapStateToProps, null)(ExhibitBoards);
