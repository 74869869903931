import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import style from '../../../exhibitor/index.module.css';
import StepButtons from '../../stepbuttons';
import { getEntity } from '../../../../actions/exhibitor/exhibitor-actions.js';
import { showAlert, ERROR, ERROR_MSG_BACKEND_FETCH } from '../../alerts';
import {
    getForwardActionForRoles,
    getBackwardActionForRoles,
    determineAuthForRoles,
} from '../../functions';
import { VERANSTALTUNG__TYP__TOUR } from '../../../../backend-id-constants';
import { timeMapper } from '../veranstaltungen-functions';
import Loader from '../../../common/loader/index.jsx';
import bannerSx from '../../organisation/banner-print/banner-print.module.scss';

/**
 * Filtert alle Veranstaltungen nach Standbesuchen, denormalisiert und sortiert sie aufsteigend nach `beginnBelegung`.
 * @returns Liste aus Standbesuchen als Einzelobjekte
 */
export const createSortedBoothVisits = (props) => {
    const relationName = 'tp_Veranstaltung_2_Stand2VeranstaltungLst';
    const lstItemName = 'TpStand2VeranstaltungLstItem';

    const boothVisits = props.workshop
        .reduce((acc, visit) => {
            if (
                // Veranstaltung nicht freigegeben?
                visit.idStatus !== 4 ||
                // Veranstaltung kein Rundgang?
                visit.idTyp !== VERANSTALTUNG__TYP__TOUR
            ) {
                return acc;
            }

            // Gehört der Stand zur Firma?
            const booths = visit[relationName][lstItemName].filter((rel) => {
                const found = props.booth.find((b) => b.ID === rel.idStand);
                return found?.idFirma === props.company?.[0].ID;
            });

            acc.push(
                booths.map((booth) => ({
                    booth,
                    visit,
                })),
            );

            return acc;
        }, [])
        .flat();

    // Aufsteigend nach Beginndatum sortieren
    boothVisits.sort((a, b) => {
        return (
            new Date(a.booth.beginnBelegung) - new Date(b.booth.beginnBelegung)
        );
    });

    return boothVisits;
};

class BoothVisits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            valid: true,
        };
    }

    async handleFetch() {
        try {
            await Promise.all([
                await this.props.dispatch(getEntity('booth')),
                await this.props.dispatch(
                    getEntity('workshop', {
                        // Edge Case: wir müssen alle Veranstaltungen zum Filtern kennen, weil sonst nicht alle Rundgänge beachtet werden
                        idFirma: undefined,
                        idTyp: VERANSTALTUNG__TYP__TOUR,
                    }),
                ),
            ]);
        } catch (e) {
            showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
            console.error('error checking code, promise rejected:', e);
            this.setState({
                state: e?.state ? e.state : 'invalid',
            });
        } finally {
            this.setState({
                ready: true,
            });
        }
    }

    componentDidMount() {
        this.handleFetch();
    }

    render() {
        if (!this.state.ready) {
            return (
                <div style={{ height: '90vh' }}>
                    <Loader visible />
                </div>
            );
        }

        const forwardAction = getForwardActionForRoles(
            'liveprogram',
            this.props,
        );
        const backwardAction = getBackwardActionForRoles(
            'stageContributions',
            this.props,
        );

        const boothVisits = createSortedBoothVisits(this.props);

        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>
                        <b>Liste der Standbesuche</b>
                        <br />
                    </h1>
                    <p>
                        Hier sehen Sie eine Auflistung der Standbesuche, die für
                        Ihren Stand während der Veranstaltung vorgesehen sind.
                        Bitte stellen Sie sicher, dass zu den angegebenen
                        Zeitfenstern ein/e AnsprechpartnerIn auf dem Stand
                        verfügbar ist, da die Rundgänge auf einer genauen
                        Zeitplanung basieren.
                    </p>
                </div>

                <table className={bannerSx.bannerTable}>
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: 0,
                                }}
                            >
                                ID
                            </th>
                            <th>Name</th>
                            <th>Raum / Stand</th>
                            <th
                                style={{
                                    width: 0,
                                }}
                            >
                                Beginn – Ende
                            </th>
                        </tr>
                        {boothVisits.map((item, index) => (
                            // rome-ignore lint/suspicious/noArrayIndexKey: Reihenfolge ändert sich hier nicht
                            <tr key={index}>
                                <td>{item.visit.ID}</td>
                                <td>{item.visit.titel}</td>
                                <td>{item.booth.standNrFinal}</td>
                                <td
                                    style={{
                                        whiteSpace: 'pre',
                                        fontWeight: 400,
                                    }}
                                >
                                    {timeMapper(item.booth.beginnBelegung)} –{' '}
                                    {timeMapper(item.booth.endeBelegung)}
                                </td>
                            </tr>
                        ))}
                    </thead>
                </table>

                <div className='buttonwrap buttonwrap-end'>
                    {/*<Link className="small-button" to="/pdfgenerator">vollständige Liste als Pdf ausgeben</Link>*/}
                </div>
                <StepButtons
                    back={backwardAction.backwardAction}
                    to={forwardAction.forwardAction}
                    labelBack={backwardAction.backwardText}
                    labelForward={forwardAction.forwardText}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, _prop) => {
    // Ermittlung der Rollenberechtigungen des angemeldeten Users
    const userAuth = determineAuthForRoles(state);

    return {
        workshop: state.workshop || [],
        booth: state.booth || [],
        company: state.company || [],
        workshop_deadline: state.deadlines?.workshop_deadline,
        workshop_deadline_within: state.deadlines.workshop_deadline_within,
        hasBoothAuthorization: userAuth.hasBoothAuthorization,
        hasWorkshopAuthorization: userAuth.hasWorkshopAuthorization,
        hasLiveprogramAuthorization: userAuth.hasLiveprogramAuthorization,
        hasClubzukunftAuthorization: userAuth.hasClubzukunftAuthorization,
        hasOrganisationAuthorization: userAuth.hasOrganisationAuthorization,
        hasCarreerStageAuthorization: userAuth.hasCarreerStageAuthorization,
    };
};

export default reduxForm({
    form: 'workshops',
})(connect(mapStateToProps, null)(BoothVisits));
