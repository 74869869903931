import React, { Component } from 'react';
import style from '../index.module.css';
import FileInput from '../fileinput';

import logoTemplate1 from '../../../images/logo_SEEK_1.jpg';
import logoTemplate2 from '../../../images/logo_SEEK_3.jpg';
import logoTemplate3 from '../../../images/logo_SEEK_2.jpg';
import logoTemplate4 from '../../../images/logo_SEEK_4.jpg';
import logoTemplate5 from '../../../images/logo_SEEK_5.jpg';
import logoTemplate6 from '../../../images/logo_SEEK_6.jpg';

class Logos extends Component {
    constructor(props) {
        super(props);

        this.logoPositioning = [
            { id: 1, name: '1 Logo', value: 'logo_SEEK_1.jpg' },
            { id: 2, name: '2 Logos horizontal', value: 'logo_SEEK_2.jpg' },
            { id: 3, name: '2 Logos vertikal', value: 'logo_SEEK_3.jpg' },
            {
                id: 4,
                name: '3 Logos horizontal 1 oben / 2 unten',
                value: 'logo_SEEK_4.jpg',
            },
            {
                id: 5,
                name: '3 Logos vertikal 1 links / 2 rechts',
                value: 'logo_SEEK_5.jpg',
            },
            { id: 6, name: '4 Logos', value: 'logo_SEEK_6.jpg' },
        ];

        this.logoTemplates = [
            logoTemplate1,
            logoTemplate2,
            logoTemplate3,
            logoTemplate4,
            logoTemplate5,
            logoTemplate6,
        ];

        this.state = {
            valid: true,
            logoTemplate:
                this.props.positionLogos && this.props.positionLogos > 0
                    ? this.props.positionLogos - 1
                    : 'logo_SEEK_0.jpg',
            nrLogos: !this.props.positionLogos
                ? 0
                : this.props.positionLogos === 1
                ? 1
                : this.props.positionLogos > 1 && this.props.positionLogos < 4
                ? 2
                : this.props.positionLogos >= 4 && this.props.positionLogos <= 5
                ? 3
                : 4,
            datasheets: this.props.datasheets,
            reset: this.props.reset || 0,
        };

        this.input = React.createRef();

        this.fileInput = [];
    }

    handleInput(e) {
        this.setState({ valid: true });
        if (e?.target) {
            if (e.target.name === 'delete') {
                const newDatasheets = this.state.datasheets;
                newDatasheets[e.target.id - 1] = { name: '', link: '' };

                this.setState((_prevState, _props) => {
                    return {
                        datasheets: newDatasheets,
                    };
                });
            }
        }
        this.props.handleInput(e);
    }

    handleSelection(event) {
        const e = { ...event, target: { ...this.input.current } };
        if (e.currentTarget) {
            if (
                e.currentTarget.name ===
                `position-${this.props.specificLogoGroupNamePrefix}Logos`
            ) {
                this.setState((_prevState, _props) => {
                    return {
                        logoTemplate: e.currentTarget.selectedIndex - 1,
                        nrLogos:
                            e.currentTarget.selectedIndex === 1
                                ? 1
                                : e.currentTarget.selectedIndex > 1 &&
                                  e.currentTarget.selectedIndex < 4
                                ? 2
                                : e.currentTarget.selectedIndex >= 4 &&
                                  e.currentTarget.selectedIndex <= 5
                                ? 3
                                : 4,
                    };
                });
            }
        }
        this.props.handleInput(e);
    }

    resetForm() {
        this.setState({
            logoTemplate:
                this.props.positionLogos && this.props.positionLogos > 0
                    ? this.props.positionLogos - 1
                    : 'logo_SEEK_0.jpg',
            nrLogos: !this.props.positionLogos
                ? 0
                : this.props.positionLogos === 1
                ? 1
                : this.props.positionLogos > 1 && this.props.positionLogos < 4
                ? 2
                : this.props.positionLogos >= 4 && this.props.positionLogos <= 5
                ? 3
                : 4,
            datasheets: this.props.datasheets,
            reset: this.props.reset,
        });
        this.fileInput.forEach((fInput) => {
            if (fInput) {
                fInput.value = '';
            }
        });
    }

    render() {
        if (this.props.reset && this.props.reset !== this.state.reset) {
            this.resetForm();
        }
        const logos = [];
        let logoNumber = 0;
        for (let i = 0; i < this.state.nrLogos; i++) {
            logoNumber = i + 1;
            logos.push(
                <FileInput
                    key={i}
                    label={
                        this.props.standardLogo
                            ? `Standardlogo ${logoNumber} auswählen`
                            : `${this.props.specificLogoGroupNamePrefix}Logo ${logoNumber} auswählen`
                    }
                    i={logoNumber}
                    name={this.state.datasheets[i].name}
                    link={this.state.datasheets[i].link}
                    handleInput={this.handleInput.bind(this)}
                    imageField={`data4Logo${logoNumber}`}
                    nameField={`logo${logoNumber}`}
                    specificLogoGroupNamePrefix={
                        this.props.specificLogoGroupNamePrefix
                    }
                    reset={this.state.reset}
                />,
            );
        }
        const nameSelect = `position-${this.props.specificLogoGroupNamePrefix}Logos`;
        const labelSelect = nameSelect;
        const idSelect = nameSelect;

        return (
            <div>
                {/* TODO: If a defaultlogo is set for the exhibit, do not ask for logos */}
                {!this.props.standardLogoExists ? (
                    <div>
                        <label htmlFor={labelSelect}>
                            <span>
                                Positionierung der{' '}
                                {this.props.specificLogoGroupNamePrefix}Logos
                            </span>
                        </label>
                        <select
                            component='select'
                            name={nameSelect}
                            id={idSelect}
                            required
                            onChange={(e) => this.handleSelection(e)}
                            value={
                                this.state.logoTemplate !== 'logo_SEEK_0.jpg'
                                    ? this.logoPositioning[
                                          this.state.logoTemplate
                                      ].value
                                    : this.state.logoTemplate
                            }
                            ref={this.input}
                        >
                            <option key='0' value='logo_SEEK_0.jpg' hidden>
                                Bitte wählen Sie eine Logo-Anordnung
                            </option>
                            {this.logoPositioning.map((logo) => (
                                <option key={logo.id} value={logo.value}>
                                    {logo.name}
                                </option>
                            ))}
                        </select>
                        {this.state.logoTemplate !== 'logo_SEEK_0.jpg' ? (
                            <img
                                style={{ height: '130px' }}
                                alt=''
                                src={
                                    this.logoTemplates[this.state.logoTemplate]
                                }
                            />
                        ) : null}
                        <div className={style.logoContainer}>{logos}</div>
                    </div>
                ) : (
                    <div>
                        <h3>
                            Sie haben ein Standardlogo f&#xFC;r diesen Stand
                            angegeben.
                        </h3>
                    </div>
                )}
            </div>
        );
    }
}

Logos.defaultProps = {
    positionLogos: 0,
    datasheets: [
        { name: '', link: '' },
        { name: '', link: '' },
        { name: '', link: '' },
        { name: '', link: '' },
    ],
    handleInput: function (_e) {},
    standardLogo: false,
    standardLogoExists: false,
    specificLogoGroupNamePrefix: '',
};

export default Logos;
