import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';
import { AnimatedSwitch } from 'react-router-transition';
import { connect } from 'react-redux';

import Status from '../common/status';
import Counter from '../common/counter';
import Brand from '../common/brand';

import Welcome from '../questions-registration-system/welcome';
import SchoolSelection from '../questions-registration-system/school-selection';
import SchoolRegistration from '../questions-registration-system/school-registration';
import SchoolRegistrationValidate from '../questions-registration-system/school-registration-validate';
import SchoolRegistrationSend from '../questions-registration-system/school-registration-send';
import SchoolRegistrationSuccess from '../questions-registration-system/school-registration-success';
import RegistrationPersonals from '../questions-registration-system/personals';
import Group from '../questions-registration-system/group';
import Schedule from '../questions-registration-system/schedule';
import Allowance from '../questions-registration-system/allowance';
import RegistrationValidate from '../questions-registration-system/validate';
import RegistrationSend from '../questions-registration-system/send';
import RegistrationSuccess from '../questions-registration-system/success';

import Check from '../questions-invitation-system/check';
import Login from '../questions-invitation-system/login';
import Attendance from '../questions-invitation-system/attendance';
import Personals from '../questions-invitation-system/personals';
import Guests from '../questions-invitation-system/guests';
import Substitute from '../questions-invitation-system/substitute';
import Validate from '../questions-invitation-system/validate';
import Success from '../questions-invitation-system/success';
import Send from '../questions-invitation-system/send';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { setStep, clearStep } from '../../actions/common/actions';

// Exhibitor - Ausstellerportal
// General
import { determineAuthForRoles } from '../exhibitor/functions';
import ErrorBoundary from '../exhibitor/errorboundary';
import ExhibitorMenu from '../exhibitor/menu';
import ExhibitorLogin from '../exhibitor/login';
import ExhibitorCheck from '../exhibitor/check';
import ExhibitorInvalid from '../exhibitor/check/invalid.jsx';
// Home
import Home from '../exhibitor/ieform';
// Booth - Stände
import BoothOverview from '../exhibitor/booths';
import DefaultLogo from '../exhibitor/booths/defaultlogo';
import Exhibits from '../exhibitor/booths/exhibits';
import ExhibitEdit from '../exhibitor/booths/exhibits/edit';
import ExhibitBoards from '../exhibitor/booths/boards';
import BoardsEdit from '../exhibitor/booths/boards/boards-edit';
import Furnishings from '../exhibitor/booths/furnishings';
import { EditFurnishing } from '../exhibitor/booths/furnishings/furnishing.edit.jsx';
// import FurnishingsNew from "../exhibitor/booths/furnishings/new";
//import Technic from "../exhibitor/booths/technic";
import StelesOld from '../exhibitor/booths/steles';
import { Steles } from '../exhibitor/booths/steles/steles.jsx';
import StelesNew from '../exhibitor/booths/steles/new';
// import StelesEdit from '../exhibitor/booths/steles/edit';
import { StelesEdit } from '../exhibitor/booths/steles/steles.edit';
// Veranstaltungen
import Veranstaltungen from '../exhibitor/veranstaltungen';
// Workshops
import Workshops from '../exhibitor/veranstaltungen/workshops';
import WorkshopEdits from '../exhibitor/veranstaltungen/workshops/edits';
// Bühnenbeiträge
import StageContribution from '../exhibitor/veranstaltungen/stage-contributions';
// Standbesuche
import BoothVisits from '../exhibitor/veranstaltungen/booth-visits';
// Live-Progam
import LiveProgram from '../exhibitor/liveprogram';
import LiveProgramNone from '../exhibitor/liveprogram/noliveprogram';
import StageSeven from '../exhibitor/liveprogram/stageseven';
import StageSevenTabs from '../exhibitor/liveprogram/stageseven/edit';
import ShowStage from '../exhibitor/liveprogram/showstage';
import ShowStageTabs from '../exhibitor/liveprogram/showstage/edit';
// ClubZukunft
import ClubZukunft from '../exhibitor/clubzukunft';
import CareerMarket from '../exhibitor/clubzukunft/career-market';
import ContactData from '../exhibitor/clubzukunft/career-market/contactdata';
import ContactDataInternet from '../exhibitor/clubzukunft/career-market/contactdata/internet';
import Sites from '../exhibitor/clubzukunft/career-market/sites';
import SitesEdit from '../exhibitor/clubzukunft/career-market/sites/edit';
import Training from '../exhibitor/clubzukunft/career-market/training';
import CareerStage from '../exhibitor/clubzukunft/career-stage';
import CareerStageEdit from '../exhibitor/clubzukunft/career-stage/edit';
import { StudyField } from '../exhibitor/clubzukunft/career-market/study-field/study-field.jsx';
import DualStudy from '../exhibitor/clubzukunft/career-market/study';
// Organisation
import Organisation from '../exhibitor/organisation';
import { ExhibitorPasses } from '../exhibitor/organisation/exhibitor-passes/exhibitor-passes.jsx';
import ExhibitorCatering from '../exhibitor/organisation/exhibitor-catering';
import Storage from '../exhibitor/organisation/storage';
// PDF GENERATOR
import PdfGenerator from '../exhibitor/pdfgenerator';
import NewPassword from '../exhibitor/login/newPassword';

import '../exhibitor/index.home.css';
import { BannerPrint } from '../exhibitor/organisation/banner-print/banner-print.jsx';
import { BannerPrintEdit } from '../exhibitor/organisation/banner-print/banner-print.edit.jsx';
import { MAINTENANCE } from '../../global_constants.js';
import { BannerDigitalEdit } from '../exhibitor/organisation/banner-digital/banner-digital.edit.jsx';
import { BannerDigital } from '../exhibitor/organisation/banner-digital/banner-digital.jsx';

class Index extends Component {
    // rome-ignore lint/correctness/noUnreachableSuper: <explanation>möglicherweise ein falsch-positiver Treffer bzw. Rome-Bug
    constructor(props) {
        super(props);
        this.routes = [];
        this.steps = 0;
        if (process.env.REACT_APP_IDEENEXPO === 'registration') {
            this.routes = [
                this.makeRoute(
                    1,
                    { exact: true, path: '/', render: () => <Welcome /> },
                    [1],
                ),
                this.makeRoute(
                    1,
                    { path: '/welcome', render: () => <Welcome /> },
                    [1],
                ),
                this.makeRoute(
                    2,
                    {
                        path: '/school-selection',
                        render: () => <SchoolSelection />,
                    },
                    [1, 2],
                ),
                this.makeRoute(
                    3,
                    {
                        path: '/school-registration',
                        render: () => <SchoolRegistration />,
                    },
                    [1, 2, 3],
                ),
                this.makeRoute(
                    7,
                    {
                        path: '/school-registration-validate',
                        render: () => <SchoolRegistrationValidate />,
                    },
                    [1, 2, 3, 7],
                ),
                this.makeRoute(
                    7,
                    {
                        path: '/sending-school',
                        render: () => <SchoolRegistrationSend />,
                    },
                    false,
                ),
                this.makeRoute(
                    8,
                    {
                        path: '/school-registration-success',
                        render: () => <SchoolRegistrationSuccess />,
                    },
                    [1, 2, 3, 7, 8],
                ),
                this.makeRoute(
                    3,
                    {
                        path: '/personals',
                        render: () => <RegistrationPersonals />,
                    },
                    [1, 2, 3],
                ),
                this.makeRoute(
                    4,
                    { path: '/group', render: () => <Group /> },
                    [1, 2, 3, 4],
                ),
                this.makeRoute(
                    5,
                    { path: '/schedule', render: () => <Schedule /> },
                    [1, 2, 3, 4, 5],
                ),
                this.makeRoute(
                    6,
                    { path: '/allowance', render: () => <Allowance /> },
                    [1, 2, 3, 4, 5, 6],
                ),
                this.makeRoute(
                    7,
                    {
                        path: '/validate',
                        render: () => <RegistrationValidate />,
                    },
                    [1, 2, 3, 4, 5, 6, 7],
                ),
                this.makeRoute(
                    8,
                    { path: '/sending', render: () => <RegistrationSend /> },
                    false,
                ),
                this.makeRoute(
                    8,
                    { path: '/success', render: () => <RegistrationSuccess /> },
                    [1, 2, 8],
                ),
            ];
            this.steps = 8;
            this.routes.push(
                this.makeRoute(1, { path: '*', render: () => <Welcome /> }),
                [1],
            );
        } else if (process.env.REACT_APP_IDEENEXPO === 'invitation') {
            this.routes = [
                this.makeRoute(
                    1,
                    { exact: true, path: '/', render: () => <Login /> },
                    [1],
                ),
                this.makeRoute(
                    1,
                    {
                        path: '/login/:key',
                        render: ({ match }) => (
                            <Login loginkey={match.params.key} />
                        ),
                    },
                    [1],
                ),
                this.makeRoute(
                    2,
                    { path: '/check', render: () => <Check /> },
                    [1, 2],
                ),
                this.makeRoute(
                    2,
                    { path: '/attendance', render: () => <Attendance /> },
                    [1, 2, 3, 4, 5, 6],
                ),
                this.makeRoute(
                    3,
                    { path: '/personals', render: () => <Personals /> },
                    [1, 2, 3, 4, 5, 6],
                ),
                this.makeRoute(
                    3,
                    { path: '/substitute', render: () => <Substitute /> },
                    [1, 2, 3, 4, 5, 6],
                ),
                this.makeRoute(
                    4,
                    { path: '/guests', render: () => <Guests /> },
                    [1, 2, 3, 4, 5, 6],
                ),
                this.makeRoute(
                    5,
                    { path: '/validate', render: () => <Validate /> },
                    [1, 2, 3, 4, 5, 6],
                ),
                this.makeRoute(
                    5,
                    { path: '/cancellation', render: () => <Validate /> },
                    [1, 2, 3],
                ),
                this.makeRoute(
                    6,
                    { path: '/sending', render: () => <Send /> },
                    false,
                ),
                this.makeRoute(
                    6,
                    { path: '/success', render: () => <Success /> },
                    [1],
                ),
            ];
            this.steps = 6;
            this.routes.push(
                this.makeRoute(1, { path: '*', render: () => <Login /> }),
                [1],
            );
        } else if (process.env.REACT_APP_IDEENEXPO === 'exhibitor') {
            this.routes = [
                // Generell
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/',
                    render: () => <ExhibitorLogin />,
                }),
                this.makeRouteExhibitor({
                    path: '/login',
                    render: () => <ExhibitorLogin />,
                }),
                this.makeRouteExhibitor({
                    path: '/login/:key',
                    render: ({ match }) => (
                        <ExhibitorLogin loginkey={match.params.key} />
                    ),
                }),
                this.makeRouteExhibitor({
                    path: '/check',
                    render: () => <ExhibitorCheck />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/pdfgenerator',
                    render: () => <PdfGenerator />,
                }),
                // Home
                this.makeRouteExhibitor({
                    path: '/home',
                    render: () => <Home />,
                }),
                // NewPassword
                this.makeRouteExhibitor({
                    path: '/newPassword',
                    render: () => <NewPassword />,
                }),
                // Booth - Stände
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/booths',
                    render: () => <BoothOverview />,
                }),
                this.makeRouteExhibitor({
                    path: '/booths/defaultlogo',
                    render: () => <DefaultLogo />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/booths/exhibits',
                    render: () => <Exhibits />,
                }),
                this.makeRouteExhibitor({
                    path: '/booths/exhibits/edit',
                    render: () => <ExhibitEdit />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/booths/exhibit-boards',
                    render: () => <ExhibitBoards />,
                }),
                this.makeRouteExhibitor({
                    path: '/booths/exhibit-boards/edit',
                    render: () => <BoardsEdit />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/booths/steles',
                    render: () => <Steles />,
                }),
                this.makeRouteExhibitor({
                    path: '/booths/steles/new',
                    render: () => <StelesNew />,
                }),
                this.makeRouteExhibitor({
                    path: '/booths/steles/edit',
                    render: () => <StelesEdit />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/booths/furnishings',
                    render: () => <Furnishings />,
                }),
                // this.makeRouteExhibitor({path:"/booths/furnishings/new", render: ()=> <FurnishingsNew /> }),
                this.makeRouteExhibitor({
                    path: '/booths/furnishings/edit',
                    render: () => <EditFurnishing />,
                }),
                // this.makeRouteExhibitor({path:"/booths/technic", render: ()=> <Technic /> }),
                // Veranstaltungen
                this.makeRouteExhibitor({
                    path: '/veranstaltungen',
                    render: () => <Veranstaltungen />,
                }),
                // Workshops
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/workshops',
                    render: () => <Workshops />,
                }),
                this.makeRouteExhibitor({
                    path: '/workshops/edits',
                    render: () => <WorkshopEdits />,
                }),
                // Bühnenbeiträge
                this.makeRouteExhibitor({
                    path: '/stage-contributions',
                    render: () => <StageContribution />,
                }),
                // Standbesuche
                this.makeRouteExhibitor({
                    path: '/booth-visits',
                    render: () => <BoothVisits />,
                }),
                // Live-Progam
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/live-program',
                    render: () => <LiveProgram />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/live-program/noliveprogram',
                    render: () => <LiveProgramNone />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/live-program/stage-seven',
                    render: () => <StageSeven />,
                }),
                this.makeRouteExhibitor({
                    path: '/live-program/stage-seven/edit',
                    render: () => <StageSevenTabs />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/live-program/show-stage',
                    render: () => <ShowStage />,
                }),
                this.makeRouteExhibitor({
                    path: '/live-program/show-stage/edit',
                    render: () => <ShowStageTabs />,
                }),
                // ClubZukunft
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/clubzukunft',
                    render: () => <ClubZukunft />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/clubzukunft/career-market',
                    render: () => <CareerMarket />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/clubzukunft/career-market/contact',
                    render: () => <ContactData />,
                }),
                this.makeRouteExhibitor({
                    path: '/clubzukunft/career-market/contact/internet',
                    render: () => <ContactDataInternet />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/clubzukunft/career-market/sites',
                    render: () => <Sites />,
                }),
                this.makeRouteExhibitor({
                    path: '/clubzukunft/career-market/sites/edit',
                    render: () => <SitesEdit />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/clubzukunft/career-market/training',
                    render: () => <Training />,
                }),
                this.makeRouteExhibitor({
                    path: '/clubzukunft/career-market/studyfields',
                    render: () => <StudyField />,
                }),
                this.makeRouteExhibitor({
                    path: '/clubzukunft/career-market/dual-study',
                    render: () => <DualStudy />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/clubzukunft/career-stage',
                    render: () => <CareerStage />,
                }),
                this.makeRouteExhibitor({
                    path: '/clubzukunft/career-stage/edit',
                    render: () => <CareerStageEdit />,
                }),
                // Organisation
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/organisation',
                    render: () => <Organisation />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/organisation/exhibitor-passes',
                    render: () => <ExhibitorPasses />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/organisation/exhibitor-catering',
                    render: () => <ExhibitorCatering />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/organisation/storage',
                    render: () => <Storage />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/organisation/banner-print',
                    render: () => <BannerPrint />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/organisation/banner-print/edit',
                    render: () => <BannerPrintEdit />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/organisation/banner-digital',
                    render: () => <BannerDigital />,
                }),
                this.makeRouteExhibitor({
                    exact: true,
                    path: '/organisation/banner-digital/edit',
                    render: () => <BannerDigitalEdit />,
                }),
            ];
        }
        this.routes.push(
            this.makeRouteExhibitor({
                path: '*',
                render: () => <ExhibitorInvalid />,
            }),
        );
    }

    /**
     * Make a route with automatic step button / back-navigation logic.
     * @param {*} step the step number for this route
     * @param {*} props the props for the Route component
     * @param {*} clearWhitelist which routes should not be cleared, or none if false
     */
    makeRoute(step, props, clearWhitelist = []) {
        return (
            <Route
                {...props}
                key={props.path}
                render={() => {
                    this.props.dispatch(setStep(step, props.path));
                    if (clearWhitelist !== false) {
                        this.props.dispatch(clearStep(clearWhitelist));
                    }
                    return props.render();
                }}
            />
        );
    }

    /**
     * Make a route with automatic step button / back-navigation logic For EXHIBITOR.
     * @param {*} props the props for the Route component
     */
    makeRouteExhibitor(props) {
        return (
            <Route
                {...props}
                key={props.path}
                render={() => (
                    <ErrorBoundary>
                        {this.checkAuthorization(props)}
                    </ErrorBoundary>
                )}
            />
        );
    }

    checkAuthorization(props) {
        // User is logged in?
        if (
            '/login' === props.path ||
            '/check' === props.path ||
            this.props.loggedIn === true
        ) {
            // User is authorized for the component ?
            // get the rootpath of the current path, capturing group 1
            let rootpath = props.path.match('^(/[a-zA-Z0-9-]*)/?');
            if (Array.isArray(rootpath) && rootpath.length >= 2) {
                rootpath = rootpath[1];
            }
            switch (rootpath) {
                case '/booths': {
                    return this.props.hasBoothAuthorization ? (
                        props.render()
                    ) : (
                        <Redirect to='/login' />
                    );
                }
                case '/veranstaltungen':
                case '/workshops':
                case '/stage-contributions':
                case '/booth-visits': {
                    return this.props.hasWorkshopAuthorization ? (
                        props.render()
                    ) : (
                        <Redirect to='/login' />
                    );
                }
                case '/live-program': {
                    return this.props.hasLiveprogramAuthorization ? (
                        props.render()
                    ) : (
                        <Redirect to='/login' />
                    );
                }
                case '/clubzukunft': {
                    return this.props.hasClubzukunftAuthorization ? (
                        props.render()
                    ) : (
                        <Redirect to='/login' />
                    );
                }
                case '/organisation': {
                    return this.props.hasOrganisationAuthorization ? (
                        props.render()
                    ) : (
                        <Redirect to='/login' />
                    );
                }
                case '/newPassword':
                case '/login':
                case '/check':
                case '/home': {
                    return props.render();
                }
                default:
                    return <Redirect to='/login' />;
            }
        } else {
            return <Redirect to='/login' />;
        }
    }

    render() {
        let layout;

        if (process.env.REACT_APP_IDEENEXPO !== 'exhibitor') {
            layout = (
                <div>
                    <Counter />
                    <SimpleBar style={{ height: '100%' }} id='content'>
                        <Brand />
                        <AnimatedSwitch
                            mapStyles={(s) => ({
                                opacity: s.opacity,
                                transform: `translateX(${s.offset}%)`,
                            })}
                            atEnter={{ opacity: 0, offset: 100 }}
                            atLeave={{ opacity: 0, offset: -100 }}
                            atActive={{ opacity: 1, offset: 0 }}
                            className='content-switch'
                        >
                            {this.routes}
                        </AnimatedSwitch>
                    </SimpleBar>
                    {process.env.REACT_APP_IDEENEXPO !== 'registration' &&
                        !MAINTENANCE.registration?.status && (
                            <Status steps={this.steps} />
                        )}
                </div>
            );
        } else {
            layout = (
                <div>
                    <SimpleBar style={{ height: '100%' }} id='content'>
                        <Brand />
                        <AnimatedSwitch
                            mapStyles={(s) => ({
                                opacity: s.opacity,
                                transform: `translateX(${s.offset}%)`,
                            })}
                            atEnter={{ opacity: 0, offset: 100 }}
                            atLeave={{ opacity: 0, offset: -100 }}
                            atActive={{ opacity: 1, offset: 0 }}
                            className='content-switch'
                        >
                            {this.routes}
                        </AnimatedSwitch>
                    </SimpleBar>
                    <ExhibitorMenu />
                </div>
            );
        }

        return <div>{layout}</div>;
    }
}

const mapStateToProps = (state, _props) => {
    const authForRoles = determineAuthForRoles(state);
    return {
        loggedIn: Boolean(state.authenticationDataExhibitor?.accessToken),
        ...authForRoles,
    };
};

export default connect(mapStateToProps, null)(Index);
