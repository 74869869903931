export const handleLogoInput = (e, that) => {
    if (e?.target) {
        if (e.currentTarget?.name.startsWith('position-')) {
            that.setState({
                values: {
                    ...that.state.values,
                    idPositionierungLogo: e.currentTarget.selectedIndex,
                },
            });
        } else if (e.target.name === 'delete') {
            that.setState({
                values: {
                    ...that.state.values,
                    [e.target.nameField]: '',
                    [e.target.imageField]: '',
                    //['data4Logo' + e.target.id]: "",
                    //['logo' + e.target.id]: ""
                },
            });
        } else if (e.target.id.startsWith('datasheet')) {
            const id = e.target.id.charAt(e.target.id.length - 1);
            that.setState({
                values: {
                    ...that.state.values,
                    files: {
                        ...that.state.values.files,
                        [id]: {
                            file: e.target.files[0],
                            nameField: e.nameField || '',
                            imageField: e.imageField || '',
                        },
                    },
                },
            });
        }
    }
};
