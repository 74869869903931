import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class StepButtons extends Component {
    render() {
        return (
            <div className='buttonwrap'>
                {this.props.back ? (
                    <Link
                        className='button left'
                        to={this.props.back}
                        onClick={() => {
                            document
                                .querySelector('.simplebar-content-wrapper')
                                .scrollTo(0, 0);
                        }}
                    />
                ) : (
                    <span />
                )}
                {this.props.to ? (
                    this.props.disabled ? (
                        <Link
                            className={`button ${
                                this.props.toText
                                    ? 'disabled'
                                    : 'right disabled'
                            }`}
                            to='#'
                        >
                            {this.props.toText}
                        </Link>
                    ) : (
                        <Link
                            className={`button ${
                                this.props.toText ? '' : 'right'
                            }`}
                            to={this.props.to}
                            onClick={() => {
                                document
                                    .querySelector('.simplebar-content-wrapper')
                                    .scrollTo(0, 0);
                            }}
                        >
                            {this.props.toText}
                        </Link>
                    )
                ) : (
                    <span />
                )}
            </div>
        );
    }
}

export default connect(null, null)(StepButtons);
