import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import { connect } from 'react-redux';
import style from '../../index.module.css';
import FormButtons from '../../formbuttons';
import StepButtons from '../../stepbuttons';
import {
    updateLiveprogram,
    getEntity,
} from '../../../../actions/exhibitor/exhibitor-actions.js';
import {
    validateForm,
    showAlert,
    SUCCESS,
    SUCCESS_MSG,
    ERROR,
    ERROR_MSG_BACKEND_SAVE,
} from '../../alerts';
import disableForm from '../../disableForm.js';
import {
    RADIO_VALUE_LIVE_PROGRAM_DISABLED,
    RADIO_VALUE_LIVE_PROGRAM_ENABLED,
} from '../../../../global_constants';
import {
    determineAuthForRoles,
    getBackwardActionForRoles,
    getForwardActionForRoles,
} from '../../functions';

class NoLiveProgram extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            submitSuccess: false,
        };
    }
    submit(values) {
        validateForm(
            this,
            values,
            [],
            this.props.stages_deadline_within,
            this.props.noLiveProgram,
        );
        Promise.resolve()
            .then(() => {
                if (this.props.initialValues.contract_id) {
                    return this.props
                        .dispatch(
                            updateLiveprogram(this.props.contract, values),
                        )
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                }
            })
            .then(() => this.props.dispatch(getEntity('contract')))
            .then(() =>
                this.setState({
                    submitSuccess: true,
                }),
            )
            .then(() => showAlert(SUCCESS, SUCCESS_MSG))
            .then(() => {
                if (this.props.noLiveProgram) {
                    disableForm();
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_SAVE);
                this.setState({ submitSuccess: false });
                this.props.dispatch(stopSubmit('noliveprogram', e));
            });
    }
    componentDidMount() {
        if (this.props.noLiveProgram || !this.props.stages_deadline_within) {
            disableForm();
        }
    }
    resetForm() {
        this.setState((_state, _props) => ({
            submitSuccess: false,
        }));
    }
    render() {
        const forwardAction = getForwardActionForRoles(
            'stageseven',
            this.props,
        );
        const backwardAction = getBackwardActionForRoles(
            'liveprogram',
            this.props,
        );
        const { handleSubmit } = this.props;
        const radioName = 'noliveprogram';
        const idLiveProgramDisabled = 'liveprogram_disabled';
        const idLiveProgramEnabled = 'liveprogram_enabled';
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1> LiveProgramm deaktivieren </h1>
                    {!this.props.stages_deadline_within ? (
                        <div>
                            Sie können die Daten nur innerhalb des
                            Bearbeitungszeitraums bearbeiten
                            <br />
                        </div>
                    ) : null}
                    {this.props.noLiveProgram ? (
                        <div>
                            Sie haben das LiveProgramm deaktiviert
                            <br />
                        </div>
                    ) : null}
                </div>
                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <fieldset>
                        <Field
                            component='input'
                            type='radio'
                            id={idLiveProgramDisabled}
                            name={radioName}
                            value={RADIO_VALUE_LIVE_PROGRAM_DISABLED}
                            required
                        />
                        <label htmlFor={idLiveProgramDisabled}>
                            <span>
                                Wir werden <strong>keine</strong> Beiträge zum
                                LiveProgramm anbieten
                            </span>
                        </label>
                        <Field
                            component='input'
                            type='radio'
                            id={idLiveProgramEnabled}
                            name={radioName}
                            value={RADIO_VALUE_LIVE_PROGRAM_ENABLED}
                            required
                        />
                        <label htmlFor={idLiveProgramEnabled}>
                            <span>
                                Wir werden Beiträge zum LiveProgramm anbieten
                            </span>
                        </label>
                        <FormButtons
                            handleSubmit={handleSubmit}
                            submit={this.submit.bind(this)}
                            hideBtn={true}
                        />
                        <legend>(*) = Pflichtfeld</legend>
                    </fieldset>
                </form>
                <StepButtons
                    back={backwardAction.backwardAction}
                    to={forwardAction.forwardAction}
                    labelBack={backwardAction.backwardText}
                    labelForward={forwardAction.forwardText}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, _prop) => {
    // Ermittlung der Rollenberechtigungen des angemeldeten Users
    const userAuth = determineAuthForRoles(state);
    let contract = {};
    let noLiveProgram = true;
    let initialValues = {};

    const hasContracts =
        Array.isArray(state.contract) && state.contract?.length > 0;
    if (hasContracts) {
        contract = state.contract[0];
        noLiveProgram = contract.liveProgrammDeaktiviert === 1;
        initialValues = {
            contract_id: contract.ID || null,
            noliveprogram: noLiveProgram
                ? RADIO_VALUE_LIVE_PROGRAM_DISABLED
                : RADIO_VALUE_LIVE_PROGRAM_ENABLED,
        };
    }

    return {
        initialValues: initialValues,
        ...userAuth,
        hasLiveProgram: !noLiveProgram,
        noLiveProgram,
        stages_deadline_within: state.deadlines?.stages_deadline_within,
        contract: contract,
    };
};

export default connect(
    mapStateToProps,
    null,
)(
    reduxForm({
        form: 'noliveprogram',
        destroyOnUnmount: false,
        keepDirtyOnReinitialize: true,
        enableReinitialize: true,
    })(NoLiveProgram),
);
