import React from 'react';
import StepButtons from '../../common/stepbuttons';

export default (props) => (
    <>
        <h1>
            <strong>Fehler</strong>
        </h1>
        <p>
            {props.reason && typeof props.reason !== 'object' ? (
                props.reason
            ) : (
                <div>Passwort und/oder Benutzername unbekannt.</div>
            )}
        </p>
        {props.forwardTo ? (
            <StepButtons back={`${props.forwardTo}`} />
        ) : (
            <StepButtons back='/' />
        )}
    </>
);
