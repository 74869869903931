import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import FormButtons from '../../../formbuttons';
import { getEntity } from '../../../../../actions/exhibitor/exhibitor-actions';
import {
    updateBoard,
    insertBoard,
} from '../../../../../actions/exhibitor/board';
import style from '../../../index.module.css';
import disableForm from '../../../disableForm.js';
import Logos from '../../../logos';
import { handleLogoInput } from '../../../logos/logos.js';
import UploadFile from '../../../uploadfile';
import {
    validateForm,
    SUCCESS,
    SUCCESS_MSG,
    showAlert,
    ERROR,
    ERROR_MSG_BACKEND_SAVE,
    ERROR_MSG_BACKEND_FETCH,
    ERROR_MSG_UPLOAD,
} from '../../../alerts';
import { blockScreen } from '../../../functions';
import {
    EXPONAT_TAFEL__TYP__A3,
    EXPONAT_TAFEL__TYP__A4,
} from '../../../../../backend-id-constants.js';

class BoardsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            logoTemplate: '',
            charsTextTafel: props.initialValues?.text_tafel
                ? Number(330 - props.initialValues.text_tafel.length)
                : 330,
            submitSuccess: false,
            values: { files: {} }, // logos
            reset: 0, // logos
        };
    }
    resetForm() {
        this.setState({ submitSuccess: false });
        this.setState((_state, props) => ({
            charsTextTafel: props.initialValues?.text_tafel
                ? Number(330 - props.initialValues.text_tafel.length)
                : 330,
            reset: this.state.reset + 1, //logos
        }));
    }
    handleInput(e) {
        this.setState({ valid: true });
        if (e && e.target.name === 'text_tafel') {
            this.setState((_state, _props) => ({
                charsTextTafel: 330 - Number(e.target.value.length),
            }));
        }
        handleLogoInput(e, this); //logos
    }

    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                if (this.props.boothExists) {
                    return this.props.dispatch(getEntity('booth'));
                } else {
                    Promise.resolve();
                }
            })
            .then(() => {
                if (
                    this.props.exhibit &&
                    !Object.keys(this.props.exhibit).length
                ) {
                    return this.props.dispatch(getEntity('exhibit'));
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                if (this.props.board) {
                    Promise.all(
                        this.props.exhibit.map((item) => {
                            return new Promise((resolve, reject) => {
                                return this.props
                                    .dispatch(
                                        getEntity('board', {
                                            idExponat: item.ID,
                                        }),
                                    )
                                    .then((result) => {
                                        resolve(result);
                                    })
                                    .then(() => {
                                        this.setState({
                                            reset: this.state.reset + 1, //logos
                                        });
                                    })
                                    .catch((result) => {
                                        reject(result);
                                    });
                            });
                        }),
                    );
                } else {
                    Promise.resolve();
                }
            })
            .then(() => {
                if (this.props.initialValues['status'] === 'freigegeben') {
                    disableForm();
                }
            })
            .catch((e) => {
                showAlert(ERROR, ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    submit(valuesParam) {
        let values = {
            //logos
            ...valuesParam,
            ...this.state.values,
        };
        validateForm(
            this,
            values,
            ['status', 'exponate', 'name'],
            this.props.exhibit_deadline_within,
        );

        Promise.all(
            Object.entries(values.files).map((file) => {
                //logos
                // resolve logo images
                return new Promise((resolve, reject) => {
                    if (file[1].file && typeof file[1].file === 'object') {
                        return UploadFile(file[1].file)
                            .then((img) => {
                                values = {
                                    ...values,
                                    [file[1].imageField]: img.file,
                                    [file[1].nameField]: img.name,
                                };
                                resolve(img);
                            })
                            .catch((_result) => {
                                reject({ _error: ERROR_MSG_UPLOAD });
                            });
                    } else {
                        resolve();
                    }
                });
            }),
        )
            .then(() => {
                blockScreen(true);

                // if updating an existing entity
                if (this.props.handle) {
                    return this.props
                        .dispatch(updateBoard(this.props.found, values))
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                } else {
                    // if creating a new entity
                    return this.props
                        .dispatch(
                            insertBoard({
                                ...values,
                                idStand: this.props.booth_id || '',
                            }),
                        )
                        .then((json) => {
                            // update the board (Exponattafel) that was newly created in Redux
                            this.props
                                .dispatch(
                                    getEntity('board', { ID: json.ID || '' }),
                                )
                                .then((_e) => {
                                    this.setState({
                                        reset: this.state.reset + 1, //logos
                                    });
                                });
                            return json;
                        })
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                }
            })
            .then((json) => {
                if (!this.props.handle) {
                    this.props.history.push(
                        `/booths/exhibit-boards/edit?${this.props.booth_id}?${json.ID}`,
                    );
                }
            })
            .then(() =>
                this.setState({
                    submitSuccess: true,
                    reset: this.state.reset + 1, //logos
                }),
            )
            .then(() => showAlert(SUCCESS, SUCCESS_MSG))
            .then(() => {
                blockScreen(false);

                if (this.props.initialValues['status'] === 'freigegeben') {
                    disableForm();
                }
            })
            // .then(() => {
            // 	// gewünscht war nach dem Speichern zur Übersichtsseite zurückzukommen
            // 	this.props.dispatch(push("/booths/exhibit-boards"))
            // 	})
            .catch((e) => {
                blockScreen(false);

                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_SAVE);
                this.setState({ submitSuccess: false });
                this.props.dispatch(stopSubmit('boardsedit', e));
            });
    }
    render() {
        const { handleSubmit } = this.props;

        const charsTextTafel = this.state.charsTextTafel;
        return (
            <div className={style.question}>
                <div className={style.header}>
                    {this.props.initialValues.status === 'freigegeben' ? (
                        <div>Sie haben diese Daten bereits freigegeben</div>
                    ) : null}
                    {!this.props.exhibit_deadline_within ? (
                        <div>
                            Sie können die Daten nur innerhalb des
                            Bearbeitungszeitraums bearbeiten
                        </div>
                    ) : null}
                    {this.props.handle ? (
                        <h1>
                            Exponattafel {this.props.found?.bezeichnung}{' '}
                            bearbeiten
                        </h1>
                    ) : (
                        <h1>Neue Exponattafel erstellen</h1>
                    )}
                </div>

                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <fieldset>
                        <legend />
                        <label htmlFor='name'>
                            <span>
                                Bitte geben Sie dieser Exponattafel eine
                                treffende Bezeichnung (nur intern) (*)
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='board_id'
                            id='board_id'
                            hidden
                            value={this.props.handle || ''}
                        />
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='booth_id'
                            id='booth_id'
                            hidden
                            value={this.props.booth_id || ''}
                        />
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='name'
                            id='name'
                        />
                        <label htmlFor='aufsteller'>
                            <span>Welche Aufsteller benötigen Sie</span>
                        </label>
                        <Field
                            component='select'
                            name='aufsteller'
                            id='aufsteller'
                            required
                        >
                            <option value={EXPONAT_TAFEL__TYP__A3}>
                                DIN A3 Aufsteller
                            </option>
                            <option value={EXPONAT_TAFEL__TYP__A4}>
                                DIN A4 Aufsteller
                            </option>
                        </Field>
                        <label htmlFor='exponate'>
                            <span>Exponat (*)</span>
                        </label>
                        <Field
                            component='select'
                            name='exponate'
                            id='exponate'
                            required
                        >
                            <option value='' hidden>
                                Bitte wählen Sie ein Exponat aus (*)
                            </option>
                            {this.props.exhibit
                                .filter(
                                    (item) =>
                                        item.idStand === this.props.booth_id &&
                                        ((this.props.found &&
                                            this.props.found.idExponat ===
                                                item.ID) ||
                                            this.props.boards.find(
                                                (board) =>
                                                    board.idExponat === item.ID,
                                            ) == null),
                                )
                                .map((item, _index) => (
                                    <option key={item.ID} value={item.ID}>
                                        {item.projekttitel}
                                    </option>
                                ))}
                        </Field>
                        <label htmlFor='ueberschrift1'>
                            <span>
                                Überschrift Zeile 1 (maximal 20 Zeichen)
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='ueberschrift1'
                            id='ueberschrift1'
                            maxLength='20'
                        />
                        <label htmlFor='ueberschrift2'>
                            <span>
                                Überschrift Zeile 2 (maximal 20 Zeichen)
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='ueberschrift2'
                            id='ueberschrift2'
                            maxLength='20'
                        />
                        <label htmlFor='text_tafel'>
                            <span>Text (10 Zeilen à ca. 33 Zeichen)</span>
                        </label>
                        <Field
                            name='text_tafel'
                            maxLength='330'
                            rows='10'
                            style={{
                                resize: 'none',
                            }}
                            placeholder=''
                            component='textarea'
                            id='text_tafel'
                            onChange={(e) => this.handleInput(e)}
                        />
                        <legend>
                            Bitte geben Sie maximal 330 Zeichen ein. Es bleiben
                            noch {charsTextTafel} Zeichen.
                        </legend>
                        <h2>Gestaltung der Logofläche</h2>
                        <hr />
                        <p>
                            Sofern erforderlich, können Sie auf den
                            Exponattafeln bis zu vier verschiedene Logos
                            platzieren. Alle Logodateien sollten als
                            Vektorgrafik im eps-Format hochgeladen werden.
                        </p>
                        <Logos
                            positionLogos={
                                this.props.initialValues.idPositionierungLogo ||
                                0
                            }
                            datasheets={[
                                {
                                    name: this.props.found?.logo1 || '',
                                    link:
                                        `data:image/png;base64,${this.props.found?.data4Logo1}` ||
                                        '',
                                },
                                {
                                    name: this.props.found?.logo2 || '',
                                    link:
                                        `data:image/png;base64,${this.props.found?.data4Logo2}` ||
                                        '',
                                },
                                {
                                    name: this.props.found?.logo3 || '',
                                    link:
                                        `data:image/png;base64,${this.props.found?.data4Logo3}` ||
                                        '',
                                },
                                {
                                    name: this.props.found?.logo4 || '',
                                    link:
                                        `data:image/png;base64,${this.props.found?.data4Logo4}` ||
                                        '',
                                },
                            ]}
                            handleInput={this.handleInput.bind(this)}
                            reset={this.state.reset}
                            standardLogoExists={this.props.standardLogoExists}
                        />
                        <div className={style.space} />
                    </fieldset>
                    <FormButtons
                        showCancel={true}
                        overviewLink='/booths/exhibit-boards'
                        hideBtn={true}
                        handleSubmit={handleSubmit}
                        submit={this.submit.bind(this)}
                    />
                    <legend>(*) = Pflichtfeld</legend>
                </form>
                {/* <StepButtons back={'/booths/exhibit-boards'} to={'/booths/steles'}  labelBack={'Liste der Exponattafeln'} labelForward={'Stelen'} /> */}
            </div>
        );
    }
}

const mapStateToProps = (state, _props) => {
    const propsSearch = state.router?.location?.search || '??';
    const boothTemp = parseInt(
        propsSearch.substring(
            propsSearch.indexOf('?') + 1,
            propsSearch.lastIndexOf('?'),
        ),
    );
    const booth_id = state.router?.location?.state?.booth_id || boothTemp || '';
    const handleTemp = parseInt(
        propsSearch.substring(propsSearch.lastIndexOf('?') + 1),
    );

    const booth =
        state.booth && Array.isArray(state.booth)
            ? state.booth.find((x) => x.ID === booth_id)
            : {};

    const handle = state.router?.location?.state?.handle || handleTemp || '';
    const Props = {
        feature: state.feature || [],
        exhibit: state.exhibit || [],
        booth_id: booth_id,
        booth: booth,
        boothExists: state.booth && !Object.keys(state.booth).length,
        boards: state.board || [],
        board: state.board && !Object.keys(state.board).length,
    };
    if (handle == null) {
        return { ...Props, initialValues: {} };
    }

    const found =
        state.board && Array.isArray(state.board)
            ? state.board.find((x) => x.ID === handle)
            : {};

    let initialValues = {
        aufsteller: EXPONAT_TAFEL__TYP__A3,
    };
    if (found && found.ID === handle && booth) {
        initialValues = {
            board_id: handle || '',
            name: found.bezeichnung || '',
            text_tafel: found.exponattafelText || '',
            aufsteller: found.idTyp || EXPONAT_TAFEL__TYP__A3,
            exponate: found.idExponat || '',
            ueberschrift1: found.ueberschriftZeile1 || '',
            ueberschrift2: found.ueberschriftZeile2 || '',
            status:
                found.kzFreigabeAussteller === 1
                    ? 'freigegeben'
                    : found.kzFreigabeAussteller === 0
                    ? 'inbearbeitung'
                    : '',
            idPositionierungLogo: found.idLogopositionierung
                ? found.idLogopositionierung !== -1
                    ? found.idLogopositionierung
                    : 0
                : 0,
            data4Logo1: found.data4Logo1 || '',
            data4Logo2: found.data4Logo2 || '',
            data4Logo3: found.data4Logo3 || '',
            data4Logo4: found.data4Logo4 || '',
            logo1: found.logo1 || '',
            logo2: found.logo2 || '',
            logo3: found.logo3 || '',
            logo4: found.logo4 || '',
        };
    }
    return {
        ...Props,
        initialValues,
        found,
        exhibit_deadline_within: state.deadlines?.exhibit_deadline_within,
        handle: handle,
        standardLogoExists: booth?.kzStandardlogos || 0,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'boardsedit',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(BoardsEdit),
);
