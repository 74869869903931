import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { push } from 'connected-react-router';
import { store } from '../../../store';

import Loader from '../../common/loader';
import Question from '../../common/question';
import { mapSchuleAnrede } from '../../../backend-id-constants';
import { BACKEND_URL, SCHOOL_ADD } from '../../../global_constants';

import { setSchoolUpdate } from '../../../actions/registration-system/registration-actions.js';

import style from '../../common/index.module.css';

class SendSchool extends Question {
    constructor(props) {
        super(props, ['form.school_registration.values'], {});
    }
    validate() {
        this.send();
    }

    send() {
        const regdata = this.props.formdata.school_registration.values;

        const idSchulForm = this.props.schoolTypeList.find(
            (obj) => obj.name === regdata.schoolType,
        ).id;
        const salutationId = Number(regdata.salutation);

        const schoolValues = {
            emailSchule: regdata.emailschool,
            // faxSchule: ?,
            idSchulForm: idSchulForm,
            // internetAdresseSchule: ?,
            nameSchule: regdata.schoolname,
            bundeslandSchule: this.props.plzMapping.Bundesland || 'UNBEKANNT',
            // nummerSchule: ?,
            ortSchule: regdata.city,
            plzSchule: regdata.zipcode,
            strasseSchule: regdata.street,
            idAnrede: mapSchuleAnrede(salutationId)?.value || -1,
            antragStellerName: regdata.lastname,
            antragStellerVorname: regdata.firstname,
            antragStellerPosition: regdata.position,
            antragStellerEmail: regdata.email,
            antragStellerTelefon: regdata.phone,
            // vorwahlSchule: ?,
            // Evtl. ergängzen: Informationen zum Antragssteller?
            idStatus: 1,
        };

        const json = JSON.stringify({
            login: {
                ...this.props.authenticationData.login,
                userId4Hist: undefined,
            },
            ...schoolValues,
        });

        fetch(BACKEND_URL + SCHOOL_ADD, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.props.authenticationData.login.accessToken}`,
            },
            body: json,
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                try {
                    return JSON.parse(text);
                } catch (error) {
                    return Promise.reject(error);
                }
            })
            .then((json) => {
                this.props.dispatch(setSchoolUpdate(json));
                return json;
            })
            .then((json) => {
                store.dispatch(
                    change(
                        'school_selection',
                        'school_selection',
                        `${json.ID}`,
                    ),
                );
                return json;
            })
            .then(() => {
                this.props.dispatch(push('/school-registration-success'));
            })
            .catch((error) => {
                console.error('error sending school, promise rejected:', error);
                this.setState({
                    invalid: true,
                });
            });
    }

    render() {
        if (!this.state.invalid) {
            return (
                <div className={style.question}>
                    <Loader visible={true} />
                </div>
            );
        } else {
            return (
                <>
                    <div className={style.question}>
                        <h1>
                            <strong>Fehler</strong> beim Absenden
                        </h1>
                        <p>
                            Es gab einen Fehler beim Speichern der Daten. Bitte
                            laden Sie die Seite neu und versuchen es erneut.
                        </p>
                    </div>
                </>
            );
        }
    }
}

export default connect((s) => ({ formdata: s.form, ...s }))(SendSchool);
