// -----------------------------------------------------------------------------------
// Funktion prüft, ob eine Anmeldung aufgrund bestimmter Status im Email-Status

import {
    TEILNEHMER__STATUS_WORKFLOW__FORMBLATT_VERSCHICKT,
    WORKSHOP__STATUS,
    mapWorkshopStatus,
} from '../../backend-id-constants';

// und im Workshop-Status durch die Schule noch editiert werden darf
export default function isWorkflowEditable(anmeldung) {
    return (
        anmeldung.idStatusWorkflow <
            TEILNEHMER__STATUS_WORKFLOW__FORMBLATT_VERSCHICKT &&
        [
            WORKSHOP__STATUS.NONE,
            WORKSHOP__STATUS.OFFEN,
            WORKSHOP__STATUS.IN_KLAERUNG,
        ].includes(mapWorkshopStatus(anmeldung.idStatusWorkshop))
    );
}
