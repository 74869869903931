import { combineReducers } from 'redux';

import { connectRouter } from 'connected-react-router';
import { reducer as reduxFormReducer } from 'redux-form';
import { localizeReducer } from 'react-localize-redux';

const stepReducer = (state = { step: 0, routes: {} }, action) => {
    switch (action.type) {
        case 'SET_STEP':
            return {
                step: action.step,
                routes: {
                    [action.step]: action.route,
                    ...state.routes,
                },
            };
        case 'CLEAR_STEP': {
            const routes = Object.keys(state.routes)
                .map(Number)
                .filter((key) => action.whitelist.includes(key))
                .reduce((obj, key) => {
                    obj[key] = state.routes[key];
                    return obj;
                }, {});
            return {
                step: state.step,
                routes: routes,
            };
        }
        default:
            return state;
    }
};

const plzMappingReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_PLZMAPPING':
            return action.plzMapping;
        default:
            return state;
    }
};

const zipCodeCitiesReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_ZIPCODE_CITIES':
            return action.zipCodeCities;
        default:
            return state;
    }
};

const schoolListReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_SCHOOL_LIST':
            return action.schoolList;
        case 'SET_SCHOOL_UPDATE': {
            return handleUpdateRedux(state, action.schoolList);
        }
        default:
            return state;
    }
};

const groupRegistrationListReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_GROUP_REGISTRATION_LIST':
            return action.groupRegistrationList;
        case 'SET_GROUP_REGISTRATION_UPDATE': {
            return handleUpdateRedux(state, action.groupRegistrationList);
        }
        default:
            return state;
    }
};

const groupDefaultsReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_GROUP_DEFAULTS':
            return action.groupDefaults;
        default:
            return state;
    }
};

const maxGroupReducer = (state = -1, action) => {
    switch (action.type) {
        case 'SET_MAX_GROUP':
            return action.maxGroup;
        default:
            return state;
    }
};

const schoolTypeListReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_SCHOOL_TYPE_LIST':
            return action.schoolTypeList;
        default:
            return state;
    }
};

const schoolTrackListReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_SCHOOL_TRACK_LIST':
            return action.schoolTrackList;
        default:
            return state;
    }
};

const classLevelListReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_CLASS_LEVEL_LIST':
            return action.classLevelList;
        default:
            return state;
    }
};

const allowanceListReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_ALLOWANCE':
            return action.allowanceList;
        default:
            return state;
    }
};

const arrivalDayListReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_ARRIVAL_DAY_LIST':
            return action.arrivalDayList;
        default:
            return state;
    }
};

const editedAnmeldungReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_EDITED_ANMELDUNG':
            return action.editedAnmeldung;
        default:
            return state;
    }
};

const transportListReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_TRANSPORT_LIST':
            return action.transportList;
        default:
            return state;
    }
};

const departureTimeSchoolListReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_DEPARTURE_TIME_SCHOOL_LIST':
            return action.departureTimeSchoolList;
        default:
            return state;
    }
};

const arrivalTimeListReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_ARRIVAL_TIME_LIST':
            return action.arrivalTimeList;
        default:
            return state;
    }
};

const authenticationReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_AUTHENTICATION_DATA':
            return action.authenticationData;
        // TODO: filter out unwanted keys e.g. userId4Hist
        default:
            return state;
    }
};

const invitationReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_INVITATION':
            return action.invitation;
        case 'SET_INVITATION_DEADLINE':
            return Object.assign(
                state || {},
                {
                    invitationEditDeadline:
                        action.deadline.invitationEditDeadline,
                },
                {
                    invitationEdit_deadline_within:
                        action.deadline.invitationEdit_deadline_within,
                },
            );
        default:
            return state;
    }
};

const anonymousReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_ANONYMOUS':
            return action.anonymous;
        default:
            return state;
    }
};

const personReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_PERSON':
            return action.person;
        case 'SET_PERSON_UPDATE': {
            return handleUpdateRedux(state, action.person);
        }
        default:
            return state;
    }
};

const eventReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_EVENT':
            return action.event;
        default:
            return state;
    }
};

const exhibitReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_EXHIBIT':
            return action.exhibit;
        case 'SET_EXHIBIT_UPDATE': {
            return handleUpdateRedux(state, action.exhibit);
        }
        case 'SET_EXHIBIT_DELETE': {
            return state.filter((item) => item.ID !== action.exhibit.ID);
        }
        default:
            return state;
    }
};

const boothReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_BOOTH':
            return action.booth;
        case 'SET_BOOTH_UPDATE': {
            return handleUpdateRedux(state, action.booth);
        }

        default:
            return state;
    }
};

const boothFeatureReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_BOOTH_FEATURE':
            return action.boothFeature;
        case 'SET_BOOTH_FEATURE_UPDATE': {
            return handleUpdateRedux(state, action.boothFeature);
        }
        default:
            return state;
    }
};

const workshopReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_WORKSHOP':
            return action.workshop;
        case 'SET_WORKSHOP_UPDATE': {
            return handleUpdateRedux(state, action.workshop);
        }
        case 'SET_WORKSHOP_DELETE': {
            return state.filter((item) => item.ID !== action.workshop.ID);
        }
        default:
            return state;
    }
};

const companyReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_COMPANY':
            return action.company;
        case 'SET_COMPANY_UPDATE': {
            return handleUpdateRedux(state, action.company);
        }
        default:
            return state;
    }
};

const organisationReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_ORGANISATION':
            return action.organisation;
        default:
            return state;
    }
};

const storageReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_STORAGE':
            return action.storage;
        case 'SET_STORAGE_UPDATE': {
            return handleUpdateRedux(state, action.storage);
        }
        default:
            return state;
    }
};

const cateringReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_CATERING':
            return action.catering;
        case 'SET_CATERING_UPDATE': {
            return handleUpdateRedux(state, action.catering);
        }
        default:
            return state;
    }
};

const passesReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_PASSES':
            return action.passes;
        case 'SET_PASSES_UPDATE': {
            return handleUpdateRedux(state, action.passes);
        }
        case 'SET_PASSES_DELETE': {
            return state.filter((item) => item.ID !== action.passes.ID);
        }
        default:
            return state;
    }
};

const featureReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_FEATURE':
            return action.feature;
        case 'SET_FEATURE_UPDATE': {
            return handleUpdateRedux(state, action.feature);
        }
        default:
            return state;
    }
};

const authenticationExhibitorReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_AUTHENTICATION_DATA_EXHIBITOR':
            return action.authenticationDataExhibitor;
        // TODO: filter out unwanted keys e.g. userId4Hist
        default:
            return state;
    }
};

const authTimeoutReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_AUTH_TIMEOUT':
            return action.authTimeout;
        default:
            return state;
    }
};

const exhibitorReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_EXHIBITOR':
            return action.exhibitor;
        default:
            return state;
    }
};

const boardReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_BOARD': {
            return action.board;
        }
        case 'SET_BOARD_UPDATE': {
            return handleUpdateRedux(state, action.board);
        }
        case 'SET_BOARD_DELETE': {
            return state.filter((item) => item.ID !== action.board.ID);
        }
        default:
            return state;
    }
};

const steleReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_STELE':
            return action.stele;
        case 'SET_STELE_UPDATE': {
            return handleUpdateRedux(state, action.stele);
        }
        case 'SET_STELE_DELETE': {
            return state.filter((item) => item.ID !== action.stele.ID);
        }
        default:
            return state;
    }
};

const themeWorldReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_THEME_WORLD':
            return action.themeWorld;
        default:
            return state;
    }
};

const trainingReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_TRAINING':
            return action.training;
        default:
            return state;
    }
};

const trainingDefReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_TRAINING_DEF':
            return action.trainingdef;
        default:
            return state;
    }
};

const studyfieldReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_STUDYFIELD':
            return action.studyfield;
        case 'SET_STUDYFIELD_UPDATE': {
            return handleUpdateRedux(state, action.studyfield);
        }
        default:
            return state;
    }
};

const studyfieldDefReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_STUDYFIELD_DEF':
            return action.studyfielddef;
        default:
            return state;
    }
};

const dualstudyReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_DUALSTUDY':
            return action.dualstudy;
        case 'SET_DUALSTUDY_UPDATE': {
            return handleUpdateRedux(state, action.dualstudy);
        }
        default:
            return state;
    }
};

const dualstudyDefReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_DUALSTUDY_DEF':
            return action.dualstudydef;
        default:
            return state;
    }
};

const showstageReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_SHOWSTAGE':
            return action.showstage;
        case 'SET_SHOWSTAGE_UPDATE': {
            return handleUpdateRedux(state, action.showstage);
        }
        case 'SET_SHOWSTAGE_DELETE': {
            return state.filter((item) => item.ID !== action.showstage.ID);
        }
        default:
            return state;
    }
};

const stagesevenReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_STAGESEVEN':
            return action.stageseven;
        case 'SET_STAGESEVEN_UPDATE': {
            return handleUpdateRedux(state, action.stageseven);
        }
        case 'SET_STAGESEVEN_DELETE': {
            return state.filter((item) => item.ID !== action.stageseven.ID);
        }
        default:
            return state;
    }
};

const careerstageReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_CAREERSTAGE':
            return action.careerstage;
        case 'SET_CAREERSTAGE_UPDATE': {
            return handleUpdateRedux(state, action.careerstage);
        }
        case 'SET_CAREERSTAGE_DELETE': {
            return state.filter((item) => item.ID !== action.careerstage.ID);
        }
        default:
            return state;
    }
};

const exponatzgReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_EXPONATZG_LIST':
            return action.exponatzg;
        default:
            return state;
    }
};

const steletypeReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_STELETYPE':
            return action.steletype;
        default:
            return state;
    }
};

const booth2featureReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_BOOTH2FEATURE':
            return action.booth2feature;
        case 'SET_BOOTH2FEATURE_UPDATE': {
            return handleUpdateRedux(state, action.booth2feature);
        }
        default:
            return state;
    }
};

const infodeskReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_INFODESK':
            return action.infodesk;
        case 'SET_INFODESK_UPDATE': {
            return handleUpdateRedux(state, action.infodesk);
        }
        default:
            return state;
    }
};

const presentationmoduleReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_PRESENTATIONMODULE':
            return action.presentationmodule;
        case 'SET_PRESENTATIONMODULE_UPDATE': {
            return handleUpdateRedux(state, action.presentationmodule);
        }
        default:
            return state;
    }
};

const company2siteReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_COMPANY2SITE':
            return action.company2site;
        case 'SET_COMPANY2SITE_UPDATE': {
            return handleUpdateRedux(state, action.company2site);
        }
        case 'SET_COMPANY2SITE_DELETE': {
            return state.filter((item) => item.ID !== action.company2site.ID);
        }
        default:
            return state;
    }
};

const veranstzpReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_VERANSTZP_LIST':
            return action.veranstzp;
        default:
            return state;
    }
};

const veranstzielgruppeReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_VERANSTZIELGRUPPE_LIST': {
            return action.veranstzielgruppe;
        }
        default:
            return state;
    }
};

const bezugsgruppeReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_BEZUGSGRUPPE_LIST': {
            return action.bezugsgruppe;
        }
        default:
            return state;
    }
};

const user2roleReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_USER2ROLE':
            return action.user2role;
        case 'SET_USER2ROLE_UPDATE': {
            return handleUpdateRedux(state, action.user2role);
        }
        default:
            return state;
    }
};

const siteReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_SITE': {
            return action.site;
        }
        default:
            return state;
    }
};

const projectReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_PROJECT':
            return action.project;
        default:
            return state;
    }
};

const contractReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_CONTRACT':
            return action.contract;
        default:
            return state;
    }
};

const deadlinesReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_DEADLINES':
            return action.deadlines;
        default:
            return state;
    }
};

const company2personReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_COMPANY2PERSON':
            return action.company2person;
        default:
            return state;
    }
};

const guestReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_GUESTS':
            return action.guests;
        default:
            return state;
    }
};

const substituteReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_SUBSTITUTE': {
            if (
                Array.isArray(action.substitute) &&
                action.substitute.length > 0
            ) {
                return action.substitute[0];
            } else {
                return action.substitute;
            }
        }
        default:
            return state;
    }
};

const bannerPrintReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_BANNER_PRINT':
            return action.bannerPrint;
        case 'SET_BANNER_PRINT_UPDATE': {
            return handleUpdateRedux(state, action.bannerPrint);
        }
        default:
            return state;
    }
};
const bannerPrintListReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_BANNER_PRINT_LIST':
            return action.bannerPrintList;
        default:
            return state;
    }
};

const bannerDigitalReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_BANNER_DIGITAL':
            return action.bannerDigital;
        case 'SET_BANNER_DIGITAL_UPDATE': {
            return handleUpdateRedux(state, action.bannerDigital);
        }
        default:
            return state;
    }
};
const bannerDigitalListReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_BANNER_DIGITAL_LIST':
            return action.bannerDigitalList;
        default:
            return state;
    }
};

const firmaKategorieListReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_FIRMA_KATEGORIE_LIST':
            return action.firmaKategorieList;
        default:
            return state;
    }
};

const handleUpdateRedux = (state = {}, action) => {
    if (Array.isArray(action)) {
        // if state is an array, we need to filter out the <items> that are
        // already - outdated - in state and return all other existing state
        // items plus the new versions of state
        if (Array.isArray(state) && state.length > 0) {
            const updatedItems = [];
            const newItems = [];
            const existingItems = [];
            const arr = new Array(action.length);
            state.forEach((itemInState) => {
                let found = false;
                action.forEach((itemInPayload, ind) => {
                    if (itemInPayload.ID === itemInState.ID) {
                        updatedItems.push(itemInPayload);
                        found = true;
                        arr[ind] = true;
                    }
                });
                if (!found) {
                    existingItems.push(itemInState);
                }
            });
            for (let i = 0; i < arr.length; i++) {
                if (!arr[i]) {
                    newItems.push(action[i]);
                }
            }
            return [...existingItems, ...newItems, ...updatedItems];
        } else {
            // if state is not an array, return (all of the deconstructed)
            // action.<item>
            return [...action];
        }
    } else {
        if (Array.isArray(state)) {
            const newState = state.filter((item) => item.ID !== action.ID);
            newState.push(action);
            return newState;
        } else {
            return [action];
        }
    }
};

export const rootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        form: reduxFormReducer,
        localize: localizeReducer,
        step: stepReducer,
        plzMapping: plzMappingReducer,
        zipCodeCities: zipCodeCitiesReducer,
        schoolList: schoolListReducer,
        groupRegistrationList: groupRegistrationListReducer,
        groupDefaults: groupDefaultsReducer,
        maxGroup: maxGroupReducer,
        schoolTypeList: schoolTypeListReducer,
        schoolTrackList: schoolTrackListReducer,
        classLevelList: classLevelListReducer,
        allowanceList: allowanceListReducer,
        arrivalDayList: arrivalDayListReducer,
        editedAnmeldung: editedAnmeldungReducer,
        transportList: transportListReducer,
        departureTimeSchoolList: departureTimeSchoolListReducer,
        arrivalTimeList: arrivalTimeListReducer,
        authenticationData: authenticationReducer,
        invitation: invitationReducer,
        anonymous: anonymousReducer,
        person: personReducer,
        event: eventReducer,
        exhibit: exhibitReducer,
        booth: boothReducer,
        boothFeatures: boothFeatureReducer,
        workshop: workshopReducer,
        company: companyReducer,
        organisation: organisationReducer,
        storage: storageReducer,
        catering: cateringReducer,
        passes: passesReducer,
        feature: featureReducer,
        authenticationDataExhibitor: authenticationExhibitorReducer,
        authTimeout: authTimeoutReducer,
        exhibitor: exhibitorReducer,
        board: boardReducer,
        stele: steleReducer,
        themeWorld: themeWorldReducer,
        training: trainingReducer,
        trainingdef: trainingDefReducer,
        studyfield: studyfieldReducer,
        studyfielddef: studyfieldDefReducer,
        dualstudy: dualstudyReducer,
        dualstudydef: dualstudyDefReducer,
        showstage: showstageReducer,
        stageseven: stagesevenReducer,
        careerstage: careerstageReducer,
        exponatzg: exponatzgReducer,
        steletype: steletypeReducer,
        booth2feature: booth2featureReducer,
        infodesk: infodeskReducer,
        presentationmodule: presentationmoduleReducer,
        company2site: company2siteReducer,
        veranstzp: veranstzpReducer,
        veranstzielgruppe: veranstzielgruppeReducer,
        bezugsgruppe: bezugsgruppeReducer,
        site: siteReducer,
        user2role: user2roleReducer,
        project: projectReducer,
        contract: contractReducer,
        deadlines: deadlinesReducer,
        company2person: company2personReducer,
        substitute: substituteReducer,
        guests: guestReducer,
        bannerPrintList: bannerPrintListReducer,
        bannerPrint: bannerPrintReducer,
        bannerDigitalList: bannerDigitalListReducer,
        bannerDigital: bannerDigitalReducer,
        firmaKategorie: firmaKategorieListReducer,
    });
