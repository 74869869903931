import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
    updateStage,
    insertCareerstage,
} from '../../../../../../actions/exhibitor/careerstage';
import style from '../../../../index.module.css';
import { store } from '../../../../../../store.js';
import disableForm from '../../../../disableForm.js';
import {
    max2Formats,
    showAlert,
    SUCCESS,
    SUCCESS_MSG,
    ERROR,
    validateForm,
    ERROR_MSG_BACKEND_SAVE,
} from '../../../../alerts';
import { areMaximumNumberOfCheckboxesChecked } from '../../../../functions';

class CareerStageEditBasis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            showIndividuelleAusstellerformatText:
                props.initialValues?.format_individuell,
            submitSuccess: false,
        };
    }

    handleInput(e) {
        this.setState({ valid: true });
        if (
            e &&
            e.target.type === 'checkbox' &&
            e.target.name.startsWith('format_')
        ) {
            if (e.target.checked) {
                const careerStageValues =
                    store.getState().form.careerstageeditbasis?.values;
                const checkboxValues = [
                    careerStageValues.format_fit,
                    careerStageValues.format_individuell,
                    careerStageValues.format_karriere,
                    careerStageValues.format_reim,
                ];
                if (areMaximumNumberOfCheckboxesChecked(checkboxValues, 1)) {
                    e.preventDefault();
                    return;
                }
            }
            if (e && e.target.name === 'format_individuell') {
                this.setState((state) => ({
                    showIndividuelleAusstellerformatText:
                        !state.showIndividuelleAusstellerformatText,
                }));
            }
        }
    }

    resetForm() {
        this.setState((_state, props) => ({
            showIndividuelleAusstellerformatText:
                props.initialValues?.format_individuell,
            submitSuccess: false,
        }));
    }

    componentDidMount() {
        if (this.props.initialValues['status'] === 'freigegeben') {
            disableForm();
        }
    }

    submit(values) {
        validateForm(
            this,
            values,
            ['status', 'beitrag_titel'],
            this.props.stages_deadline_within,
            this.props.noLiveProgram,
        );
        max2Formats([
            values['format_reim'],
            values['format_fit'],
            values['format_karriere'],
            values['format_individuell'],
        ]);
        const storeState = store.getState().form;
        const careerstageeditcontact =
            storeState.careerstageeditcontact?.values || {};
        Promise.resolve()
            .then(() => {
                if (this.props.handle) {
                    return this.props
                        .dispatch(
                            updateStage(this.props.found, {
                                ...careerstageeditcontact,
                                ...values,
                            }),
                        )
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                } else {
                    // if creating a new careerstage
                    return this.props
                        .dispatch(
                            insertCareerstage({
                                ...careerstageeditcontact,
                                ...values,
                            }),
                        )
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                }
            })
            .then((json) => {
                if (!this.props.handle) {
                    this.props.history.push(
                        `/clubzukunft/career-stage/edit?${json.careerstage.ID}`,
                    );
                }
            })
            .then(() =>
                this.setState({
                    submitSuccess: true,
                }),
            )
            .then(() => showAlert(SUCCESS, SUCCESS_MSG))
            .then(() => {
                if (this.props.initialValues['status'] === 'freigegeben') {
                    disableForm();
                }
            })
            // .then(() => {
            // 	// gewünscht war nach dem Speichern zur Übersichtsseite zurückzukommen
            // 	this.props.dispatch(push("/clubzukunft/career-stage"))
            // 	})
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_SAVE);
                this.setState({ submitSuccess: false });
                this.props.dispatch(stopSubmit('careerstageeditbasis', e));
            });
    }
    render() {
        const { handleSubmit } = this.props;
        const showIndividuelleAusstellerformatText = this.state
            .showIndividuelleAusstellerformatText
            ? { display: 'inline-block' }
            : { display: 'none' };
        const maxLengthCareerStageTitelBeitrag = 200;
        const maxLengthCareerStageBeschreibung = 800;
        const maxLengthCareerStageWuenscheErgaenzungen = 800;

        return (
            <div className={style.header}>
                {this.props.initialValues.status === 'freigegeben' ? (
                    <div>Sie haben diese Daten bereits freigegeben</div>
                ) : null}
                {!this.props.stages_deadline_within ? (
                    <div>
                        Sie können die Daten nur innerhalb des
                        Bearbeitungszeitraums bearbeiten
                    </div>
                ) : null}
                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <fieldset>
                        <legend />
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='careerstage_id'
                            id='careerstage_id'
                            hidden
                            disabled
                            value={this.props.handle || ''}
                            ref={this.input}
                        />
                        <label htmlFor='beitrag_titel'>
                            <span className={style.bold}>
                                Titel des Beitrags (*)
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='beitrag_titel'
                            id='beitrag_titel'
                            ref={this.input}
                            maxLength={maxLengthCareerStageTitelBeitrag}
                        />
                        <label>
                            <span className={style.bold}>Beitragsformat</span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='format_fit'
                            name='format_fit'
                            onChange={(e) => this.handleInput(e)}
                        />
                        <label htmlFor='format_fit'>
                            <span className={style.bold}>Fit für</span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='format_reim'
                            name='format_reim'
                            onChange={(e) => this.handleInput(e)}
                        />
                        <label htmlFor='format_reim'>
                            <span className={style.bold}>
                                2 Wahrheiten – 1 Lüge
                            </span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='format_karriere'
                            name='format_karriere'
                            onChange={(e) => this.handleInput(e)}
                        />
                        <label htmlFor='format_karriere'>
                            <span className={style.bold}>
                                Das Karriere-Quiz
                            </span>
                        </label>
                        <Field
                            component='input'
                            type='checkbox'
                            id='format_individuell'
                            name='format_individuell'
                            onChange={(e) => this.handleInput(e)}
                        />
                        <label htmlFor='format_individuell'>
                            <span className={style.bold}>
                                individuelles Ausstellerformat (Abstimmung mit
                                dem Veranstalter erforderlich)
                            </span>
                        </label>
                        <div style={showIndividuelleAusstellerformatText}>
                            <label htmlFor='format_individuell_text'>
                                <span className={style.bold} />
                            </label>
                            <Field
                                component='input'
                                placeholder=''
                                type='text'
                                name='format_individuell_text'
                                id='format_individuell_text'
                                ref={this.input}
                            />
                        </div>
                        <label htmlFor='beitrag_beschreibung'>
                            <span className={style.bold}>
                                Erste Beschreibung Ihres Bühnenprogramms
                            </span>
                        </label>
                        <Field
                            component='textarea'
                            placeholder=''
                            type='textarea'
                            name='beitrag_beschreibung'
                            rows='8'
                            maxLength={maxLengthCareerStageBeschreibung}
                            style={{
                                resize: 'none',
                            }}
                            id='beitrag_beschreibung'
                            ref={this.input}
                        />
                        <label htmlFor='beitrag_wuensche'>
                            <span className={style.bold}>
                                Wünsche/Ergänzungen (zur internen Verwendung)
                            </span>
                        </label>
                        <Field
                            component='textarea'
                            placeholder=''
                            type='textarea'
                            name='beitrag_wuensche'
                            rows='8'
                            maxLength={maxLengthCareerStageWuenscheErgaenzungen}
                            style={{
                                resize: 'none',
                            }}
                            id='beitrag_wuensche'
                            ref={this.input}
                        />
                        {/* <FormButtons showCancel={true} overviewLink='/clubzukunft/career-stage' hideBtn={true} handleSubmit={handleSubmit} submit={(this.submit.bind(this))} />
							<legend>(*) = Pflichtfeld</legend> */}
                    </fieldset>
                </form>
                {/* <StepButtons back={'/clubzukunft/career-stage'} toTab={() => this.props.toTab(1)} disabled={!this.state.valid} labelBack={'Liste der KarriereBühne Beiträge'} labelForward={'Ansprechpartner'} /> */}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const found =
        state.careerstage && Array.isArray(state.careerstage)
            ? state.careerstage.find((x) => x.ID === props.handle)
            : [];

    // •	Fit für -> bleibt
    // •	2 Wahrheiten – 1 Lüge – neues Format (IE2024) ->aus _reim
    // •	Das Karriere-Quiz -> bleibt
    // •	individuelles Ausstellerformat (Abstimmung mit dem Veranstalter erforderlich) -> bleibt

    let initialValues = { status: '' };
    if (found && found.ID === props.handle) {
        initialValues = {
            careerstage_id: props.handle || '',
            beitrag_titel: found.titelBeitrag || '',
            format_fit: found.kzFitFuer === 1,
            format_reim: found.kzReimEinfach === 1,
            format_karriere: found.kzDasKarriereQuiz === 1,
            format_individuell: found.kzIndividuellesAusstellerformat === 1,
            format_individuell_text:
                found.individuellesAusstellerformatBeschreibung || '',
            beitrag_beschreibung: found.beschreibung || '',
            beitrag_wuensche: found.wuenscheErgaenzungen || '',
            status:
                found.kzFreigabeAussteller === 1
                    ? 'freigegeben'
                    : found.kzFreigabeAussteller === 0
                    ? 'inbearbeitung'
                    : '',
        };
    }
    return {
        initialValues: initialValues,
        stages_deadline_within: state.deadlines?.stages_deadline_within,
        found,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'careerstageeditbasis',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(CareerStageEditBasis),
);
