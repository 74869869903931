import { store } from '../../../store';
import {
    BACKEND_URL,
    ENV,
    INFODESK_SAVE,
    PRESENTATIONMODULE_SAVE,
} from '../../../global_constants';
import {
    setInfodeskUpdate,
    setPresenationsmoduleUpdate,
} from '../exhibitor-actions.js';
import {
    showAlert,
    ERROR_UNAUTHORIZED,
    ERROR,
} from '../../../components/exhibitor/alerts';

const mapPresentationModule = (values) => ({
    logo1: values.logo1 || '',
    logo2: values.logo2 || '',
    logo3: values.logo3 || '',
    logo4: values.logo4 || '',
    data4Logo1: values.data4Logo1 || '',
    data4Logo2: values.data4Logo2 || '',
    data4Logo3: values.data4Logo3 || '',
    data4Logo4: values.data4Logo4 || '',
    idPositionierungLogo: values.idPositionierungLogo || -1,
    ueberschriftZeile1: values.ueberschriftZeile1 || '',
    ueberschriftZeile2: values.ueberschriftZeile2 || '',
    fliesstext: values.fliesstext || '',
});

const mapInfodesk = (values) => ({
    ...mapPresentationModule(values),
    idPositionierungSteleLogo: values.idPositionierungSteleLogo || -1,
    steleLogo1: values.steleLogo1 || '',
    steleLogo2: values.steleLogo2 || '',
    steleLogo3: values.steleLogo3 || '',
    steleLogo4: values.steleLogo4 || '',
    data4SteleLogo1: values.data4SteleLogo1 || '',
    data4SteleLogo2: values.data4SteleLogo2 || '',
    data4SteleLogo3: values.data4SteleLogo3 || '',
    data4SteleLogo4: values.data4SteleLogo4 || '',
});

export const updateInfodesk = (json, values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    return function (dispatch) {
        ENV === 'development' &&
            console.info('updating the Infodesk with id = ', json.ID);
        const newValues = mapInfodesk(values);
        const jsonNew = { ...json, ...newValues };
        return fetch(BACKEND_URL + INFODESK_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(jsonNew),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('Infodesk details fetch:', json);
                if (!json.ID) {
                    return Promise.reject(
                        'Infodesk details fetch validity check: fail',
                    );
                }
                return json;
            })
            .then((json) => {
                return dispatch(setInfodeskUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};

export const updatePresentationsmodul = (json, values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    return function (dispatch) {
        ENV === 'development' &&
            console.info('updating the Presentationsmodul with id = ', json.ID);
        const newValues = mapPresentationModule(values);
        const jsonNew = { ...json, ...newValues };
        return fetch(BACKEND_URL + PRESENTATIONMODULE_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(jsonNew),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('Presentationsmodul details fetch:', json);
                if (!json.ID) {
                    return Promise.reject(
                        'Presentationsmodul details fetch validity check: fail',
                    );
                }
                return json;
            })
            .then((json) => {
                return dispatch(setPresenationsmoduleUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                if (e === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                return Promise.reject(e);
            });
    };
};
