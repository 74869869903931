import React from 'react';
import style from '../index.module.css';
import StepButtons from '../stepbuttons';
import { showAlert, ERROR, SUCCESS, ERROR_MSG_BACKEND_FETCH } from '../alerts';
import { saveUser } from '../../../actions/exhibitor/exhibitor-actions';
import { store } from '../../../store';

class NewPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = { newPasswordSet: false };
    }

    updateUser = () => {
        const newPW = document.getElementById('input-newPassword').value;
        const newPWRepeat = document.getElementById(
            'input-newPassword-checked',
        ).value;

        if (newPW.match(/[^a-zA-Z0-9]/)) {
            showAlert(
                ERROR,
                'Bitte verwenden Sie in Ihrem Passwort keine Sonderzeichen und Umlaute.',
            );
            return;
        } else if (newPW === '' || newPWRepeat === '') {
            showAlert(ERROR, 'Bitte füllen Sie beide Passwortfelder aus.');
            return;
        } else if (newPW !== newPWRepeat) {
            showAlert(
                ERROR,
                'Das Passwort und die Wiederholung sind unterschiedlich.',
            );
            return;
        } else if (newPW.match(/123456/)) {
            showAlert(
                ERROR,
                'Bitte verwenden Sie nicht die Zeichenfolge 123456.',
            );
            return;
        } else if (newPW.length < 8) {
            showAlert(
                ERROR,
                'Ihr neues Passwort muss mindestens 8 Zeichen lang sein.',
            );
            return;
        } else if (newPW.length > 12) {
            showAlert(
                ERROR,
                'Ihr neues Passwort darf nicht länger als 12 Zeichen lang sein.',
            );
            return;
        } else if (
            newPW.match(/[A-Z]/) == null ||
            newPW.match(/[a-z]/) == null ||
            newPW.match(/[0-9]/) == null
        ) {
            showAlert(
                ERROR,
                'Bitte verwenden Sie in Ihrem Passwort einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl.',
            );
            return;
        }

        Promise.resolve()
            .then(() => {
                return store
                    .dispatch(
                        saveUser(
                            store.getState().exhibitor[0].login.userIdLogin,
                            newPW,
                        ),
                    )
                    .then(null, (_e) => Promise.reject());
            })

            .then(() => {
                showAlert(SUCCESS, 'Das Passwort wurde geändert.');
                this.setState({ newPasswordSet: true });
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    };

    render() {
        return (
            <div className={style.question}>
                <h1>
                    <strong>Passwort ändern </strong>
                </h1>
                <p>Hier können Sie Ihr Passwort ändern.</p>
                <p>
                    Ihr neues Passwort muss zwischen 8 und 12 Zeichen lang sein,
                    einen Kleinbuchstaben, einen Großbuchstaben und eine Zahl
                    enthalten. Bitte verwenden Sie keine Sonderzeichen und
                    Umlaute. Wenn die Änderung erfolgreich war, werden Sie
                    erneut auf die Login-Seite geleitet und müssen sich neu
                    anmelden.
                </p>
                <div className='newPassword-container'>
                    <form>
                        <input
                            id='input-newPassword'
                            className='input-newPassword'
                            type='password'
                            placeholder='Neues Passwort'
                        />
                        <input
                            id='input-newPassword-checked'
                            className='input-newPassword'
                            type='password'
                            placeholder='Passwort bestätigen'
                        />
                    </form>
                    {this.state.newPasswordSet === false ? (
                        <div className='buttonwrap'>
                            <a
                                className='button'
                                onClick={() => {
                                    this.updateUser();
                                }}
                            >
                                Speichern
                            </a>
                        </div>
                    ) : (
                        <div className='stepButton-right'>
                            <StepButtons to={'/home'} labelForward={'Home'} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default NewPassword;
