import React from 'react';
import { connect } from 'react-redux';
import { store } from '../../../store';

import style from '../../common/index.module.css';
import Loader from '../../common/loader';
import Question from '../../common/question';

import Home from '../ieform';
import NewPassword from '../login/newPassword';
import Invalid from './invalid';
import { push } from 'connected-react-router';

import { ENV } from '../../../global_constants.js';
import {
    tempAuthenticate,
    getEntity,
    readDeadlines,
    refreshLogin,
} from '../../../actions/exhibitor/exhibitor-actions.js';

class Check extends Question {
    constructor(props) {
        // super(props, [["form", "login", "values", "loginkey"]], {
        // 	state: null,
        // });
        super(props, ['form'], {
            state: null,
            reason: '',
        });
    }

    performCheck = async () => {
        if (!this.props.loggedIn) {
            this.setState({
                state: 'invalid',
            });
        }

        ENV === 'development' &&
            console.log(
                'fetch DATA',
                this.props.authenticationData,
                this.props.formdata.login,
            );

        try {
            await this.props.dispatch(
                tempAuthenticate(this.props.username, this.props.password),
            );
            this.props.dispatch(
                refreshLogin(this.props.username, this.props.password),
            );
        } catch (_e) {
            this.setState({
                state: 'invalid',
                reason: 'Benutzername und/oder Passwort unbekannt.',
            });
        }

        try {
            await this.props.dispatch(getEntity('exhibitor'));
            if (
                !Array.isArray(store.getState().exhibitor) ||
                store.getState().exhibitor.length < 1
            ) {
                throw new Error(
                    'Das Ausstellerportal ist für Aussteller und deren Mitarbeiter/innen vorgesehen. Setzen Sie sich bitte mit ihrer Kontaktperson in Verbindung, wenn Sie Zugang zum Ausstellerportal wünschen.',
                );
            }
            await Promise.all([
                this.props.dispatch(getEntity('project')),
                this.props.dispatch(
                    getEntity('user2role', {
                        USER_NR: store.getState().exhibitor[0].idUser,
                    }),
                ),
                this.props.dispatch(getEntity('company')),
            ]);
            this.props.dispatch(getEntity('themeWorld'));
            this.props.dispatch(readDeadlines);
            this.props.dispatch(getEntity('contract'));
            this.setState({
                state: 'valid',
            });
            this.props.dispatch(push('/home'));
        } catch (e) {
            // TODO: usability: show different messages depending on error
            // TODO: maintenance: log errors to backend
            console.error('error checking code, promise rejected:', e);
            this.setState({
                state: e?.state ? e.state : 'invalid',
                reason: e?.reason ? e.reason : e,
            });
        }
    };
    validate() {
        setTimeout(() => this.performCheck(), 0);
    }
    setFooterHtml() {
        return null;
    }
    renderQuestion() {
        let ren = (
            <div style={{ height: '90vh' }}>
                <Loader visible={true} />
            </div>
        );
        switch (this.state.state) {
            case 'valid-firstLogin': {
                ren = <NewPassword />;
                break;
            }
            case 'valid': {
                ren = <Home />;
                break;
            }
            case 'invalid': {
                ren = <Invalid reason={this.state.reason} />;
                break;
            }
            default:
                break;
        }
        return <div className={style.question}>{ren}</div>;
    }
    // componentDidMount() {
    // 	this.validate()
    // }
}

export default connect(
    (s) => ({
        formdata: s.form,
        username: s.router?.location?.state?.username || '',
        password: s.router?.location?.state?.password || '',
        loggedIn: s.authenticationData?.login?.accessToken || false,
        ...s,
    }),
    null,
)(Check);
