import React from 'react';
import { connect } from 'react-redux';

import style from '../../common/index.module.css';
import Loader from '../../common/loader';
import Question from '../../common/question';

import Already from './already';
import Expired from './expired';
import Deadline from './deadline';
import Disabled from './disabled';
import Invalid from './invalid';
import { Redirect } from 'react-router-dom';
import { push } from 'connected-react-router';

import Footer from '../../common/footer';

import {
    updateInvitation,
    updatePerson,
    setEvent,
    setAnonymous,
} from '../../../actions/invitation-system/invitation-actions';

import {
    BACKEND_URL,
    ENV,
    PERSON_LIST,
    EVENT_GET,
} from '../../../global_constants';
import { change } from 'redux-form';

class Check extends Question {
    constructor(props) {
        super(props, [['form', 'login', 'values', 'loginkey']], {
            state: null,
        });
    }

    performCheck() {
        if (!this.props.formdata.login) {
            return this.setState({ state: 'invalid' });
        }

        const code = this.props.formdata.login.values.loginkey;

        Promise.resolve()
            .then(() => this.props.dispatch(updateInvitation(code)))
            .then((invitation) => {
                if (invitation.idEvent && invitation.idEvent > 0) {
                    return fetch(BACKEND_URL + EVENT_GET, {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${invitation.login.accessToken}`,
                        },
                        body: JSON.stringify({
                            login: {
                                // TODO: robustness: pick required properties manually
                                ...invitation.login,
                                userId4Hist: undefined,
                            },
                            ID: invitation.idEvent,
                        }),
                    })
                        .then((response) => {
                            if (!response.ok) {
                                return Promise.reject(response.statusText);
                            } else {
                                return response.text();
                            }
                        })
                        .then((text) => {
                            try {
                                return JSON.parse(text);
                            } catch (e) {
                                return Promise.reject(e);
                            }
                        })
                        .then((eventObj) => {
                            const event = {
                                name: eventObj.name || '', // TODO: enhancement: fetch event info separately from the event ID
                                date: new Date(eventObj.beginn),
                                begleiter: eventObj.idBegleitung,
                                vertretung: eventObj.kzVertretung,
                                anmeldeschluss: new Date(
                                    eventObj.anmeldeschluss,
                                ),
                                invitationAllowed: eventObj.kzEinladungSystem,
                                delegationAllowed: eventObj.kzVertretung,
                                maxCompanions: eventObj.idBegleitung,
                            };
                            this.props.dispatch(setEvent(event));
                            return [invitation, event];
                        })
                        .then(([invitation, event]) => {
                            // Zusaetzliche Prüfung Anmeldung ueberhaupt erlaubt
                            if (event.invitationAllowed === 0) {
                                return Promise.reject({ state: 'disabled' });
                            }

                            // Abgleich mit Anmeldeschluss
                            const anmeldeschluss = event.anmeldeschluss;
                            const now = Date.now();
                            if (now > anmeldeschluss.getTime()) {
                                return Promise.reject({ state: 'deadline' });
                            }
                            return invitation;
                        });
                } else {
                    return Promise.resolve(invitation);
                }
            })
            .then((invitation) => {
                if (invitation.idPerson <= 0) {
                    this.props.dispatch(
                        change('attendance', 'attendance', 'self'),
                    );
                    this.props.dispatch(setAnonymous(true));
                    return this.props.dispatch(push('/personals'));
                } else {
                    this.props.dispatch(setAnonymous(false));
                }
                return Promise.all([
                    // TODO: refactor: extract this into its own action
                    fetch(BACKEND_URL + PERSON_LIST, {
                        method: 'POST', // *GET, POST, PUT, DELETE, etc.
                        mode: 'cors', // no-cors, cors, *same-origin
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${invitation.login.accessToken}`,
                        },
                        body: JSON.stringify({
                            login: {
                                // TODO: robustness: pick required properties manually
                                ...invitation.login,
                                userId4Hist: undefined,
                            },
                            ID: invitation.idPerson,
                        }),
                    })
                        .then((response) => {
                            if (!response.ok) {
                                return Promise.reject(response.statusText);
                            } else {
                                return response.text();
                            }
                        })
                        .then((text) => {
                            try {
                                return JSON.parse(text);
                            } catch (e) {
                                return Promise.reject(e);
                            }
                        })
                        .then((responseData) => {
                            ENV === 'development' &&
                                console.log('person response', responseData);
                            if (
                                responseData.TpPersonLstItem.length < 1 ||
                                responseData.TpPersonLstItem[0].ID <= 0
                            ) {
                                return Promise.reject(
                                    "invited user doesn't exist",
                                );
                            }
                            const person = responseData.TpPersonLstItem[0];
                            const ID = person.ID;
                            ENV === 'development' &&
                                console.info('setting person to', ID, person);
                            return Promise.resolve()
                                .then((_e) =>
                                    this.props.dispatch(updatePerson(ID)),
                                )
                                .then(responseData);
                        })
                        .then((responseData) => {
                            ENV === 'development' &&
                                console.log('person set:', responseData);
                        }),
                ]).then(() => {
                    this.setState({
                        state: 'valid',
                    });
                });
            })
            .catch((e) => {
                console.error('error checking code, promise rejected:', e);
                ENV === 'development' && console.log('e.state:', e?.state);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
        // TODO: usability: show different messages depending on error
        // TODO: maintenance: log errors to backend
    }

    validate() {
        setTimeout(() => this.performCheck(), 300);
    }

    setFooterHtml() {
        switch (this.state.state) {
            case 'already':
            case 'expired':
            case 'deadline':
            case 'disabled':
            case 'valid':
            case 'invalid':
                return <Footer />;
            default:
                return null;
        }
    }

    renderQuestion() {
        let ren = <Loader visible={true} />;
        let valid = false;
        switch (this.state.state) {
            case 'already': {
                ren = <Already />;
                break;
            }
            case 'expired': {
                ren = <Expired />;
                break;
            }
            case 'deadline': {
                ren = <Deadline />;
                break;
            }
            case 'disabled': {
                ren = <Disabled />;
                break;
            }
            case 'valid': {
                valid = true;
                break;
            }
            case 'invalid': {
                ren = <Invalid />;
                break;
            }
            default:
                break;
        }
        if (valid) {
            return <Redirect to={'/attendance'} />;
        }
        return <div className={style.question}>{ren}</div>;
    }
}

export default connect(
    (s) => ({
        formdata: s.form,
        invitationEdit_deadline_within:
            s.invitation?.invitationEdit_deadline_within || false,
        ...s,
    }),
    null,
)(Check);
