import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import style from '../../../exhibitor/index.module.css';
import StepButtons from '../../stepbuttons';
import { getEntity } from '../../../../actions/exhibitor/exhibitor-actions.js';
import { showAlert, ERROR, ERROR_MSG_BACKEND_FETCH } from '../../alerts';
import {
    getForwardActionForRoles,
    getBackwardActionForRoles,
    determineAuthForRoles,
} from '../../functions';
import { VERANSTALTUNG__TYP__BUEHNEN_BEITRAG } from '../../../../backend-id-constants';
import {
    statusMapper,
    timeMapper,
    tnMapper,
} from '../veranstaltungen-functions';

class StageContribution extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
        };
    }

    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                if (
                    this.props.workshop &&
                    !Object.keys(this.props.workshop).length
                ) {
                    return this.props
                        .dispatch(getEntity('workshop'))
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_FETCH }),
                        );
                } else {
                    return Promise.resolve();
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    // Pflegbare Liste der Veranstaltungs-Typen muss hier zum Vergleich herangezogen werden. Diese sind in
    // der Produktiv- und Test-Umgebung unterschiedlich definiert.
    showFilter(workshop) {
        return workshop.idTyp === VERANSTALTUNG__TYP__BUEHNEN_BEITRAG;
    }

    render() {
        const forwardAction = getForwardActionForRoles(
            'boothVisits',
            this.props,
        );
        const backwardAction = getBackwardActionForRoles(
            'workshops',
            this.props,
        );

        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>
                        <b>Liste der Bühnenbeiträge</b>
                        <br />
                    </h1>
                    <p>
                        Im Folgenden sind die Bühnenbeiträge aufgelistet, die
                        Sie mit unserer Bühnenagentur abgestimmt haben.
                    </p>
                </div>
                <div className='exhibitorTableWrap'>
                    <div className='schoolTableWrap'>
                        <ul className='schoolTable nine-gridlistpagesworkshops'>
                            <div className='header'>ID</div>
                            <div className='header'>Name</div>
                            <div className='header'>Raum / Stand</div>
                            <div className='header'>Titel Internet</div>
                            <div className='header'>Beginn</div>
                            <div className='header'>Buchungs&shy;status</div>
                            <div className='header'>TN ge&shy;bucht</div>
                            <div className='header'>Freigabe</div>
                            <ul className='nineCols'>
                                {this.props.workshop.map((veranstaltung) => {
                                    let standnummer = '';
                                    if (
                                        veranstaltung
                                            .tp_Veranstaltung_2_Stand2VeranstaltungLst
                                            .TpStand2VeranstaltungLstItem[0]
                                    ) {
                                        standnummer =
                                            veranstaltung
                                                .tp_Veranstaltung_2_Stand2VeranstaltungLst
                                                .TpStand2VeranstaltungLstItem[0]
                                                .standNrFinal;
                                    }
                                    if (this.showFilter(veranstaltung)) {
                                        return (
                                            <li className='schoolTable nine-gridlistpagesworkshops'>
                                                <label>
                                                    {veranstaltung.ID}{' '}
                                                </label>
                                                <label>
                                                    {veranstaltung.titel}
                                                </label>
                                                <label>{standnummer}</label>
                                                <label>
                                                    {
                                                        veranstaltung.titelInternet
                                                    }
                                                </label>
                                                <label>
                                                    {timeMapper(
                                                        veranstaltung.zeitBeginn,
                                                    )}
                                                </label>
                                                <label>
                                                    {statusMapper(
                                                        veranstaltung.kzStatusFreigabeAussteller,
                                                        veranstaltung.idBelegung,
                                                    )}
                                                </label>
                                                <label>
                                                    {tnMapper(
                                                        veranstaltung.kzStatusFreigabeAussteller,
                                                        veranstaltung,
                                                    )}
                                                </label>
                                                {veranstaltung.kzStatusFreigabeAussteller ===
                                                1 ? (
                                                    <div>Ja</div>
                                                ) : (
                                                    <div>Nein</div>
                                                )}
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        </ul>
                    </div>
                </div>
                <div className='buttonwrap buttonwrap-end'>
                    {/*<Link className="small-button" to="/pdfgenerator">vollständige Liste als Pdf ausgeben</Link>*/}
                </div>
                <StepButtons
                    back={backwardAction.backwardAction}
                    to={forwardAction.forwardAction}
                    labelBack={backwardAction.backwardText}
                    labelForward={forwardAction.forwardText}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, _prop) => {
    // Ermittlung der Rollenberechtigungen des angemeldeten Users
    const userAuth = determineAuthForRoles(state);

    return {
        workshop: state.workshop || [],
        workshop_deadline: state.deadlines?.workshop_deadline,
        workshop_deadline_within: state.deadlines.workshop_deadline_within,
        hasBoothAuthorization: userAuth.hasBoothAuthorization,
        hasWorkshopAuthorization: userAuth.hasWorkshopAuthorization,
        hasLiveprogramAuthorization: userAuth.hasLiveprogramAuthorization,
        hasClubzukunftAuthorization: userAuth.hasClubzukunftAuthorization,
        hasOrganisationAuthorization: userAuth.hasOrganisationAuthorization,
        hasCarreerStageAuthorization: userAuth.hasCarreerStageAuthorization,
    };
};

export default reduxForm({
    form: 'workshops',
})(connect(mapStateToProps, null)(StageContribution));
