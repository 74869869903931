import React from 'react';

import StepButtons from '../../common/stepbuttons';

export default () => (
    <>
        <h1>Fehler</h1>
        <p>Dieser persönliche Zugangscode wurde bereits registriert.</p>
        <StepButtons back='/' />
    </>
);
