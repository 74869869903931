import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { store } from '../../../../../../store';
import { withRouter } from 'react-router-dom';

import { ENV } from '../../../../../../global_constants';
import FileInput from '../../../../fileinput';
import style from '../../../../index.module.css';
import FormButtons from '../../../../formbuttons';
import { handleLogoInput } from '../../../../logos/logos.js';
import disableForm from '../../../../disableForm.js';
import exhibitSubmit from '../submit.js';
import {
    validateForm,
    showAlert,
    ERROR,
    ERROR_MSG_BACKEND_FETCH,
} from '../../../../alerts';

class ExhibitEditDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            charsTextInternet: (
                store.getState().form.exhibitseditdescription?.values || []
            ).exponat_beschreibung
                ? 800 -
                  Number(
                      store.getState().form.exhibitseditdescription.values
                          .exponat_beschreibung.length,
                  )
                : props.initialValues.charsTextInternet
                ? props.initialValues.charsTextInternet
                : 800,
            submitSuccess: false,
            values: { files: this.props.files || {} }, // Files
            reset: 0, // Files
        };
    }

    handleInput(e) {
        this.setState({ valid: true });

        if (e && e.target.name === 'exponat_beschreibung') {
            this.setState((_state, _props) => ({
                charsTextInternet: 800 - Number(e.target.value.length),
            }));
        }

        handleLogoInput(e, this); //Files

        this.props.handleInput(e);
    }

    resetForm() {
        this.setState((_state, props) => ({
            charsTextInternet: (
                store.getState().form.exhibitseditdescription?.values || []
            ).exponat_beschreibung
                ? 800 -
                  Number(
                      store.getState().form.exhibitseditdescription.values
                          .exponat_beschreibung.length,
                  )
                : props.initialValues.charsTextInternet
                ? props.initialValues.charsTextInternet
                : 800,
            submitSuccess: false,
        }));
    }

    // BT 2022-02-05: Umstellung auf DidMount, weil WillMount laut Doku deprecated und unsafe for
    // state operations
    componentDidMount() {
        Promise.resolve()
            .then(() => {
                if (this.props.initialValues['status'] === 'freigegeben') {
                    disableForm();
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    submit(valuesParam) {
        const values = {
            //Files
            ...valuesParam,
            ...this.state.values,
        };

        const storeState = store.getState();
        const exhibitseditspecificationsValues =
            storeState.form?.exhibitseditspecifications?.values || {};
        const exhibitseditbasisValues =
            storeState.form?.exhibitseditbasis?.values || {};

        validateForm(
            this,
            {
                ...exhibitseditbasisValues,
                ...exhibitseditspecificationsValues,
                ...values,
            },
            ['status', 'exponat_titel'],
        );
        exhibitSubmit(
            exhibitseditbasisValues,
            values,
            exhibitseditspecificationsValues,
            this,
        );
        this.props.toTab(2);
    }

    render() {
        const { handleSubmit } = this.props;
        const charsTextInternet = this.state.charsTextInternet;

        return (
            <div className={style.header}>
                {this.props.initialValues.status === 'freigegeben' ? (
                    <div>Sie haben diese Daten bereits freigegeben.</div>
                ) : null}
                {!this.props.exhibit_deadline_within ? (
                    <div>
                        Sie können die Daten nur innerhalb des
                        Bearbeitungszeitraums bearbeiten.
                    </div>
                ) : null}
                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <fieldset>
                        <legend />
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='exponat_id'
                            id='exponat_id'
                            hidden
                            disabled
                            value={this.props.handle || ''}
                        />
                        <label htmlFor='exponat_titel_desc'>
                            <span className={style.bold}>
                                Titel (für Pressearbeit und Webseite)
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='exponat_titel_desc'
                            id='exponat_titel_desc'
                        />
                        <label htmlFor='exponat_beschreibung'>
                            <span className={style.bold}>
                                Exponatbeschreibung (für Pressearbeit und
                                Webseite)
                            </span>
                        </label>
                        <Field
                            component='textarea'
                            placeholder=''
                            type='textarea'
                            name='exponat_beschreibung'
                            rows='8'
                            maxLength='800'
                            style={{
                                resize: 'none',
                            }}
                            id='exponat_beschreibung'
                            onChange={(e) => this.handleInput(e)}
                        />
                        <legend>
                            Bitte geben Sie maximal 800 Zeichen ein. Es bleiben
                            noch {charsTextInternet} Zeichen.
                        </legend>
                        <label htmlFor='keyWord1'>
                            <span className={style.bold}>Keyword 1</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='keyWord1'
                            id='keyWord1'
                        />
                        <label htmlFor='keyWord2'>
                            <span className={style.bold}>Keyword 2</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='keyWord2'
                            id='keyWord2'
                        />
                        <label htmlFor='keyWord3'>
                            <span className={style.bold}>Keyword 3</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='keyWord3'
                            id='keyWord3'
                        />
                        <div className={style.space} />
                        <FileInput
                            key={5}
                            label='Datenblatt zum Exponat (optional, nur interne Verwendung)'
                            i={5}
                            name={this.props.initialValues.datenblatt}
                            link={`data:application/pdf;base64,${this.props.initialValues.data4Datenblatt}`}
                            handleInput={this.handleInput.bind(this)}
                            imageField='data4Datenblatt'
                            nameField='datenblatt'
                            reset={this.state.reset}
                        />
                        <FormButtons
                            showCancel={true}
                            overviewLink='/booths/exhibits'
                            hideBtn={true}
                            handleSubmit={handleSubmit}
                            submit={this.submit.bind(this)}
                        />
                        <legend>(*) = Pflichtfeld</legend>
                    </fieldset>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const Props = {
        idStand: props.booth_id,
    };
    const handle = props.handle;
    if (handle == null) {
        return { ...Props, initialValues: { status: '' } };
    }
    const found = state.exhibit.find((x) => x.ID === handle);

    ENV === 'development' && console.log('Found-state-2-props:', found);

    let initialValues = { status: '' };
    if (found && found.ID === handle) {
        initialValues = {
            exponat_id: handle || '',
            exponat_titel_desc: found.titelInternet || '',
            exponat_beschreibung: found.textInternet || '',
            keyWord1: found.keyWord1 || '',
            keyWord2: found.keyWord2 || '',
            keyWord3: found.keyWord3 || '',
            datenblatt: found.datenblatt || '',
            data4Datenblatt: found.data4Datenblatt || '',
            charsTextInternet: found.textInternet
                ? 800 - Number(found.textInternet.length)
                : 800,
            status:
                found.kzFreigabeAussteller === 1
                    ? 'freigegeben'
                    : found.kzFreigabeAussteller === 0
                    ? 'inbearbeitung'
                    : '',
        };
    }

    return {
        ...Props,
        initialValues: initialValues,
        exhibit_deadline_within: state.deadlines?.exhibit_deadline_within,
        found,
        handle: handle,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'exhibitseditdescription',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(ExhibitEditDescription),
);
