import logo from '../../../images/Ideenexpo_logo_ohne_Jahreszahl_HKS10_fh.jpg';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import style from '../../exhibitor/index.module.css';

class PdfGenerator extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillMount() {}
    //<Brand/>
    render() {
        return (
            <div className={[style.question, 'pdf--container'].join(' ')}>
                <div className={[style.header, 'pdf--header'].join(' ')}>
                    <img
                        className={'pdf--img'}
                        src={logo}
                        alt='Ideenexpo'
                        align='top'
                    />
                    <h1 className={'pdf--title'}>
                        <b>Liste der (Exponate) für den User (Mustermann)</b>
                        <hr />
                    </h1>
                </div>
                <div className={'pdf--item'}>
                    <h2 className={'pdf--item--header'}>
                        <b>(Item Name)</b>
                    </h2>
                    <ul className={'schoolTable two-grid pdf--table'}>
                        <div>Titel des Exponats</div>
                        <div>Sturm im Windkanal</div>
                        <div>Interaktiv</div>
                        <div>ja</div>
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (_state, _prop) => ({});

export default connect(mapStateToProps, null)(PdfGenerator);
