import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import UploadFile from '../../uploadfile';
import style from '../../index.module.css';
import FormButtons from '../../formbuttons';
import Logos from '../../logos';
import { updateDefaultLogo } from '../../../../actions/exhibitor/booth/index.js';
import { handleLogoInput } from '../../logos/logos.js';
import { getEntity } from '../../../../actions/exhibitor/exhibitor-actions.js';
import { BoothStateEntry } from './booth-state-entry/index.jsx';
import {
    areAllExhibitsApproved,
    areAllExhibitPanelsApproved,
    areAllStelesApproved,
} from '../approve-state-functions.js';
import {
    showAlert,
    SUCCESS,
    SUCCESS_MSG,
    ERROR,
    ERROR_MSG_BACKEND_SAVE,
    ERROR_MSG_UPLOAD,
} from '../../alerts';
import { blockScreen } from '../../functions';
import { RADIO_VALUE_NO, RADIO_VALUE_YES } from '../../../../global_constants';

class DefaultLogo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            showDefaultLogo:
                props.initialValues?.standardlogo === RADIO_VALUE_YES,
            values: { files: {} }, // logos
            reset: 0, // logos
            freigabeExponate: false,
            freigabeExponatTafeln: false,
            freigabeStelen: false,
        };
        this.input = React.createRef();
        this.found = props.found;
    }
    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                if (this.props.booth) {
                    return this.props.dispatch(
                        getEntity('booth', { ID: this.found.ID }),
                    );
                } else {
                    Promise.resolve();
                }
            })
            .then(() => {
                // BT 22022-03-09: Ausstelle sollen auch nach Ablauf der Dealine noch angeben können,
                // ob Standardlogo verwendet wird oder nicht.
                // if (!this.props.exhibit_deadline_within) {
                // 	disableForm();
                // }
            })
            .then(() => {
                this.setState({
                    showDefaultLogo:
                        this.props.initialValues?.standardlogo ===
                        RADIO_VALUE_YES,
                });
            });
    }

    async componentDidMount() {
        const freigabeExponate = await areAllExhibitsApproved(this.found);
        const freigabeExponatTafeln = await areAllExhibitPanelsApproved(
            this.found,
        );
        const freigabeStelen = await areAllStelesApproved(this.found);
        this.setState({
            ...this.state,
            freigabeExponate,
            freigabeExponatTafeln,
            freigabeStelen,
        });
    }

    resetForm() {
        this.setState({
            submitSuccess: false,
            reset: this.state.reset + 1, //logos
            showDefaultLogo:
                this.props.initialValues?.standardlogo === RADIO_VALUE_YES,
        });
    }
    handleInput(e) {
        this.setState({ valid: true });
        if (e && e.target.name === 'standardlogo') {
            this.setState((_state) => ({
                showDefaultLogo: e.target.value === RADIO_VALUE_YES,
            }));
        }
        handleLogoInput(e, this); //logos
    }
    submit(valuesParam) {
        blockScreen(true);

        let values = {
            //logos
            ...valuesParam,
            ...this.state.values,
        };
        Promise.all(
            Object.entries(values.files).map((file) => {
                //logos
                // resolve logo images
                return new Promise((resolve, reject) => {
                    if (file[1].file && typeof file[1].file === 'object') {
                        return UploadFile(file[1].file)
                            .then((img) => {
                                values = {
                                    ...values,
                                    [file[1].imageField]: img.file,
                                    [file[1].nameField]: img.name,
                                };
                                resolve(img);
                            })
                            .catch((_result) => {
                                reject({ _error: ERROR_MSG_UPLOAD });
                            });
                    } else {
                        resolve();
                    }
                });
            }),
        )
            .then(() => {
                // if updating an existing Default Logo
                if (this.props.handle) {
                    return this.props
                        .dispatch(updateDefaultLogo(this.props.found, values))
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                }
            })
            .then(() => {
                blockScreen(false);

                this.setState({
                    submitSuccess: true,
                    reset: this.state.reset + 1, //logos
                });
            })
            .then(() => showAlert(SUCCESS, SUCCESS_MSG))
            .catch((e) => {
                blockScreen(false);

                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_SAVE);
                this.setState({ submitSuccess: false });
                this.props.dispatch(stopSubmit('defaultlogo', e));
            });
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <div className={style.question}>
                <div className={style.header}>
                    {!this.props.exhibit_deadline_within ? (
                        <div>
                            Sie können die Daten nur innerhalb des
                            Bearbeitungszeitraums bearbeiten.
                        </div>
                    ) : null}
                    <h1>
                        Standardlogo für Stand {this.props.found?.bezeichnung}{' '}
                        bearbeiten
                    </h1>
                    <h2>Status Freigaben durch Aussteller</h2>
                    <div className='schoolTableWrap'>
                        <ul className='schoolTable two-grid-approval'>
                            <div className='header'>Ausstattung</div>
                            <div className='header'>Freigabe Aussteller</div>
                            <BoothStateEntry
                                label='Exponate'
                                status={this.state.freigabeExponate}
                            />
                            <BoothStateEntry
                                label='Exponattafeln'
                                status={this.state.freigabeExponatTafeln}
                            />
                            <BoothStateEntry
                                label='Stelen'
                                status={this.state.freigabeStelen}
                            />
                        </ul>
                    </div>
                    <h2>Standardlogo</h2>
                    <p>
                        Falls Sie Änderungswünsche in Bezug auf das Stand-Logo
                        haben, setzen Sie sich bitte mit Ihrer/Ihrem
                        Aussteller-BetreuerIn in Verbindung.
                    </p>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <fieldset disabled={true}>
                            <Field
                                component='input'
                                placeholder=''
                                type='text'
                                name='stand_id'
                                id='stand_id'
                                hidden
                                disabled
                                value={this.props.handle || ''}
                            />
                            <legend>
                                Möchten Sie für diesen Stand ein Standardlogo
                                benutzen?
                            </legend>
                            <Field
                                component='input'
                                type='radio'
                                name='standardlogo'
                                id='radioYes'
                                value={RADIO_VALUE_YES}
                                required
                                onChange={(e) => this.handleInput(e)}
                            />
                            <label htmlFor='radioYes'>
                                <span>Ja</span>
                            </label>
                            <Field
                                component='input'
                                type='radio'
                                name='standardlogo'
                                id='radioNo'
                                value={RADIO_VALUE_NO}
                                required
                                onChange={(e) => this.handleInput(e)}
                            />
                            <label htmlFor='radioNo'>
                                <span>Nein</span>
                            </label>
                            {this.state.showDefaultLogo ? (
                                <Logos
                                    positionLogos={
                                        this.props.initialValues
                                            .idPositionierungLogo || 0
                                    }
                                    datasheets={[
                                        {
                                            name: this.props.found?.logo1 || '',
                                            link:
                                                `data:image/png;base64,${this.props.found?.data4Logo1}` ||
                                                '',
                                        },
                                        {
                                            name: this.props.found?.logo2 || '',
                                            link:
                                                `data:image/png;base64,${this.props.found?.data4Logo2}` ||
                                                '',
                                        },
                                        {
                                            name: this.props.found?.logo3 || '',
                                            link:
                                                `data:image/png;base64,${this.props.found?.data4Logo3}` ||
                                                '',
                                        },
                                        {
                                            name: this.props.found?.logo4 || '',
                                            link:
                                                `data:image/png;base64,${this.props.found?.data4Logo4}` ||
                                                '',
                                        },
                                    ]}
                                    handleInput={this.handleInput.bind(this)}
                                    reset={this.state.reset}
                                    standardLogo={true}
                                />
                            ) : null}
                            <FormButtons
                                showCancel={true}
                                overviewLink='/booths'
                                hideBtn={true}
                                handleSubmit={handleSubmit}
                                submit={this.submit.bind(this)}
                            />
                        </fieldset>
                    </form>
                    {/* <StepButtons back="/booths" disabled={!this.state.valid}  labelBack={'Liste der Stände'}/> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, _props) => {
    const handle =
        state.router?.location?.state?.handle ||
        parseInt((state.router?.location?.search || '?').substring(1)) ||
        '';
    const Props = {
        booth: state.booth && !Object.keys(state.booth).length,
    };
    if (typeof handle === 'undefined') {
        return { ...Props, initialValues: {} };
    }
    const found =
        state.booth && Array.isArray(state.booth)
            ? state.booth.find((x) => x.ID === handle)
            : [];
    let initialValues;
    if (found?.ID === handle) {
        initialValues = {
            stand_id: handle || '',
            standardlogo:
                found.kzStandardlogos === 1 ? RADIO_VALUE_YES : RADIO_VALUE_NO,
            idPositionierungLogo:
                found.idLogopositionierung == null ||
                found.idLogopositionierung < 0
                    ? 0
                    : found.idLogopositionierung,
            data4Logo1: found.data4Logo1 || '',
            data4Logo2: found.data4Logo2 || '',
            data4Logo3: found.data4Logo3 || '',
            data4Logo4: found.data4Logo4 || '',
            logo1: found.logo1 || '',
            logo2: found.logo2 || '',
            logo3: found.logo3 || '',
            logo4: found.logo4 || '',
        };
    }
    return {
        ...Props,
        handle: handle,
        exhibit_deadline_within: state.deadlines?.exhibit_deadline_within,
        initialValues: initialValues || {},
        found: found || {},
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'defaultlogo',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(DefaultLogo),
);
