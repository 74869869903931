import { SubmissionError } from 'redux-form';

import { store } from 'react-notifications-component';

import { isNoNumber } from '../../common/util/Validator/isNumber';

export const SUCCESS = 'Speichern erfolgreich!';
export const SUCCESS_MSG_INSERT = 'Das Anlegen war erfolgreich!';
export const SUCCESS_MSG_SAVE = 'Das Aktualisieren war erfolgreich!';
export const SUCCESS_MSG = 'Element gespeichert!';

export function SUCCESS_DELETE(obj, msg) {
    return `${obj || 'Das Element'} wurde erfolgreich gelöscht${
        msg ? `, ${msg}` : '.'
    }`;
}

export const DELETE = 'Löschen erfolgreich!';

export const WARNING = 'Nicht möglich!';
export const WARNING_MSG_RELEASED =
    'Sie haben diese Daten bereits freigegeben.';
export const WARNING_MSG_DEADLINE =
    'Sie können diese Daten nur innerhalb der Bearbeitungsfrist bearbeiten.';
export const WARNING_MSG_STANDARDLOGO =
    'Sie haben ein Standardlogo für diesen Stand festgelegt';
export const WARNING_MSG_MAX2FORMATS =
    'Bitte wählen Sie maximal 2 Formate für Ihre Bühnen-Veranstaltung aus.';

export const ERROR = 'Fehler!';
export const ERROR_MSG_MANDATORY =
    'Bitte füllen Sie zunächst alle Pflichtfelder (*) aus.';
export const ERROR_MSG_UPLOAD = 'Datei konnte nicht übermittelt werden.';
export const ERROR_MSG_BACKEND_SAVE = 'BACKEND: Update fehlgeschlagen.';
export const ERROR_MSG_BACKEND_INSERT = 'BACKEND: Anlegen fehlgeschlagen.';
export const ERROR_MSG_BACKEND_FETCH =
    'Daten oder Teile der Daten konnten nicht aus dem Backend geladen werden.';
export const ERROR_LIVEPROGRAM = 'Sie haben das LiveProgramm deaktiviert.';
export const ERROR_UNAUTHORIZED =
    'Sie sind nicht länger mit dem Backend verbunden. Bitte loggen Sie sich erneut ein';
export const ERROR_ANMELDECODE = 'Der Anmeldecode war nicht valide.';
export const ERROR_ZIPCODE_FORMAT =
    'Die angegebene Postleitzahl ist nicht valide.';
export const ERROR_LOCATION_FORMAT = 'Der angegebene Ort ist nicht valide.';

export function showWarningAlertForNaN(fieldNames) {
    if (fieldNames.length !== 0) {
        const joinedFieldNames = fieldNames.join(', ');
        showAlert(
            WARNING,
            `In folgenden Feldern sind nur Zahlen als Eingaben erlaubt:
			${joinedFieldNames}`,
        );
    }
}

export function showAlert(
    title = '',
    message = '',
    additionalInfo = '',
    insert = 'top',
    container = 'top-center',
    duration = 2000,
    onScreen = true,
    showIcon = true,
    touch = true,
    click = true,
) {
    const chosenType =
        title === WARNING
            ? 'warning'
            : title === ERROR
            ? 'danger'
            : title === SUCCESS || title === DELETE
            ? 'success'
            : 'info';
    const chosenMsg = additionalInfo
        ? `${message}, ${additionalInfo}`
        : message;
    store.addNotification({
        title: title,
        message: chosenMsg,
        type: chosenType,
        insert: insert,
        container: container,
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
            duration: duration,
            onScreen: onScreen,
            showIcon: showIcon,
            touch: touch,
            click: click,
        },
    });
}

export function validateForm(
    that,
    values,
    mandatoryFieldsExist,
    _withinDeadline,
    checkLiveProgram,
    standardLogoExists,
) {
    const fieldNames = validateNaNKeyMap(values, workshopKeyMap);
    if (fieldNames.length > 0) {
        showWarningAlertForNaN(fieldNames);
        throw new SubmissionError({});
    }

    if (checkLiveProgram) {
        if (that.props.noLiveProgram) {
            showAlert(ERROR, ERROR_LIVEPROGRAM);
            throw new SubmissionError({});
        }
    }
    if (arguments.length === 6 && standardLogoExists) {
        showAlert(WARNING, WARNING_MSG_STANDARDLOGO);
        throw new SubmissionError({});
    }
    if (
        that.props.initialValues &&
        that.props.initialValues['status'] === 'freigegeben'
    ) {
        showAlert(WARNING, WARNING_MSG_RELEASED);
        throw new SubmissionError({});
    }

    // else if (arguments.length >= 4 && !withinDeadline) {
    // 	showAlert(WARNING, WARNING_MSG_DEADLINE);
    // 	throw new SubmissionError({ });
    // }
    else if (mandatoryFieldsExist) {
        mandatoryFieldsExist.forEach((item) => {
            if (!values[item]) {
                showAlert(ERROR, ERROR_MSG_MANDATORY);
                throw new SubmissionError({});
            }
        });
    }
}

const workshopKeyMap = {
    workshop_dauer: 'Workshop-Dauer',
    workshop_aufbau: 'Workshop-Aufbau',
    workshop_abbau: 'Workshop-Abbau',
    haeufigkeit: 'Workshop-Häufigkeit',
};

const validateNaNKeyMap = (values, keyMap) => {
    return Object.entries(values)
        .filter(([key]) => Object.keys(keyMap).includes(key))
        .reduce((acc, [key, value]) => {
            if (isNoNumber(value)) {
                acc.push(keyMap[key]);
            }
            return acc;
        }, []);
};

export function max2Formats(arr) {
    let values = 0;
    arr.forEach((item) => {
        if (item) {
            values = values + 1;
        }
    });
    if (values > 2) {
        showAlert(WARNING, WARNING_MSG_MAX2FORMATS);
        throw new SubmissionError({});
    }
}
