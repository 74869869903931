import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Field, destroy, reduxForm } from 'redux-form';
import {
    getEntity,
    setPasses,
} from '../../../../actions/exhibitor/exhibitor-actions.js';
import { getOrganisation } from '../../../../actions/exhibitor/organisation';
import {
    insertExhibitorPass,
    updateExhibitorPass,
} from '../../../../actions/exhibitor/organisation/index.js';
import {
    DELETE,
    ERROR,
    ERROR_MSG_BACKEND_FETCH,
    ERROR_MSG_BACKEND_SAVE,
    ERROR_MSG_MANDATORY,
    ERROR_UNAUTHORIZED,
    SUCCESS,
    SUCCESS_DELETE,
    SUCCESS_MSG,
    showAlert,
} from '../../alerts';
import style from '../../index.module.css';
import StepButtons from '../../stepbuttons';

import { compose } from 'redux';
import { createEntityConfig } from '../../../../actions/exhibitor/fetcher/entity-list-detail.fetcher';
import { PassItem } from './pass-item.jsx';

// TODO replace with local styles
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {
    fetchDeleteEntityFromConfig,
    fetchUpdateEntityFromConfig,
} from '../../../../actions/exhibitor/fetcher/fetcher.js';
import { ENV } from '../../../../global_constants.js';
import bannerSx from '../../organisation/banner-print/banner-print.module.scss';
import {
    BannerFileInput,
    createFileBody,
} from '../banner-print/banner-print.edit.jsx';
import Loader from '../../../common/loader/index.jsx';

const mapStateToProps = (state) => {
    return {
        organisation: state.organisation?.[0],
        passes: state.passes || [],
        formValues: state.form?.exhibitorPassEdit?.values,
        initialValues: {
            textZeile1: state.organisation?.[0]?.textZeile1 || '',
            textZeile2: state.organisation?.[0]?.textZeile2 || '',
        },
    };
};

export const ExhibitorPassesList = ({
    dispatch,
    organisation,
    passes: initialPasses,
    formValues,
}) => {
    const [isReady, setIsReady] = useState(false);

    const findById = (id, arr) => arr.find((obj) => obj.ID === id);

    const setPassByIdOrIndex = (idOrIndex, data) => {
        const newPasses = initialPasses.map((pass, i) => {
            if (pass.ID === idOrIndex || i === idOrIndex) {
                return {
                    ...pass,
                    ...data,
                };
            }
            return pass;
        });
        dispatch(setPasses(newPasses));
    };

    const handleUpsertPass = async (pass) => {
        if (pass?.kzFreigabeAussteller === 1) {
            return;
        }
        const prev = findById(pass.ID, initialPasses);
        const isNew = !prev?.ID;

        if (isNew) {
            await dispatch(insertExhibitorPass(pass));
            return;
        }

        await dispatch(updateExhibitorPass(prev, pass));
    };

    const validatePasses = (passes) => {
        const hasEmptyName = passes.some((pass) => !pass.vornameNachname);
        if (hasEmptyName) {
            showAlert(ERROR, ERROR_MSG_MANDATORY);
            return false;
        }
        return true;
    };

    const handleSavePasses = async (passes) => {
        if (!validatePasses(passes)) {
            return;
        }
        await Promise.all(
            passes.map((pass) => {
                handleUpsertPass(pass);
            }),
        );
    };

    const handleApproveAllPasses = async () => {
        handleSavePasses(
            initialPasses.map((pass) => ({
                ...pass,
                status: 'freigegeben',
            })),
        );
    };

    const handleAddEmptyPass = () => {
        if (initialPasses.length >= 3) {
            return;
        }
        const newPasses = [
            ...initialPasses,
            { vornameNachname: '', status: 'inbearbeitung' },
        ];
        dispatch(setPasses(newPasses));
    };

    const handleFetch = async () => {
        setIsReady(false);
        try {
            // TODO show loader
            await dispatch(getOrganisation('organisation'));
            await dispatch(getEntity('passes'));
            // TODO ready now, hide loader
        } catch (e) {
            showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
            console.error('error checking code, promise rejected:', e);
        } finally {
            setIsReady(true);
        }
    };

    const handleDeletePass = async (pass, localIndex) => {
        const config = createEntityConfig('passes');

        if (!pass.ID && localIndex) {
            const newPasses = initialPasses.filter((_, i) => i !== localIndex);
            dispatch(setPasses(newPasses));
            return;
        }

        try {
            setIsReady(false);
            await fetchDeleteEntityFromConfig(config, pass.ID);
            showAlert(
                DELETE,
                SUCCESS_DELETE(`Der Ausstellerausweis ${pass.vornameNachname}`),
            );
        } catch (e) {
            ENV === 'development' && console.log('error occured', e);
            if (e === 'Unauthorized') {
                showAlert(ERROR, ERROR_UNAUTHORIZED);
                return;
            }
            showAlert(ERROR, 'Element konnte nicht gelöscht werden.');
        } finally {
            await handleFetch();
        }
    };

    const handleSubmit = async () => {
        const config = createEntityConfig('organisation');

        try {
            setIsReady(false);
            if (!organisation) {
                throw new Error('No organisation set');
            }

            if (
                !formValues ||
                [formValues.textZeile1, formValues.textZeile1].some(
                    (_) => !_.length,
                )
            ) {
                showAlert(ERROR, ERROR_MSG_MANDATORY);
                return;
            }

            handleSavePasses(initialPasses);

            const fileBody = await createFileBody(
                formValues?.rawFile,
                'ausstellerAusweisListe',
                '',
            );
            const updatedOrganisation = {
                ...organisation,
                ...(fileBody || {}),
                ausstellerAusweisListeExtension: '.xslx',
                ...formValues,
            };

            await fetchUpdateEntityFromConfig(config, updatedOrganisation);

            handleSubmitSuccess();
        } catch (e) {
            ENV === 'development' && console.log('error occured', e);
            if (e === 'Unauthorized') {
                showAlert(ERROR, ERROR_UNAUTHORIZED);
                return;
            }
            showAlert(ERROR, ERROR_MSG_BACKEND_SAVE);
        } finally {
            handleFetch();
        }
    };

    const handleSubmitSuccess = () => {
        showAlert(SUCCESS, SUCCESS_MSG);
    };

    useEffect(() => {
        handleFetch();

        return () => {
            dispatch(destroy('exhibitorPassEdit'));
        };
    }, []);

    const hasAtLeastOneFreigabe = useMemo(() => {
        return initialPasses.some((pass) => pass.kzFreigabeAussteller === 1);
    }, [initialPasses]);

    const canAddPasses = !hasAtLeastOneFreigabe && initialPasses.length < 3;

    if (!isReady) {
        return (
            <div style={{ height: '90vh' }}>
                <Loader visible />
            </div>
        );
    }

    return (
        <div className={style.question}>
            <div className={style.header}>
                <h1>Ausstellerausweise</h1>
                <p>
                    <u>Jede/Jeder</u> StandbetreuerIn erhält einen
                    Ausstellerausweis, der während der gesamten IdeenExpo gut
                    sichtbar getragen wird. Nur mit diesem Ausweis ist ein
                    Zugang zu den Lagerbereichen und dem Ausstellercatering
                    möglich. Die Ausgabe erfolgt ab dem 06. Juni 2024 am
                    Ausstellercounter in der Halle 8. Bitte laden Sie eine
                    Excelliste mit den Namen <u>aller</u> Personen hoch, die
                    während der Veranstaltung an Ihrem Stand arbeiten und geben
                    Sie zusätzlich in dem untenstehenden Formular die drei
                    Personen an, die berechtigt sind, die Ausweise abzuholen.
                    Sind über den gesamten Zeitraum nur drei StandbetreuerInnen
                    eingeplant, ist eine Excelliste nicht erforderlich. Deadline
                    für die Eintragungen ist der 19. April 2024.
                    <br />
                    Weitere Informationen zu den Ausstellerausweisen finden Sie
                    im Dokument "Allgemeine Ausstellerinformationen", welches im
                    Ausstellerbereich auf unserer Webseite verfügbar ist.
                </p>
                <form onSubmit={(e) => e.preventDefault()}>
                    <fieldset>
                        <p>
                            Die Ausstellerausweise werden ausschließlich mit dem{' '}
                            <b>Namen Ihrer Institution</b> versehen. Dafür
                            stehen zwei Zeilen mit jeweils 20 Zeichen zur
                            Verfügung. Bitte tragen Sie in die untenstehenden
                            Freitextfelder eine treffende Bezeichnung ein.
                        </p>
                        <label>
                            <span className={style.bold}>Zeile 1 (*)</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='textZeile1'
                            id='textZeile1'
                            maxLength={20}
                            disabled={hasAtLeastOneFreigabe}
                            required
                        />
                        <label>
                            <span className={style.bold}>Zeile 2</span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='textZeile2'
                            id='textZeile2'
                            maxLength={20}
                            disabled={hasAtLeastOneFreigabe}
                        />
                    </fieldset>
                    <legend>(*) = Pflichtfeld</legend>
                </form>
                <div
                    className={classNames(bannerSx.bannerActions, {
                        [bannerSx.disabled]: !canAddPasses,
                    })}
                    style={{ marginTop: '30px' }}
                >
                    <div
                        className={bannerSx.action}
                        onClick={() => {
                            handleAddEmptyPass();
                        }}
                    >
                        <div className={bannerSx.label}>Ausweis hinzufügen</div>
                        <FontAwesomeIcon icon={faPlus} size='1x' />
                    </div>
                </div>
                <table className={bannerSx.bannerTable}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th style={{ width: 0 }}>Freigabe</th>
                            <th style={{ width: 0 }}>Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>
                        {initialPasses.map((pass, i) => (
                            <PassItem
                                key={`${pass.ID}${i}`}
                                index={i}
                                hasFreigabe={hasAtLeastOneFreigabe}
                                onDelete={handleDeletePass}
                                onChange={(data) => {
                                    setPassByIdOrIndex(pass.ID ?? i, data);
                                }}
                                pass={pass}
                            />
                        ))}
                    </tbody>
                </table>
                <form onSubmit={(e) => e.preventDefault()}>
                    <fieldset>
                        <label>
                            <b>Excelliste</b>
                        </label>
                        {formValues?.rawFile ? (
                            <div>
                                Ausgewählte Datei: {formValues.rawFile.name}
                            </div>
                        ) : organisation?.ausstellerAusweisListe ? (
                            <div>
                                Freigegebene Datei:{' '}
                                {organisation.ausstellerAusweisListe}
                            </div>
                        ) : (
                            <div>Bislang keine Datei hochgeladen</div>
                        )}
                        <Field
                            name='rawFile'
                            component={BannerFileInput}
                            disabled={hasAtLeastOneFreigabe}
                        />
                    </fieldset>
                </form>

                <div className='buttonwrap' hidden={hasAtLeastOneFreigabe}>
                    <a
                        className={classNames('button', {
                            disabled: hasAtLeastOneFreigabe,
                        })}
                        // rome-ignore lint/a11y/useValidAnchor: quick fix
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        Speichern
                    </a>
                    <a
                        className={classNames('button', {
                            disabled: hasAtLeastOneFreigabe,
                        })}
                        // rome-ignore lint/a11y/useValidAnchor: quick fix
                        onClick={() => {
                            handleApproveAllPasses();
                        }}
                    >
                        Freigeben
                    </a>
                </div>
            </div>
            <StepButtons
                back={'/organisation'}
                to={'/organisation/exhibitor-catering'}
                labelBack={'Organisation'}
                labelForward={'Ausstellercatering'}
            />
        </div>
    );
};

export const ExhibitorPasses = compose(connect(mapStateToProps, null))(
    reduxForm({
        form: 'exhibitorPassEdit',
        destroyOnUnmount: true,
        enableReinitialize: true,
        // keepDirtyOnReinitialize: false,
    })(ExhibitorPassesList),
);
