import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import {
    setBooth,
    setBoothFeature,
    setInfodesk,
    setPresenationsmodule,
} from '../../../../actions/exhibitor/exhibitor-actions';
import { createEntityConfig } from '../../../../actions/exhibitor/fetcher/entity-list-detail.fetcher';
import { fetchSingleEntityFromConfig } from '../../../../actions/exhibitor/fetcher/fetcher';

import {
    updateInfodesk,
    updatePresentationsmodul,
} from '../../../../actions/exhibitor/infodesk';
import Loader from '../../../common/loader';
import style from '../../../exhibitor/index.module.css';
import {
    ERROR,
    ERROR_MSG_BACKEND_SAVE,
    SUCCESS,
    SUCCESS_MSG,
    WARNING,
    WARNING_MSG_STANDARDLOGO,
    showAlert,
} from '../../alerts';
import Formbuttons from '../../formbuttons';
import { blockScreen } from '../../functions';
import Logos from '../../logos';
import UploadFile from '../../uploadfile';

const mapStateToProps = (state) => {
    const params = new URLSearchParams(state.router.location.search);

    /** @type {'infodesk'|'infodeskwithjobinfo'|'presentationmodule'} */
    const type = params.get('type');
    const boothId = Number(params.get('boothId'));
    const handle = Number(params.get('handle'));
    const boothFeatureId = Number(params.get('boothFeatureId'));
    const standardLogoExists = Number(state.booth.kzStandardlogos) || 0;

    const boothFeature = Array.isArray(state.boothFeatures)
        ? null
        : state.boothFeatures;

    let furnishing = null;
    switch (type) {
        case 'infodesk':
        case 'infodeskwithjobinfo': {
            if (Array.isArray(state.infodesk)) {
                break;
            }
            furnishing = state.infodesk;
            break;
        }
        case 'presentationmodule': {
            if (Array.isArray(state.presentationmodule)) {
                break;
            }
            furnishing = state.presentationmodule;
            break;
        }
    }

    const initialValues = {
        standardLogoExists,
        idPositionierungLogo: 0,
        idPositionierungSteleLogo: 0,
        files: {},
        ...(furnishing || {}),
    };

    return {
        boothFeature,
        furnishing,
        initialValues,
        type,
        boothId,
        boothFeatureId,
        handle,
    };
};

const EditFurnishingForm = (
    /** @type {ReturnType<typeof mapStateToProps>} */
    props,
) => {
    const [isReady, setIsReady] = useState(false);
    const [values, setValues] = useState({});

    const isInfoDesk = props.type === 'infodesk';
    const isInfoDeskWithJobInfo = props.type === 'infodeskwithjobinfo';
    const isPresentationModule = props.type === 'presentationmodule';
    const stelenPrefix = 'Stelen-';
    const maxNumberOfPossibleLogosBeforeStelenLogos = 4;
    const logoIndices = [1, 2, 3, 4];
    const typeHeader =
        isInfoDesk || isInfoDeskWithJobInfo
            ? 'Infotresen'
            : isPresentationModule
            ? 'Präsentationsmodul'
            : 'Mobiliar';

    const handleFetch = async () => {
        const booth = await fetchSingleEntityFromConfig(
            createEntityConfig('booth'),
            props.boothId,
        );
        props.dispatch(setBooth(booth));
        const boothFeature = await fetchSingleEntityFromConfig(
            createEntityConfig('boothFeature'),
            props.boothFeatureId,
        );
        props.dispatch(setBoothFeature(boothFeature));

        switch (props.type) {
            case 'infodesk':
            case 'infodeskwithjobinfo': {
                // fetch infodesk by id
                // await props.dispatch(getEntity('infodesk', props.id));
                const infodesk = await fetchSingleEntityFromConfig(
                    createEntityConfig('infodesk'),
                    props.handle,
                );
                props.dispatch(setInfodesk(infodesk));
                break;
            }
            case 'presentationmodule': {
                // fetch presentation module by id
                // await props.dispatch(getEntity('presentationmodule', props.id)); // FIXME
                const presentationmodule = await fetchSingleEntityFromConfig(
                    createEntityConfig('presentationmodule'),
                    props.handle,
                );
                props.dispatch(setPresenationsmodule(presentationmodule));
                break;
            }
            default: {
                // TODO throw?
            }
        }
    };

    useEffect(() => {
        handleFetch();
    }, []);

    useEffect(() => {
        if (props.initialValues.ID) {
            setIsReady(true);
        }
    }, [props.initialValues]);

    const submit = async (formValues) => {
        const v = {
            ...props.initialValues,
            ...formValues, // redux form
            ...values, // files, etc.
        };

        blockScreen(true);

        if (props.initialValues.standardLogoExists && !isInfoDeskWithJobInfo) {
            showAlert(WARNING, WARNING_MSG_STANDARDLOGO);
            // TODO throw?
            return;
        }
        try {
            await Promise.all(
                Object.entries(v.files).map(async (file) => {
                    if (!file[1]?.file || typeof file[1].file !== 'object') {
                        return;
                    }
                    const img = await UploadFile(file[1].file);
                    v[file[1].imageField] = img.file;
                    v[file[1].nameField] = img.name;
                }),
            );
            if (!props.handle) {
                return;
            }

            switch (props.type) {
                case 'infodesk':
                case 'infodeskwithjobinfo': {
                    await props.dispatch(updateInfodesk(props.furnishing, v));
                    break;
                }
                case 'presentationmodule': {
                    await props.dispatch(
                        updatePresentationsmodul(props.furnishing, v),
                    );
                    break;
                }
            }

            blockScreen(false);
            showAlert(SUCCESS, SUCCESS_MSG);
        } catch (e) {
            blockScreen(false);
            showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_SAVE);
            console.error(e);
            props.dispatch(stopSubmit('furnishingsedit', e));
        } finally {
            props.history.push('/booths/furnishings');
        }
    };

    const handleLogoInput = (e) => {
        if (e?.target) {
            if (e.currentTarget?.name.startsWith('position-')) {
                const positionIndex = e.currentTarget.selectedIndex;
                setValues((prev) => ({
                    ...prev,
                    idPositionierungLogo: positionIndex,
                }));
                return;
            }
            if (e.target.name === 'delete') {
                setValues((prev) => ({
                    ...prev,
                    [e.target.nameField]: '',
                    [e.target.imageField]: '',
                }));
                return;
            }
            if (e.target.id.startsWith('datasheet')) {
                const id = e.target.id.charAt(e.target.id.length - 1);
                setValues((prev) => ({
                    ...prev,
                    files: {
                        ...prev.files,
                        [id]: {
                            file: e.target.files[0],
                            nameField: e.nameField || '',
                            imageField: e.imageField || '',
                        },
                    },
                }));
                return;
            }
        }
    };

    const handleInputStelen = (e) => {
        if (
            e.currentTarget &&
            e.currentTarget.name === `position-${stelenPrefix}Logos`
        ) {
            const positionIndex = e.currentTarget.selectedIndex;
            setValues((prev) => ({
                ...prev,
                idPositionierungSteleLogo: positionIndex,
            }));
            return;
        }
        if (e.target.name === 'delete') {
            setValues((prev) => ({
                ...prev,
                [`data4SteleLogo${e.target.id}`]: '',
                [`steleLogo${e.target.id}`]: '',
            }));
            return;
        }
        if (e.target.id.startsWith(`datasheet-${stelenPrefix}`)) {
            const id = e.target.id.charAt(e.target.id.length - 1);
            const idNumber =
                Number(id) + maxNumberOfPossibleLogosBeforeStelenLogos;
            setValues((prev) => ({
                ...prev,
                files: {
                    ...prev.files,
                    [idNumber]: {
                        file: e.target.files[0],
                        nameField: `steleLogo${id}` || '',
                        imageField: `data4SteleLogo${id}` || '',
                    },
                },
            }));
            return;
        }
    };

    if (!isReady) {
        return (
            <div style={{ height: '90vh' }}>
                <Loader visible />
            </div>
        );
    }

    const isApproved = props.boothFeature?.kzFreigabeAussteller === 1;
    const isInfoDeskOrPresentationModuleWithStandardLogo =
        !isInfoDeskWithJobInfo && props.initialValues.standardLogoExists;
    const isFormDisabled =
        isApproved || isInfoDeskOrPresentationModuleWithStandardLogo;

    return (
        <div className={style.question}>
            <div className={style.header}>
                <h1>{typeHeader}</h1>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
                <fieldset disabled={isFormDisabled}>
                    {isInfoDeskWithJobInfo && (
                        <fieldset>
                            <InfoDeskWithJobInfoForm />
                        </fieldset>
                    )}
                    {props.furnishing && (
                        <Logos
                            positionLogos={Math.max(
                                0,
                                props.initialValues.idPositionierungLogo,
                            )}
                            datasheets={logoIndices.map((i) => ({
                                name: props.furnishing[`logo${i}`] || '',
                                link:
                                    `data:image/png;base64,${
                                        props.furnishing[`data4Logo${i}`]
                                    }` || '',
                            }))}
                            handleInput={handleLogoInput}
                            standardLogoExists={
                                props.initialValues.standardLogoExists
                            }
                        />
                    )}

                    {props.furnishing &&
                        isInfoDeskWithJobInfo &&
                        !props.initialValues.standardLogoExists && (
                            <Logos
                                positionLogos={Math.max(
                                    0,
                                    props.initialValues
                                        .idPositionierungSteleLogo,
                                )}
                                datasheets={logoIndices.map((i) => ({
                                    name:
                                        props.furnishing[`steleLogo${i}`] || '',
                                    link:
                                        `data:image/png;base64,${
                                            props.furnishing[
                                                `data4SteleLogo${i}`
                                            ]
                                        }` || '',
                                }))}
                                handleInput={handleInputStelen}
                                standardLogoExists={
                                    props.initialValues.standardLogoExists
                                }
                                specificLogoGroupNamePrefix={stelenPrefix}
                            />
                        )}
                </fieldset>
                <fieldset>
                    <Formbuttons
                        hideBtn={true}
                        handleSubmit={props.handleSubmit}
                        submit={submit}
                        showCancel
                        overviewLink='/booths/furnishings'
                    />
                </fieldset>
            </form>
        </div>
    );
};

const InfoDeskWithJobInfoForm = () => {
    return (
        <>
            <label htmlFor='ueberschriftZeile1'>
                <span>Überschrift Zeile 1 (maximal 20 Zeichen)</span>
            </label>
            <Field
                component='input'
                placeholder=''
                type='text'
                name='ueberschriftZeile1'
                id='ueberschriftZeile1'
                maxLength='20'
            />
            <label htmlFor='ueberschriftZeile2'>
                <span>Überschrift Zeile 2 (maximal 20 Zeichen)</span>
            </label>
            <Field
                component='input'
                placeholder=''
                type='text'
                name='ueberschriftZeile2'
                id='ueberschriftZeile2'
                maxLength='20'
            />
            <label htmlFor='fliesstext'>
                <span>Text (11 Zeilen à 30 Zeichen)</span>
            </label>
            <Field
                name='fliesstext'
                maxLength='330'
                rows='10'
                style={{
                    resize: 'none',
                }}
                placeholder=''
                component='textarea'
                id='fliesstext'
            />
        </>
    );
};

export const EditFurnishing = compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'furnishingsedit',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(EditFurnishingForm),
);
