import React from 'react';
import style from '../index.module.css';
import { connect } from 'react-redux';
import StepButtons from '../stepbuttons';
import {
    getForwardActionForRoles,
    getBackwardActionForRoles,
    determineAuthForRoles,
} from '../functions';

const ClubZukunft = (props) => {
    const forwardAction = getForwardActionForRoles('careerstage', props);
    const backwardAction = getBackwardActionForRoles('showstage', props);

    return (
        <div className={style.question}>
            <h1>
                ClubZukunft
                <br />
                (IdeenHalle 9)
            </h1>

            <p>
                Der ClubZukunft bietet den BesucherInnen Informationen über
                Ausbildungsberufe und{' '}
                {props.hasCarreerStageAuthorization
                    ? 'Studiengänge'
                    : 'akademische Bildungswege'}
                . Darüber hinaus eröffnet er den Dialog zu Unternehmen,
                Verbänden, Kammern, Hochschulen und wissenschaftlichen
                Einrichtungen. Als Aussteller können Sie sich{' '}
                {props.hasCarreerStageAuthorization
                    ? 'u. a. auf der KarriereBühne'
                    : 'hier'}{' '}
                präsentieren und Ihre Ausbildungs- und Studienangebote im
                KarriereMarkt benennen. Weitere Informationen finden Sie im pdf
                Detailinfos.
                <br />
                Diese Informationen werden auf der Website
                www.ideenexpo.de/clubzukunft zusammengetragen und für
                interessierte Jugendliche entsprechend aufbereitet. Jugendliche
                erfahren konkret, welche Unternehmen welche Ausbildungsberufe
                und dualen Studiengänge anbieten und erhalten Informationen über
                das Studienangebot der teilnehmenden Hochschulen und
                Universitäten.
            </p>

            <StepButtons
                back={backwardAction.backwardAction}
                to={forwardAction.forwardAction}
                labelBack={backwardAction.backwardText}
                labelForward={forwardAction.forwardText}
            />
        </div>
    );
};

const mapStateToProps = (state, _prop) => {
    // Ermittlung der Rollenberechtigungen des angemeldeten Users
    const userAuth = determineAuthForRoles(state);
    return {
        hasBoothAuthorization: userAuth.hasBoothAuthorization,
        hasWorkshopAuthorization: userAuth.hasWorkshopAuthorization,
        hasLiveprogramAuthorization: userAuth.hasLiveprogramAuthorization,
        hasClubzukunftAuthorization: userAuth.hasClubzukunftAuthorization,
        hasOrganisationAuthorization: userAuth.hasOrganisationAuthorization,
        hasCarreerStageAuthorization: userAuth.hasCarreerStageAuthorization,
    };
};

export default connect(mapStateToProps, null)(ClubZukunft);
