import React, { useEffect, useState, Fragment } from 'react';
import {
    deleteEntity,
    getEntity,
} from '../../../../actions/exhibitor/exhibitor-actions';
import { isStele } from '../featureTypeFilter';
import {
    DELETE,
    ERROR,
    ERROR_MSG_BACKEND_FETCH,
    SUCCESS_DELETE,
    showAlert,
} from '../../alerts';
import { connect } from 'react-redux';
import style from '../../../exhibitor/index.module.css';
import { Link } from 'react-router-dom';
import { approveItem } from '../../functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Stepbuttons from '../../stepbuttons';
import { fetchListAsEntity } from '../../../../actions/exhibitor/fetcher/fetcher';
import Loader from '../../../common/loader';

const mapStateToProps = (state, _prop) => ({
    booth: state.booth || [],
    exhibit_deadline: state.deadlines?.exhibit_deadline,
    ie_year: state.deadlines?.ie_year,
    themeWorld: state.themeWorld || [],
    stele: state.stele || [],
    steletype: state.steletype || {},
    boothFeatures: state.boothFeatures || [],
    feature: state.feature || [],
    // association between booth (Stand) and stele
    booth2feature: state.booth2feature || [],
});

export const memoProp = (key, fn) => async (props) => {
    if (props[key] && !Object.keys(props[key])?.length) {
        return fn(props);
    }
    return props[key];
};

export const StelesList = (props) => {
    const [isReady, setIsReady] = useState(false);
    const [boothFeatureSteles, setBoothFeatureSteles] = useState([]);

    const handleFetchBooth = memoProp('booth', async (props) => {
        await props.dispatch(getEntity('booth'));
    });
    const handleFetchThemeWorld = memoProp('themeWorld', async (props) => {
        await props.dispatch(getEntity('themeWorld'));
    });
    const handleFetchSteletype = memoProp('steletype', async (props) => {
        await props.dispatch(getEntity('steletype'));
    });
    const handleFetchBoothFeatures = async () => {
        await Promise.all(
            props.booth.map(async (b) => {
                if (b.ID <= 0) {
                    return;
                }
                return props.dispatch(
                    await fetchListAsEntity('boothFeature', {
                        idStand: b.ID,
                        idMobiliarTyp: 4,
                    }),
                );
            }),
        );
    };

    const handleFetchSteles = async () => {
        const filtered = props.boothFeatures.filter(Boolean).filter(isStele);
        setBoothFeatureSteles(filtered);

        if (!filtered.length) {
            return [];
        }

        await Promise.all(
            filtered.map(async (bf) => {
                if (bf.idStele <= 0) {
                    return;
                }
                return props.dispatch(
                    await fetchListAsEntity('stele', { ID: bf.idStele }),
                );
                // return props.dispatch(
                //     getEntity('stele', {
                //         ID: b.idStele,
                //     }),
                // );
            }),
        );
        setIsReady(true);
    };

    const handleFetch = async () => {
        try {
            handleFetchThemeWorld(props);
            handleFetchSteletype(props);
            handleFetchBooth(props);
        } catch (e) {
            showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
            console.error('error while fetching steles', e);
        }
    };

    useEffect(() => {
        handleFetchBoothFeatures();
    }, [props.booth]);

    useEffect(() => {
        if (props.boothFeatures?.length > 0) {
            handleFetchSteles();
        }
    }, [props.boothFeatures]);

    useEffect(() => {
        handleFetch();
    }, []);

    const showSteles =
        Array.isArray(boothFeatureSteles) &&
        Array.isArray(props.stele) &&
        boothFeatureSteles.length > 0;

    if (!isReady) {
        return (
            <div style={{ height: '90vh' }}>
                <Loader visible />
            </div>
        );
    }

    return (
        <div className={style.question}>
            <div className={style.header}>
                <h1>Liste der Stelen</h1>
                <p>
                    {props.ie_year} stellen wir jedem Stand eine begrenzte
                    Anzahl an Infostelen - in Abhängigkeit von der Standgröße -
                    zur Verfügung. Sie können diese für allgemeine
                    Unternehmensinfos, Hochschulinformationen,
                    Projektbeschreibungen, allgemeine Informationen, etc.
                    verwenden. Die Stelen werden Ihnen von Ihrer/Ihrem
                    AnsprechpartnerIn angelegt.
                </p>
                <p>
                    <span className='bold'>Infostele 50cm</span>
                    <br />
                    Auf den Infostelen können Sie in wenigen Worten alle
                    wesentlichen Informationen zu Ihrer Institution
                    kommunizieren. Für Stände im Außenbereich stehen wetterfeste
                    Infostelen 50 cm zur Verfügung. Bitte beachten Sie, dass es
                    sich um hochformatige Grafikflächen handelt.
                </p>
                {/* TODO: Deadline für die Inhalte dynamisch aus Projekt auslesen */}
                <p>
                    <span className='bold'>Infostele 150cm</span>
                    <br />
                    Diese Stelen sind nur für großflächige Stände vorgesehen;
                    die Verwendung erfolgt nur in Abstimmung mit Ihrem/Ihrer
                    AnsprechpartnerIn bei der IdeenExpo.
                </p>
                <br />
                <p>
                    Die Texte und Grafiken für alle Stelen stimmen Sie bitte mit
                    der IdeenExpo und der Grafikabteilung des Messebauers ab.
                </p>
                <p>
                    Der Text muss sich nach unseren Zeichenvorgaben richten. Der
                    Stil des Textes sollte als direkte Ansprache formuliert sein
                    (Hier kannst du erfahren, wie …).
                </p>
                {/* TODO: Deadline für die Freigabe dynamisch aus Projekt auslesen */}
                <p>
                    Die Inhalte (Texte/Grafiken) müssen von Ihnen bis zum{' '}
                    <b>18. März 2024</b> hinterlegt und freigegeben werden.
                    <br />
                    Anschließend erhalten Sie die gesetzten Layouts per Mail,
                    diese müssen bis zum <b>22. April</b> freigegeben sein.
                </p>
            </div>
            {props.booth.map((booth) => {
                const themeWorldOfBooth = props.themeWorld.find(
                    (item) => item.ID === booth.idThemenWelt,
                );
                const stelesOfBooth = boothFeatureSteles.filter(
                    (boothFeatureStele) =>
                        boothFeatureStele.idStand === booth.ID,
                );

                return (
                    <div key={booth.ID} className='exhibitorTableWrap'>
                        <Fragment key={booth.ID}>
                            <span className='exhibitorTableHeader'>
                                {booth.bezeichnung}
                                {themeWorldOfBooth
                                    ? ` (${
                                          themeWorldOfBooth.themenWeltBezeichnung ||
                                          'Bezeichnung fehlt!'
                                      })`
                                    : ' (Kein Themenbereich zugewiesen)'}
                            </span>
                            {/* <Link
                                    className='exhibitorTableButton small-button'
                                    to={{
                                        pathname: `/booths/steles/new`,
                                        state: {
                                            booth_id: booth.ID,
                                        },
                                        search: `${booth.ID}`,
                                    }}
                                    onClick={() =>
                                        props.dispatch(
                                            destroy('stelesnew', 'stelesedit'),
                                        )
                                    }
                                >
                                    Neue Stele
                                </Link> */}
                            <div className='schoolTableWrap'>
                                <ul className='schoolTable three-gridlistpages'>
                                    <div className='header'>Name</div>
                                    <div className='header'>Freigabe</div>
                                    <div className='header' />
                                    {showSteles &&
                                        stelesOfBooth.map(
                                            (boothFeatureStele) => {
                                                const steleDetail =
                                                    props.stele.find(
                                                        (stele) =>
                                                            stele.ID ===
                                                            boothFeatureStele.idStele,
                                                    );

                                                const target = new URL(
                                                    window.location.origin,
                                                );
                                                target.searchParams.set(
                                                    'boothFeatureId',
                                                    boothFeatureStele.ID,
                                                );
                                                target.searchParams.set(
                                                    'steleId',
                                                    boothFeatureStele.idStele,
                                                );
                                                target.pathname =
                                                    '/booths/steles/edit';

                                                return (
                                                    steleDetail && (
                                                        <Fragment
                                                            key={
                                                                boothFeatureStele.ID
                                                            }
                                                        >
                                                            <ul>
                                                                <Link
                                                                    to={{
                                                                        pathname:
                                                                            target.pathname,
                                                                        search: target.search,
                                                                    }}
                                                                >
                                                                    <li className='schoolTable three-gridlistpages'>
                                                                        <label>
                                                                            {
                                                                                boothFeatureStele.bezeichnung
                                                                            }
                                                                        </label>
                                                                        <div>
                                                                            {boothFeatureStele.kzFreigabeAussteller
                                                                                ? 'Ja'
                                                                                : 'Nein'}
                                                                        </div>
                                                                    </li>
                                                                </Link>
                                                            </ul>
                                                        </Fragment>
                                                    )
                                                );
                                            },
                                        )}
                                </ul>
                            </div>
                        </Fragment>
                    </div>
                );
            })}
            <Stepbuttons
                back={'/booths/exhibit-boards'}
                to={'/booths/furnishings'}
                labelBack={'Exponattafeln'}
                labelForward={'Mobiliar'}
            />
        </div>
    );
};

export const Steles = connect(mapStateToProps, null)(StelesList);
