import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import {
    getEntity,
    deleteEntity,
} from '../../../../actions/exhibitor/exhibitor-actions.js';
import style from '../../../exhibitor/index.module.css';
import StepButtons from '../../stepbuttons';
import {
    showAlert,
    ERROR,
    ERROR_MSG_BACKEND_FETCH,
    SUCCESS_DELETE,
    DELETE,
} from '../../alerts';
import { approveItem } from '../../functions';
import { filterSteles } from '../featureTypeFilter';

class Steles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            boothFeatureSteles: [],
        };
    }

    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                if (this.props.booth && !Object.keys(this.props.booth).length) {
                    return this.props.dispatch(getEntity('booth'));
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                if (
                    this.props.themeWorld &&
                    !Object.keys(this.props.themeWorld).length
                ) {
                    return this.props.dispatch(getEntity('themeWorld'));
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                if (
                    this.props.steletype &&
                    !Object.keys(this.props.steletype).length
                ) {
                    return this.props.dispatch(getEntity('steletype'));
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                if (
                    this.props.boothFeatures &&
                    !Object.keys(this.props.boothFeatures).length
                ) {
                    return Promise.all(
                        this.props.booth.map((booth) => {
                            return new Promise((resolve, reject) => {
                                if (booth.ID > 0) {
                                    const filterCriteria = {
                                        idStand: booth.ID,
                                    };
                                    return this.props
                                        .dispatch(
                                            getEntity(
                                                'boothFeature',
                                                filterCriteria,
                                            ),
                                        )
                                        .then((result) => {
                                            resolve(result);
                                        })
                                        .catch((result) => {
                                            reject(result);
                                        });
                                } else {
                                    resolve();
                                }
                            });
                        }),
                    );
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                this.setState({
                    boothFeatureSteles: filterSteles(this.props.boothFeatures),
                });
                if (this.props.stele && !Object.keys(this.props.stele).length) {
                    return Promise.all(
                        this.state.boothFeatureSteles.map((boothFeature) => {
                            return new Promise((resolve, reject) => {
                                if (boothFeature.idStele > 0) {
                                    const filterCriteria = {
                                        ID: boothFeature.idStele,
                                    };
                                    return this.props
                                        .dispatch(
                                            getEntity('stele', filterCriteria),
                                        )
                                        .then((result) => {
                                            resolve(result);
                                        })
                                        .catch((result) => {
                                            reject(result);
                                        });
                                } else {
                                    resolve();
                                }
                            });
                        }),
                    );
                } else {
                    return Promise.resolve();
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    deleteItem = (json) => {
        Promise.resolve()
            .then(() => {
                return this.props.dispatch(deleteEntity(json, 'stele'));
            })
            .then(() => {
                showAlert(
                    DELETE,
                    SUCCESS_DELETE(`Die Stele ${json.bezeichnung}`),
                );
            })
            .catch((e) => {
                showAlert(ERROR, `Element konnte nicht gelöscht werden. ${e}`);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    };

    render() {
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>Liste der Stelen</h1>
                    <p>
                        {this.props.ie_year} stellen wir jedem Stand eine
                        begrenzte Anzahl an Infostelen - in Abhängigkeit von der
                        Standgröße - zur Verfügung. Sie können diese für
                        allgemeine Unternehmensinfos, Hochschulinformationen,
                        Projektbeschreibungen, allgemeine Informationen, etc.
                        verwenden. Die Stelen werden Ihnen von Ihrer/Ihrem
                        AnsprechpartnerIn angelegt.
                    </p>
                    <p>
                        <span className='bold'>Infostele 50cm</span>
                        <br />
                        Auf den Infostelen können Sie in wenigen Worten alle
                        wesentlichen Informationen zu Ihrer Institution
                        kommunizieren. Für Stände im Außenbereich stehen
                        wetterfeste Infostelen 50 cm zur Verfügung. Bitte
                        beachten Sie, dass es sich um hochformatige
                        Grafikflächen handelt.
                    </p>
                    {/* TODO: Deadline für die Inhalte dynamisch aus Projekt auslesen */}
                    <p>
                        <span className='bold'>Infostele 150cm</span>
                        <br />
                        Diese Stelen sind nur für großflächige Stände
                        vorgesehen; die Verwendung erfolgt nur in Abstimmung mit
                        Ihrem/Ihrer AnsprechpartnerIn bei der IdeenExpo. Die
                        Texte und Grafiken für alle Stelen stimmen Sie bitte mit
                        der IdeenExpo und der Grafikabteilung des Messebauers
                        ab. Die Inhalte müssen von Ihnen bis zum 18. März 2024
                        im Ausstellerportal hinterlegt werden. Sie bekommen
                        anschließend die gesetzten Layouts zur Freigabe per Mail
                        zugeschickt.
                    </p>
                    <p>
                        Der Text muss sich nach unseren Zeichenvorgaben richten.
                        Der Stil des Textes sollte als direkte Ansprache
                        formuliert sein (Hier kannst du erfahren, wie …).
                    </p>
                    {/* TODO: Deadline für die Freigabe dynamisch aus Projekt auslesen */}
                    <p>
                        Bitte beachten Sie: Das Datum für die Freigabe durch Sie
                        ist der <strong>22. April 2024</strong>.
                    </p>
                </div>
                {this.props.booth.map((booth) => {
                    const themeWorldOfBooth = this.props.themeWorld.find(
                        (item) => item.ID === booth.idThemenWelt,
                    );
                    return (
                        <div key={booth.ID} className='exhibitorTableWrap'>
                            <Fragment key={booth.ID}>
                                <span className='exhibitorTableHeader'>
                                    {booth.bezeichnung}
                                    {themeWorldOfBooth
                                        ? ` (${
                                              themeWorldOfBooth.themenWeltBezeichnung ||
                                              'Bezeichnung fehlt!'
                                          })`
                                        : ' (Kein Themenbereich zugewiesen)'}
                                </span>
                                {/* <Link
                                    className='exhibitorTableButton small-button'
                                    to={{
                                        pathname: `/booths/steles/new`,
                                        state: {
                                            booth_id: booth.ID,
                                        },
                                        search: `${booth.ID}`,
                                    }}
                                    onClick={() =>
                                        this.props.dispatch(
                                            destroy('stelesnew', 'stelesedit'),
                                        )
                                    }
                                >
                                    Neue Stele
                                </Link> */}
                                <div className='schoolTableWrap'>
                                    <ul className='schoolTable three-gridlistpages'>
                                        <div className='header'>Name</div>
                                        <div className='header'>Freigabe</div>
                                        <div className='header' />
                                        {Array.isArray(
                                            this.state.boothFeatureSteles,
                                        ) &&
                                        Array.isArray(this.props.stele) &&
                                        this.state.boothFeatureSteles.length > 0
                                            ? this.state.boothFeatureSteles
                                                  .filter(
                                                      (boothFeatureStele) =>
                                                          boothFeatureStele.idStand ===
                                                          booth.ID,
                                                  )
                                                  .map((boothFeatureStele) => {
                                                      const steleDetail =
                                                          this.props.stele.find(
                                                              (stele) =>
                                                                  stele.ID ===
                                                                  boothFeatureStele.idStele,
                                                          );
                                                      return (
                                                          steleDetail && (
                                                              <Fragment
                                                                  key={
                                                                      boothFeatureStele.ID
                                                                  }
                                                              >
                                                                  <ul
                                                                      key={
                                                                          boothFeatureStele.ID
                                                                      }
                                                                  >
                                                                      <Link
                                                                          to={{
                                                                              pathname:
                                                                                  '/booths/steles/edit',
                                                                              state: {
                                                                                  handle: boothFeatureStele.idStele,
                                                                                  booth_id:
                                                                                      booth.ID,
                                                                                  steletype:
                                                                                      this
                                                                                          .props
                                                                                          .steletype ||
                                                                                      [],
                                                                              },
                                                                              search: `${booth.ID}?${boothFeatureStele.ID}`,
                                                                          }}
                                                                      >
                                                                          <li className='schoolTable three-gridlistpages'>
                                                                              <label>
                                                                                  {
                                                                                      boothFeatureStele.bezeichnung
                                                                                  }
                                                                              </label>
                                                                              {steleDetail.kzFreigabeAussteller ? (
                                                                                  <div>
                                                                                      Ja
                                                                                  </div>
                                                                              ) : (
                                                                                  <div>
                                                                                      <a
                                                                                          className='button approve'
                                                                                          onClick={(
                                                                                              e,
                                                                                          ) => {
                                                                                              e.preventDefault();
                                                                                              approveItem(
                                                                                                  this,
                                                                                                  'stele',
                                                                                                  steleDetail,
                                                                                              );
                                                                                          }}
                                                                                      >
                                                                                          Freigeben
                                                                                      </a>
                                                                                  </div>
                                                                              )}
                                                                              {steleDetail.kzFreigabeAussteller ? (
                                                                                  <div />
                                                                              ) : (
                                                                                  <div
                                                                                      onClick={(
                                                                                          e,
                                                                                      ) => {
                                                                                          e.preventDefault();
                                                                                          if (
                                                                                              window.confirm(
                                                                                                  `Wollen Sie den Eintrag "${boothFeatureStele.bezeichnung}" wirklich löschen?`,
                                                                                              )
                                                                                          ) {
                                                                                              this.deleteItem(
                                                                                                  boothFeatureStele,
                                                                                              );
                                                                                          }
                                                                                      }}
                                                                                  >
                                                                                      <FontAwesomeIcon
                                                                                          icon={
                                                                                              faTrash
                                                                                          }
                                                                                          size='1x'
                                                                                      />
                                                                                  </div>
                                                                              )}
                                                                          </li>
                                                                      </Link>
                                                                  </ul>
                                                              </Fragment>
                                                          )
                                                      );
                                                  })
                                            : null}
                                    </ul>
                                </div>
                            </Fragment>
                        </div>
                    );
                })}
                <StepButtons
                    back={'/booths/exhibit-boards'}
                    to={'/booths/furnishings'}
                    labelBack={'Exponattafeln'}
                    labelForward={'Mobiliar'}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, _prop) => ({
    booth: state.booth || [],
    exhibit_deadline: state.deadlines?.exhibit_deadline,
    ie_year: state.deadlines?.ie_year,
    themeWorld: state.themeWorld || [],
    stele: state.stele || [],
    steletype: state.steletype || {},
    boothFeatures: state.boothFeatures || [],
    feature: state.feature || [],
    // association between booth (Stand) and stele
    booth2feature: state.booth2feature || [],
});

export default connect(mapStateToProps, null)(Steles);
