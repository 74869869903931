import {
    ERROR,
    ERROR_UNAUTHORIZED,
    WARNING_MSG_RELEASED,
    showAlert,
} from '../../components/exhibitor/alerts';
import {
    AUTHENTICATION,
    BACKEND_URL,
    BOARD_DELETE,
    CAREERSTAGE_DELETE,
    COMPANY2SITE_DELETE,
    COMPANY_SAVE,
    CONTRACT_SAVE,
    CUSTOMLIST_LIST,
    DATETIME_PATTERN,
    ENV,
    EXHIBIT_DELETE,
    IGNORE_DEADLINES,
    PASSES_DELETE,
    RADIO_VALUE_LIVE_PROGRAM_DISABLED,
    REFRESH_LOGIN_TIMEOUT,
    SHOWSTAGE_DELETE,
    STAGESEVEN_DELETE,
    STELE_DELETE,
    USER_GET,
    USER_PWCHANGE,
    WORKSHOP_DELETE,
} from '../../global_constants';
import { store } from '../../store';
import { PURGE } from 'redux-persist';

import { authenticateSystemUser } from '../common/actions';
import {
    createEntityConfig,
    fetchEntityTuple,
} from './fetcher/entity-list-detail.fetcher';
import { FIRMA_LST_DEF_KATEGORIE } from '../../backend-id-constants';

export const setAuthenticationExhibitor = (authenticationDataExhibitor) => ({
    type: 'SET_AUTHENTICATION_DATA_EXHIBITOR',
    authenticationDataExhibitor: authenticationDataExhibitor,
});

export const setAuthTimeout = (authTimeout) => ({
    type: 'SET_AUTH_TIMEOUT',
    authTimeout: authTimeout,
});

export const setDeadlines = (deadlines) => ({
    type: 'SET_DEADLINES',
    deadlines: deadlines,
});

export const setExhibitList = (exhibit) => ({
    type: 'SET_EXHIBIT_LIST',
    exhibitList: exhibit.TpExponatLstItem,
});

export const setExhibit = (exhibit) => ({
    type: 'SET_EXHIBIT',
    exhibit: exhibit,
});

export const setExhibitUpdate = (exhibit) => ({
    type: 'SET_EXHIBIT_UPDATE',
    exhibit: exhibit,
});

export const setExhibitDelete = (exhibit) => ({
    type: 'SET_EXHIBIT_DELETE',
    exhibit: exhibit,
});

export const setBoothList = (booth) => ({
    type: 'SET_BOOTH_LIST',
    boothList: booth.TpStandLstItem,
});

export const setBooth = (booth) => ({
    type: 'SET_BOOTH',
    booth: booth,
});

export const setBoothUpdate = (booth) => ({
    type: 'SET_BOOTH_UPDATE',
    booth: booth,
});

export const setBoothFeatureList = (boothFeature) => ({
    type: 'SET_BOOTH_FEATURE_LIST',
    boothFeatureList: boothFeature.TpStandAusstattungLstItem,
});

export const setBoothFeature = (boothFeature) => ({
    type: 'SET_BOOTH_FEATURE',
    boothFeature: boothFeature,
});

export const setBoothFeatureUpdate = (boothFeature) => ({
    type: 'SET_BOOTH_FEATURE_UPDATE',
    boothFeature: boothFeature,
});

export const setWorkshopList = (workshop) => ({
    type: 'SET_WORKSHOP_LIST',
    workshopList: workshop.TpVeranstaltungLstItem,
});

export const setWorkshop = (workshop) => ({
    type: 'SET_WORKSHOP',
    workshop: workshop,
});

export const setWorkshopUpdate = (workshop) => ({
    type: 'SET_WORKSHOP_UPDATE',
    workshop: workshop,
});

export const setWorkshopDelete = (workshop) => ({
    type: 'SET_WORKSHOP_DELETE',
    workshop: workshop,
});

export const setOrganisationList = (organisation) => ({
    type: 'SET_ORGANISATION_LIST',
    organisation: organisation,
});

export const setOrganisation = (organisation) => ({
    type: 'SET_ORGANISATION',
    organisation: organisation,
});

export const setPassesList = (passes) => ({
    type: 'SET_PASSES_LIST',
    passesList: passes.TpAusstellerausweisLstItem,
});

export const setPasses = (passes) => ({
    type: 'SET_PASSES',
    passes: passes,
});

export const setPassesUpdate = (passes) => ({
    type: 'SET_PASSES_UPDATE',
    passes: passes,
});

export const setPassesDelete = (passes) => ({
    type: 'SET_PASSES_DELETE',
    passes: passes,
});

export const setCateringList = (catering) => ({
    type: 'SET_CATERING_LIST',
    cateringList: catering.TpAusstellercateringLstItem,
});

export const setCatering = (catering) => ({
    type: 'SET_CATERING',
    catering: catering,
});

export const setCateringUpdate = (catering) => ({
    type: 'SET_CATERING_UPDATE',
    catering: catering,
});

export const setStorageList = (storage) => ({
    type: 'SET_STORAGE_LIST',
    storage: storage,
});

export const setStorage = (storage) => ({
    type: 'SET_STORAGE',
    storage: storage,
});

export const setStorageUpdate = (storage) => ({
    type: 'SET_STORAGE_UPDATE',
    storage: storage,
});

export const saveExhibit = (values) => ({
    type: 'SET_EXHIBIT',
    exhibit: values,
});

export const setPersonList = (person) => ({
    type: 'SET_PERSON_LIST',
    personList: person.TpPersonLst,
});

export const setPerson = (person) => ({
    type: 'SET_PERSON',
    person: person,
});

export const setPersonUpdate = (person) => ({
    type: 'SET_PERSON_UPDATE',
    person: person,
});

export const setCompanyList = (company) => ({
    type: 'SET_COMPANY_LIST',
    companyList: company.TpFirmaLstItem,
});

export const setCompany = (company) => ({
    type: 'SET_COMPANY',
    company: company,
});

export const setCompanyUpdate = (company) => ({
    type: 'SET_COMPANX_UPDATE',
    company: company,
});

export const setFeatureList = (feature) => ({
    type: 'SET_FEATURE_LIST',
    featureList: feature.TpAusstattungLstItem,
});

export const setFeature = (feature) => ({
    type: 'SET_FEATURE',
    feature: feature,
});

export const setFeatureUpdate = (feature) => ({
    type: 'SET_FEATURE_UPDATE',
    feature: feature,
});

export const setExhibitor = (exhibitor) => ({
    type: 'SET_EXHIBITOR',
    exhibitor: exhibitor,
});

export const setExhibitorList = (exhibitor) => ({
    type: 'SET_EXHIBITOR_LIST',
    exhibitorList: exhibitor.TpAusstellerBenutzer,
});

export const setBannerPrint = (item) => ({
    type: 'SET_BANNER_PRINT',
    bannerPrint: item,
});

export const setBannerPrintList = (list) => ({
    type: 'SET_BANNER_PRINT_LIST',
    bannerPrintList: list,
});

export const setBannerDigital = (item) => ({
    type: 'SET_BANNER_DIGITAL',
    bannerDigital: item,
});

export const setBannerDigitalList = (list) => ({
    type: 'SET_BANNER_DIGITAL_LIST',
    bannerDigitalList: list,
});

export const setFirmaKategorieList = (list) => ({
    type: 'SET_FIRMA_KATEGORIE_LIST',
    firmaKategorieList: list.TpLstDefLstItem,
});

export const setBoard = (board) => ({
    type: 'SET_BOARD',
    board: board,
});

export const setBoardUpdate = (board) => ({
    type: 'SET_BOARD_UPDATE',
    board: board,
});

export const setBoardDelete = (board) => ({
    type: 'SET_BOARD_DELETE',
    board: board,
});

export const setBoardList = (board) => ({
    type: 'SET_BOARD_LIST',
    boardList: board.TpExponatTafelLstItem,
});

export const setStele = (stele) => ({
    type: 'SET_STELE',
    stele: stele,
});

export const setSteleUpdate = (stele) => ({
    type: 'SET_STELE_UPDATE',
    stele: stele,
});

export const setSteleDelete = (stele) => ({
    type: 'SET_STELE_DELETE',
    stele: stele,
});

export const setSteleList = (stele) => ({
    type: 'SET_STELE_LIST',
    steleList: stele.TpSteleLstItem,
});

export const setThemeWorldList = (themeWorldList) => ({
    type: 'SET_THEME_WORLD_LIST',
    themeWorldList: themeWorldList.TpThemenWeltLstItem,
});

export const setThemeWorld = (themeWorld) => ({
    type: 'SET_THEME_WORLD',
    themeWorld: themeWorld,
});

export const setTrainingList = (traininglist) => ({
    type: 'SET_TRAINING_LIST',
    traininglist: traininglist.TpAusbildungsberufLstItem,
});

export const setTraining = (training) => ({
    type: 'SET_TRAINING',
    training: training,
});

export const setTrainingDef = (trainingdef) => ({
    type: 'SET_TRAINING_DEF',
    trainingdef: trainingdef.TpLstDefLstItem,
});

export const setStudyfieldList = (studyfield) => ({
    type: 'SET_STUDYFIELD_LIST',
    studyfieldList: studyfield.TpStudienrichtungLstItem,
});

export const setStudyfield = (studyfield) => ({
    type: 'SET_STUDYFIELD',
    studyfield: studyfield,
});

export const setStudyfieldDef = (studyfielddef) => ({
    type: 'SET_STUDYFIELD_DEF',
    studyfielddef: studyfielddef.TpLstDefLstItem,
});

export const setStudyfieldUpdate = (studyfield) => ({
    type: 'SET_STUDYFIELD_UPDATE',
    studyfield: studyfield,
});

export const setDualstudyList = (dualstudy) => ({
    type: 'SET_DUALSTUDY_LIST',
    dualstudyList: dualstudy.TpDualesStudiumLstItem,
});

export const setDualstudy = (dualstudy) => ({
    type: 'SET_DUALSTUDY',
    dualstudy: dualstudy,
});

export const setDualstudyDef = (dualstudydef) => ({
    type: 'SET_DUALSTUDY_DEF',
    dualstudydef: dualstudydef.TpLstDefLstItem,
});

export const setDualstudyUpdate = (dualstudy) => ({
    type: 'SET_DUALSTUDY_UPDATE',
    dualstudy: dualstudy,
});

export const setStageList = (stagelist) => ({
    type: 'SET_STAGE_LIST',
    stagelist: stagelist.TpBuehneLstItem,
});

export const setShowstage = (showstage) => ({
    type: 'SET_SHOWSTAGE',
    showstage: showstage,
});

export const setShowstageUpdate = (showstage) => ({
    type: 'SET_SHOWSTAGE_UPDATE',
    showstage: showstage,
});

export const setShowstageDelete = (showstage) => ({
    type: 'SET_SHOWSTAGE_DELETE',
    showstage: showstage,
});

export const setStageseven = (stageseven) => ({
    type: 'SET_STAGESEVEN',
    stageseven: stageseven,
});

export const setStagesevenUpdate = (stageseven) => ({
    type: 'SET_STAGESEVEN_UPDATE',
    stageseven: stageseven,
});

export const setStagesevenDelete = (stageseven) => ({
    type: 'SET_STAGESEVEN_DELETE',
    stageseven: stageseven,
});

export const setCareerstage = (careerstage) => ({
    type: 'SET_CAREERSTAGE',
    careerstage: careerstage,
});

export const setCareerstageUpdate = (careerstage) => ({
    type: 'SET_CAREERSTAGE_UPDATE',
    careerstage: careerstage,
});

export const setCareerstageDelete = (careerstage) => ({
    type: 'SET_CAREERSTAGE_DELETE',
    careerstage: careerstage,
});

export const setExponatzielgruppeList = (exponatzg) => ({
    type: 'SET_EXPONATZG_LIST',
    exponatzg: exponatzg.TpLstDefLstItem,
});

export const setSteleType = (steletype) => ({
    type: 'SET_STELETYPE',
    steletype: steletype,
});

export const setSteleTypeList = (steletypelist) => ({
    type: 'SET_STELETYPE_LIST',
    steletypelist: steletypelist.TpSteletypLstItem,
});

export const setBooth2feature = (booth2feature) => ({
    type: 'SET_BOOTH2FEATURE',
    booth2feature: booth2feature,
});

export const setBooth2featureList = (_booth2featurelist) => ({
    type: 'none',
});

export const setBooth2featureUpdate = (booth2feature) => ({
    type: 'SET_BOOTH2FEATURE_UPDATE',
    booth2feature: booth2feature,
});

export const setInfodeskList = (infodesklist) => ({
    type: 'SET_INFODESK_LIST',
    infodesklist: infodesklist,
});

export const setInfodesk = (infodesk) => ({
    type: 'SET_INFODESK',
    infodesk: infodesk,
});

export const setInfodeskUpdate = (infodesk) => ({
    type: 'SET_INFODESK_UPDATE',
    infodesk: infodesk,
});

export const setPresenationsmoduleList = (presentationmodulelist) => ({
    type: 'SET_PRESENTATIONMODULE_LIST',
    presentationmodulelist: presentationmodulelist,
});

export const setPresenationsmodule = (presentationmodule) => ({
    type: 'SET_PRESENTATIONMODULE',
    presentationmodule: presentationmodule,
});

export const setPresenationsmoduleUpdate = (presentationmodule) => ({
    type: 'SET_PRESENTATIONMODULE_UPDATE',
    presentationmodule: presentationmodule,
});

export const setCompany2site = (company2site) => ({
    type: 'SET_COMPANY2SITE',
    company2site: company2site,
});

export const setCompany2siteList = (_company2sitelist) => ({
    type: 'none',
});

export const setCompany2siteUpdate = (company2site) => ({
    type: 'SET_COMPANY2SITE_UPDATE',
    company2site: company2site,
});

export const setCompany2siteDelete = (company2site) => ({
    type: 'SET_COMPANY2SITE_DELETE',
    company2site: company2site,
});

export const setVeranstzpList = (veranstzp) => ({
    type: 'SET_VERANSTZP_LIST',
    veranstzp: veranstzp.TpLstDefLstItem,
});

export const setVeranstzielgruppeList = (veranstzielgruppe) => ({
    type: 'SET_VERANSTZIELGRUPPE_LIST',
    veranstzielgruppe: veranstzielgruppe.TpLstDefLstItem,
});

export const setBezugsgruppeList = (bezugsgruppe) => ({
    type: 'SET_BEZUGSGRUPPE_LIST',
    bezugsgruppe: bezugsgruppe.TpLstDefLstItem,
});

export const setArrivalTimeList = (arrivaltime) => ({
    type: 'SET_ARRIVALTIME_LIST',
    arrivaltime: arrivaltime.TpLstDefLstItem,
});

export const setUser2roleList = (_user2rolelist) => ({
    type: 'none',
});

export const setUser2role = (user2role) => ({
    type: 'SET_USER2ROLE',
    user2role: user2role,
});

export const setUser2roleUpdate = (user2role) => ({
    type: 'SET_USER2ROLE_UPDATE',
    user2role: user2role,
});

export const setSiteList = (sitelist) => ({
    type: 'SET_SITE_LIST',
    sitelist: sitelist,
});

export const setSite = (site) => ({
    type: 'SET_SITE',
    site: site,
});

export const setProjectList = (_projectlist) => ({
    type: 'none',
});

export const setProject = (project) => ({
    type: 'SET_PROJECT',
    project: project,
});

export const setContractList = (_contractlist) => ({
    type: 'none',
});

export const setContract = (contract) => ({
    type: 'SET_CONTRACT',
    contract: contract,
});

export const setCompany2person = (company2person) => ({
    type: 'SET_COMPANY2PERSON',
    company2person: company2person,
});

export const setCompany2personList = (_company2personlist) => ({
    type: 'none',
});

const mapCompany = (values) => {
    return {
        firmennameKarriereboerse: values.firmenname || '',
        karriereUnternehmensWebsite: values.webseite || '',
        websiteSchueler: values.webseite_schueler || '',
        websiteStudenten: values.webseite_studenten || '',
        branchenportal: values.branchenportal || '',
        ansprSchName: values.namePupils || '',
        ansprSchTelefon: values.telefonPupils || '',
        ansprSchFax: values.faxPupils || '',
        ansprSchEmail: values.emailPupils || '',
        ansprStName: values.name || '',
        ansprStTel: values.telefon || '',
        ansprStFax: values.fax || '',
        ansprStEmail: values.email || '',
    };
};

const mapLiveprogram = (values) => {
    return {
        liveProgrammDeaktiviert:
            values.noliveprogram === RADIO_VALUE_LIVE_PROGRAM_DISABLED ? 1 : 0,
    };
};

// getEntity = function that requests entities from the backend
// and "fills" the respective Redux object
// Generelly, first makes a getLst request and then as many get requests as there are entities in the respective list
// entity = entity that should be requested from the backend
// e.g. "booth" (Endpoint stand)
// getSpecific = this function can also be used to update specific
// entities in Redux. In that case then getSpecific is used
// e.g. {"ID":json.exhibit.idStand}
export const getEntity = (entity, getSpecific) => {
    return async function (dispatch) {
        try {
            // filterParameterForList2 = filter argument for getLst request,
            // when only a specific entity should be updated in Redux (getSpecific)
            const filterParameterForList2 =
                getSpecific &&
                Object.keys(getSpecific).length !== 0 &&
                getSpecific.constructor === Object
                    ? getSpecific
                    : {};
            const entityConfig = createEntityConfig(entity) || {
                entityListEndPoint: '',
                entityGetEndPoint: '',
                dispatchEntityList: '',
                dispatchEntity: '',
                dispatchEntity2: '',
                tpEntityLstItem: '',
                filterParameterForList: {},
                idForItem: '',
            };
            // ENV === 'development' && console.log('entityConfig:', entityConfig);
            const [tpEntityLst, entityList] = await fetchEntityTuple(
                entityConfig,
                {
                    ...entityConfig.filterParameterForList,
                    ...filterParameterForList2,
                },
            );

            dispatch(entityConfig.dispatchEntityList(tpEntityLst));
            const entities = entityList.filter((entity) => Boolean(entity));
            if (getSpecific && Object.keys(getSpecific).length !== 0) {
                dispatch(entityConfig.dispatchEntity2(entities));
            } else {
                dispatch(entityConfig.dispatchEntity(entities));
            }
        } catch (error) {
            ENV === 'development' && console.log('error occured', error);
            if (error === 'Unauthorized') {
                showAlert(ERROR, ERROR_UNAUTHORIZED);
            }
        }
    };
};

export const updateCompany = (json, values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    return function (dispatch) {
        const newValues = mapCompany(values);
        const jsonNew = { ...json, ...newValues };
        ENV === 'development' &&
            console.info('updating the company with id = ', json.ID);
        return fetch(BACKEND_URL + COMPANY_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(jsonNew),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('company details fetch:', json);
                if (!json.ID) {
                    return Promise.reject(
                        'company details fetch validity check: wrong code',
                    );
                }
                return json;
            })
            .then((json) => {
                return dispatch(setCompanyUpdate(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                return Promise.reject(e);
            });
    };
};

/**
 *
 * @param {'targetgroup'|'trainingdef'|'studyfielddef'|'dualstudydef'|'datesdef'|'targetgroupworkshop'|'bezugsgruppe'|'firmakategoriedef'} list
 * @returns {void}
 */
export const getCustomLst = (list) => {
    const config = {
        targetgroup: {
            idLst: '4299400',
            dispatchAction: setExponatzielgruppeList,
        },
        trainingdef: {
            idLst: '4849400',
            dispatchAction: setTrainingDef,
        },
        studyfielddef: {
            idLst: '4869400',
            dispatchAction: setStudyfieldDef,
        },
        dualstudydef: {
            idLst: '4859400',
            dispatchAction: setDualstudyDef,
        },
        datesdef: {
            idLst: '4309400',
            dispatchAction: setVeranstzpList,
        },
        targetgroupworkshop: {
            idLst: '4289400',
            dispatchAction: setVeranstzielgruppeList,
        },
        bezugsgruppe: {
            idLst: '4279400',
            dispatchAction: setBezugsgruppeList,
        },
        firmakategoriedef: {
            idLst: FIRMA_LST_DEF_KATEGORIE,
            dispatchAction: setFirmaKategorieList,
        },
    }[list];

    const authenticationData = store.getState().authenticationDataExhibitor;
    const getState = store.getState();
    const _exhibitor =
        Array.isArray(getState.exhibitor) && getState.exhibitor.length >= 1
            ? getState.exhibitor[0]
            : {};
    return async (dispatch) => {
        try {
            const response = await fetch(BACKEND_URL + CUSTOMLIST_LIST, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authenticationData.accessToken}`,
                },
                body: JSON.stringify({
                    login: {
                        // TODO: pick properties manually!
                        ...authenticationData,
                        userId4Hist: undefined,
                    },
                    idLst: config.idLst,
                }),
            });
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            const json = JSON.parse(await response.text());
            return dispatch(config.dispatchAction(json));
        } catch (e) {
            ENV === 'development' && console.log('error occured', e);
            if (e === 'Unauthorized') {
                showAlert(ERROR, ERROR_UNAUTHORIZED);
            }
        }
    };
};

export const deleteEntity = (json, entity) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    let deleteEndpoint = '';
    let dispatchAction = '';
    let idForItem = '';
    let withinDeadline = true;
    let alreadyReleased = false;
    switch (entity) {
        case 'exhibit': {
            deleteEndpoint = EXHIBIT_DELETE;
            dispatchAction = setExhibitDelete;
            idForItem = 'ID';
            withinDeadline =
                store.getState().deadlines?.exhibit_deadline_within || false;
            alreadyReleased = json?.kzFreigabeAussteller === 1;
            break;
        }
        case 'stele': {
            deleteEndpoint = STELE_DELETE;
            dispatchAction = setSteleDelete;
            idForItem = 'ID';
            withinDeadline =
                store.getState().deadlines?.exhibit_deadline_within || false;
            alreadyReleased = json?.kzFreigabeAussteller === 1;
            break;
        }
        case 'board': {
            deleteEndpoint = BOARD_DELETE;
            dispatchAction = setBoardDelete;
            idForItem = 'ID';
            withinDeadline =
                store.getState().deadlines?.exhibit_deadline_within || false;
            alreadyReleased = json?.kzFreigabeAussteller === 1;
            break;
        }
        case 'stageseven': {
            deleteEndpoint = STAGESEVEN_DELETE;
            dispatchAction = setStagesevenDelete;
            idForItem = 'ID';
            withinDeadline =
                store.getState().deadlines?.stages_deadline_within || false;
            alreadyReleased = json?.kzFreigabeAussteller === 1;
            break;
        }
        case 'showstage': {
            deleteEndpoint = SHOWSTAGE_DELETE;
            dispatchAction = setShowstageDelete;
            idForItem = 'ID';
            withinDeadline =
                store.getState().deadlines?.stages_deadline_within || false;
            alreadyReleased = json?.kzFreigabeAussteller === 1;
            break;
        }
        case 'careerstage': {
            deleteEndpoint = CAREERSTAGE_DELETE;
            dispatchAction = setCareerstageDelete;
            idForItem = 'ID';
            withinDeadline =
                store.getState().deadlines?.stages_deadline_within || false;
            alreadyReleased = json?.kzFreigabeAussteller === 1;
            break;
        }
        case 'company2site': {
            deleteEndpoint = COMPANY2SITE_DELETE;
            dispatchAction = setCompany2siteDelete;
            idForItem = 'ID';
            // TODO: no such deadline exists yet
            // TODO: no "kzFreigabeAussteller" or similar field exists,
            alreadyReleased = false;
            break;
        }
        case 'passes': {
            deleteEndpoint = PASSES_DELETE;
            dispatchAction = setPassesDelete;
            idForItem = 'ID';
            // TODO: no such deadline exists yet
            alreadyReleased = json?.kzFreigabeAussteller === 1;
            break;
        }
        case 'workshop': {
            deleteEndpoint = WORKSHOP_DELETE;
            dispatchAction = setWorkshopDelete;
            idForItem = 'ID';
            withinDeadline =
                store.getState().deadlines?.workshop_deadline_within || false;
            alreadyReleased = json?.idStatus === 6;
            break;
        }
    }
    return function (dispatch) {
        ENV === 'development' &&
            console.info('deleting the element with id = ', json[idForItem]);
        // only allow delete if object wasn't released yet
        if (alreadyReleased) {
            return Promise.reject(WARNING_MSG_RELEASED);
        }
        // only allow delete within the valid range of dates
        // if (!withinDeadline) { return Promise.reject(WARNING_MSG_DEADLINE)}
        return fetch(BACKEND_URL + deleteEndpoint, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify({
                ID: json[idForItem],
                login: {
                    // TODO: pick properties manually!
                    ...authenticationData,
                    userId4Hist: undefined,
                },
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then(() => {
                return dispatch(dispatchAction(json));
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                return Promise.reject(e);
            });
    };
};

export const updateLiveprogram = (json, values) => {
    const authenticationData = store.getState().authenticationDataExhibitor;
    return async function (_dispatch) {
        const newValues = mapLiveprogram(values);
        const jsonNew = { ...json, ...newValues };
        ENV === 'development' &&
            console.info('updating the contract with id = ', json.ID);
        return fetch(BACKEND_URL + CONTRACT_SAVE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authenticationData.accessToken}`,
            },
            body: JSON.stringify(jsonNew),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((json) => {
                ENV === 'development' &&
                    console.info('contract details fetch:', json);
                if (!json.ID) {
                    return Promise.reject(
                        'contract details fetch validity check: wrong code',
                    );
                }
                return json;
            })
            .then((json) => {
                // dispatch(setInvitation(json));
                return json;
            })
            .catch((e) => {
                ENV === 'development' && console.log('error occured', e);
                return Promise.reject(e);
            });
    };
};

export const readDeadlines = () => {
    const project = store.getState().project || [];
    const activeProject =
        Array.isArray(project) && project.length > 0 ? project[0] : null;

    const hasBegin = activeProject?.beginn;
    const dateBegin = hasBegin ? new Date(activeProject.beginn) : null;

    const hasEnd = activeProject?.ende;
    const dateEnd = hasEnd ? new Date(activeProject.ende) : null;

    const isDateDeadline1 =
        activeProject?.deadline1 &&
        DATETIME_PATTERN.test(activeProject.deadline1);
    const dateDeadline1 = isDateDeadline1
        ? new Date(activeProject.deadline1)
        : null;

    const isDateDeadline2 =
        activeProject?.deadline2 &&
        DATETIME_PATTERN.test(activeProject.deadline2);
    const dateDeadline2 = isDateDeadline2
        ? new Date(activeProject.deadline2)
        : null;

    const isDateDeadline3 =
        activeProject?.deadline3 &&
        DATETIME_PATTERN.test(activeProject.deadline3);
    const dateDeadline3 = isDateDeadline3
        ? new Date(activeProject.deadline3)
        : null;

    const exhibit_deadline = isDateDeadline1
        ? format(dateDeadline1, longFormat)
        : '(Projektdeadline nicht spezifiziert)';
    const workshop_deadline = isDateDeadline2
        ? format(dateDeadline2, longFormat)
        : '(Projektdeadline nicht spezifiziert)';
    const stages_deadline = isDateDeadline3
        ? format(dateDeadline3, longFormat)
        : '(Projektdeadline nicht spezifiziert)';
    const ie_begin = hasBegin
        ? format(dateBegin, longFormat)
        : '(Beginn der IdeenExpo nicht spezifiziert)';
    const ie_end = hasEnd
        ? format(dateEnd, longFormat)
        : '(Beginn der IdeenExpo nicht spezifiziert)';
    const ie_year = hasBegin
        ? format(dateBegin, yearFormat)
        : '(Beginn der IdeenExpo nicht spezifiziert)';

    // TODO: Test if current date is between
    // a) the starting date for Aussteller to make changes (property/date does not yet exist)
    // and b) the respective deadline.
    // Fake it for now: starting date is 2020-02-16
    // TODO: We need a "starting date" (Beginn der Bearbeitungsfrist) in Backend
    const startDate = new Date('2020-02-16').setHours(0, 0, 0, 0);
    const unixTimeStart = new Date(null);
    const exhibitEditStart = startDate;
    const exhibitEditEnd = isDateDeadline1
        ? toEndOfDay(dateDeadline1)
        : unixTimeStart;
    const workshopEditStart = startDate;
    const workshopEditEnd = isDateDeadline2
        ? toEndOfDay(dateDeadline2)
        : unixTimeStart;
    const stagesEditStart = startDate;
    const stagesEditEnd = isDateDeadline3
        ? toEndOfDay(dateDeadline3)
        : unixTimeStart;
    const today = new Date().setHours(0, 0, 0, 0);
    const exhibit_deadline_within = isDateDeadline1
        ? isWithinDeadline(exhibitEditStart, today, exhibitEditEnd)
        : false;
    const workshop_deadline_within = isDateDeadline2
        ? isWithinDeadline(workshopEditStart, today, workshopEditEnd)
        : false;
    const stages_deadline_within = isDateDeadline3
        ? isWithinDeadline(stagesEditStart, today, stagesEditEnd)
        : false;

    Promise.resolve()
        .then(() => {
            return store.dispatch(
                setDeadlines({
                    exhibit_deadline,
                    workshop_deadline,
                    stages_deadline,
                    ie_begin,
                    ie_end,
                    ie_year,
                    exhibit_deadline_within: IGNORE_DEADLINES
                        ? true
                        : exhibit_deadline_within,
                    workshop_deadline_within: IGNORE_DEADLINES
                        ? true
                        : workshop_deadline_within,
                    stages_deadline_within: IGNORE_DEADLINES
                        ? true
                        : stages_deadline_within,
                }),
            );
        })
        .catch((e) => {
            ENV === 'development' && console.log(e);
        });
};

const format = (date, format) => {
    return date.toLocaleString('de-DE', format);
};

const longFormat = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
};

const yearFormat = {
    year: 'numeric',
};

const toEndOfDay = (date) => {
    return date.setHours(23, 59, 59, 0);
};

const isWithinDeadline = (start, today, end) => {
    if (IGNORE_DEADLINES) {
        return true;
    }

    return start < end && end > today;
};

export const refreshLogin = (username, password) => {
    return function (dispatch) {
        const timeout = setTimeout(() => {
            if (
                store.getState().authenticationDataExhibitor.accessToken &&
                store.getState().authenticationData.login.accessToken
            ) {
                dispatch(tempAuthenticate(username, password))
                    .then(() => dispatch(authenticateSystemUser()))
                    .then(
                        () => dispatch(refreshLogin(username, password)),
                        (_error) => {
                            dispatch({
                                type: PURGE,
                                key: `persist: ideenexpo-${process.env.REACT_APP_IDEENEXPO}`,
                                result: () => null,
                            });
                            dispatch({ type: 'LOGOUT' });
                        },
                    );
            }
        }, REFRESH_LOGIN_TIMEOUT);
        dispatch(setAuthTimeout(timeout));
    };
};

export const tempAuthenticate = (username, password) => {
    // AUTHENTICATE
    return function (dispatch) {
        return fetch(BACKEND_URL + AUTHENTICATION, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${
                    store.getState().authenticationDataExhibitor.accessToken
                }`,
            },
            body: `username=${username}&password=${password}&idlanguage=49&mandantnr=1&applid=975`,
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((responseData) => {
                dispatch(setAuthenticationExhibitor(responseData));
                return responseData;
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    };
};

export const getUser = () => {
    // AUTHENTICATE
    return function (dispatch) {
        return fetch(BACKEND_URL + USER_GET, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${
                    store.getState().authenticationDataExhibitor.accessToken
                }`,
            },
            body: {},
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((responseData) => {
                dispatch(setAuthenticationExhibitor(responseData));
                return responseData;
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    };
};

export const saveUser = (_username, password) => {
    return function (dispatch) {
        const authenticationData = store.getState().authenticationDataExhibitor;
        const json = {
            login: authenticationData,
            PASSWORD_NEW: password,
            PASSWORD_NEW_REPEAT: password,
        };

        return fetch(BACKEND_URL + USER_PWCHANGE, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${
                    store.getState().authenticationDataExhibitor.accessToken
                }`,
            },
            body: JSON.stringify(json),
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                return JSON.parse(text);
            })
            .then((responseData) => {
                dispatch(setAuthenticationExhibitor(responseData));
                return responseData;
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    };
};

// Helper function: sole purpose is to delete redundant (duplicated)
// trainings (Ausbildungsberuf) objects, that were created erroneously
// in development
// export const deleteTraining = (ID) => {
// 	const authenticationData = store.getState().authenticationDataExhibitor;
// 	var deleteEndpoint = TRAINING_DELETE;
// 	var idForItem = ID;
// 	return function(dispatch) {
// 		ENV === "development" && console.info("deleting the element with id = ", eval(idForItem));
// 		return fetch(BACKEND_URL + deleteEndpoint, {
// 			method: "POST",
// 			mode: "cors",
// 			headers: {
// 				"Content-Type": "application/json",
// 				Authorization: `Bearer ${
// 					authenticationData.accessToken
// 				}`,
// 			},
// 			body: JSON.stringify({
// 				"ID": eval(idForItem),
// 				login: {
// 					// TODO: pick properties manually!
// 					...authenticationData,
// 					userId4Hist: undefined,
// 				}
// 			}),
// 		})
// 		.then(response => {
// 			if (!response.ok) {
// 				return Promise.reject(response.statusText);
// 			} else {
// 				return response.text();
// 			}
// 		})
// 		.then(text => {
// 			return JSON.parse(text);
// 		})
// 		.catch(e => {
// 			ENV === "development" && console.log("error occured", e)
// 			return Promise.reject(e);
// 		})
// 	}
// }
// To call the function (above), put this into some component's
// UNSAFE_componentWillMount's Promise Chain
// IDs need to be collected by calling ausbildungsberuf/getLst with filter
// "idFirma": <yourCompanysID> and then extracted by using regex
// In vim, I extracted them by
// 1) search for: "workflowTaskId": 0,\n \{12\}"ID": \(\d\+\) and then
// 2) substituting with %s//donotdeletemejustyet: \1/g
// 3) deleting every line that does not contain donotdeletemejustyet: v//d
// 4) removing donotdeletemejustyet, e.g. by using block-edit/delete
// .then(() => {
// 	if (true) {
//  var deleteThese = [6871, 6872, 6873]
// 		Promise.all(deleteThese.map(item => {
// 			return new Promise((resolve, reject) => {
// 				return this.props.dispatch( deleteTraining(item))
// 				.then((result) => {
// 					resolve(result)
// 				})
// 				.catch((result) => {
// 					reject(result)
// 				})
// 			})
// 		}))
// 	} else {
// 		Promise.resolve()
// 	}
// })
