import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { destroy, reduxForm } from 'redux-form';

import { setBannerDigitalList } from '../../../../actions/exhibitor/exhibitor-actions';
import { createEntityConfig } from '../../../../actions/exhibitor/fetcher/entity-list-detail.fetcher';
import { fetchItemListFromConfig } from '../../../../actions/exhibitor/fetcher/fetcher';
import style from '../../index.module.css';
import {
    approveBannerDigital,
    deleteBannerDigital,
} from './banner-digital.actions';
import sx from '../banner-print/banner-print.module.scss';
import { getOrganisation } from '../../../../actions/exhibitor/organisation';
import Loader from '../../../common/loader';
import Stepbuttons from '../../stepbuttons';

const mapStateToProps = (state) => {
    return {
        list: state.bannerDigitalList,
    };
};

export const BannerDigitalList = ({ dispatch, list }) => {
    const history = useHistory();
    const [isReady, setIsReady] = useState(false);
    const [currentlyApprovingIds, setCurrentlyApprovingIds] = useState({});

    const handleFetch = async () => {
        setIsReady(false);
        await dispatch(getOrganisation());
        const ec = createEntityConfig('bannerDigital');
        const list = await fetchItemListFromConfig(ec);
        await dispatch(setBannerDigitalList(list));

        setIsReady(true);
    };

    useEffect(() => {
        handleFetch();
    }, []);

    if (!isReady) {
        return (
            <div style={{ height: '90vh' }}>
                <Loader visible />
            </div>
        );
    }

    return (
        <div className={style.question}>
            <div className={style.header}>
                <h1>Webseite/App</h1>
            </div>
            <legend>
                Zusätzlich zum Branding Ihres Standmobiliars benötigen wir
                Ihr(e) Logo(s) für den „Logoteppich“ auf der IdeenExpo-Webseite
                und die IdeenExpo-App. Bitte laden Sie hier Grafik-Dateien in
                einer geeigneten Auflösung hoch und vergeben für jede Datei eine
                kurze Beschreibung.
                <br />
                <br />
                <b>Vorgaben für Web-Logo:</b>
                <br />
                Bevorzugte Dateiformate sind jpg, gif, tiff, png. Die Auflösung
                des Logos sollte mind. 72 dpi bzw. eine Breite von mindestens
                250 px haben.
            </legend>
            <div className={sx.bannerActions} style={{ marginTop: '60px' }}>
                <Link
                    className={sx.action}
                    to={'/organisation/banner-digital/edit'}
                    onClick={() => dispatch(destroy('banner'))}
                >
                    <div className={sx.label}>Logo hinzufügen</div>
                    <FontAwesomeIcon icon={faPlus} size='1x' />
                </Link>
            </div>
            <table className={sx.bannerTable}>
                <thead>
                    <tr>
                        <th>Beschreibung</th>
                        <th>Datei</th>
                        <th style={{ width: 0 }}>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map?.((banner) => (
                        <tr
                            key={banner.ID}
                            onClick={() => {
                                history.push(
                                    `/organisation/banner-digital/edit?id=${banner.ID}`,
                                );
                            }}
                        >
                            <td>{banner.beschreibung}</td>
                            <td>{banner.bannerDigitalDatei}</td>
                            <td>
                                <div className={sx.actions}>
                                    {banner.kzFreigabeAussteller === 1 ? (
                                        <div className={classNames(sx.badge)}>
                                            Freigegeben
                                        </div>
                                    ) : (
                                        <div
                                            className={classNames(
                                                sx.action,
                                                sx.button,
                                            )}
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                setCurrentlyApprovingIds(
                                                    (_) => ({
                                                        ..._,
                                                        [banner.ID]: true,
                                                    }),
                                                );
                                                await approveBannerDigital(
                                                    banner.ID,
                                                );
                                                await handleFetch();
                                                setCurrentlyApprovingIds(
                                                    (_) => ({
                                                        ..._,
                                                        [banner.ID]: false,
                                                    }),
                                                );
                                            }}
                                        >
                                            {currentlyApprovingIds[
                                                banner.ID
                                            ] === true ? (
                                                <Loader visible />
                                            ) : (
                                                'Freigeben'
                                            )}
                                        </div>
                                    )}
                                    {banner.kzFreigabeAussteller === 0 && (
                                        <div
                                            className={classNames(
                                                sx.action,
                                                sx.button,
                                                sx.transparent,
                                            )}
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                await deleteBannerDigital(
                                                    banner.ID,
                                                );
                                                handleFetch();
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                size='1x'
                                            />
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Stepbuttons
                back='/organisation/banner-print'
                labelBack='Hallenbanner'
            />
        </div>
    );
};

export const BannerDigital = compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'organisationBanner',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(BannerDigitalList),
);
