import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { destroy } from 'redux-form';

import { getEntity } from '../../../../actions/exhibitor/exhibitor-actions.js';
import { fetchListAsEntity } from '../../../../actions/exhibitor/fetcher/fetcher.js';
import Loader from '../../../common/loader/index.jsx';
import { ERROR, ERROR_MSG_BACKEND_FETCH, showAlert } from '../../alerts';
import {
    determineAuthForRoles,
    getForwardActionForRoles,
} from '../../functions';
import style from '../../index.module.css';
import StepButtons from '../../stepbuttons';
import {
    isInfoDesk,
    isInfoDeskOrPresentationModule,
    isInfoDeskWithJobInfo,
    isPresentationModule,
    isSteleOrBoard,
} from '../featureTypeFilter';

export const castArray = (obj) => (Array.isArray(obj) ? obj : []);

export const Furnishings = (props) => {
    const [isReady, setIsReady] = useState(false);

    const handleFetchBooths = async () => {
        try {
            props.dispatch(await fetchListAsEntity('booth'));
            await props.dispatch(getEntity('themeWorld'));
        } catch (e) {
            showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
            console.error('error checking code, promise rejected:', e);
        }
    };

    const handleFetchBoothFeatures = async () => {
        try {
            await Promise.all(
                castArray(props.booth).map(async (booth) => {
                    if (booth.ID <= 0) {
                        return;
                    }
                    const filterCriteria = {
                        idStand: booth.ID,
                    };

                    return props.dispatch(
                        await fetchListAsEntity('boothFeature', filterCriteria),
                    );
                }),
            );
        } catch (e) {
            showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
            console.error('error checking code, promise rejected:', e);
        }
    };

    const handleFetchPresentationmodulesAndInfodesks = async () => {
        try {
            await Promise.all(
                castArray(props.boothFeatures).map(async (boothFeature) => {
                    if (isPresentationModule(boothFeature)) {
                        if (boothFeature.idPraesentationModul <= 0) {
                            return;
                        }
                        const filterCriteria = {
                            ID: boothFeature.idPraesentationModul,
                        };
                        return props.dispatch(
                            await fetchListAsEntity(
                                'presentationmodule',
                                filterCriteria,
                            ),
                        );
                    }

                    if (
                        isInfoDesk(boothFeature) ||
                        isInfoDeskWithJobInfo(boothFeature)
                    ) {
                        if (boothFeature.idInfotresen <= 0) {
                            return;
                        }

                        const filterCriteria = {
                            ID: boothFeature.idInfotresen,
                        };
                        return props.dispatch(
                            await fetchListAsEntity('infodesk', filterCriteria),
                        );
                    }
                }),
            );
        } catch (e) {
            showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
            console.error('error checking code, promise rejected:', e);
        } finally {
            setIsReady(true);
        }
    };

    useEffect(() => {
        handleFetchBooths();
    }, []);

    useEffect(() => {
        if (props.booth?.length > 0) {
            handleFetchBoothFeatures();
        }
    }, [props.booth]);

    useEffect(() => {
        if (props.boothFeatures?.length > 0) {
            handleFetchPresentationmodulesAndInfodesks();
        }
    }, [props.boothFeatures]);

    const forwardAction = getForwardActionForRoles('veranstaltungen', props);

    if (!isReady) {
        return (
            <div style={{ height: '90vh' }}>
                <Loader visible />
            </div>
        );
    }

    return (
        <div className={style.question}>
            <div className={style.header}>
                <h1>Mobiliar</h1>
                <p>
                    Hier sehen Sie eine Übersicht des mit Ihrer/Ihrem
                    AusstellerbetreuerIn abgesprochenen Mobiliars. Das Mobiliar
                    muss bis zum <strong>20. Februar 2024</strong> definiert
                    sein.
                </p>
                <p>
                    Im Rahmen der weiteren Standaufplanung können sich
                    Änderungen ergeben. Diese werden dann nicht mehr abgebildet.
                </p>
                <p>
                    An dieser Stelle hinterlegen Sie bitte die Logoinformationen
                    für Präsentationsmodule (großer Tresen) und Infotresen
                    (kleiner Tresen).
                </p>
            </div>
            {castArray(props.booth).map((booth) => {
                const themeWorldOfBooth = props.themeWorld.find(
                    (item) => item.ID === booth.idThemenWelt,
                );
                return (
                    <div key={booth.ID} className='exhibitorTableWrap'>
                        <span className='exhibitorTableHeader'>
                            {booth.bezeichnung}
                            {themeWorldOfBooth
                                ? ` (${
                                      themeWorldOfBooth.themenWeltBezeichnung ||
                                      'Bezeichnung fehlt!'
                                  })`
                                : ' (Kein Themenbereich zugewiesen)'}
                        </span>
                        {/* <Link
                                className='exhibitorTableButton small-button'
                                to={{
                                    pathname: `/booths/furnishings/new/${booth.ID}`,
                                    state: { booth_id: booth.ID },
                                }}
                                onClick={() =>
                                    props.dispatch(
                                        destroy('furnishingsnew'),
                                    )
                                }
                            >
                                Neues Mobiliar
                            </Link> */}
                        <div className='schoolTableWrap'>
                            <ul className='schoolTable furnishings-grid nohover'>
                                <div className='header'>Bezeichnung</div>
                                {Array.isArray(props.boothFeatures) &&
                                    props.boothFeatures.length > 0 &&
                                    props.boothFeatures
                                        .filter(
                                            (boothFeature) =>
                                                boothFeature.idStand ===
                                                booth.ID,
                                        )
                                        .map((bf) => (
                                            <BoothFeature
                                                key={bf.ID}
                                                booth={booth}
                                                boothFeature={bf}
                                                dispatch={props.dispatch}
                                                infodesk={props.infodesk}
                                                presentationmodule={
                                                    props.presentationmodule
                                                }
                                            />
                                        ))}
                            </ul>
                        </div>
                    </div>
                );
            })}
            <StepButtons
                back={'/booths/steles'}
                to={forwardAction.forwardAction}
                labelBack={'Stelen'}
                labelForward={forwardAction.forwardText}
            />
        </div>
    );
};

const mapFeatureType = (feature) => {
    if (isInfoDesk(feature)) {
        return 'infodesk';
    } else if (isInfoDeskWithJobInfo(feature)) {
        return 'infodeskwithjobinfo';
    } else if (isPresentationModule(feature)) {
        return 'presentationmodule';
    }
    return '';
};

export const BoothFeature = ({
    booth,
    boothFeature,
    dispatch,
    infodesk,
    presentationmodule,
}) => {
    const type = mapFeatureType(boothFeature);
    const handle =
        isInfoDesk(boothFeature) || isInfoDeskWithJobInfo(boothFeature)
            ? boothFeature.idInfotresen
            : boothFeature.idPraesentationModul;

    const target = new URL(window.location.origin);
    target.searchParams.set('boothId', booth.ID);
    target.searchParams.set('type', type);
    target.searchParams.set('boothFeatureId', boothFeature.ID);
    target.searchParams.set('handle', handle);
    target.pathname = '/booths/furnishings/edit';

    return (
        <ul>
            {/* Only Präsentationsmodule and Infotresen can be edited */}
            {isInfoDeskOrPresentationModule(boothFeature) ? (
                <li className='schoolTable furnishings-grid nohover'>
                    <label>
                        <Link
                            to={{
                                pathname: target.pathname,
                                search: target.search,
                            }}
                            onClick={() => dispatch(destroy('furnishingsedit'))}
                        >
                            <span>
                                {boothFeature.stueckZahl
                                    ? `${boothFeature.stueckZahl}*`
                                    : null}{' '}
                                {boothFeature.bezeichnung}
                            </span>
                            <br />
                        </Link>
                        {/* TODO: CSS verbessern*/}
                        {Array.isArray(infodesk) &&
                            infodesk
                                .filter(
                                    (infodesk) =>
                                        infodesk.ID ===
                                        boothFeature.idInfotresen,
                                )
                                .map((infodesk) => (
                                    <Infodesk
                                        key={infodesk.ID}
                                        infodesk={infodesk}
                                        booth={booth}
                                    />
                                ))}
                        {Array.isArray(presentationmodule) &&
                            presentationmodule
                                .filter(
                                    (presentationmodule) =>
                                        presentationmodule.ID ===
                                        boothFeature.idPraesentationModul,
                                )
                                .map((module) => (
                                    <PresentationModule
                                        key={module.ID}
                                        module={module}
                                        booth={booth}
                                    />
                                ))}
                    </label>
                </li>
            ) : // features / Ausstattung that is neither presentationmodule nor infodesk ...
            isSteleOrBoard(boothFeature) ? null : (
                <li className='schoolTable furnishings-grid nohover'>
                    <label>
                        {boothFeature.stueckZahl
                            ? `${boothFeature.stueckZahl}*`
                            : null}{' '}
                        {boothFeature.bezeichnung}
                    </label>
                </li>
            )}
        </ul>
    );
};

const buildImageUrl = (data) => `data:image/png;base64,${data}`;

const PresentationModule = ({ module, booth }) => {
    if (booth.kzStandardLogos === 1) {
        return (
            <>
                <br />
                Sie haben ein Standardlogo für diesen Stand angegeben.
            </>
        );
    }

    const logoIndices = [1, 2, 3, 4];

    // TODO Dieser Block kann wieder rein, sobald wir in der Listenansicht auch die Dateinamen für Logos erhalten (ee8d09a6-8c79-4b54-9b79-0176ff45356a)
    // const hasLogo = logoIndices.some((i) => Boolean(module[`logo${i}`]));
    // if (!hasLogo) {
    //     return (
    //         <>
    //             <br />
    //             <span> Es wurden noch keine Logos hochgeladen.</span>
    //         </>
    //     );
    // }

    return (
        <>
            {logoIndices.map(
                (i) =>
                    module[`logo${i}`] && (
                        <div key={i}>
                            <a
                                className={'logoInTable'}
                                href={buildImageUrl(module[`data4Logo${i}`])}
                                download={module[`logo${i}`]}
                            >
                                Logo {i} für Präsentationsmodule (
                                {module[`logo${i}`]})
                            </a>
                        </div>
                    ),
            )}
        </>
    );
};

const Infodesk = ({ infodesk, booth }) => {
    if (booth.kzStandardLogos === 1) {
        return (
            <>
                <br />
                Sie haben ein Standardlogo für diesen Stand angegeben.
            </>
        );
    }

    const logoIndices = [1, 2, 3, 4];

    // TODO Dieser Block kann wieder rein, sobald wir in der Listenansicht auch die Dateinamen für Logos erhalten (ee8d09a6-8c79-4b54-9b79-0176ff45356a)
    // const hasLogo = logoIndices.some((i) => Boolean(infodesk[`logo${i}`]));
    // if (!hasLogo) {
    //     return (
    //         <>
    //             <br />
    //             <span> Es wurden noch keine Logos hochgeladen.</span>
    //         </>
    //     );
    // }

    return (
        <>
            {logoIndices.map(
                (i) =>
                    infodesk[`logo${i}`] && (
                        <div key={i}>
                            <a
                                className={'logoInTable'}
                                href={buildImageUrl(infodesk[`data4Logo${i}`])}
                                download={infodesk[`logo${i}`]}
                            >
                                Logo {i} für Infotresen ({infodesk[`logo${i}`]})
                            </a>
                        </div>
                    ),
            )}
        </>
    );
};

const mapStateToProps = (state, _prop) => {
    // Ermittlung der Rollenberechtigungen des angemeldeten Users
    const userAuth = determineAuthForRoles(state);

    return {
        booth: state.booth || [],
        themeWorld: state.themeWorld || [],
        boothFeatures: state.boothFeatures || [],
        booth2feature: state.booth2feature || [],
        feature: state.feature || [],
        infodesk: state.infodesk || [],
        presentationmodule: state.presentationmodule || [],
        hasBoothAuthorization: userAuth.hasBoothAuthorization,
        hasWorkshopAuthorization: userAuth.hasWorkshopAuthorization,
        hasLiveprogramAuthorization: userAuth.hasLiveprogramAuthorization,
        hasClubzukunftAuthorization: userAuth.hasClubzukunftAuthorization,
        hasOrganisationAuthorization: userAuth.hasOrganisationAuthorization,
        hasCarreerStageAuthorization: userAuth.hasCarreerStageAuthorization,
    };
};

export default connect(mapStateToProps, null)(Furnishings);
