import React, { Component } from 'react';
import style from '../index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default class FileInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            valid: true,
            name: this.props.name,
            link: this.props.link,
            reset: this.props.reset || 0,
        };

        this.fileInput = [];
    }

    handleInput(event) {
        let e = event;
        if (e?.target) {
            if (e.target.name === 'delete') {
                this.setState({
                    name: '',
                    link: '',
                });

                // BT 2022-02-20: Versuch, die Logo-Löschprobleme mit globaler Variable zu umgehen
                // window.SteleLogosToDelete.set(this.props.i, true);
            } else {
                e = {
                    ...e,
                    nameField: this.props.nameField,
                    imageField: this.props.imageField,
                };
            }
        }
        this.props.handleInput(e);
    }

    resetForm() {
        this.setState({
            name: this.props.name,
            link: this.props.link,
            reset: this.props.reset,
        });
        this.fileInput.forEach((fInput) => {
            if (fInput) {
                fInput.value = '';
            }
        });
    }

    render() {
        if (this.props.reset && this.props.reset !== this.state.reset) {
            this.resetForm();
        }
        const i = this.props.i;
        const nameInput = `datasheet-${this.props.specificLogoGroupNamePrefix}${i}`;
        const labelInput = nameInput;
        const idInput = nameInput;
        return (
            <div className={style.image__container}>
                <label htmlFor={labelInput}>
                    <span>{this.props.label}</span>
                </label>
                {this.state.name ? (
                    <div>
                        Bisherige Datei:{' '}
                        <a
                            className={style.image}
                            href={this.state.link}
                            download={this.state.name}
                        >
                            {this.state.name}
                        </a>
                        <a
                            className={style.delete}
                            onClick={(e) => {
                                e.preventDefault();
                                if (
                                    window.confirm(
                                        `Wollen Sie den Eintrag "${this.state.name}" wirklich löschen?`,
                                    )
                                ) {
                                    this.handleInput({
                                        target: {
                                            name: 'delete',
                                            id: i,
                                            nameField: this.props.nameField,
                                            imageField: this.props.imageField,
                                        },
                                    });
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} size='1x' />
                        </a>
                    </div>
                ) : (
                    <div>Bislang keine Datei hochgeladen</div>
                )}
                <input
                    type='file'
                    name={nameInput}
                    id={idInput}
                    onChange={(e) => this.handleInput(e)}
                    ref={(ref) => this.fileInput[i] === ref}
                />
            </div>
        );
    }
}

FileInput.defaultProps = {
    name: '',
    link: '',
    label: '',
    i: 0,
    imageField: '',
    nameField: '',
    specificLogoGroupNamePrefix: '',
    handleInput: function (_e) {},
};
