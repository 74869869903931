import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';
import 'react-tabs/style/react-tabs.css';
import style from '../../../../exhibitor/index.module.css';
import ExhibitNewBasis from './basis';
import ExhibitNewDescription from './description';
import ExhibitNewSpecifications from './specifications';
import { handleLogoInput } from '../../../logos/logos.js';
import {
    getEntity,
    getCustomLst,
} from '../../../../../actions/exhibitor/exhibitor-actions.js';
import { showAlert, ERROR, ERROR_MSG_BACKEND_FETCH } from '../../../alerts';

class ExhibitsTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            selectedIndex: 0,
            values: { files: {} }, // Files
        };
    }

    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                if (this.props.boothExists) {
                    return this.props
                        .dispatch(getEntity('booth'))
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_FETCH }),
                        );
                }
            })
            .then(() => {
                if (this.props.exhibit) {
                    return this.props
                        .dispatch(getEntity('exhibit'))
                        .then(null, (_e) => Promise.reject());
                }
            })
            .then(() => {
                if (this.props.exponatzg) {
                    return this.props
                        .dispatch(getCustomLst('targetgroup'))
                        .then(null, (_e) => Promise.reject());
                }
            })
            .then(() =>
                this.setState({
                    submitSuccess: true,
                }),
            )
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    handleSelect = (index) => {
        this.setState({ selectedIndex: index });
    };

    handleButtonClick = (number) => {
        this.setState({ selectedIndex: number });
    };

    handleInput(e) {
        handleLogoInput(e, this); //Files
    }

    render() {
        const found = this.props.found || {};
        return (
            <div className={style.question}>
                <div>
                    {this.props.handle ? (
                        <h1>Exponat {found.projekttitel} bearbeiten</h1>
                    ) : (
                        <h1>Neues Exponat erstellen</h1>
                    )}
                </div>
                <Tabs
                    selectedIndex={this.state.selectedIndex}
                    onSelect={this.handleSelect}
                    className={`${style.reactTabs}`}
                >
                    <TabList>
                        <Tab>
                            Basisdaten
                            <br />
                            Eintragungen können bis zum{' '}
                            {this.props.exhibit_deadline} vorgenommen werden.
                        </Tab>
                        <Tab>
                            Beschreibungen
                            <br />
                            Eintragungen können bis zum{' '}
                            {this.props.exhibit_deadline} vorgenommen werden.
                        </Tab>
                        <Tab>
                            Technische Daten
                            <br />
                            Eintragungen können bis zum{' '}
                            {this.props.exhibit_deadline} vorgenommen werden.
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <ExhibitNewBasis
                            handle={this.props.handle}
                            booth_id={this.props.booth_id}
                            toTab={this.handleButtonClick}
                            files={this.state.values.files}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ExhibitNewDescription
                            handle={this.props.handle}
                            booth_id={this.props.booth_id}
                            toTab={this.handleButtonClick.bind(this)}
                            files={this.state.values.files}
                            handleInput={this.handleInput.bind(this)}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ExhibitNewSpecifications
                            handle={this.props.handle}
                            booth_id={this.props.booth_id}
                            toTab={this.handleButtonClick.bind(this)}
                            files={this.state.values.files}
                        />
                    </TabPanel>
                </Tabs>
            </div>
        );
    }
}
const mapStateToProps = (state, _prop) => {
    const propsSearch = state.router?.location?.search || '??';
    const boothTemp = parseInt(
        propsSearch.substring(
            propsSearch.indexOf('?') + 1,
            propsSearch.lastIndexOf('?'),
        ),
    );
    const handleTemp = parseInt(
        propsSearch.substring(propsSearch.lastIndexOf('?') + 1),
    );

    const handle = state.router?.location?.state?.handle || handleTemp || '';

    return {
        handle: handle,
        booth_id: state.router?.location?.state?.booth_id,
        found:
            state.exhibit && Array.isArray(state.exhibit) && handle
                ? state.exhibit.find((x) => x.ID === handle)
                : {},
        booth: state.router?.location?.state?.booth || boothTemp || '',
        boothExists: state.booth && !Object.keys(state.booth).length,
        exhibit_deadline: state.deadlines?.exhibit_deadline,
        exhibit: state.exhibit && !Object.keys(state.exhibit).length,
        exponatzg: state.exponatzg && !Object.keys(state.exponatzg).length,
    };
};
export default connect(mapStateToProps, null)(ExhibitsTabs);
