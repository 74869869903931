import React from 'react';
import { connect } from 'react-redux';
import StepButtons from '../../common/stepbuttons';

const Disabled = (_props) => (
    <>
        <h1>
            <strong>Fehler</strong>
        </h1>
        <p>
            Die Anmeldung wurde für dieses Event deaktiviert. Aus diesem Grunde
            ist eine Anmeldung nicht möglich.
        </p>
        <StepButtons back='/' />
    </>
);
export default connect(
    (s) => ({
        invitationEditDeadline: s.invitation?.invitationEditDeadline || false,
    }),
    null,
)(Disabled);
