import React from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import logo from '../../../images/Ideenexpo_logo_ohne_Jahreszahl_HKS10_fh.jpg';

const Brand = (props) => {
    const id = 'brand--exhibitor';
    return (
        <div id={id}>
            <img alt='' src={logo} />
            {
                /* 2024-04-05 neue Anforderung: Einladungssystem soll keine IE-Projekt-Info anzeigen */
                process.env.REACT_APP_IDEENEXPO !== 'invitation' &&
                props.authenticationData.TpProjektLstItem ? (
                    <div className='info'>
                        {props.authenticationData.TpProjektLstItem[0].name}
                        <br />
                        {dayjs(
                            props.authenticationData.TpProjektLstItem[0].beginn,
                        ).format('DD')}
                        {`. - ${dayjs(
                            props.authenticationData.TpProjektLstItem[0].ende,
                        ).format('DD.MM.YYYY')}`}
                    </div>
                ) : (
                    <div />
                )
            }
        </div>
    );
};

export default connect((state) => state)(Brand);
