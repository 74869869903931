import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-tabs/style/react-tabs.css';
import style from '../../../../exhibitor/index.module.css';
import ShowStageEditContact from './contact';
import ShowStageEditBasis from './basis';
import { getEntity } from '../../../../../actions/exhibitor/exhibitor-actions.js';
import { showAlert, ERROR, ERROR_MSG_BACKEND_FETCH } from '../../../alerts';

class ShowStageTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            selectedIndex: 0,
        };
    }

    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                if (this.props.showstage) {
                    Promise.resolve();
                } else {
                    this.props.dispatch(getEntity('showstage'));
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    handleSelect = (index) => {
        this.setState({ selectedIndex: index });
    };

    handleButtonClick = (number) => {
        this.setState({ selectedIndex: number });
    };
    render() {
        return (
            <div className={style.question}>
                <div>
                    {this.props.handle ? (
                        <h1>
                            Beitrag "{this.props.found?.titelBeitrag}" für die
                            Showbühne bearbeiten
                        </h1>
                    ) : (
                        <h1>Neuen Beitrag für die Showbühne erstellen</h1>
                    )}
                </div>
                {/* <Tabs selectedIndex={this.state.selectedIndex} onSelect={this.handleSelect} className={`${style.reactTabs}`}>
					<TabList> */}
                <h2>
                    Beitrag
                    <br />
                    Eintragungen können bis zum {this.props.stages_deadline}{' '}
                    vorgenommen werden.
                </h2>

                {/* </TabList>
					<TabPanel> */}
                <ShowStageEditBasis
                    handle={this.props.handle}
                    showstage={this.props.showstage}
                    toTab={this.handleButtonClick}
                />
                {/* </TabPanel>
					<TabPanel> */}
                <h2>
                    Ansprechpartner
                    <br />
                    Eintragungen können bis zum {this.props.stages_deadline}{' '}
                    vorgenommen werden.
                </h2>
                <ShowStageEditContact
                    handle={this.props.handle}
                    showstage={this.props.showstage}
                    toTab={this.handleButtonClick}
                />
                {/* </TabPanel>
				</Tabs> */}
            </div>
        );
    }
}
const mapStateToProps = (state, _prop) => {
    const handle =
        state.router?.location?.state?.handle ||
        parseInt((state.router?.location?.search || '?').substring(1)) ||
        '';

    return {
        handle: handle,
        found:
            state.showstage && Array.isArray(state.showstage)
                ? state.showstage.find((x) => x.ID === handle)
                : [],
        stages_deadline: state.deadlines?.stages_deadline,
        showstage: state.showstage && Object.keys(state.showstage).length,
    };
};
export default connect(mapStateToProps, null)(ShowStageTabs);
