import React, { Fragment } from 'react';

/**
 * Utility for replacing all '|' with HTML entity '&shy;' (soft hyphen) for auto line breaks in small spaces.
 * Usage as tagged template literal, e.g.:
 * @example const str = shy`Lo|rem ip|sum do|lor` // returns 'Lo&shy;rem ip&shy;sum do&shy;lor'
 * @param {(TemplateStringsArray | string)[]} strings All strings in the template literal
 */
export const shy = (...strings) =>
    strings
        .flat()
        .filter(Boolean)
        .flatMap((s) => s.split('|'))
        .map((s, i, arr) => (
            // rome-ignore lint/suspicious/noArrayIndexKey: Static indices
            <Fragment key={i}>
                {s}
                {i !== arr.length - 1 && <>&shy;</>}
            </Fragment>
        ));
