import React from 'react';
import style from '../index.module.css';
import StepButtons from '../stepbuttons';
import { connect } from 'react-redux';
import {
    getForwardActionForRoles,
    getBackwardActionForRoles,
    determineAuthForRoles,
} from '../functions';

const Organisation = (props) => {
    const forwardAction = getForwardActionForRoles('passes', props);
    const backwardAction = getBackwardActionForRoles('studyfields', props);

    return (
        <div className={style.question}>
            <h1>Organisation auf der {props.ie_name}</h1>
            <p>Hier finden Sie folgende Eingabemöglichkeiten:</p>
            <ul>
                <li>Ausstellerausweise</li>
                <li>Catering</li>
                <li>Lagerflächen</li>
                <li>
                    Logo(s) für Hallenbanner, Webseite und App (nur für
                    Sponsoren)
                </li>
            </ul>
            <p>
                Bitte geben Sie Ihre Daten zu den benötigten Ausstellerausweisen
                und zum Catering bis spätestens zum 19. April 2024 in das System
                ein. Die erforderliche Lagerfläche ist bis zum 9. Mai 2024 zu
                definieren.
            </p>
            <StepButtons
                back={backwardAction.backwardAction}
                to={forwardAction.forwardAction}
                labelBack={backwardAction.backwardText}
                labelForward={forwardAction.forwardText}
            />
        </div>
    );
};

const mapStateToProps = (state, _prop) => {
    // Ermittlung der Rollenberechtigungen des angemeldeten Users
    const userAuth = determineAuthForRoles(state);

    return {
        ie_name: (state.authenticationData?.TpProjektLstItem || [
            { name: 'IdeenExpo' },
        ])[0].name,
        hasBoothAuthorization: userAuth.hasBoothAuthorization,
        hasWorkshopAuthorization: userAuth.hasWorkshopAuthorization,
        hasLiveprogramAuthorization: userAuth.hasLiveprogramAuthorization,
        hasClubzukunftAuthorization: userAuth.hasClubzukunftAuthorization,
        hasOrganisationAuthorization: userAuth.hasOrganisationAuthorization,
        hasCarreerStageAuthorization: userAuth.hasCarreerStageAuthorization,
    };
};

export default connect(mapStateToProps, null)(Organisation);
