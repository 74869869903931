import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';
import disableForm from '../../exhibitor/disableForm.js';
import isWorkflowEditable from '../functions.js';

import StepButtons from '../../common/stepbuttons';
import style from '../../common/index.module.css';
import {
    MAIL_PATTERN,
    PHONE_PATTERN,
    ZIPCODE_PATTERN,
} from '../../../global_constants';
import Question from '../../common/question';

class Personals extends Question {
    constructor(props) {
        super(props, null, {
            back: '#',
            to: '#',
            lastname: '',
            firstname: '',
            email: '',
            phone: '',
            emailSchool: '',
            phoneSchool: '',
            valid: false,
        });
        this.salutation = React.createRef();
        this.lastname = React.createRef();
        this.firstname = React.createRef();
        this.email = React.createRef();
        this.phone = React.createRef();
        this.emailSchool = React.createRef();
        this.phoneSchool = React.createRef();

        this.school = {};
        if (
            this.props.schoolList &&
            this.props.schoolList.length > 0 &&
            this.props.form.school_selection &&
            this.props.form.school_selection.values
        ) {
            this.props.schoolList.map((school) => {
                if (
                    school.name ===
                    this.props.form.school_selection.values.school_selection
                ) {
                    this.school = school;
                }
            });
        }
    }
    componentDidMount() {
        if (
            this.school &&
            (!this.props.form.registration_personals ||
                !this.props.form.registration_personals.values ||
                !this.props.form.registration_personals.values.emailSchool ||
                !this.props.form.registration_personals.values.phoneSchool)
        ) {
            this.props.change('emailSchool', this.school.schoolEMail);
            this.props.change('phoneSchool', this.school.schoolPhone);
        }

        if (this.props.editedAnmeldung.id > -1) {
            const anmeldung =
                this.props.groupRegistrationList[
                    this.props.editedAnmeldung.index
                ];

            this.props.change('lastname', anmeldung.name);
            this.props.change('firstname', anmeldung.vorname);
            this.props.change('email', anmeldung.emailPrivat);
            this.props.change('phone', anmeldung.telefonPrivat);
            this.props.change(
                'salutation',
                anmeldung.idAnrede === 1 ? 'Herr' : 'Frau',
            );
            this.props.change('emailSchool', anmeldung.EMail);
            this.props.change('phoneSchool', anmeldung.telefon);
            this.setState({ valid: true });

            // Bearbeitung nur unter bestimmten Bedingungen erlaubt
            if (!isWorkflowEditable(anmeldung)) {
                disableForm();
            }
        } else {
            super.componentDidMount();
        }
    }
    validate() {
        this.setState({
            valid:
                this.salutation.current.value &&
                this.lastname.current.value &&
                this.firstname.current.value &&
                this.validateEmail(this.email.current.value) &&
                this.validatePhone(this.phone.current.value) &&
                this.validateEmailSchool(this.emailSchool.current.value) &&
                this.validatePhoneSchool(this.phoneSchool.current.value),
        });
    }
    validateSalutation = (value) => {
        const valid = value !== '' ? value : undefined;
        this.setState({ validSalutation: !!valid });
        return valid;
    };
    validateLastname = (value) => {
        const valid = value !== '' ? value : undefined;
        this.setState({ validLastname: !!valid });
        return valid;
    };
    validateFirstname = (value) => {
        const valid = value !== '' ? value : undefined;
        this.setState({ validFirstname: !!valid });
        return valid;
    };
    validatePhone = (value) => {
        const valid = value && PHONE_PATTERN.test(value) ? value : undefined;
        this.setState({ validPhone: !!valid });
        return valid;
    };
    validatePhoneSchool = (value) => {
        const valid = value && PHONE_PATTERN.test(value) ? value : undefined;
        this.setState({ validPhoneSchool: !!valid });
        return valid;
    };
    validateEmail = (value) => {
        const valid = value && MAIL_PATTERN.test(value) ? value : undefined;
        this.setState({ validEmail: !!valid });
        return valid;
    };
    validateEmailSchool = (value) => {
        const valid = value && MAIL_PATTERN.test(value) ? value : undefined;
        this.setState({ validEmailSchool: !!valid });
        return valid;
    };
    validateZipcode = (value) => {
        const valid = value && ZIPCODE_PATTERN.test(value) ? value : undefined;
        this.setState({ validZipcode: !!valid });
        return valid;
    };
    handleInput() {
        this.setState({}, () => {
            this.validate();
        });
    }
    renderQuestion() {
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>
                        <b>Persönliche</b> Daten
                    </h1>
                    <p>
                        Vervollständigen Sie bitte nachfolgend Ihre persönlichen
                        Daten.
                    </p>
                </div>
                <fieldset>
                    <label htmlFor='salutation'>Anrede</label>
                    <Field
                        component='select'
                        name='salutation'
                        ref={this.salutation}
                        validate={this.validateSalutation}
                        className={classNames({
                            invalid: !this.state.validSalutation,
                        })}
                        onChange={(_e) => this.handleInput()}
                    >
                        <option value='' hidden>
                            Bitte wählen
                        </option>
                        <option value='Frau'>Frau</option>
                        <option value='Herr'>Herr</option>
                        <option value='KeineAngabe'>Keine Angabe</option>
                    </Field>
                    <label htmlFor='lastname'>Nachname</label>
                    <Field
                        component='input'
                        type='text'
                        name='lastname'
                        maxLength='40'
                        validate={this.validateLastname}
                        className={classNames({
                            invalid: !this.state.validLastname,
                        })}
                        ref={this.lastname}
                        onChange={(_e) => this.handleInput()}
                    />
                    <label htmlFor='firstname'>Vorname</label>
                    <Field
                        component='input'
                        type='text'
                        maxLength='100'
                        validate={this.validateFirstname}
                        className={classNames({
                            invalid: !this.state.validFirstname,
                        })}
                        name='firstname'
                        ref={this.firstname}
                        onChange={(_e) => this.handleInput()}
                    />
                    <label htmlFor='email'>
                        E-Mail (für die Zusendung der Anmeldebestätigung)
                    </label>
                    <Field
                        component='input'
                        type='email'
                        name='email'
                        maxLength='200'
                        validate={this.validateEmail}
                        className={classNames({
                            invalid: !this.state.validEmail,
                        })}
                        ref={this.email}
                        onChange={(_e) => this.handleInput()}
                    />
                    <label htmlFor='phone'>
                        Mobiltelefon (für die Erreichbarkeit während der
                        Veranstaltung)
                    </label>
                    <Field
                        component='input'
                        type='tel'
                        name='phone'
                        maxLength='30'
                        validate={this.validatePhone}
                        className={classNames({
                            invalid: !this.state.validPhone,
                        })}
                        ref={this.phone}
                        onChange={(_e) => this.handleInput()}
                    />
                    <label
                        style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                        htmlFor='emailSchool'
                    >
                        E-Mail der Schule (für eine Kopie der Mail an die
                        Schule)
                        <p>
                            <b>Hinweis: </b>Bitte tragen Sie eine E-Mail-Adresse
                            ein, die das Empfangen externer E-Mails erlaubt.
                            Intern genutzte Sekretariat-E-Mail-Adressen
                            verbieten z.T. die Zustellung schulfremder E-Mails
                            (Iserv-System o.ä.).
                        </p>
                    </label>
                    <Field
                        component='input'
                        type='email'
                        name='emailSchool'
                        maxLength='100'
                        validate={this.validateEmailSchool}
                        className={classNames({
                            invalid: !this.state.validEmailSchool,
                        })}
                        ref={this.emailSchool}
                        onChange={(_e) => this.handleInput()}
                    />
                    <label htmlFor='phoneSchool'>
                        Telefon der Schule (für eventuelle Rückfragen zur
                        Anmeldung)
                    </label>
                    <Field
                        component='input'
                        type='tel'
                        name='phoneSchool'
                        maxLength='200'
                        validate={this.validatePhoneSchool}
                        className={classNames({
                            invalid: !this.state.validPhoneSchool,
                        })}
                        ref={this.phoneSchool}
                        onChange={(_e) => this.handleInput()}
                    />
                </fieldset>
                <StepButtons
                    back='/school-selection'
                    to='/group'
                    disabled={!this.state.valid}
                />
            </div>
        );
    }
}

export default reduxForm({
    form: 'registration_personals',
    destroyOnUnmount: false,
})(
    connect(
        (s) => ({ formdata: s.form, ...s }), // Christian fragen, siehe seine Personals
        null,
    )(Personals),
);
