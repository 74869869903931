import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import FormButtons from '../../../formbuttons';
import style from '../../../../exhibitor/index.module.css';
import StepButtons from '../../../stepbuttons';
import {
    getEntity,
    getCustomLst,
} from '../../../../../actions/exhibitor/exhibitor-actions.js';
import {
    updateClubzukunftList,
    insertClubzukunftList,
} from '../../../../../actions/exhibitor/clubzukunft/index.js';
import {
    showAlert,
    SUCCESS,
    SUCCESS_MSG,
    ERROR,
    ERROR_MSG_BACKEND_SAVE,
    ERROR_MSG_BACKEND_FETCH,
    ERROR_UNAUTHORIZED,
} from '../../../alerts';

class DualStudy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            submitSuccess: false,
        };
    }
    handleInput(_e) {
        this.setState({ valid: true });
    }
    resetForm() {
        this.setState({
            submitSuccess: false,
        });
    }
    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                if (
                    this.props.dualstudyDef &&
                    this.props.dualstudyDef.length > 0
                ) {
                    return Promise.resolve();
                } else {
                    return this.props
                        .dispatch(getCustomLst('dualstudydef'))
                        .then(null, (_e) => Promise.reject());
                }
            })
            .then(() => {
                if (this.props.dualstudy && this.props.dualstudy.length > 0) {
                    return Promise.resolve();
                } else {
                    return this.props
                        .dispatch(getEntity('dualstudy'))
                        .then(null, (_e) => Promise.reject());
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }
    submit(values) {
        Promise.all(
            this.props.dualstudyDef.map((def) => {
                return new Promise((resolve, reject) => {
                    const valuesMod = {
                        kzAngeboten: values[def.dualstudyName],
                    };
                    if (
                        values[def.dualstudyName] !==
                        this.props.initialValues[def.dualstudyName]
                    ) {
                        if (def.dualstudyIndex > -1) {
                            // if updating an existing Duales Studium
                            this.props
                                .dispatch(
                                    updateClubzukunftList(
                                        this.props.dualstudy[
                                            def.dualstudyIndex
                                        ],
                                        valuesMod,
                                        'dualstudy',
                                    ),
                                )
                                .then((result) => {
                                    resolve(result);
                                })
                                .catch((e) => {
                                    reject({ _error: e });
                                });
                        } else {
                            // if creating a new Duales Studium
                            this.props
                                .dispatch(
                                    insertClubzukunftList(
                                        valuesMod,
                                        def.ID,
                                        'dualstudy',
                                    ),
                                )
                                .then((result) => {
                                    resolve(result);
                                })
                                .catch((e) => {
                                    reject({ _error: e });
                                });
                        }
                    } else {
                        resolve();
                    }
                });
            }),
        )
            .then(() => {
                return this.props
                    .dispatch(getEntity('dualstudy'))
                    .then(null, (_e) =>
                        Promise.reject({ _error: ERROR_MSG_BACKEND_FETCH }),
                    );
            })
            .then(() =>
                this.setState({
                    submitSuccess: true,
                }),
            )
            .then(() => showAlert(SUCCESS, SUCCESS_MSG))
            .catch((e) => {
                showAlert(ERROR, ERROR_MSG_BACKEND_SAVE);
                if (e._error === 'Unauthorized') {
                    showAlert(ERROR, ERROR_UNAUTHORIZED);
                }
                this.setState({ submitSuccess: false });
                this.props.dispatch(stopSubmit('dualstudy', e));
            });
    }
    render() {
        const { handleSubmit } = this.props;
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>Duales / Triales Studium</h1>
                    <p>
                        Bitte klicken Sie hier die Fachrichtungen an, für die
                        Sie ein Duales / Triales Studium anbieten. Bitte
                        berücksichtigen Sie vorrangig MINT-Studiengänge.
                        Aufgrund des vielfältigen Studienangeboten möchten wir
                        uns auf allgemeine Fachrichtungen beschränken.
                    </p>
                </div>

                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <fieldset>
                        <div className='exhibitorTableWrap'>
                            <span className='exhibitorTableHeader' />
                            <div className='schoolTableWrap'>
                                <ul className='schoolTable study-grid'>
                                    <div className='header'>Bezeichnung</div>
                                    <div className='header'>
                                        wird an&shy;ge&shy;bo&shy;ten
                                    </div>
                                    <ul>
                                        {Array.isArray(
                                            this.props.dualstudyDef,
                                        ) && this.props.dualstudyDef.length > 0
                                            ? this.props.dualstudyDef.map(
                                                  (def, index) => (
                                                      <li
                                                          key={index}
                                                          className='schoolTable study-grid'
                                                      >
                                                          <label>
                                                              {def.description}
                                                          </label>
                                                          <label>
                                                              <Field
                                                                  component='input'
                                                                  type='checkbox'
                                                                  id={`${def.dualstudyName}`}
                                                                  name={`${def.dualstudyName}`}
                                                              />
                                                              <span />
                                                          </label>
                                                      </li>
                                                  ),
                                              )
                                            : null}
                                    </ul>
                                </ul>
                            </div>
                        </div>
                        <FormButtons
                            hideBtn={true}
                            handleSubmit={handleSubmit}
                            submit={this.submit.bind(this)}
                        />
                        <legend />
                    </fieldset>
                </form>
                <StepButtons
                    back={'/clubzukunft/career-market/training'}
                    to={'/clubzukunft/career-market/studyfields'}
                    labelBack={'Ausbildungsberufe'}
                    labelForward={'Studienrichtung'}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, _props) => {
    let initialValues = {};

    let dualstudyDef =
        state.dualstudydef && Array.isArray(state.dualstudydef)
            ? state.dualstudydef.map((def) => ({
                  ID: def.ID,
                  description: def.description,
                  dualstudyName: def.description
                      .replace(/[- /()\\ßäöüÄÖÜ]/g, '_')
                      .toLowerCase(),
                  dualstudyIndex: -1,
              }))
            : [];

    const dualstudy = state.dualstudy;

    if (
        Array.isArray(dualstudy) &&
        dualstudy.length > 0 &&
        Array.isArray(dualstudyDef) &&
        dualstudyDef.length > 0
    ) {
        dualstudyDef = dualstudyDef.map((def) => {
            let result = def;
            dualstudy.forEach((study, index) => {
                if (def.ID === study.idDualesStudium) {
                    initialValues = {
                        ...initialValues,
                        [def.dualstudyName]: study.kzAngeboten || 0,
                    };
                    result = {
                        ID: def.ID,
                        description: def.description,
                        dualstudyName: def.dualstudyName,
                        dualstudyIndex: index,
                    };
                }
            });
            return result;
        });
    }

    return {
        initialValues: initialValues,
        dualstudyDef: dualstudyDef || [],
        dualstudy: state.dualstudy || [],
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, null, null, {
        areStatesEqual: (next, prev) => {
            return (
                prev.dualstudydef === next.dualstudydef &&
                prev.dualstudy === next.dualstudy
            );
        },
    }),
)(
    reduxForm({
        form: 'dualstudy',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(DualStudy),
);
