import React from 'react';
import './state-indicator.css';
import classNames from 'classnames';

export const StateIndicator = ({ state, label }) => {
    const formatState = (s) => {
        return s ? 'erteilt' : 'offen';
    };

    return (
        <div
            className={classNames({
                indicator: true,
                red: !state,
                green: state,
            })}
        >
            {state ? iconYes : iconNo}
            {label || formatState(state)}
        </div>
    );
};

const iconYes = (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        className='icon icon-tabler icon-tabler-circle-check-filled'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        strokeWidth='2'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
    >
        <title>state-indicator</title>
        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
        <path
            d='M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z'
            strokeWidth='0'
            fill='currentColor'
        />
    </svg>
);

const iconNo = (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        className='icon icon-tabler icon-tabler-circle-x-filled'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        strokeWidth='2'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
    >
        <title>state-indicator</title>
        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
        <path
            d='M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z'
            strokeWidth='0'
            fill='currentColor'
        />
    </svg>
);
