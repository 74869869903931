import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
    updateStage,
    insertShowstage,
} from '../../../../../../actions/exhibitor/showstage';
import style from '../../../../index.module.css';
import { store } from '../../../../../../store.js';
import disableForm from '../../../../disableForm.js';
import {
    showAlert,
    SUCCESS,
    SUCCESS_MSG,
    ERROR,
    validateForm,
    ERROR_MSG_BACKEND_SAVE,
} from '../../../../alerts';

class ShowStageEditBasis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            submitSuccess: false,
        };
    }

    handleInput(_e) {
        this.setState({ valid: true });
    }

    resetForm() {
        this.setState((_state, _props) => ({
            submitSuccess: false,
        }));
    }

    componentDidMount() {
        if (
            this.props.initialValues['status'] === 'freigegeben' ||
            this.props.noLiveProgram
        ) {
            disableForm();
        }
    }

    submit(values) {
        validateForm(
            this,
            values,
            ['status', 'beitrag_titel'],
            this.props.stages_deadline_within,
            this.props.noLiveProgram,
        );
        const storeState = store.getState().form;
        const showstageeditcontact =
            storeState.showstageeditcontact?.values || {};
        Promise.resolve()
            .then(() => {
                if (this.props.handle) {
                    return this.props
                        .dispatch(
                            updateStage(this.props.found, {
                                ...showstageeditcontact,
                                ...values,
                            }),
                        )
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                } else {
                    // if creating a new showstage
                    return this.props
                        .dispatch(
                            insertShowstage({
                                ...showstageeditcontact,
                                ...values,
                            }),
                        )
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                }
            })
            .then((json) => {
                if (!this.props.handle) {
                    this.props.history.push(
                        `/live-program/show-stage/edit?${json.showstage.ID}`,
                    );
                }
            })
            .then(() =>
                this.setState({
                    submitSuccess: true,
                }),
            )
            .then(() => showAlert(SUCCESS, SUCCESS_MSG))
            .then(() => {
                if (
                    this.props.initialValues['status'] === 'freigegeben' ||
                    this.props.noLiveProgram
                ) {
                    disableForm();
                }
            })
            // .then(() => {
            // // gewünscht war nach dem Speichern zur Übersichtsseite zurückzukommen
            // this.props.dispatch(push("/live-program/show-stage"))
            // })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_SAVE);
                this.setState({ submitSuccess: false });
                this.props.dispatch(stopSubmit('showstageeditbasis', e));
            });
    }

    render() {
        const { handleSubmit } = this.props;
        const maxLengthShowStageTitelBeitrag = 200;
        const maxLengthShowStageArtBeitrag = 200;
        const maxLengthShowStageBeschreibung = 800;
        const maxLengthShowStageWuenscheErgaenzungen = 800;

        return (
            <div className={style.header}>
                {this.props.initialValues.status === 'freigegeben' ? (
                    <div>Sie haben diese Daten bereits freigegeben</div>
                ) : null}
                {!this.props.stages_deadline_within ? (
                    <div>
                        Sie können die Daten nur innerhalb des
                        Bearbeitungszeitraums bearbeiten
                    </div>
                ) : null}
                {this.props.noLiveProgram ? (
                    <div>Sie haben das LiveProgramm deaktiviert</div>
                ) : null}
                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <fieldset>
                        <legend />
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='showstage_id'
                            id='showstage_id'
                            hidden
                            disabled
                            value={this.props.handle || ''}
                            ref={this.input}
                        />
                        <label htmlFor='beitrag_titel'>
                            <span className={style.bold}>
                                Titel des Beitrags (*)
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='beitrag_titel'
                            id='beitrag_titel'
                            ref={this.input}
                            maxLength={maxLengthShowStageTitelBeitrag}
                        />
                        <label htmlFor='beitrag_art'>
                            <span className={style.bold}>
                                Art des Beitrags (Quiz, Talk, Musik, …)
                            </span>
                        </label>
                        <Field
                            component='input'
                            placeholder=''
                            type='text'
                            name='beitrag_art'
                            id='beitrag_art'
                            ref={this.input}
                            maxLength={maxLengthShowStageArtBeitrag}
                        />
                        <label htmlFor='beitrag_beschreibung'>
                            <span className={style.bold}>
                                Erste Beschreibung Ihres Bühnenprogramms
                            </span>
                        </label>
                        <Field
                            component='textarea'
                            placeholder=''
                            type='textarea'
                            name='beitrag_beschreibung'
                            rows='8'
                            maxLength={maxLengthShowStageBeschreibung}
                            style={{
                                resize: 'none',
                            }}
                            id='beitrag_beschreibung'
                            ref={this.input}
                        />
                        <label htmlFor='beitrag_wuensche'>
                            <span className={style.bold}>
                                Wünsche/Ergänzungen (zur internen Verwendung)
                            </span>
                        </label>
                        <Field
                            component='textarea'
                            placeholder=''
                            type='textarea'
                            name='beitrag_wuensche'
                            rows='8'
                            maxLength={maxLengthShowStageWuenscheErgaenzungen}
                            style={{
                                resize: 'none',
                            }}
                            id='beitrag_wuensche'
                            ref={this.input}
                        />
                        {/* <FormButtons showCancel={true} overviewLink='/live-program/show-stage' hideBtn={true} handleSubmit={handleSubmit} submit={(this.submit.bind(this))} />
							<legend>(*) = Pflichtfeld</legend> */}
                    </fieldset>
                </form>
                {/* <StepButtons back={'/live-program/show-stage'} toTab={() => this.props.toTab(1)} disabled={!this.state.valid} labelBack={'Liste der ShowBühne Beiträge'} labelForward={'Ansprechpartner'} /> */}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const found =
        state.showstage && Array.isArray(state.showstage)
            ? state.showstage.find((x) => x.ID === props.handle)
            : [];
    const noLiveProgram =
        state.contract &&
        Array.isArray(state.contract) &&
        state.contract.length > 0 &&
        state.contract[0].liveProgrammDeaktiviert === 1;
    let initialValues = { status: '' };
    if (found && found.ID === props.handle) {
        initialValues = {
            showstage_id: props.handle || '',
            beitrag_titel: found.titelBeitrag || '',
            beitrag_art: found.artBeitrag || '',
            beitrag_beschreibung: found.beschreibung || '',
            beitrag_wuensche: found.wuenscheErgaenzungen || '',
            status:
                found.kzFreigabeAussteller === 1
                    ? 'freigegeben'
                    : found.kzFreigabeAussteller === 0
                    ? 'inbearbeitung'
                    : '',
        };
    }
    return {
        initialValues: initialValues,
        hasLiveProgram: !noLiveProgram,
        noLiveProgram,
        stages_deadline_within: state.deadlines?.stages_deadline_within,
        found,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'showstageeditbasis',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(ShowStageEditBasis),
);
