import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import IBAN from 'iban';
import classNames from 'classnames';
import disableForm from '../../exhibitor/disableForm.js';
import isWorkflowEditable from '../functions.js';
import StepButtons from '../../common/stepbuttons';
import style from '../../common/index.module.css';
import Question from '../../common/question';
import { ENV } from '../../../global_constants';
import {
    TEILNEHMER__ZUSCHUSS__4EUR,
    TEILNEHMER__ZUSCHUSS__GVH,
    TEILNEHMER__ZUSCHUSS__KEIN,
} from '../../../backend-id-constants';

class Allowance extends Question {
    constructor(props) {
        super(props, null, {
            valid: false,
        });
        this.radioYes = React.createRef();
        this.radioNo = React.createRef();
        this.accountHolder = React.createRef();
        this.Iban = React.createRef();
        this.paymentReference = React.createRef();

        const school = props.schoolList.find(
            (school) =>
                school.ID.toString() ===
                props.formdata.school_selection.values.school_selection,
        );
        this.isOepnv = school.kzOepnv === 1;

        // Wird benötigt, um auch im read only modus die vorherige Auswahl zum Zuschuss korrekt zu setzen
        this.idZuschuss = -1;
    }
    validate() {
        const allowanceValues = this.props.formdata.allowance?.values;
        if (allowanceValues?.allowance == null) {
            return false;
        }
        if (allowanceValues?.allowance === 'yes') {
            if (this.isOepnv) {
                this.idZuschuss = TEILNEHMER__ZUSCHUSS__GVH;
                return true;
            }
            this.idZuschuss = TEILNEHMER__ZUSCHUSS__4EUR;
            return (
                Boolean(allowanceValues?.accountHolder) &&
                IBAN.isValid(allowanceValues?.IBAN)
            );
        }
        this.idZuschuss = TEILNEHMER__ZUSCHUSS__KEIN;
        return true;
    }
    componentDidMount() {
        if (this.props.editedAnmeldung.id > -1) {
            const anmeldung =
                this.props.groupRegistrationList[
                    this.props.editedAnmeldung.index
                ];
            ENV === 'development' && console.log('anmeldung', anmeldung);

            this.props.change(
                'allowance',
                anmeldung.idZuschuss === TEILNEHMER__ZUSCHUSS__GVH ||
                anmeldung.idZuschuss === TEILNEHMER__ZUSCHUSS__4EUR
                    ? 'yes'
                    : 'no',
            );
            this.props.change('accountHolder', anmeldung.kontoinhaber);
            this.props.change('IBAN', anmeldung.IBAN);
            this.props.change('paymentReference', anmeldung.verwendungszweck);
            this.setState({ valid: true });

            // bei der Anmeldung getroffene Auswahl zum Zuschuss
            this.idZuschuss = anmeldung.idZuschuss;

            // Bearbeitung nur unter bestimmten Bedingungen erlaubt
            if (!isWorkflowEditable(anmeldung)) {
                disableForm();
            }
        } else {
            this.setState({ valid: this.validate() });
        }
    }
    componentDidUpdate(oldProps, _oldState) {
        const isAllowanceDifferent =
            this.props.formdata.allowance?.values ===
            oldProps.formdata.allowance?.values;
        if (!isAllowanceDifferent) {
            this.setState({ valid: this.validate() });
        }
    }
    validateIBAN = (value) => {
        const valid = IBAN.isValid(value);
        this.setState({ validIBAN: valid });
        return valid;
    };
    handleInput() {
        this.setState({ valid: this.validate() });
    }

    renderQuestion() {
        const isOepnv = this.isOepnv;
        const allowanceValue = this.props.formdata.allowance?.values?.allowance;
        const yesAllowance = allowanceValue === 'yes';
        const labelHeading = isOepnv
            ? 'GVH-Gruppenfahrschein'
            : 'Reisekostenzuschuss';
        const labelText = isOepnv
            ? 'Als Schule innerhalb des GVH-Verbundes haben Sie die Möglichkeit, einen GVH-Gruppenfahrschein zu beantragen. Diesen bekommen Sie rechtzeitig vor der Veranstaltung per Mail zugesendet. Hier müssen Sie vor Antritt der Fahrt die Namen aller Fahrgäste der Reisegruppe eintragen.'
            : 'Möchten Sie einen Anreisekostenzuschuss in Höhe von 4 € pro Person beantragen? (Die Zahlung erfolgt nach Veranstaltungsende auf Basis der tatsächlichen Teilnehmerzahl.)';
        const labelYes = isOepnv
            ? 'Hiermit beantragen wir einen GVH-Gruppenfahrschein.'
            : 'Hiermit beantrage ich einen Anreisekostenzuschuss.';
        const labelNo = isOepnv
            ? 'Wir benötigen keinen GVH-Gruppenfahrschein.'
            : 'Ich beantrage keinen Anreisekostenzuschuss.';

        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>{labelHeading} beantragen</h1>
                </div>
                <fieldset>
                    <legend>{labelText}</legend>
                    <div className='row'>
                        <Field
                            component='input'
                            type='radio'
                            id='radioYes'
                            name='allowance'
                            value='yes'
                            ref={this.radioYes}
                            required
                        />
                        <label htmlFor='radioYes'>
                            <span>{labelYes}</span>
                        </label>
                    </div>
                    {yesAllowance && !isOepnv && (
                        <div>
                            <label htmlFor='accountHolder'>Kontoinhaber</label>
                            <Field
                                component='input'
                                type='text'
                                name='accountHolder'
                                maxLength='100'
                                ref={this.accountHolder}
                                onChange={(_e) => this.handleInput()}
                            />
                            <label htmlFor='IBAN'>IBAN</label>
                            <Field
                                component='input'
                                type='text'
                                name='IBAN'
                                maxLength='50'
                                ref={this.Iban}
                                onChange={(_e) => this.handleInput()}
                                validate={this.validateIBAN}
                                className={classNames({
                                    invalid: !this.state.validIBAN,
                                })}
                            />
                            <label htmlFor='paymentReference'>
                                Verwendungszweck (optional)
                            </label>
                            <Field
                                component='input'
                                type='text'
                                name='paymentReference'
                                maxLength='100'
                                ref={this.paymentReference}
                                onChange={(_e) => this.handleInput()}
                            />
                        </div>
                    )}
                    <div className='row'>
                        <Field
                            component='input'
                            type='radio'
                            id='radioNo'
                            name='allowance'
                            value='no'
                            ref={this.radioNo}
                            required
                        />
                        <label htmlFor='radioNo'>
                            <span>{labelNo}</span>
                        </label>
                    </div>
                </fieldset>
                <StepButtons
                    back='/schedule'
                    to='/validate'
                    disabled={!this.state.valid}
                />
            </div>
        );
    }
}

export default reduxForm({
    form: 'allowance',
    destroyOnUnmount: false,
})(connect((s) => ({ formdata: s.form, ...s }), null)(Allowance));
