import {
    BOOTHAUTHROLE,
    WORKSHOPAUTHROLE,
    LIVEPROGRAMAUTHROLE,
    CAREERSTAGEAUTHROLE,
    CLUBZUKUNFTAUTHROLE,
    ORGANISATIONAUTHROLE,
} from '../../global_constants.js';
import { updateExhibit } from '../../actions/exhibitor/exhibit';
import { updateBoard } from '../../actions/exhibitor/board';
import { updateStele } from '../../actions/exhibitor/stele';
import { updateWorkshop } from '../../actions/exhibitor/workshop';
import { updateStage } from '../../actions/exhibitor/stageseven';
import { approveShowStage } from '../../actions/exhibitor/showstage';
import { approveCareerStage } from '../../actions/exhibitor/careerstage';

const steps = {
    home: 1,
    booths: 2,
    furnishings: 2.1,
    veranstaltungen: 3,
    workshops: 3.1,
    stageContributions: 3.2,
    boothVisits: 3.3,
    liveprogram: 4,
    stageseven: 4.1,
    showstage: 4.2,
    clubzukunft: 5,
    careerstage: 5.1,
    careermarket: 5.2,
    contactdata: 5.21,
    dualstudy: 5.25,
    studyfields: 5.36,
    organisation: 6,
    passes: 6.1,
};

// -----------------------------------------------------------------------------------
// Funktion ermittelt die Route und den Label für die nächste Seite in Abhängigkeit
// von den Rollen des angemeldeten Users
export function getForwardActionForRoles(targetPage, props) {
    let forwardStep = '';
    let forwardLabel = ' ';

    if (props.hasBoothAuthorization && steps[targetPage] <= steps.booths) {
        forwardStep = '/booths';
        forwardLabel = 'Stände';
    } else if (
        props.hasWorkshopAuthorization &&
        steps[targetPage] <= steps.veranstaltungen
    ) {
        forwardStep = '/veranstaltungen';
        forwardLabel = 'Veranstaltungen';
    } else if (
        props.hasWorkshopAuthorization &&
        steps[targetPage] <= steps.workshops
    ) {
        forwardStep = '/workshops';
        forwardLabel = 'Workshops';
    } else if (
        props.hasWorkshopAuthorization &&
        steps[targetPage] <= steps.stageContributions
    ) {
        forwardStep = '/stage-contributions';
        forwardLabel = 'Bühnenbeiträge';
    } else if (
        props.hasWorkshopAuthorization &&
        steps[targetPage] <= steps.boothVisits
    ) {
        forwardStep = '/booth-visits';
        forwardLabel = 'Standbesuche';
    } else if (
        props.hasLiveprogramAuthorization &&
        steps[targetPage] <= steps.liveprogram
    ) {
        forwardStep = '/live-program';
        forwardLabel = 'LiveProgramm';
    } else if (
        props.hasLiveprogramAuthorization &&
        props.hasLiveProgram &&
        steps[targetPage] <= steps.stageseven
    ) {
        forwardStep = '/live-program/stage-seven';
        forwardLabel = 'BühneSieben';
    } else if (
        props.hasClubzukunftAuthorization &&
        steps[targetPage] <= steps.clubzukunft
    ) {
        forwardStep = '/clubzukunft';
        forwardLabel = 'ClubZukunft';
    } else if (
        props.hasClubzukunftAuthorization &&
        props.hasCarreerStageAuthorization &&
        steps[targetPage] <= steps.careerstage
    ) {
        forwardStep = '/clubzukunft/career-stage';
        forwardLabel = 'KarriereBühne';
    } else if (
        props.hasClubzukunftAuthorization &&
        steps[targetPage] <= steps.careermarket
    ) {
        forwardStep = '/clubzukunft/career-market';
        forwardLabel = 'KarriereMarkt';
    } else if (
        props.hasClubzukunftAuthorization &&
        steps[targetPage] <= steps.contactdata
    ) {
        forwardStep = '/clubzukunft/career-market/contact';
        forwardLabel = 'Kontaktdaten';
    } else if (
        props.hasOrganisationAuthorization &&
        steps[targetPage] <= steps.organisation
    ) {
        forwardStep = '/organisation';
        forwardLabel = 'Organisation';
    } else if (
        props.hasOrganisationAuthorization &&
        steps[targetPage] <= steps.passes
    ) {
        forwardStep = '/organisation/exhibitor-passes';
        forwardLabel = 'Ausstellerausweise';
    }

    return {
        forwardAction: forwardStep,
        forwardText: forwardLabel,
    };
}

// -----------------------------------------------------------------------------------
// Funktion ermittelt die Route und den Label für die vorhergehende Seite in Abhängigkeit
// von den Rollen des angemeldeten Users. Wichtig ist hier die Prüfung in umgekehrter
// Reihenfolge und die Umkehrung der zweiten Bedingung
export function getBackwardActionForRoles(targetPage, props) {
    let backwardStep = '';
    let backwardLabel = '';

    if (
        props.hasOrganisationAuthorization &&
        steps.organisation <= steps[targetPage]
    ) {
        backwardStep = '/organisation';
        backwardLabel = 'Organisation';
    } else if (
        props.hasClubzukunftAuthorization &&
        steps.studyfields <= steps[targetPage]
    ) {
        backwardStep = '/clubzukunft/career-market/studyfields';
        backwardLabel = 'Studienrichtungen';
    } else if (
        props.hasClubzukunftAuthorization &&
        steps.dualstudy <= steps[targetPage]
    ) {
        backwardStep = '/clubzukunft/career-market/dual-study';
        backwardLabel = 'Duales Studium';
    } else if (
        props.hasClubzukunftAuthorization &&
        props.hasCarreerStageAuthorization &&
        steps.careerstage <= steps[targetPage]
    ) {
        backwardStep = '/clubzukunft/career-stage';
        backwardLabel = 'KarriereBühne';
    } else if (
        props.hasClubzukunftAuthorization &&
        steps.clubzukunft <= steps[targetPage]
    ) {
        backwardStep = '/clubzukunft';
        backwardLabel = 'ClubZukunft';
    } else if (
        props.hasLiveprogramAuthorization &&
        props.hasLiveProgram &&
        steps.showstage <= steps[targetPage]
    ) {
        backwardStep = '/live-program/show-stage';
        backwardLabel = 'ShowBühne';
    } else if (
        props.hasLiveprogramAuthorization &&
        props.hasLiveProgram &&
        steps.stageseven <= steps[targetPage]
    ) {
        backwardStep = '/live-program/stage-seven';
        backwardLabel = 'BühneSieben';
    } else if (
        props.hasLiveprogramAuthorization &&
        steps.liveprogram <= steps[targetPage]
    ) {
        backwardStep = '/live-program';
        backwardLabel = 'LiveProgramm';
    } else if (
        props.hasWorkshopAuthorization &&
        steps.boothVisits <= steps[targetPage]
    ) {
        backwardStep = '/booth-visits';
        backwardLabel = 'Standbesuche';
    } else if (
        props.hasWorkshopAuthorization &&
        steps.stageContributions <= steps[targetPage]
    ) {
        backwardStep = '/stage-contributions';
        backwardLabel = 'Bühnenbeiträge';
    } else if (
        props.hasWorkshopAuthorization &&
        steps.workshops <= steps[targetPage]
    ) {
        backwardStep = '/workshops';
        backwardLabel = 'Workshops';
    } else if (
        props.hasWorkshopAuthorization &&
        steps.veranstaltungen <= steps[targetPage]
    ) {
        backwardStep = '/veranstaltungen';
        backwardLabel = 'Veranstaltungen';
    } else if (
        props.hasBoothAuthorization &&
        steps.furnishings <= steps[targetPage]
    ) {
        backwardStep = '/booths/furnishings';
        backwardLabel = 'Mobiliar';
    } else if (
        props.hasBoothAuthorization &&
        steps.booths <= steps[targetPage]
    ) {
        backwardStep = '/booths';
        backwardLabel = 'Stände';
    } else if (props.hasBoothAuthorization && steps.home <= steps[targetPage]) {
        backwardStep = '/home';
        backwardLabel = 'Home';
    }

    return {
        backwardAction: backwardStep,
        backwardText: backwardLabel,
    };
}

// -----------------------------------------------------------------------------------
// Funktion ermittelt die Berechtigungen des angemeldeten Users
export function determineAuthForRoles(state) {
    let hasBoothAuthorization = false;
    let hasCarreerStageAuthorization = false;
    let hasClubzukunftAuthorization = false;
    let hasLiveprogramAuthorization = false;
    let hasOrganisationAuthorization = false;
    let hasWorkshopAuthorization = false;

    if ('user2role' in state) {
        const userRoles = state.user2role.map((user2Role) => user2Role.ROLLE);
        hasBoothAuthorization = userRoles.includes(BOOTHAUTHROLE);
        hasCarreerStageAuthorization = userRoles.includes(CAREERSTAGEAUTHROLE);
        hasClubzukunftAuthorization = userRoles.includes(CLUBZUKUNFTAUTHROLE);
        hasLiveprogramAuthorization = userRoles.includes(LIVEPROGRAMAUTHROLE);
        hasOrganisationAuthorization = userRoles.includes(ORGANISATIONAUTHROLE);
        hasWorkshopAuthorization = userRoles.includes(WORKSHOPAUTHROLE);
    }

    return {
        hasBoothAuthorization,
        hasCarreerStageAuthorization,
        hasClubzukunftAuthorization,
        hasLiveprogramAuthorization,
        hasOrganisationAuthorization,
        hasWorkshopAuthorization,
    };
}

// -----------------------------------------------------------------------------------
// Funktion speichert die Freigabe eines Objektes im Backend (wird aus den Übersichts-
// Seiten heraus aufgerufen)

export function approveItem(that, itemType, object) {
    // Dieses Attribut mit Wert 1 bedeutet im Backend Freigabe durch den Aussteller
    const approvableItems = [
        'exhibit',
        'board',
        'stele',
        'stageseven',
        'showbühne',
        'karrierebühne',
    ];
    const isApprovable = approvableItems.includes(itemType);
    if (isApprovable) {
        object.kzFreigabeAussteller = 1;
    } else if (itemType === 'workshop') {
        object.kzStatusFreigabeAussteller = 1;
    }

    // Den Datensatz über die Schnittstelle im Backend mit dem geänderten Freigabezustand speichern
    // Der zweite Parameter (false) bewirkt, dass in der Methode keine weiteren Werte aus der Detailmaske
    // in das JSON gemergt werden
    let status;

    switch (itemType) {
        case 'exhibit': {
            status = updateExhibit(object, false);
            break;
        }
        case 'board': {
            status = updateBoard(object, false);
            break;
        }
        case 'stele': {
            status = updateStele(object, false);
            break;
        }
        case 'workshop': {
            status = updateWorkshop(object, false);
            break;
        }
        case 'stageseven': {
            status = updateStage(object, false);
            break;
        }
        case 'showbühne': {
            status = approveShowStage(object, false);
            break;
        }
        case 'karrierebühne': {
            status = approveCareerStage(object, false);
            break;
        }
        default: {
            status = null;
            break;
        }
    }

    // Wenn das Speichern nicht funktioniert hat, muss das Kennzeichen im Frontend auch zurückgesetzt
    // werden
    Promise.resolve(status).then((status) => {
        if (status == null || status === 500) {
            if (isApprovable) {
                object.kzFreigabeAussteller = 0;
            } else if (itemType === 'workshop') {
                object.kzStatusFreigabeAussteller = 0;
            }
        }
        // Das sorgt dafür, dass die Component neu gerendert und die Freigabe hier direkt visualisiert wird
        if (that) {
            that.setState({ state: that.state });
        }
    });
}

// -----------------------------------------------------------------------------------
// Funktion legt ein Fullscreen-Overlay über die Seite. Das wird eingeblnedet, wenn ein
// Upload gemacht wird. Es wird ausgeblendet, wenn der Upload durchist. Das soll verhindern,
// dass man wegen eines größeren Uploads auf andere Siten kommt und dort während des
// Uploads weitere Aktionen auslöst. Das kann wegen der asynchronen Requests die komplette
// Anwendung so durcheinander bringen, dass nichts mehr geht.

export function blockScreen(mode) {
    if (mode === true) {
        const blocker = document.createElement('DIV');
        blocker.id = 'actionblocker';

        blocker.innerHTML =
            '<div class="spinner">\
                <div class="bounce1"></div>\
                <div class="bounce2"></div>\
                <div class="bounce3"></div>\
                Speichervorgang läuft ... <br />\
            </div>';
        document.body.appendChild(blocker);
    }

    if (mode === false) {
        const blocker = document.getElementById('actionblocker');
        if (blocker) {
            document.body.removeChild(blocker);
        }
    }
}

/**
 * Funktion ermittelt, ob in der übergebenen Menge von Checkbox-Werten mindestens die maximale Anzahl angehakt wurde.
 *
 * @param {boolean[]} checkboxValues die Menge der Checkbox-Werte
 * @param {number} maxNumber die maximale Anzahl
 * @returns false, wenn Array von Checkbox-Werten übergeben wird und die maximale Anzahl an Checkbox-Werten noch nicht erreicht ist, sonst true
 */
export function areMaximumNumberOfCheckboxesChecked(checkboxValues, maxNumber) {
    if (Array.isArray(checkboxValues)) {
        const checkedCount = checkboxValues.reduce(
            (accumulator, currentValue) =>
                currentValue ? accumulator + 1 : accumulator,
            0,
        );
        return checkedCount >= maxNumber;
    }
    return true;
}
