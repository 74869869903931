import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { StateIndicator } from '../state-indicator';
import { isBoothApproved } from '../approve-state-functions.js';

class Booth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            boothApproval: false,
        };
        this.booth = props.booth;
        this.themeWorld = props.themeWorld;
        this.onClickHandler = props.onClickHandler;
    }

    async componentDidMount() {
        if (this.booth) {
            const boothApproval = await isBoothApproved(this.booth);
            this.setState({
                ...this.state,
                boothApproval,
            });
        }
    }

    render() {
        return (
            <ul className='sixCols' key={this.booth.ID}>
                <Link
                    to={{
                        pathname: '/booths/defaultlogo/edit',
                        state: {
                            handle: this.booth.ID,
                        },
                        search: `${this.booth.ID}`,
                    }}
                    id={this.booth.ID}
                    onClick={this.onClickHandler}
                    className='no_underline'
                >
                    <li className='schoolTable booths-grid'>
                        <label>{this.booth.bezeichnung}</label>
                        <label>{this.booth.standNrFinal}</label>
                        <label>{this.themeWorld}</label>
                        <label>{this.booth.flaecheSoll}</label>
                        <label>
                            {this.booth.kzStandardlogos ? 'Ja' : 'Nein'}
                        </label>
                        <label>
                            <StateIndicator
                                state={this.state.boothApproval}
                                label=''
                            />
                        </label>
                    </li>
                </Link>
            </ul>
        );
    }
}

export default Booth;
